import { useState } from 'react';
import {
  SeatDrawAdditionalButton,
  SeatDrawButton,
} from '../2seatDrawPage/2-1studentSetting/StudentSettingStyle';
import Drawing from '../2seatDrawPage/2-4seatDrawing/Drawing';
import {
  AddButton,
  StudentSettingInput,
  CurrentCornerStudents,
  CurrentStudentSet,
  SeatDrawContentBox,
  SeatDrawDetailBox,
  SeatDrawPageContentBox,
  SeatDrawPageContentBox_left,
  SeatDrawPageContentBox_right,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleBox_right,
  SeatDrawPageTitleDetail,
  SeatDrawSecondaryButton,
  SeatDrawThirdButton,
  StudentSettingInput_wide,
  StudentSettingInput_narrow,
  CornerNum,
} from '../SeatDrawPageStyle';
import cornerSeatsImg from '../../img/cornerSeats.png';
import adjacentSeatsImg from '../../img/adjacentSeats.png';

const SeatBeforeSetting = ({
  num1,
  setNum1,
  num2,
  setNum2,
  handleSetAdjacentNums,
  adjacentNums,
  handleRemoveAdjacentNum,
  saveAdjacentNums,
  // inputSeparateNums,
  // handleSeparateInputChange,
  // handleAddSeparateNums,
  separateNums,
  saveSeparateNums,
  inputCornerNums,
  handleCornerInputChange,
  handleAddCornerNums,
  cornerNums,
  saveCornerNums,
  setCornerNums,
  fetchAdjacentNums,
  isPreAssignMode,
  setIsPreAssignMode,
  fetchCornerNums,
  handleRemoveCornerNum,
  handleKeyPressAdjacent,
  handleKeyPressCorner,
}) => {
  const [isEditCornerNumMode, setIsEditCornerNumMode] = useState(true);
  const [isEditAdjacentNumMode, setIsEditAdjacentNumMode] = useState(true);
  return (
    <>
      <SeatDrawContentBox>
        <SeatDrawPageTitleBox>
          <SeatDrawPageTitleBox_left>
            <SeatDrawPageTitle>구석에 앉을 학생들</SeatDrawPageTitle>
            <SeatDrawPageTitleDetail>
              지나친 수업 방해 행동을 하는 학생들을 교실 앞, 뒤 끝쪽에 배치하여
              수업 분위기를 유지할 수 있습니다.
            </SeatDrawPageTitleDetail>
          </SeatDrawPageTitleBox_left>
          <SeatDrawPageTitleBox_right>
            {isEditCornerNumMode ? (
              <>
                <SeatDrawSecondaryButton
                  onClick={() => {
                    setCornerNums('');
                  }}
                >
                  번호 리셋
                </SeatDrawSecondaryButton>
                <SeatDrawSecondaryButton
                  onClick={() => {
                    fetchCornerNums();
                  }}
                >
                  불러오기
                </SeatDrawSecondaryButton>
                <SeatDrawButton onClick={() => saveCornerNums(cornerNums)}>
                  저장하기
                </SeatDrawButton>
              </>
            ) : (
              <SeatDrawButton
                onClick={() => setIsEditCornerNumMode(!isEditCornerNumMode)}
              >
                수정하기
              </SeatDrawButton>
            )}
          </SeatDrawPageTitleBox_right>
        </SeatDrawPageTitleBox>{' '}
        {isEditCornerNumMode && (
          <>
            <SeatDrawPageContentBox>
              <SeatDrawPageContentBox_left>
                <img
                  src={cornerSeatsImg}
                  style={{ width: '200px', height: 'auto' }}
                />
              </SeatDrawPageContentBox_left>
              <SeatDrawPageContentBox_right>
                <CurrentStudentSet>
                  {/* 현재 구석에 앉을 학생들 : {cornerNums.join(', ')} */}
                  현재 구석에 앉을 학생들(클릭 시 삭제)&nbsp;:&nbsp;
                  {cornerNums &&
                    [...cornerNums]
                      .sort((a, b) => a - b)
                      .map((num, index) => (
                        <CornerNum
                          key={index}
                          onClick={() => handleRemoveCornerNum(num)}
                        >
                          {num}
                        </CornerNum>
                      ))}
                  {/* // .reduce((prev, curr) => [prev, ', ', curr])} */}
                </CurrentStudentSet>
                <div>
                  <StudentSettingInput_wide
                    type="text"
                    value={inputCornerNums}
                    onChange={handleCornerInputChange}
                    onKeyPress={handleKeyPressCorner}
                    placeholder="번호를 콤마로 구분하여 입력"
                  />
                  <SeatDrawThirdButton onClick={handleAddCornerNums}>
                    추가
                  </SeatDrawThirdButton>
                </div>
              </SeatDrawPageContentBox_right>
            </SeatDrawPageContentBox>

            <SeatDrawDetailBox>
              <div>📌최대 4명의 학생까지 등록가능합니다.</div>
              <div>
                📌학생들은 A,B,C,D 구역 중 각기 다른 영역에 랜덤 배치되며, 구역
                내 3개 자리 중 1개에 랜덤 배정됩니다.
              </div>{' '}
              <div>
                📌수업 방해 행동을 하는 학생이 없다면 설정하지 않아도 됩니다.
              </div>
            </SeatDrawDetailBox>
          </>
        )}
      </SeatDrawContentBox>
      <SeatDrawContentBox>
        <SeatDrawPageTitleBox>
          <SeatDrawPageTitleBox_left>
            <SeatDrawPageTitle>서로 가까이 앉을 학생들</SeatDrawPageTitle>
            <SeatDrawPageTitleDetail>
              도움이 필요한 학생들이 서로 가까이 앉도록 할 수 있습니다.
            </SeatDrawPageTitleDetail>
          </SeatDrawPageTitleBox_left>
          <SeatDrawPageTitleBox_right>
            {isEditAdjacentNumMode ? (
              <>
                <SeatDrawButton onClick={() => saveAdjacentNums(adjacentNums)}>
                  저장하기
                </SeatDrawButton>
                {/* <SeatDrawButton
                  onClick={() => {
                    setIsEditAdjacentNumMode(!isEditAdjacentNumMode);
                  }}
                >
                  취소하기
                </SeatDrawButton> */}
              </>
            ) : (
              <SeatDrawButton
                onClick={() => {
                  setIsEditAdjacentNumMode(!isEditAdjacentNumMode);
                }}
              >
                수정하기
              </SeatDrawButton>
            )}
          </SeatDrawPageTitleBox_right>
        </SeatDrawPageTitleBox>
        {isEditAdjacentNumMode && (
          <>
            <SeatDrawPageContentBox>
              <SeatDrawPageContentBox_left>
                <img
                  src={adjacentSeatsImg}
                  style={{ width: '200px', height: 'auto' }}
                />
              </SeatDrawPageContentBox_left>
              <SeatDrawPageContentBox_right>
                <CurrentStudentSet>
                  현재 가까이 앉을 학생(클릭 시 삭제)&nbsp;:&nbsp;
                  {adjacentNums.map((pair, index) => (
                    <CornerNum
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRemoveAdjacentNum(pair)}
                    >
                      {pair.join(', ')}
                    </CornerNum>
                  ))}
                </CurrentStudentSet>
                <div>
                  <StudentSettingInput_narrow
                    type="number"
                    value={num1}
                    onChange={(e) => setNum1(e.target.value)}
                    onKeyPress={handleKeyPressAdjacent}
                    placeholder="첫 번째 번호"
                  />
                  <StudentSettingInput_narrow
                    type="number"
                    value={num2}
                    onChange={(e) => setNum2(e.target.value)}
                    onKeyPress={handleKeyPressAdjacent}
                    placeholder="두 번째 번호"
                  />
                  <SeatDrawThirdButton onClick={handleSetAdjacentNums}>
                    추가
                  </SeatDrawThirdButton>
                </div>
              </SeatDrawPageContentBox_right>
            </SeatDrawPageContentBox>

            <SeatDrawDetailBox>
              <div>
                📌입력한 쌍은 서로 인접한 자리에 배치됩니다. 1명 랜덤 배치 후
                나머지 1명은 앞,뒤,양,옆 중 한 곳에 배치됩니다.
              </div>
              <div>📌여러개의 쌍을 입력할 수 있습니다.</div>{' '}
            </SeatDrawDetailBox>
          </>
        )}
      </SeatDrawContentBox>
    </>
  );
};

export default SeatBeforeSetting;
