import React, { useState, useEffect, useContext, useCallback } from 'react';
import UserContext from '../../../contexts/userContext';
import axios from 'axios';
import StudentSettingContainer from './StudentSettingContainer';

const StudentSettingComponent = ({
  numberList,

  notUsingNumbers,
  setNumberList,
  setNotUsingNumbers,
  frontNums,
  setFrontNums,
  setIsDrawSettingOpen,
  genderMode,
  setGenderMode,
  seats,
  blockedSeats,
  maleSeatsNum,
  femaleSeatsNum,
  localGenderMap,
  setLocalGenderMap,
  availableSeats,
  actualStudentCount,
  femaleCount,
  maleCount,
  saveStudentInfo,
  fetchStudentInfo,
  adjacentNums,
  cornerNums,
  // preAssignment,
  setAdjacentNums,
  setCornerNums,
  // setPreAssignment,
  preAssigningSeats,
  setPreAssigningSeats,
  seatPreAssignments,
  setSeatPreAssignments,
  setMaleCount,
  setFemaleCount,
  openRegisterModal,
  loadFrontNums,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  const [genderSelectionMode, setGenderSelectionMode] = useState(null); // 현재 남녀 선택 모드

  return (
    <>
      <StudentSettingContainer
        localGenderMap={localGenderMap}
        setLocalGenderMap={setLocalGenderMap}
        genderSelectionMode={genderSelectionMode}
        notUsingNumbers={notUsingNumbers}
        setNotUsingNumbers={setNotUsingNumbers}
        numberList={numberList}
        setNumberList={setNumberList}
        setGenderSelectionMode={setGenderSelectionMode}
        saveStudentInfo={saveStudentInfo}
        fetchStudentInfo={fetchStudentInfo}
        frontNums={frontNums}
        setFrontNums={setFrontNums}
        setIsDrawSettingOpen={setIsDrawSettingOpen}
        genderMode={genderMode}
        setGenderMode={setGenderMode}
        seats={seats}
        blockedSeats={blockedSeats}
        availableSeats={availableSeats}
        actualStudentCount={actualStudentCount}
        maleSeatsNum={maleSeatsNum}
        femaleSeatsNum={femaleSeatsNum}
        femaleCount={femaleCount}
        maleCount={maleCount}
        adjacentNums={adjacentNums}
        cornerNums={cornerNums}
        // preAssignment={preAssignment}
        setAdjacentNums={setAdjacentNums}
        setCornerNums={setCornerNums}
        // setPreAssignment={setPreAssignment}
        preAssigningSeats={preAssigningSeats}
        setPreAssigningSeats={setPreAssigningSeats}
        setSeatPreAssignments={setSeatPreAssignments}
        seatPreAssignments={seatPreAssignments}
        setMaleCount={setMaleCount}
        setFemaleCount={setFemaleCount}
        openRegisterModal={openRegisterModal}
        loadFrontNums={loadFrontNums}
      />
    </>
  );
};

export default StudentSettingComponent;
