import styled from 'styled-components';
import {
  colors,
  fontSize,
  gray,
  height,
  margin,
  padding,
  pageWidth,
  ratio,
} from '../styles/commonStyle';
import { media } from '../styles/media';
import { FaCommentDots } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaBloggerB } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

export const PageContainerBox = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DrawPageContainerBox = styled(PageContainerBox)`
  ${media.mobile`
      display: none;  `}
`;

export const PageHeader = styled.header`
  width: 100%;
  border-bottom: 1px solid ${gray.light};

  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: ${colors.background};
  z-index: 999 !important;

  ${media.mobile`
      display: none;  `}
`;

export const PageSubheader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${gray.light};

  ${media.mobile`
      border-bottom: none;
  `}
  display: flex;
  justify-content: center;
  position: fixed;
  top: ${margin.top1};
  // top:header높이 +1
  // background-color: ${colors.background};
  z-index: 888 !important;
`;

export const PageMain = styled.main`
  flex: 1;

  ${media.desktop`
  margin-top: ${margin.top2};
  `}

  ${media.tablet`
    margin-top: ${margin.top2Tablet};
 `}

  ${media.mobile`
    margin-top: ${margin.top1};
    margin-bottom: ${margin.bottomMobile};
  `}
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: red;
`;

export const PageBox = styled.div`
  // background-color: yellow;
  ${media.desktop`
width: ${pageWidth.desktop};  
margin: ${margin.large} 0px;
  padding: ${padding.medium};
  `}

  ${media.tablet`
width: ${pageWidth.tablet};
  margin: ${margin.large} 0px;
    padding: ${padding.medium};
      `}

  ${media.mobile`
  width:90%;
    margin-bottom  :${margin.bottomMobile};

  `}
`;

export const ProgressPageBox = styled.div`
  ${media.desktop`
// width: ${pageWidth.desktop};  
margin: ${margin.large} 0px ${margin.small} 0px;
  padding: ${padding.medium};
  `}

  ${media.tablet`
// width: ${pageWidth.tablet};
margin: ${margin.large} 0px ${margin.small} 0px;
    padding: ${padding.medium};

      `}

  ${media.mobile`
    width:90%;
    margin-bottom  :${margin.bottomMobile};
  `}
`;

export const PageFooter = styled.footer`
  width: 100%;
  background-color: ${gray.extralight};
  display: flex;
  justify-content: center;
`;

export const ButtomLayout = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6ce;
`;
export const MainPageBannerContainer = styled.div`
  width: 92%;
  height: 60px;
  position: fixed;
  bottom: 0;
`;

// -------------------- 오른쪽 하단 아이콘들  -------------------- //

export const IconContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FeedbackButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${colors.lightGreen};
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: ${colors.lightGreenDark};
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const FeedbackIcon = styled(FaCommentDots)`
  width: 30px;
  height: 30px;
`;

export const SNSIcon = styled(FaTwitter)`
  width: 30px;
  height: 30px;
`;

export const InstagramIcon = styled(FaInstagram)`
  width: 30px;
  height: 30px;
`;

export const BlogIcon = styled(FaBloggerB)`
  width: 30px;
  height: 30px;
`;
