import React from 'react';
import { useNavigate } from '../../node_modules/react-router-dom/dist/index';
import { FaDesktop } from 'react-icons/fa';
import Tooltip from './Tooltip';
import LeftTooltip from './LeftTooltip';

//top menu bar에서 쓰는 것
const DownloadProgramDiv = ({}) => {
  const navigate = useNavigate();
  // const handleDownload = () => {
  //   const fileUrl = 'https://teachernote.me/desktop-program/teachernote.zip';
  //   window.open(fileUrl, '_blank');
  //   // setShowTopMenuModal(false);
  // };

  return (
    <div
      onClick={() => {
        navigate('/useOnDesktop');
      }}
    >
      <LeftTooltip text="데스크탑에서 이용하는 방법">
        <FaDesktop />
      </LeftTooltip>

      {/* 데스크탑에서 이용하기 */}
    </div>
  );
};

export default DownloadProgramDiv;
