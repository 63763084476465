import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { HelmetContent } from '../components/HelmetContent';
import HeaderMenuBar from './HeaderMenuBar';
import {
  PageMain,
  PageContainerBox,
  PageHeader,
  PageFooter,
  FeedbackButton,
  FeedbackIcon,
  IconContainer,
  SNSIcon,
  InstagramIcon,
} from './PageContainerStyle';
import Footer from './Footer';
import HeaderMenuBarMobile from './HeaderMenuBarMobile';
import MobileBottomMenuBar from './MobileBottomMenuBar';
import MobileTopMenuBar from '../topMenuBarMobile/MobileTopMenuBar';
import {
  useLocation,
  useNavigate,
} from '../../node_modules/react-router-dom/dist/index';
import RegisterModalComponent from '../pages/registerModal/RegisterModalComponent';
import LoginModalComponent from '../pages/loginModal/LoginModalComponent';

const PageContainer = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();

  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton');

  // -------------------- 설정 저장->회원가입/로그인 모달  -------------------- //

  // register 창을 띄우고 가입 완료 시 콜백을 실행하는 함수

  const openRegisterModal = () => {
    console.log('openRegisterModal');
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    // console.log('openLoginModal');
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  // -------------------- 유저 코멘트 아이콘  -------------------- //

  const navigate = useNavigate();

  const goToInsta = () => {
    navigate('/bloglist/insta');
  };
  const goToUserComment = () => {
    navigate('/usercomment');
  };
  return (
    <PageContainerBox>
      <HelmetContent />

      <PageHeader>
        {!isMobile && (
          <HeaderMenuBar
            isRegisterModalOpen={isRegisterModalOpen}
            setRegisterModalOpen={setRegisterModalOpen}
            isLoginModalOpen={isLoginModalOpen}
            setLoginModalOpen={setLoginModalOpen}
            modalSource={modalSource}
            setModalSource={setModalSource}
          />
        )}
      </PageHeader>
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}
      <PageMain>{children}</PageMain>
      {isMobile && <MobileBottomMenuBar />}
      {location.pathname !== '/login' &&
        location.pathname !== '/register' &&
        location.pathname !== '/progress' && (
          <PageFooter>
            <Footer />
          </PageFooter>
        )}
      {!isMobile && (
        <IconContainer>
          <FeedbackButton onClick={goToInsta}>
            <InstagramIcon />
          </FeedbackButton>
          <FeedbackButton onClick={goToUserComment}>
            <FeedbackIcon />
          </FeedbackButton>
        </IconContainer>
      )}
    </PageContainerBox>
  );
};

export default PageContainer;
