import styled from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const AuthBox = styled.div`
  width: 100%;

  ${media.mobile`
      margin-top: -${margin.top1};
    `}

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AuthLayout1 = styled.div`
  padding-bottom: ${padding.large};

  font-size: ${fontSize.extraLarge};
  font-weight: ${fontWeight.bold};
  color: ${colors.primary};
  text-align: center;

  ${media.mobile`
  font-size: ${fontSize.categoryLarge};
  padding:${padding.large} 0;
  `}
`;
export const AuthLayout2 = styled.div``;
export const AuthLayout3 = styled.div``;
