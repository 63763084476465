import { useCallback, useEffect, useRef, useState } from 'react';
import PreAssignment from './PreAssignment';

const PreAssignmentContainer = ({
  setSeats,
  setBlockedSeats,
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  //   handleSeatClick,
  //   handlePreAssignInput,
  setGenderSeatRule,
  setPreAssigningSeats,
  numberList,
  notUsingNumbers,
  setNumberList,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
  seatPreAssignments,
  setSeatPreAssignments,
  fetchPreAssignments,
  cornerNums,
  adjacentNums,
  setCornerNums,
  setAdjacentNums,
}) => {
  const [isPreAssignMode, setIsPreAssignMode] = useState(true);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedSeat(null);
      }
    };

    if (selectedSeat) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSeat]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSelectedSeat(null);
        // inputRef.current이 존재하고 selectedSeat이 있을 때 handlePreAssignInput 호출
        if (selectedSeat) {
          handlePreAssignInput({ target: inputRef.current });
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedSeat]);

  // --------------------  사전 자리 세팅  -------------------- //
  // useEffect(() => {
  //   console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
  // }, [preAssigningSeats]);
  //미리 번호 배치하는 모드면 번호 배치, 그게 아니면 사용 여부 X 토글
  const handleSeatClick = (rowIdx, colIdx) => {
    const seatKey = `${rowIdx}-${colIdx}`;

    if (isPreAssignMode) {
      if (blockedSeats.has(seatKey)) {
        return; // blockedSeats에 포함된 좌석이면 클릭 무시
      }

      // seatPreAssignments에 미리 배치된 번호가 있으면 삭제
      if (seatPreAssignments[seatKey] || preAssigningSeats[seatKey]) {
        const updatedPreAssignedSeats = { ...seatPreAssignments };
        delete updatedPreAssignedSeats[seatKey];
        setPreAssigningSeats(updatedPreAssignedSeats);
        setSeatPreAssignments(updatedPreAssignedSeats);

        return;
      }

      // preAssigningSeats에 이미 번호가 설정되어 있는 경우 해당 번호 삭제
      // if (preAssigningSeats[seatKey]) {
      //   const updatedPreAssignedSeats = { ...preAssigningSeats };
      //   delete updatedPreAssignedSeats[seatKey];
      //   setSeatPreAssignments(updatedPreAssignedSeats);
      //   return;
      // }

      // 클릭한 자리에 번호 배치
      setSelectedSeat(seatKey);
    } else {
      // 다른 모드일 때 동작 (필요 시 추가)
    }
  };

  //미리 번호 배치
  const handlePreAssignInput = (event) => {
    const { value } = event.target;

    if (value.trim() === '') {
      return;
    }

    if (selectedSeat) {
      const [rowIdx, colIdx] = selectedSeat.split('-').map(Number);
      const seatGender = genderMap[value];

      const isGenderMismatch = genderSeatRule
        ? (seatGender === '여' && colIdx % 2 === 0) ||
          (seatGender === '남' && colIdx % 2 !== 0)
        : (seatGender === '여' && colIdx % 2 !== 0) ||
          (seatGender === '남' && colIdx % 2 === 0);

      const isAlreadyAssigned =
        Object.values(preAssigningSeats).includes(value) ||
        Object.values(seatPreAssignments).includes(value);

      const isNotUsingNumber = notUsingNumbers.includes(parseInt(value));

      // 입력한 번호가 cornerNums 또는 adjacentNums에 포함된 경우
      const isInCornerNums = cornerNums.includes(parseInt(value));
      const isInAdjacentNums = adjacentNums.some((pair) =>
        pair.includes(parseInt(value)),
      );

      if (isInCornerNums || isInAdjacentNums) {
        const confirmRemoval = window.confirm(
          '기존 등록 내역을 삭제하고 사용하지 않는 번호로 설정하시겠습니까?',
        );

        if (confirmRemoval) {
          if (isInCornerNums) {
            setCornerNums((prevCornerNums) =>
              prevCornerNums.filter((num) => num !== parseInt(value)),
            );
          }

          if (isInAdjacentNums) {
            setAdjacentNums((prevAdjacentNums) =>
              prevAdjacentNums.filter(
                (pair) => !pair.includes(parseInt(value)),
              ),
            );
          }
        } else {
          setSelectedSeat(null); // 사용자가 취소를 선택하면 입력 상태 종료
          return;
        }
      }

      // 미리 배치된 번호의 수가 4개를 초과하지 않도록 제한
      const assignedSeatCount = Object.keys(seatPreAssignments).filter(
        (key) => seatPreAssignments[key] !== null,
      ).length;

      if (assignedSeatCount >= 4) {
        alert('미리 배치할 수 있는 번호는 최대 4개입니다.');
        setSelectedSeat(null);
        return;
      }

      if (seatGender && isGenderMismatch && genderMode) {
        alert('선택한 번호의 성별과 자리가 맞지 않습니다.');
        setSelectedSeat(null); // 입력 상태 종료
      } else if (isNotUsingNumber) {
        alert(
          '사용할 수 없는 번호입니다. 학생 설정에서 X표시를 확인해주세요. ',
        );
        setSelectedSeat(null); // 입력 상태 종료
      } else if (isAlreadyAssigned) {
        const confirmChange = window.confirm(
          '이미 배치된 번호입니다. 이 자리로 바꾸시겠습니까?',
        );
        if (confirmChange) {
          const updatedPreAssignedSeats = Object.fromEntries(
            Object.entries(preAssigningSeats).map(([key, val]) =>
              val === value ? [key, null] : [key, val],
            ),
          );

          // 기존 seatPreAssignments에서 해당 값을 삭제
          const updatedSeatAssignments = Object.fromEntries(
            Object.entries(seatPreAssignments).map(([key, val]) =>
              val === value ? [key, null] : [key, val],
            ),
          );

          setPreAssigningSeats({
            ...updatedPreAssignedSeats,
            [selectedSeat]: value,
          });

          setSeatPreAssignments(updatedSeatAssignments); // seatPreAssignments 업데이트
        }
        setSelectedSeat(null); // 입력 상태 종료
      } else {
        setPreAssigningSeats((prev) => ({
          ...prev,
          [selectedSeat]: value,
        }));
        setSelectedSeat(null);
      }
    }
  };

  //자리 리셋
  const handleReset = () => {
    setPreAssigningSeats({});
    setSeatPreAssignments({});
  };

  //자리 불러오기
  const handleLoad = () => {
    setBlockedSeats(blockedSeats);
    fetchPreAssignments();
  };

  return (
    <>
      <PreAssignment
        pairMode={pairMode}
        genderMode={genderMode}
        setIsPreAssignMode={setIsPreAssignMode}
        isPreAssignMode={isPreAssignMode}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        seats={seats}
        blockedSeats={blockedSeats}
        preAssigningSeats={preAssigningSeats}
        genderMap={genderMap}
        genderSeatRule={genderSeatRule}
        selectedSeat={selectedSeat}
        handleSeatClick={handleSeatClick}
        handlePreAssignInput={handlePreAssignInput}
        savePreAssignment={savePreAssignment}
        inputRef={inputRef}
        seatPreAssignments={seatPreAssignments}
        handleReset={handleReset}
        handleLoad={handleLoad}
      />
    </>
  );
};
export default PreAssignmentContainer;
