import { Helmet } from 'react-helmet';

export const HelmetContent = () => {
  return (
    <Helmet>
      <meta
        name="description"
        content="초대박 인기 어플 - 진도기록, 수업기록 관리를 한 번에! 티처노트"
      />
      <meta
        property="og:title"
        content="티처노트 - 하루를 함께하는 선생님 노트"
      />
      <meta
        property="og:description"
        content="초대박 인기 어플 - 진도기록, 수업기록 관리를 한 번에! 티처노트"
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
    </Helmet>
  );
};
