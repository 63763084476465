import React from 'react';
import styled from 'styled-components';
import { colors, gray } from '../styles/commonStyle';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  z-index: 10;
  cursor: pointer;

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipText = styled.div`
  visibility: hidden;
  opacity: 0;
  min-width: 150px;
  width: auto;
  white-space: nowrap;

  padding: 10px;
  bottom: 50%; /* Tooltip을 왼쪽에 배치 */
  right: 110%; /* Tooltip을 왼쪽에 배치 */
  transform: translateY(50%); /* 수직 정렬 조정 */

  background-color: ${colors.background};
  color: ${colors.lightGreen};
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -14px; /* 화살표를 툴팁의 오른쪽에 배치 */
    transform: translateY(-50%);
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent transparent ${colors.background}; /* 화살표의 배경색 */
  }
`;

const LeftTooltip = ({ text, children }) => (
  <TooltipContainer>
    {children}
    <TooltipText>{text}</TooltipText>
  </TooltipContainer>
);

export default LeftTooltip;
