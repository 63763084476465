import { useEffect, useState } from 'react';

import StudentSeatSetting from './StudentSeatSetting';

const StudentSeatSettingContainer = ({
  // resetSeats,
  //붙을 학생
  adjacentNums,
  setAdjacentNums,
  saveAdjacentNums,
  //떨어질 학생
  separateNums,
  setSeparateNums,
  saveSeparateNums,
  //구석 자리
  cornerNums,
  setCornerNums,
  saveCornerNums,
  preAssigningSeats,
  setPreAssigningSeats,
  fetchAdjacentNums,
  fetchCornerNums,
  numberList,
  notUsingNumbers,
  seatPreAssignments,
  setSeatPreAssignments,
}) => {
  useEffect(() => {
    // console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
    // console.log('preAssigningSeats:' + Array.from(preAssigningSeats));
  }, [preAssigningSeats]);

  // -------------------- 가까이 앉을 학생들 -------------------- //
  const [num1, setNum1] = useState('');
  const [num2, setNum2] = useState('');

  const handleSetAdjacentNums = () => {
    if (num1 === num2) {
      alert(
        '똑같은 두개의 번호를 설정할 수 없습니다. 서로 다른 두 개의 번호를 입력해주세요.',
      );
      setNum1('');
      setNum2('');
      return; // 함수 실행을 중지합니다.
    }

    // num1과 num2가 numberList에 포함되어 있는지 확인
    if (
      !numberList.includes(parseInt(num1)) ||
      !numberList.includes(parseInt(num2))
    ) {
      alert(
        '입력된 번호 중 존재하지 않는 번호가 있습니다. 학생 설정의 학생 번호를 확인해주세요.',
      );
      setNum1('');
      setNum2('');
      return; // 함수 실행을 중지합니다.
    }

    // num1과 num2가 notUsingNumbers에 포함되어 있는지 확인

    if (
      notUsingNumbers.includes(parseInt(num1)) ||
      notUsingNumbers.includes(parseInt(num2))
    ) {
      alert('사용할 수 없는 번호입니다. 학생 설정의 X표시를 확인해주세요.');
      setNum1('');
      setNum2('');
      return; // 함수 실행을 중지합니다.
    }

    // num1 또는 num2가 cornerNums에 포함되어 있는지 확인
    const isNum1InCorner = cornerNums.includes(parseInt(num1));
    const isNum2InCorner = cornerNums.includes(parseInt(num2));

    if (isNum1InCorner || isNum2InCorner) {
      const confirmMessage = `집중이 필요한 학생들에 이미 등록된 번호입니다. 기존 내역을 삭제하고 가까이 앉을 학생으로 등록하시겠습니까?`;
      if (window.confirm(confirmMessage)) {
        // 사용자가 yes를 선택하면 해당 번호를 cornerNums에서 삭제
        const updatedCornerNums = cornerNums.filter(
          (num) => num !== parseInt(num1) && num !== parseInt(num2),
        );
        setCornerNums(updatedCornerNums);
      } else {
        setNum1('');
        setNum2('');
        return; // 함수 실행을 중지합니다.
      }
    }

    // num1 또는 num2가 이미 adjacentNums에 포함되어 있는지 확인
    const isAlreadyAdjacent = adjacentNums.some(
      (pair) => pair.includes(parseInt(num1)) || pair.includes(parseInt(num2)),
    );

    if (isAlreadyAdjacent) {
      alert(
        '입력된 번호 중 하나 이상이 이미 가까이 앉을 학생으로 등록되어 있습니다.',
      );
      setNum1('');
      setNum2('');
      return; // 함수 실행을 중지합니다.
    }

    // 미리 배치된 자리인지 확인
    const num1Assigned = Object.values(preAssigningSeats).includes(
      String(num1),
    );
    const num2Assigned = Object.values(preAssigningSeats).includes(
      String(num2),
    );
    const num1InSeatAssignments = Object.values(seatPreAssignments).includes(
      String(num1),
    );
    const num2InSeatAssignments = Object.values(seatPreAssignments).includes(
      String(num2),
    );

    if (
      num1Assigned ||
      num2Assigned ||
      num1InSeatAssignments ||
      num2InSeatAssignments
    ) {
      const confirmMessage =
        '이미 배정된 자리입니다. 미리 배치된 내역을 삭제하고 진행하시겠습니까?';
      if (window.confirm(confirmMessage)) {
        // 사용자가 yes를 선택하면 해당 번호를 preAssigningSeats 및 seatPreAssignments에서 삭제
        const updatedPreAssigningSeats = Object.fromEntries(
          Object.entries(preAssigningSeats).map(([key, val]) =>
            val === String(num1) || val === String(num2)
              ? [key, null]
              : [key, val],
          ),
        );
        const updatedSeatAssignments = Object.fromEntries(
          Object.entries(seatPreAssignments).map(([key, val]) =>
            val === String(num1) || val === String(num2)
              ? [key, null]
              : [key, val],
          ),
        );

        setPreAssigningSeats(updatedPreAssigningSeats);
        setSeatPreAssignments(updatedSeatAssignments);
      } else {
        setNum1('');
        setNum2('');
        return; // 함수 실행을 중지합니다.
      }
    }

    if (adjacentNums.length >= 3) {
      alert('가까이 앉을 학생들은 최대 3쌍까지 설정할 수 있습니다.');
      setNum1('');
      setNum2('');
      return; // 함수 실행을 중지합니다.
    }

    if (num1 && num2) {
      const parsedNum1 = parseInt(num1);
      const parsedNum2 = parseInt(num2);
      const newPair = [
        Math.min(parsedNum1, parsedNum2),
        Math.max(parsedNum1, parsedNum2),
      ];

      const exists = adjacentNums.some(
        (pair) => pair[0] === newPair[0] && pair[1] === newPair[1],
      );

      if (cornerNums.includes(parsedNum1) && cornerNums.includes(parsedNum2)) {
        // alert('코너에 앉을 학생들끼리는 가까이 앉을 수 없습니다.');
      } else if (!exists) {
        const updatedAdjacentNums = [...adjacentNums, newPair].sort(
          (a, b) => a[0] - b[0] || a[1] - b[1],
        );
        setAdjacentNums(updatedAdjacentNums);
        setNum1('');
        setNum2('');
      } else {
        alert('이미 존재하는 쌍입니다.');
      }
    } else {
      alert('두 번호를 모두 입력해주세요.');
    }
  };

  const handleRemoveAdjacentNum = (pairToRemove) => {
    const updatedAdjacentNums = adjacentNums.filter(
      (pair) => pair[0] !== pairToRemove[0] || pair[1] !== pairToRemove[1],
    );
    setAdjacentNums(updatedAdjacentNums);
  };

  // -------------------- 구석에 앉을 학생들 -------------------- //
  const [inputCornerNums, setInputCornerNums] = useState('');

  const handleCornerInputChange = (e) => {
    setInputCornerNums(e.target.value);
  };
  const handleAddCornerNums = () => {
    const nums = inputCornerNums
      .split(',')
      .map(Number)
      .filter((num) => !isNaN(num));

    // 유효한 번호인지 확인
    const invalidNumbers = nums.filter((num) => !numberList.includes(num));
    if (invalidNumbers.length > 0) {
      alert(
        `${invalidNumbers.join(
          ', ',
        )}번은 없는 번호입니다. 학생 설정의 번호를 확인해주세요.`,
      );
      setInputCornerNums('');
      return; // 함수 실행을 중지합니다.
    }

    // 사용할 수 없는 번호인지 확인
    const notAllowedNumbers = nums.filter((num) =>
      notUsingNumbers.includes(num),
    );
    if (notAllowedNumbers.length > 0) {
      alert(
        ` ${notAllowedNumbers.join(
          ', ',
        )}번은 사용할 수 없는 번호입니다. 학생 설정의 X표시를 확인해주세요.`,
      );
      setInputCornerNums('');
      return; // 함수 실행을 중지합니다.
    }

    // 가까이 앉을 학생들에 이미 등록된 번호인지 확인
    const adjacentPairs = adjacentNums.flat();
    const overlappingPairs = nums.filter((num) => adjacentPairs.includes(num));

    if (overlappingPairs.length > 0) {
      const confirmMessage = `가까이 앉을 학생들에 이미 등록된 번호입니다. 기존 내역을 삭제하고 집중이 필요한 학생으로 등록하시겠습니까?`;
      if (window.confirm(confirmMessage)) {
        // 사용자가 yes를 선택하면 해당 번호를 adjacentNums에서 삭제
        const updatedAdjacentNums = adjacentNums.filter(
          (pair) =>
            !overlappingPairs.includes(pair[0]) &&
            !overlappingPairs.includes(pair[1]),
        );
        setAdjacentNums(updatedAdjacentNums);
      } else {
        setInputCornerNums('');
        return; // 함수 실행을 중지합니다.
      }
    }

    // 이미 배치된 번호인지 확인
    const alreadyAssignedNums = nums.filter(
      (num) =>
        Object.values(preAssigningSeats).includes(String(num)) ||
        Object.values(seatPreAssignments).includes(String(num)),
    );

    if (alreadyAssignedNums.length > 0) {
      const confirmMessage = `${alreadyAssignedNums.join(
        ', ',
      )}번은 이미 배정된 자리입니다. 미리 배치된 내역을 삭제하고 진행하시겠습니까?`;
      if (window.confirm(confirmMessage)) {
        // 사용자가 yes를 선택하면 해당 번호를 preAssigningSeats 및 seatPreAssignments에서 삭제
        const updatedPreAssigningSeats = Object.fromEntries(
          Object.entries(preAssigningSeats).map(([key, val]) =>
            alreadyAssignedNums.includes(Number(val))
              ? [key, null]
              : [key, val],
          ),
        );
        const updatedSeatAssignments = Object.fromEntries(
          Object.entries(seatPreAssignments).map(([key, val]) =>
            alreadyAssignedNums.includes(Number(val))
              ? [key, null]
              : [key, val],
          ),
        );

        setPreAssigningSeats(updatedPreAssigningSeats);
        setSeatPreAssignments(updatedSeatAssignments);
      } else {
        setInputCornerNums('');
        return; // 함수 실행을 중지합니다.
      }
    }

    const combinedNums = [...new Set([...cornerNums, ...nums])];
    const validNums = combinedNums.filter((num) => !isNaN(num));

    if (validNums.length >= 1 && validNums.length <= 4) {
      setCornerNums(validNums);
      setInputCornerNums('');
    } else {
      alert('번호는 1개에서 4개까지 입력할 수 있습니다.');
      setInputCornerNums('');
    }

    // const combinedNums = [...new Set([...cornerNums, ...nums])];
    // const validNums = combinedNums.filter((num) => !isNaN(num));

    // if (
    //   validNums.some((num) =>
    //     Object.values(preAssigningSeats).includes(String(num)),
    //   )
    // ) {
    //   alert('이미 배정된 자리입니다. 미리 배치된 내역을 확인해주세요.');
    //   setInputCornerNums('');
    // } else if (overlappingPairs.length >= 2) {
    //   alert(
    //     '이미 가까이 앉을 학생들에 등록된 번호입니다. 번호를 수정하거나 가까이 앉을 학생들 목록에서 삭제해주세요.',
    //   );
    // } else if (validNums.length >= 1 && validNums.length <= 4) {
    //   setCornerNums(validNums);
    //   setInputCornerNums('');
    // } else {
    //   alert('번호는 1개에서 4개까지 입력할 수 있습니다.');
    //   setInputCornerNums('');
    // }
  };

  //구석에 앉을 학생 번호 클릭 시 삭제
  const handleRemoveCornerNum = (numToRemove) => {
    setCornerNums((prevCornerNums) =>
      prevCornerNums.filter((num) => num !== numToRemove),
    );
  };

  const handleKeyPressCorner = (event) => {
    if (event.key === 'Enter') {
      handleAddCornerNums();
    }
  };

  const handleKeyPressAdjacent = (event) => {
    if (event.key === 'Enter') {
      handleSetAdjacentNums();
    }
  };

  return (
    <StudentSeatSetting
      num1={num1}
      setNum1={setNum1}
      num2={num2}
      setNum2={setNum2}
      handleSetAdjacentNums={handleSetAdjacentNums}
      adjacentNums={adjacentNums}
      setAdjacentNums={setAdjacentNums}
      handleRemoveAdjacentNum={handleRemoveAdjacentNum}
      saveAdjacentNums={saveAdjacentNums}
      // inputSeparateNums={inputSeparateNums}
      // handleSeparateInputChange={handleSeparateInputChange}
      // handleAddSeparateNums={handleAddSeparateNums}
      separateNums={separateNums}
      saveSeparateNums={saveSeparateNums}
      inputCornerNums={inputCornerNums}
      handleCornerInputChange={handleCornerInputChange}
      handleAddCornerNums={handleAddCornerNums}
      cornerNums={cornerNums}
      saveCornerNums={saveCornerNums}
      setCornerNums={setCornerNums}
      fetchAdjacentNums={fetchAdjacentNums}
      fetchCornerNums={fetchCornerNums}
      handleRemoveCornerNum={handleRemoveCornerNum}
      handleKeyPressCorner={handleKeyPressCorner}
      handleKeyPressAdjacent={handleKeyPressAdjacent}
    />
  );
};

export default StudentSeatSettingContainer;
