import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import UserContext from '../../contexts/userContext';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import SelectFrontNum from '../3drawOneByOnePage/3-2studentSetting/SelectFrontNum';

import DrawPageContainer from '../../drawPageLayout/DrawPageContainer';
import {
  DrawBox,
  DrawBox_Content_65,
  DrawBox_Content_35,
  DrawBox_Main,
  DrawBox_Title,
  DrawPageBox,
  DrawPageMain,
  OneByOne_Footer,
  OneByOne_Footer_Content,
  OneByOne_Footer_Content_Left,
  OneByOne_Footer_Content_Right,
} from '../3drawOneByOnePage/OneByOneStyle';
import StudentSettingComponent from '../3drawOneByOnePage/3-2studentSetting/StudentSettingComponent';
import SeatSettingComponent from '../3drawOneByOnePage/3-1seatSetting/SeatSettingComponent';
import DrawingComponent from './2-4seatDrawing/DrawingComponent';

const SeatDrawPage1 = () => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;

  // 학생 번호
  const [numberList, setNumberList] = useState(
    Array.from({ length: 30 }, (_, index) => index + 1),
  );
  const [myNum, setMyNum] = useState(null); // 자리 설정 시 사용하는 번호
  const [usedNumbers, setUsedNumbers] = useState(new Map()); // 사용된 번호
  const [notUsingNumbers, setNotUsingNumbers] = useState([]); // 사용하지 않는 번호
  const [genderMap, setGenderMap] = useState({}); // 번호별 성별 정보

  // 자리
  const [blockedSeats, setBlockedSeats] = useState(new Set()); // X표시 하여 사용하지 않는 자리
  const [randomSeat, setRandomSeat] = useState(null); // 랜덤으로 뽑은 자리
  const [usedSeats, setUsedSeats] = useState(new Set()); // 뽑힌 자리
  const [numberSeatMap, setNumberSeatMap] = useState({}); // 번호별 좌석 정보
  const [previousNumberSeatMap, setPreviousNumberSeatMap] = useState({}); // 번호별 좌석 정보

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCol, setSelectedCol] = useState(null);

  // 자리뽑기 옵션
  const [pairMode, setPairMode] = useState(false); // 짝 만들기
  const [genderMode, setGenderMode] = useState(false); // 남녀구분
  const [genderSeatRule, setGenderSeatRule] = useState(true); // 남녀 자리 위치 바꾸기
  const [preAssigningSeats, setPreAssigningSeats] = useState({}); //미리 배치된 학생(저장 전)

  const [frontNums, setFrontNums] = useState([]); //앞자리
  const [adjacentNums, setAdjacentNums] = useState([]);
  const [separateNums, setSeparateNums] = useState([]);
  const [cornerNums, setCornerNums] = useState([]);
  const [preAssignment, setPreAssignment] = useState([]); // 미리 배치된 학생(저장 후 불러오기)
  //자리 수정
  const [isEditMode, setIsEditMode] = useState(false); // 자리 수정 모드 상태

  //자리 정보 저장, 불러오기
  const [seatPreAssignments, setSeatPreAssignments] = useState({});
  const [avoidPreviousSeats, setAvoidPreviousSeats] = useState(true); // 이전 좌석 피하기 옵션

  // useEffect(() => {
  //   console.log('numberSeatMap:' + JSON.stringify(numberSeatMap));
  // }, [numberSeatMap]);

  // 기본 자리 세팅
  const [seats, setSeats] = useState(
    Array(5) // 세로 자리 수
      .fill()
      .map(() => Array(6).fill(null)), // 가로 자리 수
  );
  // -------------------- 저장된 사전 세팅 불러오기  -------------------- //

  const fetchSeatStudentSetting = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getSeatStudentSetting/' + userId,
      );
      const seatStudentSetting = response.data;

      setAdjacentNums(seatStudentSetting[0]['adjacentNums']);
      setSeparateNums(seatStudentSetting[0]['separateNums']);
      setCornerNums(seatStudentSetting[0]['cornerNums']);
      setPreAssignment(seatStudentSetting[0]['preAssignment']);
    } catch (error) {
      console.error('학생 정보 불러오기 실패:', error);
    }
  }, [userId]);

  useEffect(() => {
    fetchSeatStudentSetting();
  }, []);

  // -------------------- 자리 다시 뽑기 -------------------- //

  // 처음부터 다시 뽑기 : 모든 상태를 초기화
  const resetSeats = () => {
    setSeats(
      Array(5)
        .fill()
        .map(() => Array(6).fill(null)),
    );
    setMyNum(null);
    setSelectedRow(null);
    setSelectedCol(null);
    setUsedSeats(new Set());
    setUsedNumbers(new Map());
    setBlockedSeats(new Set());
    setRandomSeat(null);
    setNumberSeatMap({});
  };

  return (
    <DrawPageContainer>
      <DrawPageMain>
        <DrawPageBox>
          <DrawBox>
            <DrawBox_Content_65>
              <StudentSettingComponent
                numberList={numberList}
                setGenderMap={setGenderMap}
                notUsingNumbers={notUsingNumbers}
                setNumberList={setNumberList}
                setNotUsingNumbers={setNotUsingNumbers}
                frontNums={frontNums}
                setFrontNums={setFrontNums}
              />
            </DrawBox_Content_65>
            <DrawBox_Content_35>
              {' '}
              <SeatSettingComponent
                setSeats={setSeats}
                setBlockedSeats={setBlockedSeats}
                avoidPreviousSeats={avoidPreviousSeats}
                setAvoidPreviousSeats={setAvoidPreviousSeats}
                setPairMode={setPairMode}
                pairMode={pairMode}
                setGenderMode={setGenderMode}
                genderMode={genderMode}
                seats={seats}
                blockedSeats={blockedSeats}
                preAssigningSeats={preAssigningSeats}
                genderMap={genderMap}
                genderSeatRule={genderSeatRule}
                setGenderSeatRule={setGenderSeatRule}
                setPreAssigningSeats={setPreAssigningSeats}
                numberList={numberList}
                notUsingNumbers={notUsingNumbers}
                setNumberList={setNumberList}
                preAssignment={preAssignment}
              />
            </DrawBox_Content_35>
          </DrawBox>
        </DrawPageBox>
      </DrawPageMain>
      <OneByOne_Footer>
        <OneByOne_Footer_Content_Left>
          학생 이름 설정하기
        </OneByOne_Footer_Content_Left>
        <OneByOne_Footer_Content_Right>저장하기</OneByOne_Footer_Content_Right>
      </OneByOne_Footer>
      <PageBox></PageBox>
    </DrawPageContainer>
  );
};

export default SeatDrawPage1;
