import { useMediaQuery } from 'react-responsive';
import KoreanDate from '../../commonFunction/KoreanDate';
import {
  BlogContent,
  BlogInfo,
  BlogInfoLeft,
  BlogInfoMobile,
  BlogInfoRight,
  BlogInfoRightItem,
  BlogTitle,
  BlogViewBox,
  BlogViewContainer,
  BlogViewTitleHr,
} from './BlogViewStyle';

const BlogView = ({ selectedBlog }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <BlogViewContainer>
      <BlogViewBox>
        {isMobile ? (
          <BlogInfoMobile>
            {selectedBlog.category}
            <BlogTitle>{selectedBlog.title}</BlogTitle>
            <div>
              {KoreanDate(selectedBlog.updatedAt)} {selectedBlog.userId}
            </div>
          </BlogInfoMobile>
        ) : (
          <BlogInfo>
            <BlogInfoLeft>
              {selectedBlog.category}
              <BlogTitle>{selectedBlog.title}</BlogTitle>
            </BlogInfoLeft>
            <BlogInfoRight>
              <div>{KoreanDate(selectedBlog.updatedAt)}</div>
              <div>{selectedBlog.userId}</div>
            </BlogInfoRight>
          </BlogInfo>
        )}

        <BlogViewTitleHr />
        <BlogContent
          dangerouslySetInnerHTML={{ __html: selectedBlog.content }}
        ></BlogContent>
      </BlogViewBox>
    </BlogViewContainer>
  );
};

export default BlogView;
