import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import UserContext from './userContext';

const GradeContext = createContext({
  state: { currentGrade: '1', gradeInfoDB: [] }, // 초기 상태
  actions: {
    setGrade: () => {}, // 기본 빈 함수
    fetchGradeInfo: () => {},
  },
});

const GradeProvider = ({ children }) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user ? userState.user.userId : null;
  // const userId = userState.user.userId;

  const [gradeInfoDB, setGradeInfoDB] = useState([]);
  const fetchGradeInfo = useCallback(async () => {
    try {
      const res = await axios.get(
        `https://teachernote.me/api/gradeinfo/${userId}`,
      );
      const sortedGradeInfo = res.data.sort((a, b) => a.grade - b.grade);
      setGradeInfoDB(sortedGradeInfo);
    } catch (err) {
      console.log(err);
    }
  }, [userId]);
  useEffect(() => {
    fetchGradeInfo();
  }, [fetchGradeInfo]);
  // useEffect(() => {
  //   const fetchGradeInfo = async () => {
  //     try {
  //       const res = await axios.get(
  //         `https://teachernote.me/api/gradeinfo/${userId}`,
  //       );
  //       const sortedGradeInfo = res.data.sort((a, b) => a.grade - b.grade);
  //       setGradeInfoDB(sortedGradeInfo);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchGradeInfo();
  // }, [userId]);

  const [currentGrade, setCurrentGrade] = useState(() => {
    const savedGrade = localStorage.getItem('currentGrade');
    return savedGrade || '1'; // 저장된 값이 없을 경우 기본값 '1' 사용
  });

  useEffect(() => {
    if (gradeInfoDB.length > 0) {
      localStorage.setItem('currentGrade', currentGrade);
    }
  }, [currentGrade, gradeInfoDB]);

  const setGrade = (grade) => {
    setCurrentGrade(grade);
  };

  const value = {
    state: { currentGrade, gradeInfoDB },
    actions: { setGrade, fetchGradeInfo },
  };

  return (
    <GradeContext.Provider value={value}>{children}</GradeContext.Provider>
  );
};

const GradeConsumer = GradeContext.Consumer;

export { GradeProvider, GradeConsumer };
export default GradeContext;
