import { useCallback, useEffect, useState } from 'react';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import AllTopicModeContainer from './AllTopicModeContainer';
import { TopicBox } from '../topic/TopicStyle';
import OneTopicContainer from './OneTopicContainer';
import SelectTopicForProgressNote from './SelectTopicForProgressNote';
import AddProgressNote from '../progressnoteByClass/AddProgressNote';

const ByTopicProgressNoteContainer = ({
  sortedProgressNoteByGrade,
  topics,
  updateData,
  deleteProgressNote,
  updateRecord,
}) => {
  //----------------  전체 토픽 모드/ 개별 토픽 모드   ----------------//
  const { paramTopicNum } = useParams();
  const [isAllTopicMode, setIsAllTopicMode] = useState(true);

  useEffect(() => {
    setIsAllTopicMode(!paramTopicNum);
    // console.log('isAllTopicMode:' + isAllTopicMode);
  }, [paramTopicNum]);

  //----------------  topicNum(topicId)같은 데이터 찾기  ----------------//
  //topics, progressnote 테이블에서 topicNum(topicId)같은 데이터 찾기
  const getTopicTableData = (topicNum) => {
    const topic = topics.find((topic) => topic.topicId == topicNum);
    return topic
      ? { topicTitle: topic.topicTitle, topicDetail: topic.topicDetail }
      : { topicTitle: '', topicDetail: '' };
  };

  return (
    <TopicBox>
      <SelectTopicForProgressNote
        topics={topics}
        paramTopicNum={paramTopicNum}
      />
      <AddProgressNote
        topics={topics}
        paramTopicNum={paramTopicNum}
        sortedProgressNoteByGrade={sortedProgressNoteByGrade}
      />
      {isAllTopicMode ? (
        <AllTopicModeContainer
          sortedProgressNoteByGrade={sortedProgressNoteByGrade}
          topics={topics}
          getTopicTableData={getTopicTableData}
          deleteProgressNote={deleteProgressNote}
          updateRecord={updateRecord}
        />
      ) : (
        <OneTopicContainer
          sortedProgressNoteByGrade={sortedProgressNoteByGrade}
          topics={topics}
          getTopicTableData={getTopicTableData}
          paramTopicNum={paramTopicNum}
          deleteProgressNote={deleteProgressNote}
          updateRecord={updateRecord}
        />
      )}
    </TopicBox>
  );
};

export default ByTopicProgressNoteContainer;
