import {
  MobileMenuBar,
  ClassinfoMenuItemsContainer,
} from './MobileTopMenuBarStyle';

const MobileTopMenuBarClassinfo = () => {
  return (
    <MobileMenuBar>
      <ClassinfoMenuItemsContainer>전체보기</ClassinfoMenuItemsContainer>
    </MobileMenuBar>
  );
};
export default MobileTopMenuBarClassinfo;
