import styled from 'styled-components';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  objWidth,
  objHeight,
  padding,
  pageWidth,
  blogListLeftWidth,
  imgSize,
  ratio,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const BlogListPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const BlogListPageBox = styled.div`
  width: 100%;
`;

export const BlogPageLayout1 = styled.div`
  ${media.desktop`
  padding-bottom: ${padding.large};
  `}

  ${media.tablet`
  padding-bottom: ${padding.large};
  `}

  ${media.mobile`
  padding-bottom: ${padding.mediumLarge};
  `}
  font-size: ${fontSize.categoryLarge};
  font-weight: ${fontWeight.bold};
  display: flex;
  justify-content: space-between;
`;

export const BlogPageCategory = styled.div`
  display: flex;
  align-items: center;
  // background-color: yellow;
`;

export const BlogBtn = styled.button`
  width: ${ratio.small};
  min-width: ${objWidth.mediumLarge};
  padding: ${padding.medium} ${padding.mediumLarge};
  ${media.mobile`
  padding: ${padding.small} ${padding.medium};
  `}

  background-color: ${colors.primary};
  color: ${colors.background};
  border: none;
  border-radius: ${boerderRadius.small};
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.primarydark};
  }
`;

export const BlogListCotentContainer = styled.div`
  width: 100%;
  gap: ${gap.medium};
  ${media.mobile`
  gap: ${gap.small};
  `}
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
export const BlogListCotentBox = styled.div`
  height: ${objHeight.extraLarge};
  padding: ${padding.large};
  gap: ${padding.large};
  ${media.mobile`
    height: ${imgSize.small};
    padding:  ${padding.medium};
    gap: ${padding.medium};
  `}

  border: 1px solid ${gray.light};
  border-radius: ${boerderRadius.medium};
  text-decoration: none;

  display: flex;
  align-items: start;
  // background-color: red;

  &: hover {
    cursor: pointer;
    background-color: ${gray.extralight};
  }
`;

export const BlogListRight = styled.div`
  width: 100%;
  gap: ${padding.medium};
  ${media.mobile`
    gap: ${padding.small};
  `}

  // background-color: yellow;
  // height: ${imgSize.medium};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`;

export const BlogListLeft = styled.div`
  height: ${imgSize.medium};
  ${media.mobile`
      height: ${imgSize.small};
  `}

  display: flex;
  justify-content: left;
  align-items: center;
  // background-color: gray;
`;
export const BlogListTitle = styled.div`
  width: 100%;

  font-size: ${fontSize.large};
  ${media.mobile`
  font-size: ${fontSize.mediumlarge};
`}
  font-weight: ${fontWeight.extraBold};
  color: ${gray.darktext};
`;
export const BlogListContent = styled.div`
  width: 100%;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.large};
  ${media.mobile`
  font-size: ${fontSize.medium};
`}

  color: ${gray.darktext};
  // background-color: blue;
`;
export const BlogListImage = styled.img`
  // width: 100%;
  border-radius: ${boerderRadius.medium};
  height: ${imgSize.medium};

  ${media.mobile`
    height: ${imgSize.small};
  `}// background-color: violet;
`;
