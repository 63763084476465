import { useCallback, useContext, useEffect, useState } from 'react';
import SeatSettingContainer from './PreAssignmentContainer';
import UserContext from '../../../contexts/userContext';
import axios from 'axios';

const PreAssignmentComponent = ({
  numberList,
  setNumberList,
  notUsingNumbers,

  seats,
  setSeats,
  pairMode,
  setPairMode,
  genderMode,
  setGenderMode,
  blockedSeats,
  setBlockedSeats,

  genderMap,

  genderSeatRule,
  setGenderSeatRule,

  preAssigningSeats,
  setPreAssigningSeats,
  savePreAssignment,
  seatPreAssignments,
  setSeatPreAssignments,
  fetchPreAssignments,
  loadSeatInfo,
  cornerNums,
  adjacentNums,
  setCornerNums,
  setAdjacentNums,
}) => {
  return (
    <SeatSettingContainer
      setSeats={setSeats}
      setBlockedSeats={setBlockedSeats}
      setPairMode={setPairMode}
      pairMode={pairMode}
      setGenderMode={setGenderMode}
      genderMode={genderMode}
      seats={seats}
      blockedSeats={blockedSeats}
      preAssigningSeats={preAssigningSeats}
      genderMap={genderMap}
      genderSeatRule={genderSeatRule}
      setGenderSeatRule={setGenderSeatRule}
      setPreAssigningSeats={setPreAssigningSeats}
      numberList={numberList}
      notUsingNumbers={notUsingNumbers}
      loadSeatInfo={loadSeatInfo}
      setNumberList={setNumberList}
      savePreAssignment={savePreAssignment}
      seatPreAssignments={seatPreAssignments}
      setSeatPreAssignments={setSeatPreAssignments}
      fetchPreAssignments={fetchPreAssignments}
      cornerNums={cornerNums}
      adjacentNums={adjacentNums}
      setCornerNums={setCornerNums}
      setAdjacentNums={setAdjacentNums}
    />
  );
};
export default PreAssignmentComponent;
