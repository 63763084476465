import { useNavigate } from 'react-router-dom';
import {
  HeaderLeft,
  HeaderMenuBarContainer,
  HeaderRight,
  TopMenuDownload,
  TopMenuLogo,
} from './HeaderMenuBarStyle';
import { NavLink } from 'react-router-dom';
import LogoutComponent from '../components/Logout';
import { useContext, useEffect, useReducer, useState } from 'react';
import DownloadProgramDiv from '../components/DownloadProgramMenu';
import styled from 'styled-components';
import { margin, padding, pageWidth } from '../styles/commonStyle';
import UserContext from '../contexts/userContext';
import { media } from '../styles/media';

const DrawHeaderMenuBarContainer = styled(HeaderMenuBarContainer)`
  width: 88%;
`;
const SeatDrawHeaderMenuBar = ({
  isRegisterModalOpen,
  setRegisterModalOpen,
  setModalSource,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  const [, forceUpdate] = useReducer((x) => x + 1, 0); // 리렌더링 강제

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // console.log('userState 변경');
    const token = localStorage.getItem('token');
    if (userState?.user) {
      setIsLoggedIn(true);
    } else if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userState]); // userState와 token 변경 시 반응하도록 설정

  const navigate = useNavigate();
  const goMain = () => {
    navigate('/bloglist');
  };
  const goLogin = () => {
    navigate('/login');
  };
  return (
    <DrawHeaderMenuBarContainer>
      <HeaderLeft>
        <TopMenuLogo onClick={goMain}>티처노트 1.0</TopMenuLogo>
        {/* <NavLink to="/seatdraw">자리 뽑기 설정</NavLink> */}

        <NavLink to="/">아티클</NavLink>
        <NavLink to="/drawonebyone">자리 뽑기</NavLink>
        <NavLink to="/progress">진도관리</NavLink>
        {/* <NavLink to="/progressnote/byclass">발표자 뽑기</NavLink>
        <NavLink to="/topic">모둠 뽑기</NavLink>
        <NavLink to="/classinfo">청소당번 뽑기</NavLink> */}
      </HeaderLeft>
      <HeaderRight>
        {userId ? (
          <>
            {userId}님 <LogoutComponent />{' '}
          </>
        ) : (
          <div
            onClick={() => {
              setModalSource('menubar');
              setRegisterModalOpen(true);
            }}
          >
            회원가입
          </div>
        )}
      </HeaderRight>
    </DrawHeaderMenuBarContainer>
  );
};

export default SeatDrawHeaderMenuBar;
