import { ClassInfoCardListItem } from './ClassInfoStyle';

const ClassInfoListItem = ({ classInfo, onRemove }) => {
  return (
    <ClassInfoCardListItem
      onClick={() => onRemove(classInfo.id, classInfo.grade, classInfo.class)}
    >
      {classInfo.grade}-{classInfo.class}
    </ClassInfoCardListItem>
  );
};

export default ClassInfoListItem;
