import { useContext, useEffect, useState } from 'react';
import ByTopicProgressNoteContainer from './ByTopicProgressNoteContainer';
import axios from '../../../node_modules/axios/index';
import GradeContext from '../../contexts/grade';
import UserContext from '../../contexts/userContext';
import PageContainer, {
  PageLayoutDiv1,
  PageLayoutDiv2,
  PageLayoutDiv3,
} from '../../pageLayout/PageContainer';
import SelectGrade from '../progress/SelectGrade';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import SelectGradeMenuBar from '../../topSubMenuBar/SelectGradeMenuBar';
import MobileTopMenuBar from '../../topMenuBarMobile/MobileTopMenuBar';
import { useMediaQuery } from 'react-responsive';

const ByTopicProgressNotePage = () => {
  //현재 학년, user context 받아오기
  const { state: gradeState } = useContext(GradeContext);
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;
  const grade = gradeState.currentGrade;

  //---------------- 기본 데이터 세팅  ----------------//
  //progressNote 데이터 받아오기 : 학년별 진도 노트 데이터 가져오기
  const [progressNoteByGradeDB, setProgressNoteByGradeDB] = useState([]);
  useEffect(() => {
    const fetchProgressNoteByGrade = async () => {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/progressnote/' + userId,
          {
            params: { grade: gradeState.currentGrade },
          },
        );
        await setProgressNoteByGradeDB(res.data);
        // await console.log('progressNoteByGradeDB:' + JSON.stringify(res.data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchProgressNoteByGrade();
  }, [grade, progressNoteByGradeDB]);

  const sortedProgressNoteByGradeDB = progressNoteByGradeDB.sort((a, b) => {
    if (a.topicNum !== b.topicNum) {
      return a.topicNum - b.topicNum; // topicNum이 다른 경우 오름차순 정렬
    } else {
      return a.classNum - b.classNum; // 같은 topicNum 안에서 classNum 오름차순 정렬
    }
  });

  //topics 테이블 데이터 받아오기- 학년별 수업 주제
  const [topicsDB, setTopicsDB] = useState([]);
  useEffect(() => {
    const fetchTopicsByGrade = async () => {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/topicsbygrade/' + userId,
          {
            params: { grade: gradeState.currentGrade }, // 올바른 요청 옵션 사용
          },
        );
        await setTopicsDB(res.data);
        // console.log('topicsDB' + JSON.stringify(topicsDB));
      } catch (err) {
        console.log(err);
      }
    };
    fetchTopicsByGrade();
  }, [gradeState.currentGrade]);

  //classinfo 데이터 받아오기 (학년 별 classinfo) by user
  const [classInfoByGradeDB, setClassInfoclassInfoByGradeDB] = useState([]);
  useEffect(() => {
    const fetchClassInfoByGrade = async () => {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/classinfobygrade/' + userId,
          {
            params: { grade: grade },
          },
        );
        await setClassInfoclassInfoByGradeDB(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchClassInfoByGrade();
  }, [userId, grade]);

  //데이터 없을 경우 인풋, 있을 경우 업데이트
  const updateData = async (option, topicNum, value) => {
    // console.log(option, topicNum, value);
    switch (option) {
      case 'topicTitle':
        try {
          await axios.put(
            'https://teachernote.me/api/updatetopictitle/' + userId,
            {
              grade: grade,
              topicId: topicNum,
              value: value,
            },
          );
          // window.location.reload();
        } catch (err) {
          console.log(err);
        }
        break;
      case 'topicDetail':
        // console.log('토픽디테일');
        break;
      case 'noteValue':
        // console.log('노트 기록');
        break;
      default:
      // console.log('디폴트');
    }
  };
  //---------------- 진도노트 삭제, 업데이트  ----------------//
  //진도노트 삭제
  const deleteProgressNote = (noteId) => {
    // console.log(userId, noteId);
    const userConfirmed = window.confirm('진도 노트를 삭제하시겠습니까?');
    if (userConfirmed) {
      try {
        axios.delete(
          'https://teachernote.me/api/deleteprogressnote/' + userId,
          {
            data: { noteId: noteId }, // 삭제할 노트의 ID를 데이터로 보냄
          },
        );
        // window.location.reload();
      } catch (err) {
        console.log(err);
      }
    } else {
      // 사용자가 취소를 선택한 경우
    }
  };

  //진도노트 업데이트
  const updateRecord = async (noteId, noteDate, noteRecord) => {
    try {
      await axios.put(
        'https://teachernote.me/api/updateprogressnote/' + userId,
        {
          noteRecord: noteRecord,
          noteId: noteId,
          noteDate: noteDate,
        },
      );
      // alert('진도노트가 업데이트 되었습니다.');
      // window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <PageContainer>
      <PageSubheader>
        <SelectGradeMenuBar />
      </PageSubheader>{' '}
      {isMobile && <MobileTopMenuBar />}
      <PageBox>
        <ByTopicProgressNoteContainer
          sortedProgressNoteByGrade={sortedProgressNoteByGradeDB}
          topics={topicsDB}
          updateData={updateData}
          deleteProgressNote={deleteProgressNote}
          updateRecord={updateRecord}
        />
      </PageBox>
    </PageContainer>
  );
};

export default ByTopicProgressNotePage;
