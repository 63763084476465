import { TiArrowSync } from 'react-icons/ti';

import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import TopicListItem from './TopicListItem';
import { GradeConsumer } from '../../contexts/grade';
import { CardTitle } from '../../components/StyledCard';
import { AddBtn, TopicCard, TopicListStyledItem } from './TopicStyle';
import { MdAdd } from 'react-icons/md';

const TopicList = ({
  topics,
  onRemove,
  onUpdate,
  fetchTopicsByGrade,
  addTopic,
  showAddBtn,
  setShowNotification,
  setRegisterModalOpen,
}) => {
  return (
    <GradeConsumer>
      {({ state, actions }) => (
        <>
          <TopicCard className="TopicList">
            <CardTitle>수업주제</CardTitle>

            {topics &&
              topics.map((topic, index) => (
                <TopicListItem
                  key={index}
                  topic={topic}
                  order={index}
                  onRemove={onRemove}
                  fetchTopicsByGrade={fetchTopicsByGrade}
                  setShowNotification={setShowNotification}
                  setRegisterModalOpen={setRegisterModalOpen}
                />
              ))}
            {showAddBtn && (
              <TopicListStyledItem onClick={() => addTopic()}>
                <AddBtn>추가하기</AddBtn>
              </TopicListStyledItem>
            )}
          </TopicCard>
        </>
      )}
    </GradeConsumer>
  );
};

export default TopicList;
