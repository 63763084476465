import { useEffect, useState } from 'react';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import {
  ProgressNoteCardLayout1,
  TopicStyledLink,
} from '../progress/ProgressNoteStyle';

const AllTopicModeHeader = ({ topicNum, topicTableData }) => {
  //topic 테이블 데이터에서 받아온 값 화면에 넣기
  const [topicTitle, setTopicTitle] = useState();
  const [topicDetail, setTopicDetail] = useState();
  useEffect(() => {
    setTopicTitle(topicTableData['topicTitle']);
    setTopicDetail(topicTableData['topicDetail']);
  }, [topicTableData]);

  const [showTopicDetail, setShowTopicDetail] = useState(false);

  // 페이지 이동
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/progressnote/bytopic/${topicNum}`);
  };

  return (
    <>
      <ProgressNoteCardLayout1 onClick={handleClick}>
        <TopicStyledLink to={`/progressnote/bytopic/${topicNum}`}>
          {topicNum}
        </TopicStyledLink>

        {topicTitle ? topicTitle : `주제${topicNum}`}
      </ProgressNoteCardLayout1>
    </>
  );
};

export default AllTopicModeHeader;
