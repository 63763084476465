import styled from 'styled-components';
import {
  boerderRadius,
  colors,
  fontColors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
  pageWidth,
  ratio,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const BlogWriteBox = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BlogSubmitContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BlogSubmitContainerLayout1 = styled.div`
  width: 80%;

  font-color: ${gray.darktext};
  // font-weight: ${fontWeight.bold};

  display: flex;
`;
export const BlogSubmitContainerLayout2 = styled.div`
  width: 20%;
  display: flex;
  justify-content: right;
`;

export const SelectBlogCategory = styled.div`
  padding-right: ${padding.medium};
  // cursor: pointer !important;
  // background-color: yellow;
`;

export const BlogTitleInput = styled.input`
  width: 100%;
  padding: ${padding.large} 0;

  ${media.mobile`
  padding: 0 0 ${padding.medium} 0;
  `}

  font-size: ${fontSize.extraLarge};
  border: none;
  outline: none;

  transition: border-color 0.3s;
  &::placeholder {
    color: ${gray.medium};
    font-size: ${fontSize.extraLarge};
  }
`;

export const QuillBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .react-quill {
    border-radius: ${boerderRadius.small};
  }

  .ql-toolbar {
    ${media.desktop`
    width: ${pageWidth.desktop};
  `}

    ${media.tablet`
    width: ${pageWidth.tablet};
  `}

  ${media.mobile`
    width: ${pageWidth.mobile};
  `}
    background-color: ${gray.light};
    border: none;
  }

  .ql-container {
    border: none;
    height: auto;
  }

  .ql-editor {
    ${media.desktop`
    width: ${pageWidth.desktop};
    overflow-y: auto;
    padding: ${padding.large} 0;

  `}

    ${media.tablet`
    width: ${pageWidth.tablet};
    overflow-y: auto;    
    padding: ${padding.large} 0;

  `}
    ${media.mobile`
    width: ${pageWidth.mobile};
    overflow-y: auto;
    padding : ${padding.medium} 0;
  `}
    padding: ${padding.large} 0;
    font-size: ${fontSize.large};
    height: auto;
    min-height: ${objHeight.mobileChill};
    // background-color: yellow;
  }
`;
