import React, { useState, useContext, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../../../node_modules/axios/index';
import UserContext from '../../contexts/userContext';
import Notification from '../../components/Notification';
import {
  BlogSubmitContainer,
  BlogSubmitContainerLayout1,
  BlogSubmitContainerLayout2,
  BlogTitleInput,
  BlogWriteBox,
  QuillBox,
  SelectBlogCategory,
} from './BlogWriteStyle';
import { BlogBtn } from '../blogList/BlogListStyle';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';

const QuillComponent = ({}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;

  const [title, setTitle] = useState('');

  const [text, setText] = useState('');

  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const quillRef = useRef(null); // useRef로 ref 생성

  const handleChange = (content) => {
    setText(content);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }], // 색상 선택 옵션 추가
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'background',
  ];

  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setTitle(value);
      // setTitleExceedLimit(false); // 글자 수 초과 상태 초기화
    } else {
      // setTitleExceedLimit(true); // 글자 수 초과 시 상태 변경
    }
  };

  const handleSubmit = async () => {
    // console.log('category:' + category);
    if (!title.trim() || !text.trim()) {
      alert('제목과 본문을 모두 입력해주세요.');
      return;
    }
    try {
      const res = await axios
        .post('https://teachernote.me/api/blogwrite/' + userId, {
          category: category,
          title: title,
          content: text,
        })
        .then(() => {
          navigate('/bloglist');
        });
      // console.log('Response:', res.data);
      setTitle('');
      setText('');
      setShowNotification(true); // 알림 상태를 true로 변경
      setTimeout(() => {
        setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  //체크박스
  const [category, setCategory] = useState('talk');

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setCategory(value);
  };

  const categories = [
    { id: 'talk', label: 'TALK' },
    { id: 'announcement', label: '공지사항' },
  ];

  return (
    <>
      {isMobile && <MobileTopMenuBarArticle handleSubmit={handleSubmit} />}
      <BlogWriteBox>
        <Notification
          message="블로그가 업로드되었습니다."
          isVisible={showNotification}
        />
        {!isMobile && (
          <BlogSubmitContainer>
            <BlogSubmitContainerLayout1>
              {categories.map((categoryItem) => (
                <SelectBlogCategory key={categoryItem.id}>
                  <label>
                    <input
                      type="radio" // 체크박스를 라디오 버튼으로 변경
                      name="category"
                      value={categoryItem.id}
                      checked={categoryItem.id === category}
                      onChange={handleCheckboxChange}
                    />
                    {categoryItem.label}
                  </label>
                </SelectBlogCategory>
              ))}
              {/* <SelectBlogCategory>카테고리</SelectBlogCategory>
              <SelectBlogCategory>카테고리1 </SelectBlogCategory>
              <SelectBlogCategory>카테고리2 </SelectBlogCategory> */}
            </BlogSubmitContainerLayout1>
            <BlogSubmitContainerLayout2>
              <BlogBtn onClick={() => handleSubmit()}>올리기</BlogBtn>
            </BlogSubmitContainerLayout2>
          </BlogSubmitContainer>
        )}

        <BlogTitleInput
          value={title}
          type="text"
          placeholder="제목을 입력하세요"
          name="title"
          onChange={handleTitleChange}
        />

        <QuillBox>
          <ReactQuill
            // id="react-quill"
            ref={quillRef} // ref 추가
            value={text}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          />
        </QuillBox>
      </BlogWriteBox>
    </>
  );
};

export default QuillComponent;
