import { useEffect, useState } from 'react';
import {
  Blackboard,
  Seat,
} from '../2seatDrawPage/2-2seatSetting/SeatSettingStyle';
import SeatMap from '../seatDrawTemp/SeatMap';
import SeatBeforeSetting from './SeatBeforeSetting';

const SeatBeforeSettingContainer = ({
  // resetSeats,
  //붙을 학생
  adjacentNums,
  setAdjacentNums,
  saveAdjacentNums,
  //떨어질 학생
  separateNums,
  setSeparateNums,
  saveSeparateNums,
  //구석 자리
  cornerNums,
  setCornerNums,
  saveCornerNums,
  preAssigningSeats,
  fetchAdjacentNums,
  fetchCornerNums,
}) => {
  useEffect(() => {
    // console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
    // console.log('preAssigningSeats:' + Array.from(preAssigningSeats));
  }, [preAssigningSeats]);

  // -------------------- 가까이 앉을 학생들 -------------------- //
  const [num1, setNum1] = useState('');
  const [num2, setNum2] = useState('');

  const handleSetAdjacentNums = () => {
    if (num1 && num2) {
      const parsedNum1 = parseInt(num1);
      const parsedNum2 = parseInt(num2);
      const newPair = [
        Math.min(parsedNum1, parsedNum2),
        Math.max(parsedNum1, parsedNum2),
      ];

      const exists = adjacentNums.some(
        (pair) => pair[0] === newPair[0] && pair[1] === newPair[1],
      );

      // preAssigningSeats의 키 값을 찾기 위한 로직
      const num1Assigned = Object.values(preAssigningSeats).includes(
        String(parsedNum1),
      );
      const num2Assigned = Object.values(preAssigningSeats).includes(
        String(parsedNum2),
      );

      if (num1Assigned || num2Assigned) {
        alert('이미 배정된 자리입니다.');
      } else if (
        cornerNums.includes(parsedNum1) &&
        cornerNums.includes(parsedNum2)
      ) {
        alert('코너에 앉을 학생들끼리는 가까이 앉을 수 없습니다.');
      } else if (!exists) {
        const updatedAdjacentNums = [...adjacentNums, newPair].sort(
          (a, b) => a[0] - b[0] || a[1] - b[1],
        );
        setAdjacentNums(updatedAdjacentNums);
        setNum1('');
        setNum2('');
      } else {
        alert('이미 존재하는 쌍입니다.');
      }
    } else {
      alert('두 번호를 모두 입력해주세요.');
    }
  };

  const handleRemoveAdjacentNum = (pairToRemove) => {
    const updatedAdjacentNums = adjacentNums.filter(
      (pair) => pair[0] !== pairToRemove[0] || pair[1] !== pairToRemove[1],
    );
    setAdjacentNums(updatedAdjacentNums);
  };

  // -------------------- 떨어져 앉을 학생들 -------------------- //
  // const [inputSeparateNums, setSeparateInputNums] = useState('');

  // const handleSeparateInputChange = (e) => {
  //   setSeparateInputNums(e.target.value);
  // };

  // const handleAddSeparateNums = () => {
  //   const nums = inputSeparateNums
  //     .split(',')
  //     .map(Number)
  //     .filter((num) => !isNaN(num));

  //   const adjacentPairs = adjacentNums.flat();
  //   const overlappingPairs = nums.filter((num) => adjacentPairs.includes(num));

  //   if (overlappingPairs.length >= 2) {
  //     alert(
  //       '입력한 번호 중 2개 이상이 가까이 앉을 학생들 목록에 존재합니다. 번호를 수정하거나, 가까이 앉을 학생들 목록에서 삭제해주세요.',
  //     );
  //   } else if (nums.length >= 2 && nums.length <= 6) {
  //     setSeparateNums(nums);
  //   } else {
  //     alert('번호는 2개에서 6개까지 입력할 수 있습니다.');
  //   }
  // };
  // -------------------- 구석에 앉을 학생들 -------------------- //
  const [inputCornerNums, setInputCornerNums] = useState('');

  const handleCornerInputChange = (e) => {
    setInputCornerNums(e.target.value);
  };
  const handleAddCornerNums = () => {
    const nums = inputCornerNums
      .split(',')
      .map(Number)
      .filter((num) => !isNaN(num));

    const adjacentPairs = adjacentNums.flat();
    const overlappingPairs = nums.filter((num) => adjacentPairs.includes(num));

    const combinedNums = [...new Set([...cornerNums, ...nums])];
    const validNums = combinedNums.filter((num) => !isNaN(num));

    if (
      validNums.some((num) =>
        Object.values(preAssigningSeats).includes(String(num)),
      )
    ) {
      alert('이미 배정된 자리입니다.');
    } else if (overlappingPairs.length >= 2) {
      alert(
        '구석에 앉을 학생들은 서로 가까이 앉을 수 없습니다. 번호를 수정하거나 가까이 앉을 학생들 목록에서 삭제해주세요.',
      );
    } else if (validNums.length >= 1 && validNums.length <= 4) {
      setCornerNums(validNums);
      setInputCornerNums('');
    } else {
      alert('번호는 1개에서 4개까지 입력할 수 있습니다.');
      setInputCornerNums('');
    }
  };

  //구석에 앉을 학생 번호 클릭 시 삭제
  const handleRemoveCornerNum = (numToRemove) => {
    setCornerNums((prevCornerNums) =>
      prevCornerNums.filter((num) => num !== numToRemove),
    );
  };

  const handleKeyPressCorner = (event) => {
    if (event.key === 'Enter') {
      handleAddCornerNums();
    }
  };

  const handleKeyPressAdjacent = (event) => {
    if (event.key === 'Enter') {
      handleSetAdjacentNums();
    }
  };

  return (
    <SeatBeforeSetting
      num1={num1}
      setNum1={setNum1}
      num2={num2}
      setNum2={setNum2}
      handleSetAdjacentNums={handleSetAdjacentNums}
      adjacentNums={adjacentNums}
      handleRemoveAdjacentNum={handleRemoveAdjacentNum}
      saveAdjacentNums={saveAdjacentNums}
      // inputSeparateNums={inputSeparateNums}
      // handleSeparateInputChange={handleSeparateInputChange}
      // handleAddSeparateNums={handleAddSeparateNums}
      separateNums={separateNums}
      saveSeparateNums={saveSeparateNums}
      inputCornerNums={inputCornerNums}
      handleCornerInputChange={handleCornerInputChange}
      handleAddCornerNums={handleAddCornerNums}
      cornerNums={cornerNums}
      saveCornerNums={saveCornerNums}
      setCornerNums={setCornerNums}
      fetchAdjacentNums={fetchAdjacentNums}
      fetchCornerNums={fetchCornerNums}
      handleRemoveCornerNum={handleRemoveCornerNum}
      handleKeyPressCorner={handleKeyPressCorner}
      handleKeyPressAdjacent={handleKeyPressAdjacent}
    />
  );
};

export default SeatBeforeSettingContainer;
