import { useCallback, useEffect, useRef, useState } from 'react';
import SeatSetting from './SeatSetting';
import PreAssignment from '../../1seatBeforePage/preAssignment/PreAssignment';

const SeatSettingContainer = ({
  setSeats,
  setBlockedSeats,
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  //   handleSeatClick,
  //   handlePreAssignInput,
  setGenderSeatRule,
  setPreAssigningSeats,
  numberList,
  notUsingNumbers,
  saveSeatInfo,
  loadSeatInfo,
  setNumberList,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
}) => {
  const [isPreAssignMode, setIsPreAssignMode] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // 자리 수정 모드 상태
  const [selectedSeats, setSelectedSeats] = useState([]); // 선택된 좌석 저장

  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedSeat(null);
      }
    };

    if (selectedSeat) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSeat]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSelectedSeat(null);
        // inputRef.current이 존재하고 selectedSeat이 있을 때 handlePreAssignInput 호출
        if (selectedSeat) {
          handlePreAssignInput({ target: inputRef.current });
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedSeat]);

  // --------------------  자리 행열 추가 삭제 -------------------- //

  //아래 줄 추가, 삭제
  const addRow = () => {
    setSeats((prevSeats) => {
      if (prevSeats.length >= 8) {
        alert('최대 8줄까지 만들 수 있습니다.');
        return prevSeats;
      }
      return [...prevSeats, Array(prevSeats[0].length).fill(null)];
    });
  };
  const removeRow = () => {
    setSeats((prevSeats) => prevSeats.slice(0, -1));
  };

  //오른쪽 줄 추가, 삭제
  const addCol = () => {
    setSeats((prevSeats) => {
      if (prevSeats[0].length >= 6) {
        alert('최대 6자리까지 옆으로 배치할 수 있습니다.');
        return prevSeats;
      }
      return prevSeats.map((row) => [...row, null]);
    });
  };
  const removeCol = () => {
    setSeats((prevSeats) => prevSeats.map((row) => row.slice(0, -1)));
  };

  //자리 사용 여부 X 토글
  const toggleBlockSeat = (row, col) => {
    const seatKey = `${row}-${col}`;
    setBlockedSeats((prev) => {
      const newBlockedSeats = new Set(prev);
      if (newBlockedSeats.has(seatKey)) {
        newBlockedSeats.delete(seatKey);
      } else {
        newBlockedSeats.add(seatKey);
      }
      return newBlockedSeats;
    });
  };

  // --------------------  사전 자리 세팅  -------------------- //
  //미리 번호 배치하는 모드면 번호 배치, 그게 아니면 사용 여부 X 토글
  const handleSeatClick = (rowIdx, colIdx) => {
    const seatKey = `${rowIdx}-${colIdx}`;

    // if (isEditMode) {
    //   setSelectedSeats((prevSelectedSeats) => {
    //     if (!Array.isArray(prevSelectedSeats)) {
    //       prevSelectedSeats = [];
    //     }
    //     const newSelectedSeats = [
    //       ...prevSelectedSeats,
    //       { row: rowIdx, col: colIdx },
    //     ];

    //     if (newSelectedSeats.length === 2) {
    //       // 두 개의 좌석이 선택되면 자리 변경
    //       swapSeats(newSelectedSeats);
    //       return [];
    //     }
    //     return newSelectedSeats;
    //   });
    // } else
    if (isPreAssignMode) {
      if (blockedSeats.has(seatKey)) {
        return; // blockedSeats에 포함된 좌석이면 클릭 무시
      }
      setSelectedSeat(seatKey);
    } else {
      toggleBlockSeat(rowIdx, colIdx);
    }
  };

  //미리 번호 배치
  const handlePreAssignInput = (event) => {
    const { value } = event.target;
    // console.log('value:' + value);
    if (value.trim() === '') {
      return;
    }

    if (selectedSeat) {
      const [rowIdx, colIdx] = selectedSeat.split('-').map(Number);
      const seatGender = genderMap[value];

      const isGenderMismatch = genderSeatRule
        ? (seatGender === '여' && colIdx % 2 === 0) ||
          (seatGender === '남' && colIdx % 2 !== 0)
        : (seatGender === '여' && colIdx % 2 !== 0) ||
          (seatGender === '남' && colIdx % 2 === 0);

      const isAlreadyAssigned =
        Object.values(preAssigningSeats).includes(value);

      const isNotUsingNumber = notUsingNumbers.includes(parseInt(value));

      if (seatGender && isGenderMismatch) {
        alert('선택한 번호의 성별과 자리가 맞지 않습니다.');
      } else if (isNotUsingNumber) {
        alert('선택한 번호는 사용할 수 없는 번호입니다.');
      } else if (isAlreadyAssigned) {
        if (window.confirm('이미 배치된 번호입니다. 자리를 바꾸시겠습니까?')) {
          const updatedPreAssignedSeats = Object.fromEntries(
            Object.entries(preAssigningSeats).map(([key, val]) =>
              val === value ? [key, null] : [key, val],
            ),
          );
          setPreAssigningSeats({
            ...updatedPreAssignedSeats,
            [selectedSeat]: value,
          });
          setSelectedSeat(null);
        }
      } else {
        setPreAssigningSeats((prev) => ({
          ...prev,
          [selectedSeat]: value,
        }));
        setSelectedSeat(null);
      }
    }
  };
  // --------------------  학생수, 자리 수 비교  -------------------- //

  //구성원과 자리표 개수 비교 확인
  const confirmSetting = (
    seats,
    blockedSeats,
    pairMode,
    genderMode,
    genderSeatRule,
  ) => {
    const totalSeats = seats.length * seats[0].length;
    const numberOfBlockedSeats = blockedSeats.size;

    const remainingSeats = totalSeats - numberOfBlockedSeats;
    const remainingNumbers = numberList.length - notUsingNumbers.length;
    if (remainingSeats - remainingNumbers < 0) {
      alert('학생수가 자리수보다 많습니다. 자리를 추가해주세요.');
    } else if (remainingSeats - remainingNumbers > 0) {
      if (
        window.confirm('자리수가 학생수보다 많습니다. 이대로 저장하시겠습니까?')
      ) {
        saveSeatInfo(seats, blockedSeats, pairMode, genderMode, genderSeatRule);
      }
    } else {
      saveSeatInfo(seats, blockedSeats, pairMode, genderMode, genderSeatRule);
    }
  };
  // --------------------  자리 교환  -------------------- //

  // // 자리 교환
  // const swapSeats = (selectedSeats) => {
  //   const [firstSeat, secondSeat] = selectedSeats;

  //   // console.log('selectedSeats:' + JSON.stringify(selectedSeats));

  //   setSeats((prevSeats) => {
  //     const newSeats = prevSeats.map((row) => [...row]);
  //     const temp = newSeats[firstSeat.row][firstSeat.col];
  //     newSeats[firstSeat.row][firstSeat.col] =
  //       newSeats[secondSeat.row][secondSeat.col];
  //     newSeats[secondSeat.row][secondSeat.col] = temp;

  //     return newSeats;
  //   });
  // };

  // --------------------  자리 리셋  -------------------- //
  const resetSeats = useCallback(() => {
    setPairMode(false);
    setGenderMode(false);
    setGenderMode(false);
    setPreAssigningSeats({});
    setBlockedSeats(new Set());
    const emptySeats = Array(5) // 세로 자리 수
      .fill()
      .map(() => Array(6).fill(null)); // 가로 자리 수
    setSeats(emptySeats);
  }, []);

  return (
    <>
      <SeatSetting
        setPairMode={setPairMode}
        pairMode={pairMode}
        setGenderMode={setGenderMode}
        genderMode={genderMode}
        setIsPreAssignMode={setIsPreAssignMode}
        isPreAssignMode={isPreAssignMode}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        seats={seats}
        blockedSeats={blockedSeats}
        preAssigningSeats={preAssigningSeats}
        genderMap={genderMap}
        genderSeatRule={genderSeatRule}
        selectedSeat={selectedSeat}
        handleSeatClick={handleSeatClick}
        handlePreAssignInput={handlePreAssignInput}
        addCol={addCol}
        removeCol={removeCol}
        addRow={addRow}
        removeRow={removeRow}
        setGenderSeatRule={setGenderSeatRule}
        confirmSetting={confirmSetting}
        saveSeatInfo={saveSeatInfo}
        loadSeatInfo={loadSeatInfo}
        resetSeats={resetSeats}
        savePreAssignment={savePreAssignment}
        // isChangeSeatMode={isChangeSeatMode}
        // setIsChangeSeatMode={setIsChangeSeatMode}
        inputRef={inputRef}
      />
    </>
  );
};
export default SeatSettingContainer;
