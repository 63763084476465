import AllTopicMode from './AllTopicMode';
import AllTopicModeHeader from './AllTopicModeHeader';
import {
  NoProgressNoteStyle,
  ProgressNoteCard,
  ProgressNoteCardLayout3,
  ProgressNoteCardLayout3Inner,
  ProgressNoteClassRecord,
} from '../progress/ProgressNoteStyle';
import { MdAdd } from 'react-icons/md';

const AllTopicModeContainer = ({
  sortedProgressNoteByGrade,
  getTopicTableData,
  topics,
  deleteProgressNote,
  updateRecord,
}) => {
  const topicNums = sortedProgressNoteByGrade.map((note) => note.topicNum);
  const uniqueSortedTopicNums = [...new Set(topicNums)].sort((a, b) => a - b);

  return (
    <>
      {uniqueSortedTopicNums.map((topicNum) => {
        // 해당 topicNum에 맞는 모든 데이터
        const notes = sortedProgressNoteByGrade.filter(
          (note) => note.topicNum === topicNum,
        );
        return (
          <ProgressNoteCard key={'alltopicheader-' + topicNum}>
            <AllTopicModeHeader
              topicNum={topicNum}
              topicTableData={getTopicTableData(topicNum)}
            />
            {/* {console.log('topicNum:' + topicNum + ' ,items:' + items)} */}
            {notes.length > 0 ? (
              notes.map((note, index) => (
                <ProgressNoteCardLayout3
                  key={'alltopiccontents' + topicNum + '-' + index}
                >
                  <AllTopicMode
                    key={'alltopic-' + topicNum + '-' + index}
                    note={note}
                    topicNum={topicNum}
                    topicTableData={getTopicTableData(topicNum)}
                    deleteProgressNote={deleteProgressNote}
                    updateRecord={updateRecord}
                  />
                </ProgressNoteCardLayout3>
              ))
            ) : (
              <ProgressNoteCardLayout3>
                <ProgressNoteCardLayout3Inner>
                  <NoProgressNoteStyle>
                    <ProgressNoteClassRecord>
                      <MdAdd />
                    </ProgressNoteClassRecord>
                  </NoProgressNoteStyle>
                </ProgressNoteCardLayout3Inner>
              </ProgressNoteCardLayout3>
            )}
          </ProgressNoteCard>
        );
      })}
    </>
  );
};

export default AllTopicModeContainer;
