import { useContext, useEffect, useState } from 'react';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import AllClassModeContainer from './AllClassModeContainer';
import GradeContext from '../../contexts/grade';
import OneClassModeContainer from './OneClassModeContainer';
import AddProgressNote from './AddProgressNote';
import { margin, height } from '../../styles/commonStyle';
import styled from 'styled-components';
import { media } from '../../styles/media';

const ByClassProgressNoteContainer = ({
  sortedProgressNoteByGrade,
  topics,
  classInfoByGrade,
  deleteProgressNote,
  updateRecord,
  showAddWindow,
  setShowAddWindow,
  fetchProgressNoteByGrade,
  setShowNotification,
  setShowSampleNotification,
  setRegisterModalOpen,
}) => {
  const { state: gradeState } = useContext(GradeContext);
  const grade = gradeState.currentGrade;

  //----------------  전체 학급 모드/ 개별 학급 모드   ----------------//
  const { paramClassNum } = useParams();
  const [isAllClassMode, setIsAllClassMode] = useState(true);
  useEffect(() => {
    setIsAllClassMode(!paramClassNum);
  }, [paramClassNum]);

  //----------------  topicNum(topicId)같은 데이터 찾기  ----------------//
  // topics, progressnote 테이블에서 topicNum(topicId)같은 데이터 찾기
  const getTopicTableData = (topicNum) => {
    const topic = topics.find((topic) => topic.topicId == topicNum);
    return topic
      ? { topicTitle: topic.topicTitle, topicDetail: topic.topicDetail }
      : { topicTitle: '', topicDetail: '' };
  };
  //----------------  classInfoByGrade 에서 학급만 추출  ----------------//
  const [uniqueClass, setUniqueClass] = useState([]);
  //   const [selectedClass, setSelectedClass] = useState(uniqueClass[0]);

  useEffect(() => {
    const tempClass = [...new Set(classInfoByGrade.map((item) => item.class))];
    setUniqueClass([...tempClass]);
  }, [classInfoByGrade]);

  return (
    <>
      {showAddWindow && (
        <AddProgressNote
          topics={topics}
          paramClassNum={paramClassNum}
          sortedProgressNoteByGrade={sortedProgressNoteByGrade}
          setShowAddWindow={setShowAddWindow}
          fetchProgressNoteByGrade={fetchProgressNoteByGrade}
          classInfoByGrade={classInfoByGrade}
          setShowNotification={setShowNotification}
        />
      )}
      <>
        {isAllClassMode ? (
          <AllClassModeContainer
            sortedProgressNoteByGrade={sortedProgressNoteByGrade}
            topics={topics}
            paramClassNum={paramClassNum}
            classInfoByGrade={classInfoByGrade}
            deleteProgressNote={deleteProgressNote}
            updateRecord={updateRecord}
            setShowAddWindow={setShowAddWindow}
            showAddWindow={showAddWindow}
            setShowSampleNotification={setShowSampleNotification}
            setRegisterModalOpen={setRegisterModalOpen}
          />
        ) : (
          <OneClassModeContainer
            sortedProgressNoteByGrade={sortedProgressNoteByGrade}
            topics={topics}
            paramClassNum={paramClassNum}
            classInfoByGrade={classInfoByGrade}
            deleteProgressNote={deleteProgressNote}
            updateRecord={updateRecord}
            showAddWindow={showAddWindow}
            setShowAddWindow={setShowAddWindow}
          />
        )}
      </>
    </>
  );
};

export default ByClassProgressNoteContainer;
