import styled, { keyframes, css } from 'styled-components';
import {
  boerderRadius,
  breakpoints,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  height,
  mainColor,
  margin,
  objHeight,
  objWidth,
  padding,
  pageWidth,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

// -------------------- onebyone page-------------------- //

export const DrawPageMain = styled.main`
  flex: 1;
  width: 100%;
  height: calc(100vh - 50px);
  margin-top: ${margin.top1};
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: none;
`;

export const DrawPageBox = styled.div`
  margin-bottom: ${margin.extraLarge};
  ${media.desktop`
    width: 90%;
  `}

  ${media.tablet`
    width: 90%;
  `}
    
  ${media.mobile`
   width: 90%;
  `}
`;

export const SettingSwitchBox = styled.div`
  width: 100%;
  padding: ${padding.mediumLarge} 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  // background-color: yellow;
`;

export const SettingSwitchBoxInner = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const DrawBox = styled.div`
  width: 100%;
  display: flex;
  gap: ${gap.large};
  margin-bottom: ${gap.large};

  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

export const MobileMessageContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    font-size: 1.5em;
    text-align: center;
    background-color: #f8f8f8;
  }

  @media print {
    display: none;
  }
`;

export const OneByOne_Footer = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0px;
  z-index: 100;

  > div {
    flex: 1;
    padding: 10px;
  }

  > div:not(:last-child) {
    border-right: 1px solid ${colors.background};
  }
`;

export const OneByOne_Footer_Content = styled.div`
  width: 50%;
  height: 50px;

  font-size: ${fontSize.categoryLarge};

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  cursor: pointer;
`;

export const OneByOne_Footer_Content_Left = styled(OneByOne_Footer_Content)`
  color: ${colors.primary};
  border-top: 1px solid ${gray.light};
  background-color: ${colors.background};
  &:hover {
    background-color: ${gray.light};
  }
  font-weight: ${fontWeight.bold};
`;
export const OneByOne_Footer_Content_Right = styled(OneByOne_Footer_Content)`
  z-index: 100;
  color: ${colors.background};
  // border-bottom: 1px solid ${gray.light};

  background-color: ${colors.primary};
  &:hover {
    background-color: ${colors.primarydark};
  }
`;

// -------------------- DrawBox_Content -------------------- //

export const DrawBox_Content = styled.div`
  // height: 70vh;
  border: 1px solid ${gray.light};
  border-radius: ${boerderRadius.large};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  // background-color: red;
`;

export const DrawBox_Content_65 = styled(DrawBox_Content)`
  width: 65%;

  @media (max-width: 1350px) {
    width: 100%;
  }
  background-color: ${colors.background};
`;
export const DrawBox_Content_35 = styled(DrawBox_Content)`
  width: 35%;

  @media (max-width: 1350px) {
    width: 100%;
  }
  background-color: ${colors.background};
`;

export const DrawBox_Title = styled.div`
  position: relative;

  height: ${objHeight.medium};
  padding: ${padding.mediumLarge} ${padding.medium};
  border-bottom: 1px solid ${gray.light};

  font-size: ${fontSize.categoryLarge};
  font-weight: ${fontWeight.bold};
  // color: ${colors.primary};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DrawBox_Title_Text = styled.div`
  padding-bottom: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? padding.extraLarge : ''};
  font-size: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? fontSize.extraLarge : 'inherit'};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const DrawBox_Main = styled.div`
  flex-grow: 1;
  padding: ${padding.large} ${padding.mediumLarge} ${padding.mediumLarge}
    ${padding.large};

  display: flex;
  flex-direction: column;
`;

export const DrawBox_Main_Color = styled(DrawBox_Main)`
  // background-color: #f0f8ff;
  background-color: ${colors.lightGreenBackground};
`;

export const DrawBox_Bottom = styled.div`
  height: ${objHeight.largeMore};
  padding: ${padding.medium} 0;
  border-top: 1px solid ${gray.light};

  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // background-color: yellow;
`;

// -------------------- 번호, 버튼 -------------------- //

export const CurrentNumber = styled.div`
  width: 100%;
  color: #333;
  display: flex;
  align-items: center;
  // background-color: yellow;
  justify-content: space-between;
  position: relative;
`;

export const OneByOneButton = styled.button`
  padding: ${padding.small} ${padding.medium};

  font-size: ${fontSize.small};
  font-weight: ${fontWeight.bold};

  background-color: ${colors.background};
  color: ${gray.darktext};
  border: 1px solid ${gray.darktext};
  border-radius: ${boerderRadius.small};
  cursor: pointer;
  margin: 0 ${margin.small};

  &:hover {
    background-color: ${colors.lightGreenBackground};
  }
`;

// -------------------- 제비뽑기- DRaw -------------------- //
export const HightLightNumberContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  gap: ${gap.small};
`;

export const HightlightNumber = styled.p`
  background-color: ${colors.primarylight};
  color: ${colors.primary};
  padding: ${padding.small} ${padding.large};
  border-radius: ${boerderRadius.medium};
`;

export const DrawContainer_Top = styled.div`
  width: 100%;
  padding: ${padding.medium} 0;

  // background-color: #b0daff;
  // border-radius: ${boerderRadius.medium};
  font-weight: ${fontWeight.bold};
  // background-color: ${colors.lightGreenBorder};
  border-bottom: 1px solid ${gray.light};
  display: flex;
  justify-content: center;
  // cursor: pointer;
`;

export const DrawContainer = styled.div`
  width: 100%;
  height: 400px;

  padding: 100px 0px;
  position: relative;

  // background-color: #f0f8ff;
  background-color: ${colors.lightGreenBackground};

  // border-radius: ${boerderRadius.medium};
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const DrawInner = styled.div`
  width: 100%;
  height: 100%;

  // background-color: yellow;
`;

export const StartDraw = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${objHeight.mobileChill};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGreenBackground};
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

export const StartDrawButton = styled.button`
  // background-color: #ffcc00;
  background-color: ${colors.lightGreen};
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 20px 40px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  outline: none;

  &:hover {
    // background-color: #ff9900;
    background-color: ${colors.lightGreenDark};
    animation: ${bounce} 0.5s;
  }

  &:active {
    background-color: #ff6600;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transform: scale(0.95);
  }
`;

//뽑기 완료
export const CompletionMessage = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.lightGreen};
  margin-bottom: ${margin.mediumlarge};
  //   display: flex;
  // justify-content: center;
  // align-items: center;
  // background: linear-gradient(
  //   135deg,
  //   #f0f4c3,
  //   #c8e6c9
  // ); /* 밝은 배경 그라데이션 */
  // border-radius: 15px;
  // padding: 20px;
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;
// -------------------- SeatDrawMap-------------------- //

export const SeatDrawMapContainer = styled.div`
  // @media print {
  //   width: 80%;
  //   height: 80%;
  //   overflow-y: hidden;
  // }
`;

export const Onebyone_SeatGridContainer = styled.div`
  width: 100%;
  padding: ${padding.extraLarge} 0 ${padding.large} 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f8ff;
`;

export const Onebyone_SeatGrid = styled.div`
  width: 100%;
  gap: ${gap.medium}; /* seat 좌석간 좌우 간격 */

  display: grid;
  justify-content: center;

  grid-template-columns: repeat(
    ${(props) => props.$columns},
    ${objWidth.medium}
  ); /* seat 좌석간 좌우 간격 */

  // background-color: yellow;
`;

export const Onebyone_Seat = styled.div`
  width: ${objWidth.smallMedium};
  height: ${objWidth.smallMedium};
  border-radius: ${boerderRadius.small};
  margin-left: ${(props) =>
    props.$pairmode && props.$col % 2 === 0 ? '25px' : '0px'};

  // background-color: ${gray.light};

  background-color: ${({ $genderMode, $col, $genderSeatRule, $blocked }) => {
    if ($blocked) return `${gray.medium}`;
    if (!$genderMode) return `${gray.medium}`;
    if ($genderSeatRule) {
      return $col % 2 === 0 ? '#ccccff' : '#ffcccc';
    } else {
      return $col % 2 !== 0 ? '#ccccff' : '#ffcccc';
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ $blocked }) => ($blocked ? '1.5em' : 'inherit')};

  .blocked-text {
    font-size: 1.5em; // 글자 크기 조정
    color: ${colors.background};
  }
`;

export const Onebyone_Blackboard = styled.div`
  width: 90%;
  height: ${objWidth.small};
  margin-top: ${margin.extraLarge};

  border-radius: ${boerderRadius.medium};

  font-weight: ${fontWeight.bold};
  color: ${gray.darktext};
  background-color: ${gray.light};
  // border : 1px solid #00704a;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Onebyone_SeatDrawSecondaryButton = styled.button`
  // width: ${objWidth.mediumLarge};
  padding: 10px 20px;

  border-radius: ${boerderRadius.small};

  font-weight: ${fontWeight.bold};
  background-color: ${colors.background};
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
    cursor: pointer;
  }
`;

// -------------------- 모달 -------------------- //

export const DrawModalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // 모달 외부 배경의 투명도 설정 (50% 투명도)
    zIndex: 1000, // overlay의 z-index 설정
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',

    width: '550px',
    height: '377px',

    // boerderRadius: '20px',
    border: 'none' /* zIndex소용이 없음 */,
    // zIndex: 1000,
    // borderRadius: '10px',
    // overflow: 'hidden' ,
  },
};

export const DrawModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;
  // background-color: ${colors.lightGreenBackground};

  // border-radius: 20px;
  position: relative;
  background-image: url('paper2.png');
  background-size: contain; /* 이미지를 모달 안에 맞추기 */
  background-size: 100% 100%; /* 모달 크기에 맞추기 */
  background-position: center;
  background-repeat: no-repeat; /* 필요에 따라 반복을 방지 */
  background-color: transparent;
`;
export const ModalButtons = styled.div`
  display: flex;
  padding: ${padding.medium};
`;

export const ModalText = styled.div`
  font-size: ${fontSize.categoryLarge};
  padding-bottom: ${padding.medium};
`;

// -------------------- 손 애니메이션-------------------- //
// 애니메이션 정의
export const moveHandLeft = keyframes`
  0%, 100% { left: 0%;  }
  20%, 80% { left: 40%; }
  40%, 60% { left: 20%; }
  50% { left: 40%; }
    ${({ shuffling }) =>
      shuffling &&
      css`
        animation: shake 0.5s;
        animation-iteration-count: infinite;
      `}
`;

export const moveHandRight = keyframes`
  0%, 100% { right: 0%; }
  20%, 80% { right: 40%; }
  40%, 60% { right: 20%; }
  50% { right: 40%; }
    ${({ shuffling }) =>
      shuffling &&
      css`
        animation: shake 0.5s;
        animation-iteration-count: infinite;
      `}
`;

// css 헬퍼를 사용하여 keyframes를 래핑
const moveHandLeftAnimation = css`
  animation: ${moveHandLeft} 1.5s infinite;
`;

const moveHandRightAnimation = css`
  animation: ${moveHandRight} 1.5s infinite;
`;

export const HandImageLeft = styled.img`
  position: absolute;
  bottom: 0%;
  width: 250px;
  height: auto;
  ${({ shuffling }) => (shuffling ? moveHandLeftAnimation : 'none')};
`;

export const HandImageRight = styled.img`
  position: absolute;
  bottom: 0%;
  width: 250px;
  height: auto;
  ${({ shuffling }) => (shuffling ? moveHandRightAnimation : 'none')};
`;

// -------------------- 제비 애니메이션-------------------- //
//제비 이미지
export const JebiImage = styled.img`
  width: 120px; /* 제비 이미지 크기 */
  height: 120px;
  cursor: pointer;
  position: absolute;
  top: ${({ $position }) =>
    $position?.top !== undefined ? $position.top : 0}%;
  left: ${({ $position }) =>
    $position?.left !== undefined ? $position.left : 0}%;
  ${({ shuffling }) =>
    shuffling ? getMoveJebiAnimation() : 'none'}; // 애니메이션 추가
`;

const generateRandomKeyframes = () => keyframes`
  0%, 100% { transform: translate(0, 0); }
  20%, 80% { transform: translate(${Math.random() * 55}px, ${
  Math.random() * 40
}px); }
  40%, 60% { transform: translate(${Math.random() * 60}px, ${
  Math.random() * 30
}px); }
  50% { transform: translate(${Math.random() * 10}px, ${
  Math.random() * 55
}px); }
`;

// css 헬퍼를 사용하여 keyframes를 래핑
const getMoveJebiAnimation = () => css`
  animation: ${generateRandomKeyframes()} 1.5s infinite;
`;

export const TextEmphasis = styled.span`
  color: ${colors.primary};
`;
