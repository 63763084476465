import { useCallback, useContext, useEffect, useState } from 'react';
import SeatSettingContainer from './SeatSettingContainer';
import UserContext from '../../../contexts/userContext';
import axios from '../../../../node_modules/axios/index';

const SeatSettingComponent = ({
  numberList,
  setNumberList,
  notUsingNumbers,

  seats,
  setSeats,
  pairMode,
  setPairMode,
  genderMode,
  setGenderMode,
  blockedSeats,
  setBlockedSeats,

  genderMap,

  genderSeatRule,
  setGenderSeatRule,

  preAssigningSeats,
  setPreAssigningSeats,
  savePreAssignment,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;

  useEffect(() => {
    loadSeatInfo();
  }, []);

  // -------------------- 자리 설정 -------------------- //
  //자리 설정 저장하기
  const saveSeatInfo = useCallback(
    async (seats, blockedSeats, pairMode, genderMode, genderSeatRule) => {
      try {
        const response = await axios.post(
          'https://teachernote.me/api/saveSeatInfo',
          {
            userId,
            seats: JSON.stringify(seats),
            blockedSeats: JSON.stringify(Array.from(blockedSeats)),
            pairMode,
            genderMode,
            genderSeatRule,
          },
        );
        console.log(response.data);
      } catch (error) {
        console.error('Error saving preferences:', error);
      }
    },
    [],
  );

  //자리 설정 불러오기
  const loadSeatInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getSeatInfo/' + userId,
      );
      const data = response.data;

      setSeats(data[0].seats);
      setBlockedSeats(new Set(data[0].blockedSeats));
      setPairMode(data[0].pairMode);
      setGenderMode(data[0].genderMode);
      setGenderSeatRule(data[0].genderSeatRule);
    } catch (error) {
      console.error('Error retrieving preferences:', error);
    }
  }, [userId]);

  return (
    <SeatSettingContainer
      setSeats={setSeats}
      setBlockedSeats={setBlockedSeats}
      setPairMode={setPairMode}
      pairMode={pairMode}
      setGenderMode={setGenderMode}
      genderMode={genderMode}
      seats={seats}
      blockedSeats={blockedSeats}
      preAssigningSeats={preAssigningSeats}
      genderMap={genderMap}
      genderSeatRule={genderSeatRule}
      setGenderSeatRule={setGenderSeatRule}
      setPreAssigningSeats={setPreAssigningSeats}
      numberList={numberList}
      notUsingNumbers={notUsingNumbers}
      saveSeatInfo={saveSeatInfo}
      loadSeatInfo={loadSeatInfo}
      setNumberList={setNumberList}
      savePreAssignment={savePreAssignment}
      // isChangeSeatMode={isChangeSeatMode}
      // setIsChangeSeatMode={setIsChangeSeatMode}
    />
  );
};
export default SeatSettingComponent;
