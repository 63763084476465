import { useEffect, useState } from 'react';
import AllTopicMode from './AllTopicMode';
import AllTopicModeHeader from './AllTopicModeHeader';
import {
  NoProgressNoteStyle,
  ProgressNoteCard,
  ProgressNoteCardLayout3,
  ProgressNoteCardLayout3Inner,
  ProgressNoteClassRecord,
} from '../progress/ProgressNoteStyle';
import { MdAdd } from 'react-icons/md';
import AddProgressNote from '../progressnoteByClass/AddProgressNote';

const OneTopicContainer = ({
  sortedProgressNoteByGrade,
  getTopicTableData,
  topics,
  deleteProgressNote,
  updateRecord,
  paramTopicNum,
}) => {
  //---------------- progressnote 데이터중 param topicNum에 해당하는 데이터 찾기  ----------------//
  const [filteredProgressNoteByGrade, setFilteredProgressNoteByGrade] =
    useState([]);
  useEffect(() => {
    const tempData = sortedProgressNoteByGrade.filter(
      (item) => item.topicNum == paramTopicNum,
    );
    setFilteredProgressNoteByGrade(tempData);
  }, [sortedProgressNoteByGrade, paramTopicNum]);

  return (
    <>
      {filteredProgressNoteByGrade.length > 0 ? (
        <ProgressNoteCard key={`topic-${paramTopicNum}`}>
          <AllTopicModeHeader
            topicNum={paramTopicNum}
            topicTableData={getTopicTableData(paramTopicNum)}
          />
          {filteredProgressNoteByGrade.map((filteredNote, index) => (
            <ProgressNoteCardLayout3 key={`topic-note-${index}`}>
              <AllTopicMode
                note={filteredNote}
                topicNum={paramTopicNum}
                topicTableData={getTopicTableData(paramTopicNum)}
                deleteProgressNote={deleteProgressNote}
                updateRecord={updateRecord}
              />
            </ProgressNoteCardLayout3>
          ))}
        </ProgressNoteCard>
      ) : (
        <ProgressNoteCardLayout3>
          <ProgressNoteCardLayout3Inner>
            <NoProgressNoteStyle>
              주제{paramTopicNum}의 진도노트를 추가해보세요!
            </NoProgressNoteStyle>
          </ProgressNoteCardLayout3Inner>
        </ProgressNoteCardLayout3>
      )}
    </>
  );
};

export default OneTopicContainer;
