import QuillComponent from './QuillComponent';

const BlogWriteContainer = () => {
  
  return (
    <>
      <QuillComponent />
    </>
  );
};

export default BlogWriteContainer;
