import styled from 'styled-components';
import { Link } from '../../node_modules/react-router-dom/dist/index';
import { colors, padding } from '../styles/commonStyle';

export const AuthStyledLink = styled(Link)`
  text-decoration: none;
  color: gray;
  &:hover {
    color: ${colors.primary};
  }
`;

export const AuthStyledDiv = styled.div`
  text-decoration: none;
  color: gray;
  &:hover {
    color: ${colors.primary};
    cursor: pointer;
  }
`;
export const AuthAlertText = styled.div`
  padding: ${padding.small} 0px ${padding.small} ${padding.small};
  font-size: 14px;
  color: red;
`;
export const AuthRightText = styled.div`
  padding: ${padding.small} 0px ${padding.small} ${padding.small};
  font-size: 14px;
  color: ${colors.primary};
`;
