import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/commonStyle';

const SwitchContainer = styled.div`
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const SwitchLabel = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.$isOn ? `${colors.lightGreen}` : '#ccc'};
  border-radius: 34px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const SwitchToggle = styled.div`
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: ${(props) => (props.$isOn ? '30px' : '4px')};
  transition: left 0.3s;
`;

function Switch({ isOn, handleToggle }) {
  return (
    <SwitchContainer onClick={handleToggle}>
      <SwitchLabel $isOn={isOn}>
        <SwitchToggle $isOn={isOn} />
      </SwitchLabel>
    </SwitchContainer>
  );
}

export default Switch;
