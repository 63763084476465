import PageContainer from '../../pageLayout/PageContainer';
import LoginModalContainer from './LoginModalContainer';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // 애플리케이션의 루트 엘리먼트를 설정

const AuthModalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // 모달 외부 배경의 투명도 설정 (50% 투명도)
    zIndex: 1000, // overlay의 z-index 설정
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',

    border: 'none',
  },
};
const LoginModalComponent = ({
  isLoginModalOpen,
  setLoginModalOpen,
  openRegisterModal,
  openLoginModal,
  closeAuthModal,
  handleAuthComplete,
  modalSource,
}) => {
  return (
    <Modal
      isOpen={isLoginModalOpen}
      onRequestClose={closeAuthModal}
      style={AuthModalCustomStyles}
      contentLabel="Auth Modal"
    >
      <LoginModalContainer
        handleAuthComplete={handleAuthComplete}
        openRegisterModal={openRegisterModal}
        modalSource={modalSource}
      />
    </Modal>
  );
};

export default LoginModalComponent;
