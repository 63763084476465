import { useCallback, useContext, useEffect, useState } from 'react';
import SeatSettingContainer from './PreAssignmentContainer';
import UserContext from '../../../contexts/userContext';
import axios from 'axios';

const PreAssignmentComponent = ({
  numberList,
  setNumberList,
  notUsingNumbers,

  seats,
  setSeats,
  pairMode,
  setPairMode,
  genderMode,
  setGenderMode,
  blockedSeats,
  setBlockedSeats,

  genderMap,

  genderSeatRule,
  setGenderSeatRule,

  preAssigningSeats,
  setPreAssigningSeats,
  savePreAssignment,
  seatPreAssignments,
  setSeatPreAssignments,
  fetchPreAssignments,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;

  //자리 설정 불러오기
  const loadSeatInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getSeatInfo/' + userId,
      );
      const data = response.data;

      setSeats(data[0].seats);
      setBlockedSeats(new Set(data[0].blockedSeats));
      setPairMode(data[0].pairMode);
      setGenderMode(data[0].genderMode);
      setGenderSeatRule(data[0].genderSeatRule);
    } catch (error) {
      console.error('Error retrieving preferences:', error);
    }
  }, [userId]);

  useEffect(() => {
    loadSeatInfo();
  }, [loadSeatInfo]);

  return (
    <SeatSettingContainer
      setSeats={setSeats}
      setBlockedSeats={setBlockedSeats}
      setPairMode={setPairMode}
      pairMode={pairMode}
      setGenderMode={setGenderMode}
      genderMode={genderMode}
      seats={seats}
      blockedSeats={blockedSeats}
      preAssigningSeats={preAssigningSeats}
      genderMap={genderMap}
      genderSeatRule={genderSeatRule}
      setGenderSeatRule={setGenderSeatRule}
      setPreAssigningSeats={setPreAssigningSeats}
      numberList={numberList}
      notUsingNumbers={notUsingNumbers}
      loadSeatInfo={loadSeatInfo}
      setNumberList={setNumberList}
      savePreAssignment={savePreAssignment}
      seatPreAssignments={seatPreAssignments}
      setSeatPreAssignments={setSeatPreAssignments}
      fetchPreAssignments={fetchPreAssignments}
    />
  );
};
export default PreAssignmentComponent;
