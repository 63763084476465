import styled, { css } from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
  height,
  margin,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import {
  NavLink,
  useNavigate,
} from '../../node_modules/react-router-dom/dist/index';
import { media } from '../styles/media';
import { useEffect, useState } from 'react';
import axios from '../../node_modules/axios/index';
import { NewBadge } from '../styles/BasicStyle';

export const ArticleMenuBox = styled.div`
  ${media.desktop`
    height: ${height.header};
    width: ${pageWidth.desktop};
  `}

  ${media.tablet`
    height: ${height.headerTablet};    
    width: ${pageWidth.tablet};
  `}

  ${media.mobile`
    height: ${height.headerMobile};    
    width: ${pageWidth.mobile};
    display: none;
  `}
  height: ${height.header};
  padding: 0 ${padding.large};
  display: flex;
  align-items: center;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  background-color: ${colors.background};
`;

// 조건부 스타일링을 위해 css helper 사용
const activeStyle = css`
  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
`;

const inactiveStyle = css`
  color: black;
`;

export const ArticleMenu = styled(NavLink)`
  cursor: pointer;
  margin-right: ${margin.large};
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;

  &:hover {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
  }

  &.active {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
  }
`;
//

const ArticleMenuBar = () => {
  const navigate = useNavigate();
  const [blogDB, setBlogDB] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get('https://teachernote.me/api/blogs/');
        setBlogDB(res.data);
        // console.log('blogDB:' + JSON.stringify(res.data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchBlogs();
  }, []);

  return (
    <ArticleMenuBox>
      <ArticleMenu to="/bloglist/insta" activeclassname="active">
        영상툰<NewBadge>N</NewBadge>
      </ArticleMenu>
      <ArticleMenu to="/bloglist/announcement" activeclassname="active">
        공지사항
      </ArticleMenu>

      <ArticleMenu to="/bloglist" activeclassname="active" end>
        전체보기
      </ArticleMenu>
      {/* <ArticleMenu to="/bloglist/talk" activeclassname="active">
        TALK
      </ArticleMenu> */}

      {/* {blogDB.map((blog) => (
        <ArticleMenu key={blog.id}>{blog.category.toUpperCase()}</ArticleMenu>
      ))} */}
    </ArticleMenuBox>
  );
};

export default ArticleMenuBar;
