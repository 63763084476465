import styled, { css } from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
  height,
  margin,
  objWidth,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import { media } from '../styles/media';

export const ProgressnoteMenuBar = styled.div`
  //subheader height 고정하기
  //   height: ${height.subheaderLarge};
  padding: ${padding.small} ${padding.large};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  display: flex;
  justify-content: space-between;
  ${media.desktop`
    width: ${pageWidth.desktop};
    padding: ${padding.medium} ${padding.large};
  `}

  ${media.tablet`
      width: ${pageWidth.tablet};
      padding: ${padding.small} ${padding.large};
  `}

  ${media.mobile`
    display: none;
      width: ${pageWidth.mobile};
      padding: ${padding.small} ${padding.large}; //아직 안바꿈
  `}
`;

export const ClassProgressnoteMenuBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  //   background-color: pink;
`;

export const ProgressnoteMenuBarLeft = styled.div`
  width: 225px;
  display: flex;
  flex-direction: row;
  justify-content: left;

  //   background-color: red;
`;
export const ProgressnoteMenuBarMiddle = styled.div`
  width: 63%;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  cursor: pointer;
  ${media.tablet`
    display: none;
`}//   background-color: yellow;
`;

export const ProgressnoteMenuBarRight = styled.div`
  //   width: 90px;
  font-size: ${fontSize.large};
  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  &:hover {
    color: ${colors.primarydark};
  }
  display: flex;
  align-items: center;
  justify-content: right;
  //   background-color: green;

  ${media.desktop`
      width: ${objWidth.mediumLarge};
  `}

  ${media.tablet`
      width: ${objWidth.mediumLarge};
  `}

  ${media.mobile`
      width: ${objWidth.medium};
  `}
`;

// export const SelectGradeBox = styled.div`
//   margin: ${margin.small} 0;
//   cursor: pointer;

//   display: flex;
//   flex-direction: row;
//   justify-content: left;
//   background-color: red;
// `;

// export const GradeMenu = styled.div`
//   height: 100%;
//   width: ${objWidth.small};
//   margin-right: ${margin.large};

//   background-color: yellow;
//   cursor: pointer;
//   display: flex;
//   align-items: center;

//   &:hover {
//     color: ${colors.primary};
//     font-weight: ${fontWeight.bold};
//   }

//   ${({ $isSelectedGrade }) =>
//     $isSelectedGrade &&
//     css`
//       color: ${colors.primary};
//       font-weight: ${fontWeight.bold};
//     `}
// `;

export const SelectClassBox = styled.div`
  display: grid;
  gap: ${margin.small}; // 그리드 아이템 간의 간격을 설정합니다.

  ${media.desktop`
      width: 100%;
        grid-template-columns: repeat(auto-fill, ${objWidth.small});

  `}

  ${media.tablet`
      width: 100%;
        grid-template-columns: repeat(auto-fill, ${objWidth.small});
       

  `}

  ${media.mobile`
      width: 100%;
        grid-template-columns: repeat(auto-fill, 30px);

  `}
`;

export const SelectClassInner = styled.div`
  width: ${objWidth.small};
  font-size: ${fontSize.medium};
  color: ${gray.medium};
  font-weight: ${fontWeight.bold};

  &:hover {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${colors.primary};
      font-weight: ${fontWeight.bold};
    `}
`;
