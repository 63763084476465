import styled from 'styled-components';
import {
  boerderRadius,
  colors,
  fontColors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  padding,
  ratio,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

// export const ClassInfoPageBox = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   justify-content: center;
//   align-items: center;
//   //   background-color: yellow;
// `;
// export const ClassInfoListBox = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   background-color: green;
//   justify-content: center;
//   align-items: center;
// `;

export const ClassInfoAddBox = styled.div``;

export const ClassInfoCardBody = styled.div`
  width: 100%;
  height: 100%;

  border-radius: ${boerderRadius.large};

  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
`;
export const ClassInfoCardBodyItem = styled.div`
  width: 100%;

  border-bottom: 1px solid ${gray.light};

  display: flex;
  flex-direction: row;

  &:hover {
    background-color: ${gray.extralight};
  }
`;
export const ClassInfoCardBodyItemLayout1 = styled.div`
  width: ${ratio.small};
  height: ${objHeight.classinfoGrade};
  padding-top: ${padding.medium};

  ${media.mobile`
    padding: 0 ${padding.medium};
      height: auto;  
      font-size: ${fontSize.small};
  `}

  background-color: ${colors.lightGreenBackground};
  border-bottom: 1px solid ${colors.background};

  font-weight: ${fontWeight.bold};
  cursor: default;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ClassInfoCardBodyItemLayout2 = styled.div`
  width: ${ratio.large};
  margin: ${margin.medium};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // background-color: red;

  ${media.mobile`
  margin: ${margin.medium} 0 ${margin.medium} ${margin.medium};
  `}
`;

export const ClassInfoCardListItem = styled.div`
  margin: ${margin.medium};
  padding: ${padding.small} ${padding.medium};
  height: 20px;

  ${media.mobile`
  margin: ${margin.small};
  font-size: ${fontSize.small};

  // padding: ${padding.small};
  `}

  border-radius: ${boerderRadius.medium};

  color: ${gray.darktext};
  // font-size: ${fontSize.large};
  background-color: ${colors.lightGreenBackground};
  border: 1px solid ${colors.lightGreenBorder};

  &:hover {
    // background-color: ${gray.medium};
    background-color: ${colors.lightGreenBorder};
    // color: ${colors.background};
    // font-size: ${fontSize.large};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ClassInfoTag = styled.div`
  margin: ${margin.medium} ${margin.small};
  padding: ${padding.small} ${padding.medium};
  height: 20px;
  border: 1px solid ${colors.lightGreen};
  border-radius: ${boerderRadius.medium};
  background-color: ${colors.background};
  color: ${colors.lightGreen};

  ${media.mobile`
  margin: ${margin.small};
  `}
  color: ${gray.darktext};
  // background-color: ${gray.medium};

  text-decoration: none;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  cursor: pointer;

  &:hover {
    // background-color: ${gray.medium};
    background-color: ${colors.lightGreenBorder};
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

//---------------- ClassInfoInput Style ----------------//

export const ClassInfoForm = styled.form`
  display: flex;
  width: 100%;
`;
export const ClassInfoAddCardLayout1 = styled.div`
  width: 80%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
export const ClassInfoAddCardLayout2 = styled.div`
  width: 20%;

  border-radius: 0 0 ${boerderRadius.large} 0;
  // background-color: ${gray.medium};
  background-color: ${colors.lightGreenBorder};
  color: ${gray.darktext};
  font-weight: ${fontWeight.bold};
  &:hover {
    // background-color: ${gray.extradark};
    // background-color: ${colors.lightGreenBorder};
    // transform: scale(1.1);
  }
  &:hover svg {
    transform: scale(1.1);
  }
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ClassInfoAddCardLayout1Inner = styled.div`
  width: 100%;

  ${media.mobile`
  font-size: ${fontSize.small};
  `}

  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ClassInfoAddCardLayout1Divide = styled.div`
  width: 25%; /*4등분*/
`;

export const ClassInfoStyledInput = styled.input`
  width: 100%;
  height: 40px;

  border: none;
  border-radius: ${boerderRadius.large};
  font-size: ${fontSize.medium};
  ${media.mobile`
      font-size: ${fontSize.small};
  `}

  outline: none; /* 포커스 효과 제거 */
  transition: border-color 0.3s ease;
  text-align: center;

  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.2);
  }
`;

export const ClassInfoInputErrorText = styled.div`
  width: 100%;
  height: 100%;
  padding: ${padding.mediumLarge};

  font-size: ${fontSize.medium};

  ${media.mobile`
      font-size: ${fontSize.small};
  `}

  color: ${fontColors.alert};

  display: flex;
  justify-content: center;
  align-items: center;
`;
