import { useContext, useEffect, useState } from 'react';
import ClassInfoListItem from './ClassInfoListItem';

import { MdAdd } from 'react-icons/md';
import UserContext from '../../contexts/userContext';
import axios from '../../../node_modules/axios/index';
import {
  ClassInfoCardBody,
  ClassInfoTag,
  ClassInfoCardBodyItemLayout1,
  ClassInfoCardBodyItemLayout2,
  ClassInfoCardBodyItem,
} from './ClassInfoStyle';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { EmptyTopicList } from '../topic/TopicStyle';
import { colors, iconSize } from '../../styles/commonStyle';
import { StyledCard } from '../../components/StyledCard';
import GradeContext from '../../contexts/grade';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import Tooltip from '../../components/Tooltip';

const ClassInfoList = ({
  classInfos,
  onRemove,
  addNextClass,
  setShowNotification,
  fetchAllClassInfo,
  setRegisterModalOpen,
}) => {
  const [groupedByGrade, setGroupedByGrade] = useState({});
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const { state: gradeState, actions: gradeActions } = useContext(GradeContext);

  const [showAddBtn, setShowAddBtn] = useState([true, true, true]);
  const groupedData = {};

  // 학년별로 데이터 분류
  useEffect(() => {
    classInfos.forEach((item) => {
      const { grade } = item;
      if (!groupedData[grade]) {
        groupedData[grade] = [];
      }
      groupedData[grade].push(item);
    });
    setGroupedByGrade(groupedData);
  }, [classInfos]);

  //+버튼 보여줄지 여부 결정
  useEffect(() => {
    const newShowAddBtn = [true, true, true];

    Object.keys(groupedByGrade).forEach((key) => {
      const index = Number(key) - 1;
      const classes = groupedByGrade[key].map((item) => item.class);
      const maxClass = Math.max(...classes);
      // console.log('key:' + key + ' ,maxClass:' + maxClass);
      if (maxClass == 15) {
        newShowAddBtn[index] = false;
      } else {
        // newShowAddBtn[index] = true;
      }
    });

    setShowAddBtn(newShowAddBtn);
    // console.log('groupedByGrade:' + JSON.stringify(groupedByGrade));
    // console.log('showAddBtn:' + showAddBtn);
  }, [groupedByGrade]);

  //gradeList 만들기
  const gradeList = [...new Set(classInfos.map((item) => item.grade))];
  gradeList.sort((a, b) => a - b);

  //학년 삭제하기
  const navigate = useNavigate();
  const deleteGrade = (grade) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      setRegisterModalOpen(true);
    } else {
      const correct = grade + '학년';
      const answer = prompt(
        '학년 정보를 삭제하면 수업 주제 및 진도 데이터가 모두 삭제됩니다. 현재 학년 데이터를 삭제하시려면 "' +
          grade +
          '학년"을 입력해주세요.',
      );
      if (answer && answer == correct) {
        // console.log(grade + '학년 정보 삭제');

        try {
          axios
            .post('https://teachernote.me/api/deletegrade/' + userId, {
              grade: grade,
            })
            .then(() => {
              // window.location.reload();
              fetchAllClassInfo();
            });
        } catch (err) {
          console.log(err);
        }
      } else {
        // console.log('삭제 안함');
      }
    }
  };

  return (
    <>
      <StyledCard>
        <ClassInfoCardBody>
          {/* {gradeList.map((grade, index) => ( */}
          <ClassInfoCardBodyItem>
            <ClassInfoCardBodyItemLayout1>
              1학년
              <ClassInfoTag onClick={() => deleteGrade(1)}>
                <RiDeleteBin6Line color={`${colors.lightGreen}`} size={18} />
              </ClassInfoTag>
            </ClassInfoCardBodyItemLayout1>
            <ClassInfoCardBodyItemLayout2>
              {showAddBtn[0] && (
                <ClassInfoTag onClick={() => addNextClass(1)}>
                  <MdAdd
                    color={`${colors.lightGreen}`}
                    size={`${iconSize.large}`}
                  />
                </ClassInfoTag>
              )}

              {/* groupedByGrade[grade]가 undefined인 경우를 처리 */}
              {groupedByGrade[1]?.map((classInfo, index) => (
                <ClassInfoListItem
                  key={index}
                  classInfo={classInfo}
                  onRemove={onRemove}
                />
              ))}
            </ClassInfoCardBodyItemLayout2>
          </ClassInfoCardBodyItem>
          <ClassInfoCardBodyItem>
            <ClassInfoCardBodyItemLayout1>
              2학년
              <ClassInfoTag onClick={() => deleteGrade(2)}>
                <RiDeleteBin6Line color={`${colors.lightGreen}`} size={18} />
              </ClassInfoTag>
            </ClassInfoCardBodyItemLayout1>
            <ClassInfoCardBodyItemLayout2>
              {showAddBtn[1] && (
                <ClassInfoTag onClick={() => addNextClass(2)}>
                  <MdAdd
                    color={`${colors.lightGreen}`}
                    size={`${iconSize.large}`}
                  />
                </ClassInfoTag>
              )}

              {/* groupedByGrade[grade]가 undefined인 경우를 처리 */}
              {groupedByGrade[2]?.map((classInfo, index) => (
                <ClassInfoListItem
                  key={index}
                  classInfo={classInfo}
                  onRemove={onRemove}
                />
              ))}
            </ClassInfoCardBodyItemLayout2>
          </ClassInfoCardBodyItem>
          <ClassInfoCardBodyItem
          // isLast={index === gradeList.length - 1}
          >
            <ClassInfoCardBodyItemLayout1>
              3학년
              <ClassInfoTag onClick={() => deleteGrade(3)}>
                <RiDeleteBin6Line color={`${colors.lightGreen}`} size={18} />
              </ClassInfoTag>
            </ClassInfoCardBodyItemLayout1>
            <ClassInfoCardBodyItemLayout2>
              {showAddBtn[2] && (
                <ClassInfoTag onClick={() => addNextClass(3)}>
                  <MdAdd
                    color={`${colors.lightGreen}`}
                    size={`${iconSize.large}`}
                  />
                </ClassInfoTag>
              )}

              {/* groupedByGrade[grade]가 undefined인 경우를 처리 */}
              {groupedByGrade[3]?.map((classInfo, index) => (
                <ClassInfoListItem
                  key={index}
                  classInfo={classInfo}
                  onRemove={onRemove}
                />
              ))}
            </ClassInfoCardBodyItemLayout2>
          </ClassInfoCardBodyItem>
          {/* ))} */}
        </ClassInfoCardBody>
      </StyledCard>
      {/* )} */}
    </>
  );
};

export default ClassInfoList;
