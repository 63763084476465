import BlogView from './BlogView';

const BlogViewContainer = ({ selectedBlog }) => {
  return (
    <>
      <BlogView selectedBlog={selectedBlog} />
    </>
  );
};

export default BlogViewContainer;
