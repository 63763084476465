import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components 정의
const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Results = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const Result = styled.div`
  padding: 10px;
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
`;

function SeatDraw3() {
  const [names, setNames] = useState('');
  const [seating, setSeating] = useState([]);

  const handleNamesChange = (event) => {
    setNames(event.target.value);
  };

  const handleDrawSeats = () => {
    const nameArray = names.split('\n').filter((name) => name.trim() !== '');
    const shuffledNames = nameArray.sort(() => Math.random() - 0.5);
    setSeating(shuffledNames);
  };

  return (
    <Container>
      <Title>자리뽑기 프로그램</Title>
      <TextArea
        placeholder="이름 목록 (줄바꿈으로 구분)"
        rows={10}
        value={names}
        onChange={handleNamesChange}
      />
      <Button onClick={handleDrawSeats}>자리 뽑기</Button>
      <Results>
        {seating.map((name, index) => (
          <Result key={index}>{name}</Result>
        ))}
      </Results>
    </Container>
  );
}

export default SeatDraw3;
