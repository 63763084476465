import {
  ProgressNoteCard,
  ProgressNoteCardLayout1,
  ProgressNoteSelectClass,
} from '../progress/ProgressNoteStyle';

export const SelectClass = ({ children }) => {
  return (
    <ProgressNoteCard>
      {/* <ProgressNoteCardLayout1>학급 선택</ProgressNoteCardLayout1> */}
      <ProgressNoteSelectClass>{children}</ProgressNoteSelectClass>
    </ProgressNoteCard>
  );
};
