import React from 'react';
import styled from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
  iconSize,
  objWidth,
  padding,
} from '../styles/commonStyle';
import { Link, NavLink } from '../../node_modules/react-router-dom/dist/index';
import { IoHome } from 'react-icons/io5';
import { FaTable, FaPen } from 'react-icons/fa';
import { FaSchoolFlag } from 'react-icons/fa6';
import { PiNotepadBold } from 'react-icons/pi';
import { MdOutlineBorderColor, MdPeopleAlt } from 'react-icons/md';

import { BsFillPersonFill } from 'react-icons/bs';

const MobileMenuBar = styled.div`
  width: 100%;

  position: fixed;
  bottom: 0;
  left: 0;

  background-color: ${colors.background};
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
`;

const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${padding.small};
`;

const MenuItem = styled(NavLink)`
  // width: ${objWidth.medium};
  width: 20%;

  text-align: center;
  padding: ${padding.medium};
  color: ${gray.darktext};
  font-size: ${fontSize.extraSmall};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  text-decoration: none;

  &.active {
    color: ${colors.primary};
  }
`;

const MobileBottomMenuBar = () => {
  return (
    <MobileMenuBar>
      <MenuItemsContainer>
        <MenuItem to="/bloglist">
          <IoHome size={`${iconSize.large}`} />
          <br />홈
        </MenuItem>
        <MenuItem to="/progress">
          <FaTable size={`${iconSize.large}`} /> <br />
          진도표
        </MenuItem>
        <MenuItem to="/progressnote/byclass">
          <PiNotepadBold size={`${iconSize.large}`} />
          <br />
          진도노트
        </MenuItem>
        <MenuItem to="/topic">
          <FaPen size={`${iconSize.large}`} />
          <br />
          수업 관리
        </MenuItem>
        <MenuItem to="/classinfo">
          <MdPeopleAlt size={`${iconSize.large}`} />
          <br />
          학급 관리
        </MenuItem>
      </MenuItemsContainer>
    </MobileMenuBar>
  );
};

export default MobileBottomMenuBar;
