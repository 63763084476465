import { useEffect, useState } from 'react';
import BlogGridContainer from './BlogGridContainer';
import axios from 'axios';
import PageContainer from '../../pageLayout/PageContainer';

const BlogGridPage = () => {
  const [blogDB, setBlogDB] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get('https://teachernote.me/api/blogs/');
        setBlogDB(res.data);
        // console.log('blogDB:' + JSON.stringify(res.data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchBlogs();
  }, []);

  return (
    <PageContainer>
      <BlogGridContainer blogs={blogDB} />
    </PageContainer>
  );
};

export default BlogGridPage;
