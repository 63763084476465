import React from 'react';
import styled from 'styled-components';
import { boerderRadius, colors, padding } from '../styles/commonStyle';

// Button 스타일 컴포넌트 정의
const StyledMainButton = styled.button`
  width: 100%;
  margin: 5px 0px;
  padding: ${padding.medium} ${padding.large};
  background-color: ${colors.primary};
  color: #fff;
  border: none;
  border-radius: ${boerderRadius.small};
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 배경색 전환 효과 */

  /* 호버 효과 */
  &:hover {
    background-color: ${colors.primarydark};
  }

  /* 클릭 효과 */
  &:active {
    background-color: ${colors.primarydark};
  }
`;

const MainButton = (props) => {
  return <StyledMainButton {...props} />;
};

export default MainButton;
