import React, { useEffect } from 'react';
import {
  StudentStatusContainer,
  StudentStatus_Cell,
  StudentStatus_Td,
  StudentStatus_Title,
  CircleOverlay,
} from './StudentStatusStyle';
import { BsXLg } from 'react-icons/bs';
import { colors } from '../../../styles/commonStyle';

const StudentStatus = ({
  numberList,
  completedNum,
  notUsingNumbers,
  genderMode,
  localGenderMap,
}) => {
  // numberList를 10개씩 끊어서 배열로 나누기
  const chunkedNumberList = [];
  for (let i = 0; i < numberList.length; i += 10) {
    chunkedNumberList.push(numberList.slice(i, i + 10));
  }

  return (
    <StudentStatusContainer>
      <StudentStatus_Title>자리 배치 현황</StudentStatus_Title>
      <table>
        <tbody>
          {chunkedNumberList.map((chunk, rowIndex) => (
            <tr key={rowIndex}>
              {chunk.map((number, colIndex) => {
                const isCompleted = completedNum.includes(number);
                const isNotUsing = notUsingNumbers.includes(number);

                const gender = localGenderMap[number];

                // 성별에 따른 배경색 설정
                let backgroundColor = '';
                if (genderMode) {
                  if (gender === '여') {
                    backgroundColor = `${colors.femaleMedium}`;
                  } else if (gender === '남') {
                    backgroundColor = `${colors.maleMedium}`;
                  }
                }
                return (
                  <StudentStatus_Td
                    key={colIndex}
                    $isCompleted={isCompleted}
                    $isNotUsing={isNotUsing}
                    style={{ backgroundColor }}
                  >
                    <StudentStatus_Cell>
                      {isCompleted && !isNotUsing && <CircleOverlay />}
                      {isNotUsing ? <BsXLg /> : number}
                    </StudentStatus_Cell>
                  </StudentStatus_Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </StudentStatusContainer>
  );
};

export default StudentStatus;
