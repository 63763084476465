import styled, { keyframes } from 'styled-components';
import { boerderRadius, fontSize, padding } from '../styles/commonStyle';
import { media } from '../styles/media';

//-------------- 모달 공통 스타일 --------------//

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  padding: ${padding.large};

  ${media.mobile`
    padding: ${padding.mediumLarge};
  `}

  position: relative;
  background-color: white;
  border-radius: ${boerderRadius.medium};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

export const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const ModalTitle = styled.div`
  font-size: ${fontSize.extraLarge};
  ${media.mobile`
  font-size: ${fontSize.large};
  `}
  font-weight: 700;
  color: #4a90e2;
  margin-bottom: 20px;
  text-align: center;
`;

export const ModalContents = styled.div`
  font-size: 18px;

  ${media.mobile`
font-size: ${fontSize.medium};
  `}

  color: #333;
  line-height: 1.6;
  color: gr;
`;
