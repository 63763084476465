import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
  pageWidth,
  ratio,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const TableContainer = styled.div`
  height: auto;
  max-height: ${(props) => props.height * 0.8}px;
  //
  overflow-x: auto;
  ${media.desktop`
    min-width: ${pageWidth.desktop};
    max-width: ${(props) => props.width * 0.8}px;ZZz
  `}
  ${media.tablet`
    min-width: ${pageWidth.tablet};
  `}
   ${media.mobile`
    width: 100%;
    min-width: ${pageWidth.mobile};
  `}
`;

export const StyledTable = styled.table`
  width: 100%;

  border-collapse: collapse;
  border: none !important;
  ${media.mobile`
    font-size: ${fontSize.small};
  `}
`;

export const StyledThead = styled.thead`
  border-radius: ${boerderRadius.large};
  font-weight: ${fontWeight.bold};
  background-color: ${colors.lightGreenBackground};

  //세로 스크롤시 고정
  position: sticky;
  top: 0;
  z-index: 22;
`;
export const StyledFirstTh = styled.th`
  //가로, 세로 스크롤시 고정
  position: sticky;
  top: 0;
  left: 0;
  z-index: 23;

  height: ${objHeight.large};
  min-width: ${objWidth.medium};

  background-color: ${colors.lightGreenBackground};
  // padding: ${padding.medium};

  text-align: center;
  font-weight: ${fontWeight.bold};
  border-top: none;
  cursor: pointer;

  // 가운데 셀들만 border 적용
  &:not(:first-child):not(:last-child) {
    border-right: 1px solid ${colors.background};
  }
  &:first-child {
    border-left: 1px solid ${gray.light};
    border-bottom: 1px solid ${colors.background};
    border-right: 1px solid ${colors.background};
  }
  &:last-child {
    border-right: 1px solid ${gray.light};
    border-bottom: 1px solid ${gray.light};
  }
`;
export const StyledTh = styled.th`
  height: ${objHeight.large};

  text-align: center;
  font-weight: ${fontWeight.bold};
  border-top: none;
  // cursor: pointer;

  // 가운데 셀들만 border 적용
  &:not(:first-child):not(:last-child) {
    border-right: 1px solid ${colors.background};
  }
  &:first-child {
    border-left: 1px solid ${gray.light};
    border-bottom: 1px solid ${colors.background};
    border-right: 1px solid ${colors.background};
  }
  &:last-child {
    border-right: 1px solid ${gray.light};
    border-bottom: 1px solid ${gray.light};
  }
`;

export const StyledTbody = styled.tbody`
  border: 1px solid ${gray.light};
`;
export const FirstTr = styled.tr`
  border-left: none;
`;
export const StyledTr = styled.tr`
  border-bottom: 1px solid ${gray.light};
  text-align: center;
  border-top: none;
  &:hover {
    background-color: ${gray.extralight};
  }
`;

export const StyledTd = styled.td`
  padding: ${padding.medium} 0px;
  ${media.mobile`
  padding: ${padding.small} 0px;
  `}

  text-align: center;
  border: 1px solid ${gray.light};

  &:hover {
    background-color: ${colors.primarylight};
    cursor: pointer;
  }
`;

export const StyledFirstTd = styled.td`
  width: ${(props) => (props.$showTopicTitle ? 'auto' : '40px')};
  padding: ${padding.mediumLarge};

  background-color: ${colors.lightGreenBackground};
  border-bottom: 1px solid ${colors.background};
  border-left: none;

  //가로 스크롤 시 고정
  position: sticky;
  left: 0;
  z-index: 1;
  //   &:not(:last-child) {
  //   border-bottom: 1px solid ${colors.background};
  // }
  // &:last-child {
  //   border-bottom: 1px solid ${gray.light};
  // }
`;

export const TopicCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProgressTableClassDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: ${padding.mediumLarge} 0px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ProgressTableClass = styled.div`
  text-decoration: none;
  color: ${gray.darktext};
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledLink = styled(Link)`
  width: ${objWidth.small};
  ${media.mobile`
  width: ${objWidth.linkSmall};
  `}

  padding: ${padding.small} 0;
  margin-bottom: ${margin.small};
  background-color: ${gray.medium};
  color: ${gray.extralight};

  text-decoration: none;
  border-radius: ${boerderRadius.medium};
  text-decoration: none;
  text-align: center;

  cursor: default;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${boerderRadius.medium};
  background-color: ${colors.background};
  width: 100%;
`;
export const StyledTopicInput = styled.input`
  ${media.desktop`
  width: ${objWidth.extraLarge};
  `}

  ${media.tablet`
  width: ${objWidth.large};
  `}

  ${media.mobile`
  width: ${objWidth.mediumLarge};
  `}

  padding: ${padding.small} ${padding.medium};
  border: none;
  font-size: ${fontSize.medium};
  outline: none;
  border-radius: ${boerderRadius.medium};

  transition: border-color 0.3s ease-in-out;
  flex: 1;
  position: relative;
`;

export const SubmitIcon = styled.div`
  cursor: pointer;
  position: relative;
  top: ${padding.mediumLarge};
  right: ${padding.small};
  color: ${colors.lightGreen};
  transform: translateY(-50%);
  &:hover {
    color: ${gray.dark};
  }
`;

export const ProgressTableSelectShowTitle = styled.div`
  padding: ${padding.small} 0;
  margin-bottom: ${padding.small};
  color: ${colors.primary};
  text-decoration: none;
  border-radius: ${boerderRadius.medium};
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-weight: ${fontWeight.bold};
  &:hover {
    background-color: ${colors.lightGreenBackground};
  }
`;
export const ProgressTableSelectMode = styled.div`
  padding: ${padding.small} 0;
  margin-bottom: ${padding.small};
  color: ${colors.primary};
  text-decoration: none;
  border-radius: ${boerderRadius.medium};
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-weight: ${fontWeight.bold};
`;
export const TopicCellTitle = styled.div`
  // width: auto;

  ${media.desktop`
  min-width: ${objWidth.large};
  `}

  ${media.tablet`
  min-width: ${objWidth.large};
  `}

  ${media.mobile`
    min-width: ${objWidth.medium};
  `}
  word-wrap: break-word; /* 길이가 긴 단어를 줄바꿈 */
  word-break: break-all; /* 길이가 긴 단어를 잘라서 줄바꿈 */
  white-space: normal; /* 기본 줄바꿈 방식으로 설정 */
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  &: hover {
    color: ${colors.primary};
  }
`;

export const CheckProgressCell = styled.div`
  width: 100%;
  // width: ${objWidth.mediumLarge};
  min-width: ${objWidth.medium};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const CheckProgressDiv = styled.div`
  padding: ${padding.medium} 0;
  height: 15%;
  cursor: pointer;
  font-weight: ${fontWeight.bold};
  display: flex;
  flex-direction: column;
`;
export const HaveProgressDiv = styled.div`
  cursor: pointer;
  svg {
    color: ${colors.primary};
    transition: color 0.3s ease;
  }

  &:hover svg {
    transform: scale(1.1);
    color: ${colors.primary};
  }
`;
export const NoProgressDiv = styled.div`
  cursor: pointer;

  svg {
    color: ${gray.medium};
    transition: color 0.3s ease;
  }

  &:hover svg {
    transform: scale(1.1);
    color: ${colors.primary};
  }
`;

export const CheckDateDivHaveData = styled.div``;
export const CheckDateDivNoData = styled.div`
  color: ${gray.medium};
  font-weight: ${fontWeight.medium};
`;

export const DateShown = styled.div`
  &:hover {
    color: ${colors.primary};
  }
`;
export const CheckNoteDiv = styled.div`
  height: ${objHeight.medium};
  width: 60%;
  min-width: ${objWidth.small};
  max-width: ${objWidth.medium};

  padding: ${padding.small};
  margin: ${margin.small};

  ${media.mobile`
display: none;
  `}

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: ${gray.extralight};
  background-color: ${colors.lightGreenBackground};

  border-radius: ${boerderRadius.medium};

  color: ${gray.grayText};
  &:hover {
    background-color: ${colors.lightGreenBorder};
    // color: ${colors.primary};
  }

  &:hover svg {
    transform: scale(1.1);
    color: ${colors.primary};
  }
`;

export const NoTopicData = styled.div`
  padding: ${padding.medium} 0;
  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  &:hover {
  }
`;
