import { HelmetContent } from '../../components/HelmetContent';
import PageContainer from '../../pageLayout/PageContainer';
import Register2Container from './Register2Container';

const AuthPage = () => {
  return (
    <>
      <HelmetContent />

      <PageContainer>
        <Register2Container />
      </PageContainer>
    </>
  );
};

export default AuthPage;
