import { HelmetContent } from '../../components/HelmetContent';
import PageContainer from '../../pageLayout/PageContainer';
import useOnDesktop from '../../img/useOnDesktop.png';
import {
  CategoryText,
  CenterAlign,
  PageBox,
  PageBox_noMargin,
  TitleContainer,
  TitleDetail,
  TitleText,
} from '../../styles/BasicStyle';
import { PageContainerBox } from '../../pageLayout/PageContainerStyle';
import { IoEllipsisVertical } from 'react-icons/io5';
import { BlogPageLayout1 } from '../blogList/BlogListStyle';
import { padding } from '../../styles/commonStyle';

const UseOnDesktopPage = () => {
  return (
    <PageContainer>
      <HelmetContent />
      <PageBox_noMargin>
        <TitleContainer>
          <TitleText>데스크탑에서 이용하기</TitleText>
          <TitleDetail>
            바쁜 매일 아침 빠르게 티처노트에 접속해 학급 및 진도를 관리해보세요!
          </TitleDetail>
        </TitleContainer>
        <CenterAlign
          style={{ fontWeight: 'bold', padding: `${padding.medium}` }}
        >
          ①크롬 주소창 오른쪽 <IoEllipsisVertical />
          버튼 클릭 → ②저장 및 공유 → ③페이지를 앱으로 설치
        </CenterAlign>
        <img
          src={useOnDesktop}
          alt="로고"
          style={{ width: '100%', height: 'auto' }}
        />
      </PageBox_noMargin>
    </PageContainer>
  );
};

export default UseOnDesktopPage;
