import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AddNoteSelectBox,
  AddNoteSelectOption,
  AddProgressNoteCardBox,
  AddProgressNoteCardLayout1,
  AddProgressNoteCardLayout2,
  AddProgressNoteCardLayout2_date,
  AddProgressNoteCardLayout2_input,
  AddProgressNoteCardLayout2_text,
  AddProgressNoteCardLayout3,
  AddProgressNoteCardLayout4,
  AddProgressNoteCardLayout5,
  AddProgressNoteCardLayout5Inner,
  AddProgressNoteInput,
  AddProgressNoteSelectTopic,
  AddProgressNoteTextArea,
  DateCustomInputStyle,
} from './AddProgressNoteStyle';
import { NoteContainer, ProgressNoteCard } from '../progress/ProgressNoteStyle';
import GradeContext from '../../contexts/grade';
import axios from 'axios';
import UserContext from '../../contexts/userContext';
import ReactDatePicker from 'react-datepicker';
import KoreanDate from '../../commonFunction/KoreanDate';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../../../node_modules/react-router-dom/dist/index';
import { CardTitle, StyledCard } from '../../components/StyledCard';

const AddProgressNote = ({
  topics,
  paramClassNum,
  paramTopicNum,
  sortedProgressNoteByGrade,
  setShowAddWindow,
  fetchProgressNoteByGrade,
  classInfoByGrade,
  setShowNotification,
}) => {
  const { state: gradeState } = useContext(GradeContext);
  const grade = gradeState.currentGrade;
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;
  const validClassNumbers = classInfoByGrade.map((item) => item.class); // 유효한 classNum 목록

  const [progressNote, setProgressNote] = useState('');
  const [noteInfo, setNoteInfo] = useState({
    grade: parseInt(grade),
    classNum: parseInt(paramClassNum) || '',
    topicNum: paramTopicNum || '',
    record: '',
    recordDate: new Date(),
  });
  const [recordValue, setRecordValue] = useState('');

  const [isGradeRight, setIsGradeRight] = useState(false);
  const [isClassNumRight, setIsClassNumRight] = useState(false);
  const [isTopicNumRight, setIsTopicNumRight] = useState(false);
  const [isRecordRight, setIsRecordRight] = useState(false);

  // console.log(JSON.stringify(sortedProgressNoteByGrade));
  // 페이지가 다른 경로로 이동될 때 placeholder를 비움
  const [placeholder, setPlaceholder] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(JSON.stringify(classInfoByGrade));

  useEffect(() => {
    const previousPathname = localStorage.getItem('previousPathname');
    if (previousPathname && previousPathname !== location.pathname) {
      setRecordValue('');
      setPlaceholder('');
    }
    localStorage.setItem('previousPathname', location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    setNoteInfo((prev) => ({
      ...prev,
      classNum: parseInt(paramClassNum) || '',
      topicNum: paramTopicNum || '',
    }));

    if (noteInfo.topicNum) {
      setIsTopicNumRight(true);
    }
  }, [paramClassNum, paramTopicNum]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setNoteInfo((prev) => ({ ...prev, [name]: value }));
    },
    [noteInfo],
  );

  const handleClassChange = (e) => {
    const classNumber = parseInt(e.target.value);
    if (validClassNumbers.includes(classNumber)) {
      setIsClassNumRight(true);
    } else {
      setIsClassNumRight(false);
    }
    handleChange(e);
  };

  const handleDateChange = (date) => {
    setNoteInfo((prev) => ({ ...prev, recordDate: date }));
  };

  const handleTopicNumChange = (e) => {
    const topicNumber = e.target.value;

    if (topicNumber !== null && topicNumber !== '' && 0 < topicNumber < 35) {
      setIsTopicNumRight(true);
    } else {
      setIsTopicNumRight(false);
    }

    handleChange(e);
  };

  const handleRecordChange = (e) => {
    const record = e.target.value;
    setRecordValue(e.target.value);
    if (record.trim() !== '') {
      setIsRecordRight(true);
    } else {
      setIsRecordRight(false);
    }
    handleChange(e);
  };

  //---------------- 진도 노트 저장  ----------------//
  //기존 진도노트와 일치하는 항목 있는지 확인
  const isDuplicateEntry = sortedProgressNoteByGrade.some((item) => {
    return (
      item.grade === noteInfo.grade &&
      item.classNum === parseInt(noteInfo.classNum) &&
      item.topicNum === parseInt(noteInfo.topicNum)
    );
  });

  //진도노트 저장
  const saveProgressNote = async (noteInfo) => {
    // console.log('noteInfo:' + JSON.stringify(noteInfo));
    // console.log('isDuplicateEntry:' + isDuplicateEntry); // 디버깅용 로그
    if (
      noteInfo.classNum !== '' &&
      isClassNumRight &&
      isTopicNumRight &&
      isRecordRight
    ) {
      if (isDuplicateEntry) {
        const confirmResult = window.confirm(
          '이미 해당 항목에 대한 진도노트가 존재합니다. 진도노트를 업데이트하시겠습니까?',
        );
        if (confirmResult) {
          // 레코드 있는 경우 업데이트
          try {
            await axios
              .put(
                'https://teachernote.me/api/progressnoteone/' + userId,
                noteInfo,
              )
              .then(() => {
                fetchProgressNoteByGrade();
              });
            setRecordValue('');
            setShowAddWindow(false);
            setNoteInfo((prevNoteInfo) => ({
              ...prevNoteInfo,
              topicNum: '',
            }));
          } catch (err) {
            console.log(err);
          }
        } else {
        }
      } else {
        try {
          await axios
            .post(
              'https://teachernote.me/api/addprogressnoteone/' + userId,
              noteInfo,
            )
            .then(() => {
              fetchProgressNoteByGrade();
            });
          setRecordValue('');
          setNoteInfo((prevNoteInfo) => ({
            ...prevNoteInfo,
            topicNum: '',
          }));
          setShowAddWindow(false);
        } catch (err) {
          console.log(err);
        }
      }
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1000);
    } else {
      if (!isClassNumRight) {
        alert(
          noteInfo.classNum +
            '반이 존재하지 않습니다. 수정하시거나, 학급 관리에서 먼저 생성해주세요.',
        );
        setNoteInfo((prev) => ({ ...prev, classNum: '' }));
      } else if (!isTopicNumRight) {
        alert('주제 번호를 선택해주세요.');
      } else {
        alert('양식을 확인해주세요.');
      }
    }
  };

  //React date picker 포멧 변경
  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <DateCustomInputStyle
      className="custom-date-input"
      onClick={onClick}
      ref={ref}
    >
      {value ? value : KoreanDate(noteInfo.recordDate)}
    </DateCustomInputStyle>
  ));

  //주제 선택 option
  const [selectedTopic, setSelectedTopic] = useState('');

  const handleTopicSelect = (option) => {
    setSelectedTopic(option.topicTitle);
  };

  return (
    <NoteContainer>
      <StyledCard>
        <AddProgressNoteCardBox>
          <CardTitle>진도노트 추가</CardTitle>
          <form onSubmit={(e) => saveProgressNote(e, recordValue)}>
            <AddProgressNoteCardLayout2>
              <AddProgressNoteCardLayout2_input>
                {grade}
              </AddProgressNoteCardLayout2_input>
              <AddProgressNoteCardLayout2_text>
                학년
              </AddProgressNoteCardLayout2_text>
              <AddProgressNoteCardLayout2_input>
                <AddProgressNoteInput
                  type="number"
                  placeholder=""
                  name="classNum"
                  value={noteInfo.classNum}
                  onChange={handleClassChange}
                />
              </AddProgressNoteCardLayout2_input>
              <AddProgressNoteCardLayout2_text>
                반
              </AddProgressNoteCardLayout2_text>
              <AddProgressNoteCardLayout2_date>
                <ReactDatePicker
                  selected={noteInfo.recordDate}
                  onChange={handleDateChange}
                  dateFormat="MM/dd(eee)"
                  locale="ko"
                  customInput={<DateCustomInput />}
                  showPopperArrow={false}
                  popperPlacement="bottom-end"
                />
              </AddProgressNoteCardLayout2_date>
            </AddProgressNoteCardLayout2>
            <AddProgressNoteCardLayout3>
              <AddProgressNoteSelectTopic>
                <AddNoteSelectBox
                  name="topicNum"
                  value={noteInfo.topicNum}
                  onChange={handleTopicNumChange}
                >
                  <AddNoteSelectOption value="">수업 주제</AddNoteSelectOption>
                  {topics.map((topic, index) => (
                    <AddNoteSelectOption
                      key={index}
                      value={topic.topicId}
                      name="topicNum"
                    >
                      주제{topic.topicId}{' '}
                      {topic.topicTitle && topic.topicTitle.length > 15
                        ? topic.topicTitle.slice(0, 15) + '...'
                        : topic.topicTitle}
                    </AddNoteSelectOption>
                  ))}
                </AddNoteSelectBox>
              </AddProgressNoteSelectTopic>
            </AddProgressNoteCardLayout3>
            <AddProgressNoteCardLayout4>
              <AddProgressNoteTextArea
                placeholder=""
                // placeholder={isRecordRight ? '' : '내용을 입력하세요'} // isRecordRight가 false이면 placeholder 표시
                type="text"
                value={recordValue}
                name="record"
                onChange={handleRecordChange}
              />
            </AddProgressNoteCardLayout4>
            <button type="submit" style={{ display: 'none' }} />
          </form>
          <AddProgressNoteCardLayout5>
            <AddProgressNoteCardLayout5Inner
              onClick={() => saveProgressNote(noteInfo)}
            >
              저장하기
            </AddProgressNoteCardLayout5Inner>
            <AddProgressNoteCardLayout5Inner
              onClick={() => {
                setShowAddWindow(false);
              }}
            >
              닫기
            </AddProgressNoteCardLayout5Inner>
          </AddProgressNoteCardLayout5>
        </AddProgressNoteCardBox>
      </StyledCard>
    </NoteContainer>
  );
};

export default AddProgressNote;
