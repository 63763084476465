import styled from 'styled-components';
import { colors, gray } from '../styles/commonStyle';
import { media } from '../styles/media';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 불투명한 검정 배경 */
  z-index: 1001; /* CounterContainer보다 낮은 z-index로 설정 */
`;

export const VisitorCounterContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 40px;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   color: white;
  background-color: ${colors.background};
  //   opacity: 0.5;
  border: 1px solid ${gray.medium};
  border-radius: 10px;
  font-size: 1.5em;
  z-index: 1002;
  text-align: center;

  ${media.mobile`
       padding: 10px 20px;
       font-size: 1em;
  `}
`;
