import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from '../../../node_modules/axios/index';
import UserContext from '../../contexts/userContext';
import SeatBeforeSettingContainer from './SeatBeforeSettingContainer';
import Drawing from '../2seatDrawPage/2-4seatDrawing/Drawing';
import SeatSettingComponent from '../2seatDrawPage/2-2seatSetting/SeatSettingComponent';
import StudentSettingComponent from '../2seatDrawPage/2-1studentSetting/StudentSettingComponent';
import PageContainer from '../../pageLayout/PageContainer';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import SelectGradeMenuBar from '../../topSubMenuBar/SelectGradeMenuBar';
import {
  SeatDrawContentBox,
  SeatDrawMain,
  SeatDrawMainButton,
  SeatDrawMainDetail,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleBox_right,
  SeatDrawPageTitleDetail,
} from '../SeatDrawPageStyle';
import SeatBeforeSettingMenu from '../../topSubMenuBar/SeatBeforeSettingMenu';
import { SeatDrawButton } from '../2seatDrawPage/2-1studentSetting/StudentSettingStyle';
import PreAssignmentComponent from './preAssignment/PreAssignmentComponent';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';

const SeatBeforeSettingPage = ({}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;

  const [adjacentNums, setAdjacentNums] = useState([]); //인접 배치
  const [separateNums, setSeparateNums] = useState([]); // 떨어져서 배치
  const [cornerNums, setCornerNums] = useState([]); // 구석

  // 학생 번호
  const [numberList, setNumberList] = useState(
    Array.from({ length: 30 }, (_, index) => index + 1),
  );
  const [notUsingNumbers, setNotUsingNumbers] = useState([]); // 사용하지 않는 번호
  const [genderMap, setGenderMap] = useState({}); // 번호별 성별 정보

  // 자리
  const [seats, setSeats] = useState(
    Array(5) // 세로 자리 수
      .fill()
      .map(() => Array(6).fill(null)), // 가로 자리 수
  );
  const [blockedSeats, setBlockedSeats] = useState(new Set()); // X표시 하여 사용하지 않는 자리

  // 자리뽑기 옵션
  const [pairMode, setPairMode] = useState(false); // 짝 만들기
  const [genderMode, setGenderMode] = useState(false); // 남녀구분
  const [genderSeatRule, setGenderSeatRule] = useState(true); // 남녀 자리 위치 바꾸기
  const [preAssigningSeats, setPreAssigningSeats] = useState({}); // (저장전)미리 배치된 좌석 정보

  //자리 정보 저장, 불러오기
  const [seatPreAssignments, setSeatPreAssignments] = useState({});
  const [avoidPreviousSeats, setAvoidPreviousSeats] = useState(false); // 이전 좌석 피하기 옵션

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  // -------------------- 페이지 로드 시 데이터 불러오기 -------------------- //
  // 페이지가 처음 로드될 때 데이터 불러오기
  useEffect(() => {
    fetchAdjacentNums();
    fetchCornerNums();
    fetchPreAssignments();
  }, [userId]);

  const fetchAdjacentNums = useCallback(async () => {
    try {
      const adjRes = await axios.get(
        `https://teachernote.me/api/getadjacentnums/${userId}`,
      );
      if (adjRes.data) setAdjacentNums(adjRes.data.adjacentNums);
    } catch (error) {
      console.error('Error fetching adjacent numbers:', error);
    }
  }, [userId]);

  const fetchCornerNums = useCallback(async () => {
    try {
      const cornerRes = await axios.get(
        `https://teachernote.me/api/getcornernums/${userId}`,
      );
      if (cornerRes.data) setCornerNums(cornerRes.data.cornerNums);
    } catch (error) {
      console.error('Error fetching corner numbers:', error);
    }
  }, [userId]);

  const fetchPreAssignments = useCallback(async () => {
    try {
      const preAssignRes = await axios.get(
        `https://teachernote.me/api/getPreAssignment/${userId}`,
      );
      if (preAssignRes.data) {
        const assignments = preAssignRes.data.preAssignment;

        // 가져온 데이터를 상태에 설정
        setSeatPreAssignments(assignments);
      }
    } catch (error) {
      console.error('Error fetching pre-assignments:', error);
    }
  }, [userId]);

  // -------------------- 데이터 DB 저장 -------------------- //

  //인접 배치 DB저장
  const saveAdjacentNums = async (adjacentNums) => {
    // console.log('adjacentNums:' + JSON.stringify(adjacentNums));
    try {
      const res = await axios.post(
        'https://teachernote.me/api/saveadjacentnums/' + userId,
        {
          adjacentNums: JSON.stringify(adjacentNums),
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  //떨어져 앉을 학생들 DB저장
  const saveSeparateNums = async (separateNums) => {
    // console.log('separateNums:' + JSON.stringify(separateNums));
    try {
      const res = await axios.post(
        'https://teachernote.me/api/saveseparatenums/' + userId,
        {
          separateNums: JSON.stringify(separateNums),
        },
      );
    } catch (error) {
      console.error(error);
    }
  };
  //구석에 앉을 학생들 DB저장
  const saveCornerNums = async (cornerNums) => {
    // console.log('cornerNums:' + JSON.stringify(cornerNums));
    try {
      const res = await axios.post(
        'https://teachernote.me/api/savecornernums/' + userId,
        {
          cornerNums: JSON.stringify(cornerNums),
        },
      );
    } catch (error) {
      console.error(error);
    }
  };
  //미리 배치한 학생 저장
  const savePreAssignment = async (preAssigningSeats) => {
    // console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
    try {
      const res = await axios.post(
        'https://teachernote.me/api/savePreAssignment/' + userId,
        {
          preAssignment: JSON.stringify(preAssigningSeats),
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  // -------------------- 상단 메뉴바 이동 -------------------- //
  const [selectedMenu, setSelectedMenu] = useState('memberCheck');
  const [scrollToOtherSetting, setScrollToOtherSetting] = useState(false); // 스크롤 플래그

  const mainRef = useRef(null);
  const studentSettingRef = useRef(null);
  const seatSettingRef = useRef(null);
  const otherSettingRef = useRef(null);

  const handleMenuSelect = (menu) => {
    setSelectedMenu(menu);
    if (menu === 'otherSetting') {
      setShowMoreOptions(true);
      setScrollToOtherSetting(true); // 스크롤 플래그 설정
    } else {
      const offset = -100; // 원하는 위치로 조정
      if (menu === 'main') {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); // 페이지 맨 위로 스크롤
      } else if (menu === 'memberCheck') {
        const top =
          studentSettingRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          offset;
        window.scrollTo({ top, behavior: 'smooth' });
      } else if (menu === 'seatSetting') {
        const top =
          seatSettingRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          offset;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    if (scrollToOtherSetting && showMoreOptions) {
      const offset = -100; // 원하는 위치로 조정
      const top =
        otherSettingRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        offset;
      window.scrollTo({ top, behavior: 'smooth' });
      setScrollToOtherSetting(false); // 스크롤 플래그 재설정
    }
  }, [showMoreOptions, scrollToOtherSetting]);

  useEffect(() => {
    const handleScroll = () => {
      const mainTop = window.pageYOffset - 110;
      const studentTop =
        studentSettingRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        120;
      const seatTop =
        seatSettingRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        120;
      const otherTop =
        otherSettingRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        120;
      const scrollPos = window.pageYOffset;

      if (scrollPos < studentTop) {
        setSelectedMenu('main');
      } else if (scrollPos >= studentTop && scrollPos < seatTop) {
        setSelectedMenu('memberCheck');
      } else if (scrollPos >= seatTop && scrollPos < otherTop) {
        setSelectedMenu('seatSetting');
      } else if (scrollPos >= otherTop) {
        setSelectedMenu('otherSetting');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const goSeatDraw = () => {
    const url = '/seatdraw';
    window.open(url, '_blank');
  };

  return (
    <PageContainer>
      <PageSubheader>
        <SeatBeforeSettingMenu
          onSelect={handleMenuSelect}
          selectedMenu={selectedMenu}
        />
      </PageSubheader>
      <PageBox>
        <div ref={mainRef}>
          {/* <SeatDrawContentBox> */}
          <SeatDrawMain>
            <h1>자리 사전 세팅</h1>
            <SeatDrawMainButton onClick={goSeatDraw}>
              자리배치 페이지 바로가기
            </SeatDrawMainButton>
          </SeatDrawMain>
          <SeatDrawMainDetail>
            💡이 페이지에서 설정한 세팅은 '자리 뽑기' 페이지에서 자리 뽑기 시
            반영되어 자리가 설정됩니다.
            <br />
            💡설정한 내용은 반드시 '저장하기'를 눌러야 반영됩니다.
            <br />
          </SeatDrawMainDetail>
          {/* </SeatDrawContentBox> */}
        </div>
        <div ref={studentSettingRef}>
          <StudentSettingComponent
            numberList={numberList}
            setGenderMap={setGenderMap}
            notUsingNumbers={notUsingNumbers}
            setNumberList={setNumberList}
            setNotUsingNumbers={setNotUsingNumbers}
          />
        </div>

        <div ref={seatSettingRef}>
          <SeatSettingComponent
            numberList={numberList}
            setNumberList={setNumberList}
            notUsingNumbers={notUsingNumbers}
            genderMap={genderMap}
            seats={seats}
            setSeats={setSeats}
            blockedSeats={blockedSeats}
            setBlockedSeats={setBlockedSeats}
            avoidPreviousSeats={avoidPreviousSeats}
            setAvoidPreviousSeats={setAvoidPreviousSeats}
            pairMode={pairMode}
            setPairMode={setPairMode}
            genderMode={genderMode}
            setGenderMode={setGenderMode}
            preAssigningSeats={preAssigningSeats}
            setPreAssigningSeats={setPreAssigningSeats}
            genderSeatRule={genderSeatRule}
            setGenderSeatRule={setGenderSeatRule}
            savePreAssignment={savePreAssignment}
          />
        </div>
        <div ref={otherSettingRef}>
          <SeatDrawContentBox>
            <SeatDrawPageTitleBox>
              <SeatDrawPageTitleBox_left>
                <SeatDrawPageTitle>기타 설정</SeatDrawPageTitle>
                <SeatDrawPageTitleDetail>
                  기타 설정사항을 표시합니다.
                </SeatDrawPageTitleDetail>
              </SeatDrawPageTitleBox_left>
              <SeatDrawPageTitleBox_right>
                <SeatDrawButton
                  onClick={() => {
                    setShowMoreOptions(!showMoreOptions);
                  }}
                >
                  {showMoreOptions ? <>닫기</> : <>설정하기</>}
                </SeatDrawButton>
              </SeatDrawPageTitleBox_right>
            </SeatDrawPageTitleBox>
          </SeatDrawContentBox>
        </div>
        {showMoreOptions && (
          <>
            <SeatBeforeSettingContainer
              adjacentNums={adjacentNums}
              setAdjacentNums={setAdjacentNums}
              separateNums={separateNums}
              setSeparateNums={setSeparateNums}
              cornerNums={cornerNums}
              setCornerNums={setCornerNums}
              saveAdjacentNums={saveAdjacentNums}
              saveSeparateNums={saveSeparateNums}
              saveCornerNums={saveCornerNums}
              preAssigningSeats={preAssigningSeats}
              fetchAdjacentNums={fetchAdjacentNums}
              fetchCornerNums={fetchCornerNums}
            />
            <PreAssignmentComponent
              numberList={numberList}
              setNumberList={setNumberList}
              notUsingNumbers={notUsingNumbers}
              genderMap={genderMap}
              seats={seats}
              setSeats={setSeats}
              blockedSeats={blockedSeats}
              setBlockedSeats={setBlockedSeats}
              avoidPreviousSeats={avoidPreviousSeats}
              setAvoidPreviousSeats={setAvoidPreviousSeats}
              pairMode={pairMode}
              setPairMode={setPairMode}
              genderMode={genderMode}
              setGenderMode={setGenderMode}
              preAssigningSeats={preAssigningSeats}
              setPreAssigningSeats={setPreAssigningSeats}
              genderSeatRule={genderSeatRule}
              setGenderSeatRule={setGenderSeatRule}
              savePreAssignment={savePreAssignment}
              seatPreAssignments={seatPreAssignments}
              setSeatPreAssignments={setSeatPreAssignments}
              fetchPreAssignments={fetchPreAssignments}
            />
          </>
        )}
      </PageBox>
    </PageContainer>
  );
};

export default SeatBeforeSettingPage;
