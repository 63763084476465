import { useEffect } from 'react';
import StudentSetting from './StudentSetting';

const StudentSettingContainer = ({
  setGenderMap,
  localGenderMap,
  genderSelectionMode,
  notUsingNumbers,
  setNotUsingNumbers,
  setLocalGenderMap,
  numberList,
  setNumberList,
  setGenderSelectionMode,
  genderMap,
  saveStudentInfo,
  fetchStudentInfo,
}) => {
  // --------------------  성별 설정  -------------------- //
  //학생별 성별 설정
  useEffect(() => {
    setGenderMap(localGenderMap);
  }, [localGenderMap, setGenderMap]);
  const handleMouseEnter = (number) => {
    if (genderSelectionMode && !notUsingNumbers.includes(number)) {
      setLocalGenderMap((prev) => ({
        ...prev,
        [number]: genderSelectionMode,
      }));
    }
  };
  const handleClick = (number) => {
    if (genderSelectionMode && !notUsingNumbers.includes(number)) {
      setLocalGenderMap((prev) => ({
        ...prev,
        [number]: genderSelectionMode,
      }));
    }
  };

  // --------------------  번호 추가  -------------------- //

  //학급 번호 추가
  const addNumber = () => {
    const maxNumber = Math.max(...numberList);
    const newNumber = maxNumber + 1;
    setNumberList((prevList) => [...prevList, newNumber]);
    setGenderMap((prev) => ({
      ...prev,
      [newNumber]: null, // 새로 추가된 번호의 초기 성별은 null로 설정
    }));
  };

  // --------------------  사용 안할 번호 삭제 혹은  X 표시  -------------------- //

  //학급 번호 사용 여부 결정
  const toggleNumberUsage = (num) => {
    const maxNumber = Math.max(...numberList);
    //만약 해당 번호가 가장 큰 숫자라면 숫자 자체 삭제, 그렇지 않다면 그냥 X로 사용 안함 표시
    if (num == maxNumber) {
      setNumberList(numberList.filter((n) => n !== num));
    } else {
      if (notUsingNumbers.includes(num)) {
        // 사용하지 않는 번호 목록에서 제거
        setNotUsingNumbers(notUsingNumbers.filter((n) => n !== num));
      } else {
        // 사용하지 않는 번호 목록에 추가
        setNotUsingNumbers([...notUsingNumbers, num]);
      }
    }
  };

  // --------------------  학생 정보 리셋 -------------------- //

  const initialNumberList = Array.from({ length: 30 }, (_, i) =>
    (i + 1).toString(),
  );
  const initialLocalGenderMap = {};
  const initialNotUsingNumbers = [];

  const resetStudentInfo = () => {
    setNumberList(initialNumberList);
    setLocalGenderMap(initialLocalGenderMap);
    setNotUsingNumbers(initialNotUsingNumbers);
  };

  return (
    <StudentSetting
      setGenderSelectionMode={setGenderSelectionMode}
      numberList={numberList}
      toggleNumberUsage={toggleNumberUsage}
      addNumber={addNumber}
      handleMouseEnter={handleMouseEnter}
      handleClick={handleClick}
      notUsingNumbers={notUsingNumbers}
      localGenderMap={localGenderMap}
      genderMap={genderMap}
      saveStudentInfo={saveStudentInfo}
      fetchStudentInfo={fetchStudentInfo}
      resetStudentInfo={resetStudentInfo}
    />
  );
};

export default StudentSettingContainer;
