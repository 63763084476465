import styled from 'styled-components';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
} from '../styles/commonStyle';

export const SeatDrawMain = styled.div`
  gap: ${gap.large};

  display: flex;
  // flex-direction: column;
  align-items: center;
  // justify-content: flex-end;
  color: ${colors.primary};
`;

export const SeatDrawMainButton = styled.button`
  width: ${objWidth.large};
  height: ${objHeight.la};
  padding: 10px 20px;

  border-radius: ${boerderRadius.small};

  background-color: ${colors.primary};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primarydark};
    cursor: pointer;
  }
`;
export const SeatDrawMainDetail = styled.div`
  text-align: left;
  margin-bottom: ${margin.large};
`;

export const SeatDrawContentBox = styled.div`
  // width: 50%;
  padding: ${margin.mediumlarge} ${margin.mediumlarge};
`;

export const SeatDrawPageTitleBox = styled.div`
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SeatDrawPageTitleBox_left = styled.div``;
export const SeatDrawPageTitleBox_right = styled.div`
  display: flex;
  gap: ${gap.extraSmall};
`;

export const SeatDrawPageTitle = styled.div`
  padding: 0 ${padding.medium} 0 0;
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
`;
export const SeatDrawPageTitleDetail = styled.div`
  color: ${gray.darktext};
  font-size: ${fontSize.medium};
`;

export const SeatDrawPageContentBox = styled.div`
  width: 100%;
  // height: 100%;
  gap: ${gap.medium};
  display: flex;
  align-items: center;
  // background-color: red;
`;

export const SeatDrawPageContentBox_left = styled.div`
  flex: 0 0 auto;
  // background-color: yellow;
`;
export const SeatDrawPageContentBox_right = styled.div`
  flex: 1;
  height: 100%;
  // padding: ${padding.medium};
  gap: ${gap.extraSmall};
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-color: green;
`;

export const SeatDrawDetailBox = styled.div`
  padding: ${padding.small} 0;
  font-size: ${fontSize.medium};
  color: ${gray.darktext};
`;

export const SeatMapContentBox = styled.div`
  margin-top: ${margin.mediumlarge};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;

export const SeatGridAndEditButtions = styled.div`
  display: flex;
  justify-content: center;
`;
export const SeatGridAndEditButtions_left = styled.div``;
export const SeatGridAndEditButtions_right = styled.div`
  display: flex;
  align-items: center;
  //   gap: ${gap.medium};
`;

export const SeatDrawSecondaryButton = styled.button`
  // width: ${objWidth.mediumLarge};
  // padding: 10px 20px;
  padding: ${padding.small} ${padding.medium};

  border-radius: ${boerderRadius.small};

  font-weight: ${fontWeight.bold};
  background-color: ${colors.background};
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
    cursor: pointer;
  }
`;
export const SeatDrawThirdButton = styled.button`
  // width: ${objWidth.medium};
  padding: ${padding.small};

  border-radius: ${boerderRadius.medium};

  background-color: ${gray.medium};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
    cursor: pointer;
  }
`;

// 구석에 앉을 학생 corner nums
export const CurrentStudentSet = styled.div`
  margin-bottom: ${margin.small};

  border: 1px solid ${gray.medium};
  border-radius: ${boerderRadius.medium};

  padding: ${padding.medium};

  font-size: ${fontSize.large};
  color: ${gray.darktext};
`;

export const CornerNum = styled.span`
  padding: ${padding.extraSmall} ${padding.medium} ${padding.extraSmall}
    ${padding.mediumLarge};
  background-color: ${gray.light};
  border-radius: ${boerderRadius.medium};
  margin-right: ${margin.small};
  cursor: pointer;
  display: inline-flex; /* 요소의 크기만큼만 가로 길이를 차지 */
  align-items: center;
  justify-content: space-between;
  gap: ${gap.extraSmall};
`;

export const StudentSettingInput = styled.input`
  height: ${objHeight.small};
  padding: 8px;
  margin-right: ${margin.small};

  font-size: ${fontSize.medium};
  border: 1px solid ${gray.medium};
  border-radius: ${boerderRadius.small};
  &:focus {
    // border: 1px solid ${colors.lightGreen}; /*소용없음*/
    box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.2); /* 포커스 효과 추가 */
  }
`;

export const StudentSettingInput_wide = styled(StudentSettingInput)`
  width: ${objWidth.large};
`;

export const StudentSettingInput_narrow = styled(StudentSettingInput)`
  width: 85px;
`;

//가까이 앉을 학생
