import { useNavigate } from 'react-router-dom';
import {
  BlogBtn,
  BlogListContent,
  BlogListCotentBox,
  BlogListCotentContainer,
  BlogListImage,
  BlogListLeft,
  BlogListPageBox,
  BlogListPageContainer,
  BlogListRight,
  BlogListTitle,
  BlogPageCategory,
  BlogPageLayout1,
} from './BlogListStyle';
import logo from '../../img/logo_green.png';
import { useMediaQuery } from 'react-responsive';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import TruncateText from '../../commonFunction/TruncateText';
import UserContext from '../../contexts/userContext';
import RegisterModalComponent from '../registerModal/RegisterModalComponent';
import LoginModalComponent from '../loginModal/LoginModalComponent';

const BlogList = ({ blogs }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  const navigate = useNavigate();
  const handleClick = () => {
    if (userId) {
      navigate('/blogwrite');
    } else {
      alert('로그인 후 이용가능합니다.');
      // navigate('/register');
      setRegisterModalOpen(true);
    }
  };
  //--------------------- 로그인/회원가입 --------------------//
  //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton'); //menubar,bottombutton

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  //--------------------- 스크롤 이벤트 핸들러 --------------------//
  // 스크롤 이벤트 핸들러

  const [visiblePosts, setVisiblePosts] = useState(4); // 초기 10개의 포스트만 보이도록 설정
  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 4); // 스크롤 될 때마다 10개의 포스트를 추가로 로드
    }
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);
  const goBlogView = (postId) => {
    navigate(`/blog/${postId}`);
  };
  //desktop, tablet, mobile여부에 따라 보여줄 글자 수 다르게
  let textLimit = 100;
  if (isDesktop) {
    textLimit = 200;
  } else if (isTablet) {
    textLimit = 100;
  } else if (isMobile) {
    textLimit = 20;
  }

  useEffect(() => {
    // console.log(JSON.stringify(blogs));
  }, [blogs]);

  const isNew = (date) => {
    const currentDate = new Date();
    const articleDate = new Date(date);
    const diffTime = Math.abs(currentDate - articleDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 30;
  };

  return (
    <>
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}
      <BlogListPageContainer>
        <BlogListPageBox>
          {!isMobile && (
            <BlogPageLayout1>
              <BlogPageCategory>카테고리</BlogPageCategory>
              <BlogBtn onClick={handleClick}>글쓰기</BlogBtn>
            </BlogPageLayout1>
          )}

          <BlogListCotentContainer>
            {blogs.slice(0, visiblePosts).map((post) => (
              <BlogListCotentBox
                key={`blog-${post.id}`}
                onClick={() => goBlogView(post.id)}
              >
                <BlogListLeft>
                  <BlogListImage src={logo}></BlogListImage>
                </BlogListLeft>
                <BlogListRight>
                  <BlogListTitle>
                    {TruncateText(post.title, textLimit)}{' '}
                    {isNew(post.createdAt) && (
                      <span style={{ color: 'red' }}>new</span>
                    )}
                  </BlogListTitle>
                  <BlogListContent>
                    {TruncateText(
                      post.content.replace(/<\/?[^>]+(>|$)/g, ''),
                      textLimit,
                    )}
                  </BlogListContent>
                </BlogListRight>
              </BlogListCotentBox>
            ))}
            <div ref={loader} />
          </BlogListCotentContainer>
        </BlogListPageBox>
      </BlogListPageContainer>
    </>
  );
};

export default BlogList;
