import {
  StyledTable,
  StyledTd,
  StyledTh,
} from '../../../pages/progress/ProgressTableStyle';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  margin,
  objWidth,
} from '../../../styles/commonStyle';
import styled from 'styled-components';

export const SeatStudentSetting_Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse; /* 셀 간의 간격 제거 */
  border: 1px solid ${gray.light};
`;

export const SeatStudentSetting_Thead = styled.thead`
  background-color: ${gray.light};
`;

export const SeatStudentSetting_FirstTh = styled.th`
  width: ${objWidth.smallMedium};
  height: ${objWidth.smallMedium};
  border-right: 1px solid ${colors.background};
  border-bottom: 1px solid ${colors.background};

  // width: 50px;
  // min-width: 50px;
`;
export const SeatStudentSetting_Th = styled.th`
  width: ${objWidth.smallMedium};
  height: ${objWidth.smallMedium};
  border-right: 1px solid ${colors.background};

  // width: 50px;
`;

export const SeatStudentSetting_FirstTd = styled.td`
  width: ${objWidth.smallMedium};
  height: ${objWidth.smallMedium};

  // border-left: 1px solid ${colors.background};
  border-bottom: 1px solid ${colors.background};

  font-weight: ${fontWeight.bold};
  background-color: ${gray.light};
  display: flex;
  justify-content: center;
  align-items: center;

  // width: 50px;
`;
export const SeatStudentSetting_Td = styled.td`
  width: ${objWidth.smallMedium};
  height: ${objWidth.smallMedium};

  border-right: 1px solid ${gray.light};

  background-color: ${({ isDisabled }) =>
    isDisabled ? `${gray.medium}` : 'inherit'};
  color: ${({ isDisabled }) =>
    isDisabled ? `${colors.background}` : 'inherit'};
  font-size: ${({ isDisabled }) =>
    isDisabled ? `${fontSize.extraLarge}` : 'inherit'};
`;

export const SeatStudentSetting_Tbody = styled.tbody`
  border: 1px solid ${gray.light};
`;

export const PlusButtonTh = styled(StyledTh)`
  width: auto;
  min-width: auto;
`;

export const StudentInfoCell = styled.div`
  width: 100%;
  // min-width: ${objWidth.small};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  //   background-color: red;
`;
export const AddNumberButton = styled.button`
width: ${objWidth.smallMedium};
  font-size: ${fontSize.extraLarge};
  border: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);seatdrawbutton
  }
`;

export const SeatDrawButton = styled.button`
  width: ${objWidth.mediumLarge};
  padding: 10px 20px;

  border-radius: ${boerderRadius.small};

  background-color: ${colors.primary};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primarydark};
    cursor: pointer;
  }
`;

export const SeatDrawAdditionalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  // justify-content: flex-end;
  gap: ${gap.extraSmall};
  margin-bottom: ${margin.small};
`;

export const SeatDrawAdditionalButton = styled.button`
  width: 150px;
  padding: 10px 20px;

  border-radius: ${boerderRadius.small};

  background-color: ${gray.medium};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
  }
`;
