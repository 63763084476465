import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  SeatDrawContentBox,
  SeatMapContentBox,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleBox_right,
  SeatDrawPageTitleDetail,
  SeatGridAndEditButtions,
  SeatGridAndEditButtions_left,
  SeatDrawDetailBox,
  SeatDrawSecondaryButton,
} from '../../SeatDrawPageStyle';
import { SeatDrawButton } from '../../2seatDrawPage/2-1studentSetting/StudentSettingStyle';
import {
  Blackboard,
  Seat,
  SeatGrid,
  SeatInner,
  SeatMapContainer,
  SeatmapNumberButton,
  SeatmapNumberInput,
} from '../../2seatDrawPage/2-5afterDrawing/SeatDrawMapStyle';

const PreAssignment = ({
  pairMode,
  genderMode,
  setIsPreAssignMode,
  isPreAssignMode,

  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  selectedSeat,
  handleSeatClick,
  handlePreAssignInput,

  savePreAssignment,
  inputRef,
  seatPreAssignments,
  handleReset,
  handleLoad,
}) => {
  const location = useLocation();
  const [isSeatEditMode, setIsSeatEditMode] = useState(true);

  useEffect(() => {
    // console.log('seatPreAssignments:' + JSON.stringify(seatPreAssignments));
  }, [seatPreAssignments]);

  return (
    <SeatDrawContentBox>
      <SeatDrawPageTitleBox>
        <SeatDrawPageTitleBox_left>
          <SeatDrawPageTitle>미리 배치</SeatDrawPageTitle>
          <SeatDrawPageTitleDetail>
            특정 학생을 미리 원하는 자리에 배치해놓으면 자리 뽑기 페이지에서
            해당 학생은 미리 설정한 자리에 배치됩니다.
          </SeatDrawPageTitleDetail>
        </SeatDrawPageTitleBox_left>
        <SeatDrawPageTitleBox_right>
          {isSeatEditMode ? (
            <>
              <SeatDrawSecondaryButton onClick={handleReset}>
                배치 리셋
              </SeatDrawSecondaryButton>
              <SeatDrawSecondaryButton onClick={handleLoad}>
                불러오기
              </SeatDrawSecondaryButton>
              <SeatDrawButton>저장하기</SeatDrawButton>
            </>
          ) : (
            <SeatDrawButton
              onClick={() => {
                setIsSeatEditMode(!isSeatEditMode);
              }}
            >
              수정하기
            </SeatDrawButton>
          )}
        </SeatDrawPageTitleBox_right>
      </SeatDrawPageTitleBox>
      {isSeatEditMode && (
        <>
          <SeatMapContentBox>
            <SeatMapContainer>
              <SeatGridAndEditButtions>
                <SeatGridAndEditButtions_left>
                  <SeatGrid $columns={seats[0].length}>
                    {seats.map((row, rowIdx) =>
                      row.map((seat, colIdx) => {
                        const seatKey = `${rowIdx}-${colIdx}`;
                        const isBlocked = blockedSeats.has(seatKey);
                        const preAssignedNumber =
                          preAssigningSeats[seatKey] ||
                          seatPreAssignments[seatKey];
                        const seatNumber = seat ? `${seat}` : ``;
                        const seatGender = seat ? genderMap[seat] : null;
                        const genderMismatch =
                          genderMode &&
                          seatGender &&
                          ((seatGender === '남' && colIdx % 2 !== 0) ||
                            (seatGender === '여' && colIdx % 2 === 0));

                        return (
                          <Seat
                            key={seatKey}
                            $pairmode={pairMode}
                            $genderMode={genderMode}
                            $col={colIdx}
                            $blocked={isBlocked}
                            $genderMismatch={genderMismatch}
                            $genderSeatRule={genderSeatRule}
                            onClick={() => handleSeatClick(rowIdx, colIdx)}
                          >
                            {selectedSeat === seatKey ? (
                              <SeatInner>
                                <SeatmapNumberInput
                                  ref={inputRef}
                                  type="number"
                                  placeholder="번호 입력"
                                  onBlur={handlePreAssignInput}
                                  autoFocus
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      handlePreAssignInput(e);
                                    }
                                  }}
                                />
                                <SeatmapNumberButton
                                  onClick={handlePreAssignInput}
                                >
                                  확인
                                </SeatmapNumberButton>
                              </SeatInner>
                            ) : (
                              <>
                                {isBlocked ? (
                                  <span className="blocked-text">X</span>
                                ) : preAssignedNumber ? (
                                  preAssignedNumber
                                ) : (
                                  seatNumber
                                )}
                              </>
                            )}
                          </Seat>
                        );
                      }),
                    )}
                  </SeatGrid>
                </SeatGridAndEditButtions_left>
              </SeatGridAndEditButtions>
              <Blackboard>칠판</Blackboard>
            </SeatMapContainer>
          </SeatMapContentBox>
          <SeatDrawDetailBox>
            <div>📌해당 자리를 클릭한 후 학생 번호를 입력하시면 됩니다.</div>
          </SeatDrawDetailBox>{' '}
        </>
      )}
    </SeatDrawContentBox>
  );
};

export default PreAssignment;
