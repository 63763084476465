import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors, margin } from '../../styles/commonStyle';

const NotFoundPage = () => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    // background: linear-gradient(
    //   135deg,
    //   ${colors.lightGreenBackground},
    //   ${colors.lightGreenBorder}
    // );
  `;
  const Subtitle = styled.div``;

  return (
    <Container>
      <h1 style={{ color: `${colors.primary}` }}>찾을 수 없는 페이지</h1>
      <div style={{ marginBottom: `${margin.medium}` }}>
        항상 티처노트를 이용해주셔서 감사합니다.
        <br />
        서비스 이용에 문제가 있으신 경우 아래에 남겨주시면 신속하게 조치하도록
        하겠습니다.
      </div>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeoEHQRhUYWfQlczq_pmE6de980K8LZQyinNTzJtwo-5xsMtg/viewform?embedded=true"
        width="100%"
        height="1188"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        로드 중…
      </iframe>
    </Container>
  );
};

export default NotFoundPage;
