import styled from 'styled-components';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
  ratio,
  height,
  gap,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const NoteContainer = styled.div`
  width: 100%;
`;

export const AddNoteMobile = styled.div`
  // width: 100%;
  // margin-bottom: ${margin.mediumlarge};
  ${media.desktop`
      display: none;
  `}
  ${media.tablet`
      display: none;
  `}

  width: 50px;
  height: 50px;

  position: fixed;
  bottom: 80px;
  right: 15px;

  background-color: ${colors.primary};
  border: none;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;

  color: ${colors.primary};
  font-weight: ${fontWeight.bold};

  display: flex;
  justify-content: center;
  // background-color: pink;
`;

//----------------  AllTopicModeContainer  ----------------//
export const ProgressNoteCard = styled.div`
  height: auto;
  width: 100%;
  margin-bottom: ${margin.mediumlarge};

  border: 1px solid ${gray.light};
  border-radius: ${boerderRadius.large};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  background-color: ${colors.background};
  font-size: ${fontSize.medium};
  ${media.mobile`
  font-size: ${fontSize.small};
  `}
`;
//토픽 타이틀 or 학급명(Class mode)
export const ProgressNoteCardLayout1 = styled.div`
  height: ${objHeight.large};
  padding: ${padding.medium} 0 ${padding.small} ${padding.large};
  ${media.mobile`
    height: ${objHeight.medium};
    padding: ${padding.medium}  ;

  `}
  font-weight: ${fontWeight.bold};

  border-radius: ${boerderRadius.large} ${boerderRadius.large} 0px 0px;
  background-color: ${gray.light};
  color: ${gray.darktext};

  display: flex;
  align-items: center;
  justify-content: center;
`;
//수업 내용
export const ProgressNoteCardLayout2 = styled.div`
  height: auto;
  padding: ${padding.mediumLarge} ${padding.large};

  background-color: ${gray.extralight};
  color: ${gray.medium};
  cursor: default;

  display: flex;
  align-items: center;
`;

//학급별 기록
export const ProgressNoteCardLayout3 = styled.div`
  height: auto;
  padding: ${padding.medium};

  border-radius: 0 0 ${boerderRadius.large} ${boerderRadius.large};
  color: ${gray.darktext};
  &:hover {
    background-color: ${gray.extralight};
  }

  overflow-y: auto;
  display: flex;
  justify-content: left;
  flex-direction: column;
`;

//All Class Mode

export const ProgressNoteCardLayout3Inner = styled.div`
  height: auto;
  padding: ${padding.medium};

  ${media.mobile`
  padding: ${padding.small};
  `}

  cursor: default;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressNoteCardLayout23Inner1 = styled.div`
  width: 12%;
`;
export const ProgressNoteCardLayout23Inner2 = styled.div`
  width: 65%;
  padding: 0px ${padding.medium};

  cursor: default;
  word-wrap: break-word;
  white-space: pre-wrap;
  height: auto;
`;
export const ProgressNoteCardLayout23Inner3 = styled.div`
  width: 20%;
  display: flex;
  justify-content: end;
  padding-right: ${padding.medium};
  align-items: center;
`;

//All Class Mode - 수업 타이틀 / 입력일
export const ProgressNoteCardLayout3Inner1 = styled.div`
  width: 10%;
  ${media.mobile`
  width: 20%;
  `}
  margin-right: ${margin.mediumlarge};
  display: flex;
  align-items: center;
  // background-color: red;
`;
export const ProgressNoteCardLayout3Inner2 = styled.div`
  width: 70%;
  height: auto;
  ${media.mobile`
width: 80%;
  `}

  cursor: default;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  // background-color: yellow;
`;
export const ProgressNoteCardLayout3Inner3 = styled.div`
  width: 20%;
  height: auto;
  // margin-left: ${margin.medium};

  ${media.mobile`
    display: none;
  `}
  cursor: default;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  // background-color: green;
`;
export const ProgressNoteCardLayout3Inner4 = styled.div`
  width: 90%;
  height: auto;
  ${media.mobile`
width: 80%;
  `}

  cursor: default;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  // background-color: blue;
`;

//토픽 번호
export const TopicStyledLink = styled(Link)`
  padding: ${padding.small} ${padding.medium};
  margin: 0px ${margin.medium} 0px 0px;
  background-color: ${colors.primary};
  color: ${gray.light};
  font-weight: ${fontWeight.medium};

  text-decoration: none;
  border-radius: ${boerderRadius.medium};
  text-decoration: none;
  text-align: center;

  &:hover {
    background-color: ${colors.primarydark};
  }
`;

//학급
export const ClassStyledLink = styled(Link)`
  padding: ${padding.small};
  margin: 0px ${margin.medium} 0px 0px;
  background-color: ${gray.light};
  color: ${gray.darktext};
  text-decoration: none;
  border-radius: ${boerderRadius.medium};
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: ${gray.medium};
    color: ${colors.background};
  }
`;
//---------------- ByClassProgressNote  ----------------//

export const ProgressNoteByClassTopic = styled.div`
  width: 100%;
  padding: ${padding.small};
  // margin-right: ${margin.large};

  background-color: ${gray.darktext};
  color: ${colors.background};
  font-weight: ${fontWeight.medium};
  ${media.mobile`
  font-size: ${fontSize.small};
  `}

  text-decoration: none;
  border-radius: ${boerderRadius.medium};
  text-decoration: none;
  text-align: center;
`;

export const ProgressNoteTopicTitle = styled.div`
  width: 100%;
  ${media.mobile`
  font-size: ${fontSize.small};
  `}
  word-wrap: break-word; /* 길이가 긴 단어를 줄바꿈 */
  word-break: break-all; /* 길이가 긴 단어를 잘라서 줄바꿈 */
  white-space: normal; /* 기본 줄바꿈 방식으로 설정 */
`;

export const ProgressNoteClassRecord = styled.div`
  width: 100%;
  // width: 50px;
  padding: ${padding.small};
  // margin-right: ${margin.large};

  background-color: ${gray.light};
  color: ${gray.darktext};
  font-weight: ${fontWeight.medium};

  ${media.mobile`
  font-size: ${fontSize.small};
  `}
  text-decoration: none;
  border-radius: ${boerderRadius.medium};
  text-decoration: none;
  text-align: center;
`;

export const ProgressNoteClassRecord2 = styled.div`
  // padding-right: ${padding.large};
  width: 100%;
  height: auto;
  ${media.mobile`
    max-height: 200px;
    font-size: ${fontSize.small};
  `}

  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: ; /* 줄 수 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; /* 길이가 긴 단어를 줄바꿈 */
  word-break: break-all; /* 길이가 긴 단어를 잘라서 줄바꿈 */
  white-space: normal; /* 기본 줄바꿈 방식으로 설정 */
  &:hover {
    color: ${colors.primary};
  }
`;

export const MobileProgressRecordBtns = styled.div`
  padding: ${padding.small};
  gap: ${gap.small};
  ${media.desktop`
display:none;
  `}
  ${media.tablet`
display:none;
  `}

  display: flex;
  justify-content: right;
`;

export const ProgressNoteClassDelete = styled.div`
  width: ${objWidth.medium};
  padding: ${padding.small};
  margin: 0 0 0 ${margin.medium};

  border: 1px solid ${colors.lightGreen};
  border-radius: ${boerderRadius.medium};
  text-align: center;

  color: ${colors.lightGreen};
  // background-color: ${gray.light};
  background-color: ${colors.background};
  // color: ${gray.dark};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    // background-color: ${gray.medium};
    // color: ${colors.background};
    background-color: ${colors.lightGreenBackground};
  }
`;

export const NoProgressNoteStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const NoProgressNoteDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: ${padding.large} 0px ${padding.medium} 0px;
  display: flex;
  justify-content: center;
  color: ${gray.medium};
`;

export const PrimaryText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  padding-bottom: ${padding.large};
`;

//---------------- Select Class   ----------------//
export const ProgressNoteSelectClass = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: space-around;
  padding: ${padding.medium};
  flex-wrap: wrap;
  background-color: ${gray.extralight};
  border-radius: ${boerderRadius.large};
`;
//----------------  클릭 시 진도기록 수정  ----------------//
//byclass/all 모든 학급

export const ProgressRecordTextareaContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${boerderRadius.medium};
  background-color: ${gray.extralight};
  width: 100%;
`;
export const ProgressRecordTextarea = styled.textarea`
  font-family: 'Noto Sans KR', sans-serif;
  resize: none;
  height: ${objHeight.extraLarge};
  padding: ${padding.small} ${padding.medium};
  border: none;
  font-size: ${fontSize.medium};
  ${media.mobile`
  font-size: ${fontSize.small};
  `}
  outline: none;
  border-radius: ${boerderRadius.medium};
  background-color: ${gray.light};
  color: ${gray.darktext};

  transition: border-color 0.3s ease-in-out;
  flex: 1;
  position: relative;
`;
