import { NavLink } from '../../node_modules/react-router-dom/dist/index';
import LogoutComponent from '../components/Logout';
import { GreenBadge, NewBadge } from '../styles/BasicStyle';
import { SubheaderBarContainer } from './HeaderMenuBarStyle';

export const SubHeaderMenubar_SeatDraw = () => {
  return (
    <>
      <SubheaderBarContainer>
        <NavLink to="/drawseat">자리뽑기</NavLink>
        <NavLink to="/drawsetting">
          자리뽑기 <GreenBadge>Beta</GreenBadge>
        </NavLink>
      </SubheaderBarContainer>
    </>
  );
};

export const SubHeaderMenubar_Progress = () => {
  return (
    <>
      {' '}
      <SubheaderBarContainer>
        <NavLink to="/progress">진도표</NavLink>
        <NavLink to="/progressnote/byclass">진도노트</NavLink>
        <NavLink to="/topic">수업주제 설정</NavLink>
        <NavLink to="/classinfo">담당학급 설정</NavLink>
      </SubheaderBarContainer>
    </>
  );
};
export const SubHeaderMenubar_User = () => {
  return (
    <>
      {' '}
      <SubheaderBarContainer>
        <NavLink>
          <LogoutComponent />
        </NavLink>
      </SubheaderBarContainer>
    </>
  );
};
