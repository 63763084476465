import { useCallback, useContext, useEffect, useState } from 'react';
import SeatSettingContainer from './SeatSettingContainer';
import UserContext from '../../../contexts/userContext';
import axios from 'axios';

const SeatSettingComponent = ({
  numberList,
  setNumberList,
  notUsingNumbers,

  seats,
  setSeats,
  pairMode,
  setPairMode,
  genderMode,
  setGenderMode,
  blockedSeats,
  setBlockedSeats,

  genderMap,

  genderSeatRule,
  setGenderSeatRule,
  preAssignment,
  preAssigningSeats,
  setPreAssigningSeats,
  savePreAssignment,
  setIsDrawSettingOpen,
  setMaleSeatsNum,
  setfemaleSeatsNum,
  saveSeatInfo,
  loadSeatInfo,
  seatPreAssignments,
  setSeatPreAssignments,
  openRegisterModal,
}) => {
  return (
    <SeatSettingContainer
      setSeats={setSeats}
      setBlockedSeats={setBlockedSeats}
      setPairMode={setPairMode}
      pairMode={pairMode}
      setGenderMode={setGenderMode}
      genderMode={genderMode}
      seats={seats}
      blockedSeats={blockedSeats}
      preAssigningSeats={preAssigningSeats}
      genderMap={genderMap}
      genderSeatRule={genderSeatRule}
      setGenderSeatRule={setGenderSeatRule}
      setPreAssigningSeats={setPreAssigningSeats}
      numberList={numberList}
      notUsingNumbers={notUsingNumbers}
      saveSeatInfo={saveSeatInfo}
      loadSeatInfo={loadSeatInfo}
      setNumberList={setNumberList}
      savePreAssignment={savePreAssignment}
      setIsDrawSettingOpen={setIsDrawSettingOpen}
      setMaleSeatsNum={setMaleSeatsNum}
      setfemaleSeatsNum={setfemaleSeatsNum}
      // isChangeSeatMode={isChangeSeatMode}
      // setIsChangeSeatMode={setIsChangeSeatMode}

      seatPreAssignments={seatPreAssignments}
      setSeatPreAssignments={setSeatPreAssignments}
      openRegisterModal={openRegisterModal}
    />
  );
};
export default SeatSettingComponent;
