import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.cols}, 50px);
  grid-template-rows: repeat(${(props) => props.rows}, 50px);
  grid-gap: 5px;
  margin-bottom: 20px;
`;

const GridItem = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  background-color: ${(props) => (props.active ? '#4CAF50' : 'white')};
  cursor: pointer;
  user-select: none;
`;

const RowContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const RowDrag = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  background-color: ${(props) => (props.active ? '#4CAF50' : 'white')};
  cursor: pointer;
  user-select: none;
`;

const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const ColDrag = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  background-color: ${(props) => (props.active ? '#4CAF50' : 'white')};
  cursor: pointer;
  user-select: none;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Label = styled.label`
  display: block;
  margin: 10px 0;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Results = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const Result = styled.div`
  padding: 10px;
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
`;

function SeatDraw1() {
  const [rows, setRows] = useState(5);
  const [cols, setCols] = useState(5);
  const [grid, setGrid] = useState(
    Array(5)
      .fill()
      .map(() => Array(5).fill(false)),
  );
  const [names, setNames] = useState('');
  const [seating, setSeating] = useState([]);
  const [paired, setPaired] = useState(false);
  const [startNum, setStartNum] = useState(1);
  const [endNum, setEndNum] = useState(100);
  const [excludeNums, setExcludeNums] = useState('');

  const handleDrawSeats = () => {
    const nameArray = names.split('\n').filter((name) => name.trim() !== '');
    const excludeArray = excludeNums
      .split(',')
      .map((num) => parseInt(num.trim(), 10));
    const activePositions = grid
      .flatMap((row, rowIndex) =>
        row.map((active, colIndex) =>
          active ? rowIndex * grid[0].length + colIndex + 1 : null,
        ),
      )
      .filter((position) => position !== null);

    const availableSeats = activePositions.filter(
      (seat) => !excludeArray.includes(seat),
    );
    const shuffledNames = nameArray.sort(() => Math.random() - 0.5);
    const shuffledSeats = availableSeats.sort(() => Math.random() - 0.5);

    let newSeating = [];
    for (let i = 0; i < shuffledNames.length; i++) {
      newSeating.push({ name: shuffledNames[i], seat: shuffledSeats[i] });
    }

    setSeating(newSeating);
  };

  const toggleRow = (index) => {
    const newRows = rows === index + 1 ? 0 : index + 1;
    setRows(newRows);
    setGrid(
      Array(newRows)
        .fill()
        .map(() => Array(cols).fill(false)),
    );
  };

  const toggleCol = (index) => {
    const newCols = cols === index + 1 ? 0 : index + 1;
    setCols(newCols);
    setGrid(
      Array(rows)
        .fill()
        .map(() => Array(newCols).fill(false)),
    );
  };

  return (
    <Container>
      <Title>자리뽑기 프로그램</Title>
      <Section>
        <Label>가로로 드래그하여 행을 설정하세요:</Label>
        <RowContainer>
          {Array.from({ length: 10 }).map((_, index) => (
            <RowDrag
              key={index}
              active={index < rows}
              onMouseEnter={(e) => e.buttons === 1 && toggleRow(index)}
            />
          ))}
        </RowContainer>
        <Label>세로로 드래그하여 열을 설정하세요:</Label>
        <ColContainer>
          {Array.from({ length: 10 }).map((_, index) => (
            <ColDrag
              key={index}
              active={index < cols}
              onMouseEnter={(e) => e.buttons === 1 && toggleCol(index)}
            />
          ))}
        </ColContainer>
        <GridContainer rows={rows} cols={cols}>
          {Array.from({ length: rows }).map((_, rowIndex) =>
            Array.from({ length: cols }).map((_, colIndex) => (
              <GridItem
                key={`${rowIndex}-${colIndex}`}
                active={grid[rowIndex][colIndex]}
              />
            )),
          )}
        </GridContainer>
      </Section>
      <Section>
        <Label>시작 번호:</Label>
        <Input
          type="number"
          value={startNum}
          onChange={(e) => setStartNum(e.target.value)}
        />
        <Label>종료 번호:</Label>
        <Input
          type="number"
          value={endNum}
          onChange={(e) => setEndNum(e.target.value)}
        />
        <Label>제외할 번호:</Label>
        <Input
          type="text"
          placeholder="쉼표로 구분"
          value={excludeNums}
          onChange={(e) => setExcludeNums(e.target.value)}
        />
      </Section>
      <Section>
        <Label>이름 목록 (줄바꿈으로 구분):</Label>
        <TextArea
          rows={10}
          value={names}
          onChange={(e) => setNames(e.target.value)}
        ></TextArea>
      </Section>
      <Section>
        <Label>
          <Input
            type="checkbox"
            checked={paired}
            onChange={() => setPaired(!paired)}
          />
          짝꿍 있음
        </Label>
      </Section>
      <Button onClick={handleDrawSeats}>자리 뽑기</Button>
      <Results>
        {seating.map((seat, index) => (
          <Result key={index}>
            {seat.name} - {seat.seat}
            <Input
              type="number"
              value={seat.seat}
              onChange={(e) => {
                const updatedSeating = [...seating];
                updatedSeating[index].seat = parseInt(e.target.value, 10);
                setSeating(updatedSeating);
              }}
            />
          </Result>
        ))}
      </Results>
    </Container>
  );
}

export default SeatDraw1;
