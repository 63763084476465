import React from 'react';
import styled from 'styled-components';
import { colors, fontWeight, gray } from '../styles/commonStyle';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 10;
  cursor: pointer;

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipText = styled.div`
  visibility: hidden; /* 기본적으로 숨김 */
  opacity: 0; /* 기본적으로 숨김 */
  // min-width: 310px;

  width: auto;
  white-space: nowrap; /* 텍스트가 한 줄로 표시되도록 설정 */

  padding: 10px 30px;
  bottom: 125%; /* Tooltip을 위로 배치 */
  left: 50%;

  background-color: ${colors.background}; /* 배경색 */
  color: ${colors.lightGreen}; /* 글자 색상 */
  font-weight: ${fontWeight.medium} !important;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  transform: translateX(-50%);
  transition: opacity 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 추가 */

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 7px;
    border-style: solid;
    border-color: ${colors.background} transparent transparent transparent; /* 화살표의 배경색 */
  }
`;

const Tooltip = ({ text, children }) => (
  <TooltipContainer>
    {children}
    <TooltipText>{text}</TooltipText>
  </TooltipContainer>
);

export default Tooltip;
