import { useCallback, useEffect, useRef, useState } from 'react';
import PreAssignment from './PreAssignment';

const PreAssignmentContainer = ({
  setSeats,
  setBlockedSeats,
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  //   handleSeatClick,
  //   handlePreAssignInput,
  setGenderSeatRule,
  setPreAssigningSeats,
  numberList,
  notUsingNumbers,
  setNumberList,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
  seatPreAssignments,
  setSeatPreAssignments,
  fetchPreAssignments,
}) => {
  const [isPreAssignMode, setIsPreAssignMode] = useState(true);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // 자리 수정 모드 상태
  const [selectedSeats, setSelectedSeats] = useState([]); // 선택된 좌석 저장

  const inputRef = useRef(null);

  useEffect(() => {
    // console.log('genderMap:' + JSON.stringify(genderMap));
    // console.log('genderSeatRule:' + JSON.stringify(genderSeatRule));
    // console.log('notUsingNumbers:' + JSON.stringify(notUsingNumbers));
    // console.log('blockedSeats:' + Array.from(blockedSeats));
    // console.log('blockedSeats:' + blockedSeats);
  }, [blockedSeats]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedSeat(null);
      }
    };

    if (selectedSeat) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSeat]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSelectedSeat(null);
        // inputRef.current이 존재하고 selectedSeat이 있을 때 handlePreAssignInput 호출
        if (selectedSeat) {
          handlePreAssignInput({ target: inputRef.current });
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedSeat]);

  // --------------------  사전 자리 세팅  -------------------- //
  //미리 번호 배치하는 모드면 번호 배치, 그게 아니면 사용 여부 X 토글
  const handleSeatClick = (rowIdx, colIdx) => {
    const seatKey = `${rowIdx}-${colIdx}`;

    // if (isEditMode) {
    //   setSelectedSeats((prevSelectedSeats) => {
    //     if (!Array.isArray(prevSelectedSeats)) {
    //       prevSelectedSeats = [];
    //     }
    //     const newSelectedSeats = [
    //       ...prevSelectedSeats,
    //       { row: rowIdx, col: colIdx },
    //     ];

    //     if (newSelectedSeats.length === 2) {
    //       // 두 개의 좌석이 선택되면 자리 변경
    //       swapSeats(newSelectedSeats);
    //       return [];
    //     }
    //     return newSelectedSeats;
    //   });
    // } else
    if (isPreAssignMode) {
      if (blockedSeats.has(seatKey)) {
        return; // blockedSeats에 포함된 좌석이면 클릭 무시
      }
      setSelectedSeat(seatKey);
    } else {
    }
  };

  //미리 번호 배치
  const handlePreAssignInput = (event) => {
    const { value } = event.target;
    // console.log('value:' + value);
    if (value.trim() === '') {
      return;
    }

    if (selectedSeat) {
      const [rowIdx, colIdx] = selectedSeat.split('-').map(Number);
      const seatGender = genderMap[value];

      const isGenderMismatch = genderSeatRule
        ? (seatGender === '여' && colIdx % 2 === 0) ||
          (seatGender === '남' && colIdx % 2 !== 0)
        : (seatGender === '여' && colIdx % 2 !== 0) ||
          (seatGender === '남' && colIdx % 2 === 0);

      const isAlreadyAssigned =
        Object.values(preAssigningSeats).includes(value);

      const isNotUsingNumber = notUsingNumbers.includes(parseInt(value));

      if (seatGender && isGenderMismatch) {
        alert('선택한 번호의 성별과 자리가 맞지 않습니다.');
      } else if (isNotUsingNumber) {
        alert(
          '사용할 수 없는 번호입니다. 구성원 확인에서 번호 사용 여부를 확인해주세요.',
        );
      } else if (isAlreadyAssigned) {
        if (window.confirm('이미 배치된 번호입니다. 자리를 바꾸시겠습니까?')) {
          const updatedPreAssignedSeats = Object.fromEntries(
            Object.entries(preAssigningSeats).map(([key, val]) =>
              val === value ? [key, null] : [key, val],
            ),
          );
          setPreAssigningSeats({
            ...updatedPreAssignedSeats,
            [selectedSeat]: value,
          });
          setSelectedSeat(null);
        }
      } else {
        setPreAssigningSeats((prev) => ({
          ...prev,
          [selectedSeat]: value,
        }));
        setSelectedSeat(null);
      }
    }
  };

  //자리 리셋
  const handleReset = () => {
    setPreAssigningSeats({});
    setSeatPreAssignments({});
  };

  //자리 불러오기
  const handleLoad = () => {
    setBlockedSeats(blockedSeats);
    fetchPreAssignments();
  };

  // --------------------  자리 교환  -------------------- //

  // // 자리 교환
  // const swapSeats = (selectedSeats) => {
  //   const [firstSeat, secondSeat] = selectedSeats;

  //   // console.log('selectedSeats:' + JSON.stringify(selectedSeats));

  //   setSeats((prevSeats) => {
  //     const newSeats = prevSeats.map((row) => [...row]);
  //     const temp = newSeats[firstSeat.row][firstSeat.col];
  //     newSeats[firstSeat.row][firstSeat.col] =
  //       newSeats[secondSeat.row][secondSeat.col];
  //     newSeats[secondSeat.row][secondSeat.col] = temp;

  //     return newSeats;
  //   });
  // };

  return (
    <>
      <PreAssignment
        pairMode={pairMode}
        genderMode={genderMode}
        setIsPreAssignMode={setIsPreAssignMode}
        isPreAssignMode={isPreAssignMode}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        seats={seats}
        blockedSeats={blockedSeats}
        preAssigningSeats={preAssigningSeats}
        genderMap={genderMap}
        genderSeatRule={genderSeatRule}
        selectedSeat={selectedSeat}
        handleSeatClick={handleSeatClick}
        handlePreAssignInput={handlePreAssignInput}
        savePreAssignment={savePreAssignment}
        inputRef={inputRef}
        seatPreAssignments={seatPreAssignments}
        handleReset={handleReset}
        handleLoad={handleLoad}
      />
    </>
  );
};
export default PreAssignmentContainer;
