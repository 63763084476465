import { css } from 'styled-components';

export const media = {
  desktop: (...args) => css`
    @media (min-width: 992px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (min-width: 767.1px) and (max-width: 991px) {
      ${css(...args)};
    }
  `,
  mobile: (...args) => css`
    @media (max-width: 767px) {
      ${css(...args)};
    }
  `,
};
