import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Blackboard,
  Seat,
  SeatDrawSettingButtonContainer,
  SeatDrowBottom,
  SeatDrowBottomButtons,
  SeatDrowRightButtons,
  SeatGrid,
  SeatMapBox,
  SeatMapContainer,
  SeatMapEditButton,
  SeatmapNumberInput,
} from './SeatSettingStyle';
import {
  SeatDrawContentBox,
  SeatMapContentBox,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleBox_right,
  SeatDrawPageTitleDetail,
  SeatGridAndEditButtions,
  SeatGridAndEditButtions_left,
  SeatGridAndEditButtions_right,
  SeatDrawDetailBox,
  SeatDrawSecondaryButton,
} from '../../SeatDrawPageStyle';
import {
  SeatDrawAdditionalButton,
  SeatDrawButton,
} from '../2-1studentSetting/StudentSettingStyle';

const SeatSetting = ({
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  setIsPreAssignMode,
  isPreAssignMode,

  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  selectedSeat,
  handleSeatClick,
  handlePreAssignInput,
  addCol,
  removeCol,
  addRow,
  removeRow,
  setGenderSeatRule,
  confirmSetting,
  saveSeatInfo,
  loadSeatInfo,
  resetSeats,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
  inputRef,
}) => {
  const location = useLocation();
  const [isSeatEditMode, setIsSeatEditMode] = useState(true);

  return (
    <SeatDrawContentBox>
      <SeatDrawPageTitleBox>
        <SeatDrawPageTitleBox_left>
          <SeatDrawPageTitle>자리 기본 설정</SeatDrawPageTitle>
          <SeatDrawPageTitleDetail>
            자리 개수, 사용하지 않을 자리, 남녀 구분, 짝꿍 여부를 설정할 수
            있습니다.
          </SeatDrawPageTitleDetail>
        </SeatDrawPageTitleBox_left>
        <SeatDrawPageTitleBox_right>
          {isSeatEditMode ? (
            <>
              <SeatDrawSecondaryButton onClick={() => resetSeats()}>
                자리 리셋
              </SeatDrawSecondaryButton>
              <SeatDrawSecondaryButton onClick={() => loadSeatInfo()}>
                자리 불러오기
              </SeatDrawSecondaryButton>
              <SeatDrawButton
                onClick={() =>
                  confirmSetting(
                    seats,
                    blockedSeats,
                    pairMode,
                    genderMode,
                    genderSeatRule,
                  )
                }
              >
                저장하기
              </SeatDrawButton>
            </>
          ) : (
            <SeatDrawButton
              onClick={() => {
                setIsSeatEditMode(!isSeatEditMode);
              }}
            >
              수정하기
            </SeatDrawButton>
          )}
        </SeatDrawPageTitleBox_right>
      </SeatDrawPageTitleBox>
      {isSeatEditMode && (
        <>
          <SeatMapContentBox>
            <SeatMapContainer>
              <SeatDrawSettingButtonContainer>
                <label>
                  <input
                    type="checkbox"
                    checked={pairMode}
                    onChange={() => setPairMode(!pairMode)}
                  />
                  짝 만들기
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={genderMode}
                    onChange={() => setGenderMode(!genderMode)}
                  />
                  남녀구분
                </label>
                {genderMode && (
                  <label>
                    <input
                      type="checkbox"
                      checked={genderSeatRule}
                      onChange={() => setGenderSeatRule(!genderSeatRule)}
                    />
                    남녀위치 바꾸기
                  </label>
                )}
              </SeatDrawSettingButtonContainer>
              <SeatMapBox>
                <SeatGridAndEditButtions>
                  <SeatGridAndEditButtions_left>
                    <SeatGrid $columns={seats[0].length}>
                      {seats.map((row, rowIdx) =>
                        row.map((seat, colIdx) => {
                          const seatKey = `${rowIdx}-${colIdx}`;
                          const isBlocked = blockedSeats.has(seatKey);
                          const preAssignedNumber = preAssigningSeats[seatKey];
                          const seatNumber = seat ? `${seat}` : ``;
                          const seatGender = seat ? genderMap[seat] : null;
                          const genderMismatch =
                            genderMode &&
                            seatGender &&
                            ((seatGender === '남' && colIdx % 2 !== 0) ||
                              (seatGender === '여' && colIdx % 2 === 0));

                          return (
                            <Seat
                              key={seatKey}
                              $pairmode={pairMode}
                              $genderMode={genderMode}
                              $col={colIdx}
                              $blocked={isBlocked}
                              $genderMismatch={genderMismatch}
                              $genderSeatRule={genderSeatRule}
                              onClick={() => handleSeatClick(rowIdx, colIdx)}
                            >
                              {/* {isBlocked
                                ? 'X'
                                : preAssignedNumber
                                ? preAssignedNumber
                                : seatNumber} */}
                              {isBlocked ? (
                                <span className="blocked-text">X</span>
                              ) : (
                                selectedSeat === seatKey && (
                                  <SeatmapNumberInput
                                    ref={inputRef}
                                    type="number"
                                    placeholder="번호"
                                    onBlur={handlePreAssignInput}
                                    autoFocus
                                  />
                                )
                              )}
                            </Seat>
                          );
                        }),
                      )}
                    </SeatGrid>
                    {/* 아래쪽 + - */}
                    {isSeatEditMode && (
                      <SeatDrowBottomButtons>
                        <SeatMapEditButton onClick={addRow}>
                          +
                        </SeatMapEditButton>
                        <SeatMapEditButton onClick={removeRow}>
                          -
                        </SeatMapEditButton>
                      </SeatDrowBottomButtons>
                    )}
                  </SeatGridAndEditButtions_left>
                  <SeatGridAndEditButtions_right>
                    {/* 오른쪽 + - */}
                    {isSeatEditMode && (
                      <SeatDrowRightButtons>
                        <SeatMapEditButton onClick={addCol}>
                          +
                        </SeatMapEditButton>
                        <SeatMapEditButton onClick={removeCol}>
                          -
                        </SeatMapEditButton>
                      </SeatDrowRightButtons>
                    )}
                  </SeatGridAndEditButtions_right>
                </SeatGridAndEditButtions>
                <Blackboard>칠판</Blackboard>
              </SeatMapBox>
            </SeatMapContainer>
          </SeatMapContentBox>
          <SeatDrawDetailBox>
            <div>
              📌사용하지 않을 자리: 자리를 클릭하시면 됩니다. 해당 자리를 제외한
              자리에 학생들이 배치됩니다.
            </div>
            <div>📌분홍색에 여학생, 파란색에 남학생이 배치됩니다.</div>
          </SeatDrawDetailBox>{' '}
        </>
      )}
    </SeatDrawContentBox>
  );
};

export default SeatSetting;
