import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  SeatDrawContentBox,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleDetail,
  SeatMapContentBox,
} from '../../SeatDrawPageStyle';
import {
  DrawBox_Title,
  DrawBox_Title_Text,
} from '../../3drawOneByOnePage/OneByOneStyle';
import {
  DropdownMenu,
  MenuContainer,
  MenuItem,
} from '../../../components/DropdownMenu';
import { FiMoreVertical } from 'react-icons/fi';
import {
  Blackboard,
  Seat,
  SeatGrid,
  SeatInner,
  SeatMapContainer,
  SeatmapNumberButton,
  SeatmapNumberInput,
} from '../../3drawOneByOnePage/3-1seatSetting/SeatSettingStyle';
import Tooltip from '../../../components/Tooltip';
import { BsXLg } from 'react-icons/bs';
import { CenterAlign } from '../../../styles/BasicStyle';

const PreAssignment = ({
  pairMode,
  genderMode,
  setIsPreAssignMode,
  isPreAssignMode,

  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  selectedSeat,
  handleSeatClick,
  handlePreAssignInput,

  savePreAssignment,
  inputRef,
  seatPreAssignments,
  handleReset,
  handleLoad,
}) => {
  const [isMorePreSettingOpen, setIsMorePreSettingOpen] = useState(false);
  // DropdownMenu 바깥쪽 클릭 시 닫히도록 하는 효과
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMorePreSettingOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   // console.log('blockedSeats:' + JSON.stringify(blockedSeats));
  //   console.log('blockedSeats:' + Array.from(blockedSeats));
  // }, [blockedSeats]);
  return (
    <>
      <DrawBox_Title>
        <DrawBox_Title_Text>미리 배치</DrawBox_Title_Text>
        <MenuContainer
          ref={dropdownRef}
          onClick={() => {
            setIsMorePreSettingOpen(!isMorePreSettingOpen);
          }}
        >
          <FiMoreVertical />
          <DropdownMenu $isOpen={isMorePreSettingOpen}>
            <MenuItem
              onClick={() => {
                handleReset();
              }}
            >
              리셋
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLoad();
              }}
            >
              불러오기
            </MenuItem>
          </DropdownMenu>
        </MenuContainer>
      </DrawBox_Title>

      <SeatDrawContentBox>
        <CenterAlign>
          <SeatDrawPageTitleDetail>
            자리를 뽑을 때 해당 학생은 미리 설정한 자리에 배치됩니다.
          </SeatDrawPageTitleDetail>
        </CenterAlign>
        <SeatMapContainer>
          <SeatGrid $columns={seats[0].length}>
            {seats.map((row, rowIdx) =>
              row.map((seat, colIdx) => {
                const seatKey = `${rowIdx}-${colIdx}`;
                const isBlocked = blockedSeats.has(seatKey);
                const preAssignedNumber =
                  preAssigningSeats[seatKey] || seatPreAssignments[seatKey];
                const seatNumber = seat ? `${seat}` : ``;
                const seatGender = seat ? genderMap[seat] : null;
                const genderMismatch =
                  genderMode &&
                  seatGender &&
                  ((seatGender === '남' && colIdx % 2 !== 0) ||
                    (seatGender === '여' && colIdx % 2 === 0));

                // Tooltip 문구 결정
                const tooltipText =
                  preAssigningSeats[seatKey] || seatPreAssignments[seatKey]
                    ? '다시 클릭하면 삭제 가능'
                    : '자리 클릭하고 학생 번호를 입력!';

                return isBlocked ? (
                  <Seat
                    key={seatKey}
                    $pairmode={pairMode}
                    $genderMode={genderMode}
                    $col={colIdx}
                    $blocked={isBlocked}
                    $genderMismatch={genderMismatch}
                    $genderSeatRule={genderSeatRule}
                    style={{ cursor: 'default' }}
                    onClick={() => handleSeatClick(rowIdx, colIdx)}
                  >
                    <span className="blocked-text">
                      <BsXLg />
                    </span>
                  </Seat>
                ) : (
                  <Tooltip text={tooltipText} key={seatKey}>
                    <Seat
                      key={seatKey}
                      $pairmode={pairMode}
                      $genderMode={genderMode}
                      $col={colIdx}
                      $blocked={isBlocked}
                      $genderMismatch={genderMismatch}
                      $genderSeatRule={genderSeatRule}
                      onClick={() => handleSeatClick(rowIdx, colIdx)}
                    >
                      {selectedSeat === seatKey &&
                      !preAssigningSeats[seatKey] ? (
                        <SeatInner>
                          <SeatmapNumberInput
                            ref={inputRef}
                            type="number"
                            placeholder="번호 입력"
                            onBlur={handlePreAssignInput}
                            autoFocus
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlePreAssignInput(e);
                              }
                            }}
                          />
                          <SeatmapNumberButton onClick={handlePreAssignInput}>
                            확인
                          </SeatmapNumberButton>
                        </SeatInner>
                      ) : (
                        <>{preAssignedNumber || null}</>
                      )}
                    </Seat>
                  </Tooltip>
                );
              }),
            )}
          </SeatGrid>

          <Blackboard>칠판</Blackboard>
        </SeatMapContainer>
      </SeatDrawContentBox>
    </>
  );
};

export default PreAssignment;
