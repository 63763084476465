import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SeatSetting from './SeatSetting';
import PreAssignment from '../../1seatBeforePage/preAssignment/PreAssignment';
import UserContext from '../../../contexts/userContext';

const SeatSettingContainer = ({
  setSeats,
  setBlockedSeats,
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  //   handleSeatClick,
  //   handlePreAssignInput,
  setGenderSeatRule,
  setPreAssigningSeats,
  numberList,
  notUsingNumbers,
  saveSeatInfo,
  loadSeatInfo,
  setNumberList,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
  setIsDrawSettingOpen,
  setMaleSeatsNum,
  setfemaleSeatsNum,
  seatPreAssignments,
  setSeatPreAssignments,
  openRegisterModal,
}) => {
  const [isPreAssignMode, setIsPreAssignMode] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // 자리 수정 모드 상태
  const [selectedSeats, setSelectedSeats] = useState([]); // 선택된 좌석 저장

  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedSeat(null);
      }
    };

    if (selectedSeat) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSeat]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSelectedSeat(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedSeat]);

  // --------------------  자리 행열 추가 삭제 -------------------- //

  //아래 줄 추가, 삭제
  const addRow = () => {
    setSeats((prevSeats) => {
      if (prevSeats.length >= 8) {
        alert('최대 8줄까지 만들 수 있습니다.');
        return prevSeats;
      }
      return [...prevSeats, Array(prevSeats[0].length).fill(null)];
    });
  };
  // const removeRow = () => {
  //   setSeats((prevSeats) => prevSeats.slice(0, -1));
  // };
  const removeRow = () => {
    setSeats((prevSeats) => {
      if (prevSeats.length <= 1) {
        alert('행을 더 이상 삭제할 수 없습니다.');
        return prevSeats;
      }

      // 행을 삭제하기 전에 해당 행에 있는 blockedSeats를 제거합니다.
      const rowIdxToRemove = prevSeats.length - 1;
      setBlockedSeats((prev) => {
        const updatedBlockedSeats = new Set(
          Array.from(prev).filter(
            (seatKey) => !seatKey.startsWith(`${rowIdxToRemove}-`),
          ),
        );
        return updatedBlockedSeats;
      });

      return prevSeats.slice(0, -1);
    });
  };

  //오른쪽 줄 추가, 삭제
  const addCol = () => {
    setSeats((prevSeats) => {
      if (prevSeats[0].length >= 6) {
        alert('최대 6자리까지 옆으로 배치할 수 있습니다.');
        return prevSeats;
      }
      return prevSeats.map((row) => [...row, null]);
    });
  };
  // const removeCol = () => {
  //   setSeats((prevSeats) => prevSeats.map((row) => row.slice(0, -1)));
  // };

  const removeCol = () => {
    setSeats((prevSeats) => {
      if (prevSeats[0].length <= 1) {
        alert('열을 더 이상 삭제할 수 없습니다.');
        return prevSeats;
      }

      // 열을 삭제하기 전에 해당 열에 있는 blockedSeats를 제거합니다.
      const colIdxToRemove = prevSeats[0].length - 1;
      setBlockedSeats((prev) => {
        const updatedBlockedSeats = new Set(
          Array.from(prev).filter(
            (seatKey) => !seatKey.endsWith(`-${colIdxToRemove}`),
          ),
        );
        return updatedBlockedSeats;
      });

      return prevSeats.map((row) => row.slice(0, -1));
    });
  };
  // useEffect(() => {
  //   console.log('BlockedSeats:' + Array.from(blockedSeats));
  // }, [blockedSeats]);
  // --------------------  사전 자리 세팅  -------------------- //
  // 사용 여부 X 토글
  const handleSeatClick = (rowIdx, colIdx) => {
    const seatKey = `${rowIdx}-${colIdx}`;

    // 해당 자리에 이미 배정된 학생이 있는지 확인
    if (seatPreAssignments[seatKey] || preAssigningSeats[seatKey]) {
      const confirmMessage = '기존 설정내역을 삭제하고 진행하시겠습니까?';
      if (window.confirm(confirmMessage)) {
        // 사용자가 yes를 선택하면 seatPreAssignments에서 해당 내역 삭제
        setSeatPreAssignments((prevAssignments) => {
          const updatedAssignments = { ...prevAssignments };
          delete updatedAssignments[seatKey];
          return updatedAssignments;
        });

        setPreAssigningSeats((prevAssignments) => {
          const updatedAssignments = { ...prevAssignments };
          delete updatedAssignments[seatKey];
          return updatedAssignments;
        });

        // 좌석을 차단하거나 차단 해제하는 기능을 계속 진행
        toggleBlockSeat(rowIdx, colIdx);
      } else {
        //사용자가 no하면 함수 끝내기
        return;
      }
    } else {
      // 해당 자리에 배정된 학생이 없으면 바로 진행
      toggleBlockSeat(rowIdx, colIdx);
    }
  };

  // 자리 사용 여부 X 토글
  const toggleBlockSeat = (row, col) => {
    const seatKey = `${row}-${col}`;

    setBlockedSeats((prev) => {
      const prevArray = Array.from(prev); // 기존 상태를 배열로 변환

      let newBlockedSeats;

      if (prevArray.includes(seatKey)) {
        newBlockedSeats = prevArray.filter((key) => key !== seatKey);
      } else {
        newBlockedSeats = [...prevArray, seatKey];
      }

      return new Set(newBlockedSeats); // 새로운 Set 반환
    });
  };

  // 모든 좌석이 차단된 행과 열을 삭제하는 함수
  const checkAndRemoveBlockedRowsCols = () => {
    setSeats((prevSeats) => {
      // 1. 차단된 행을 삭제
      let updatedSeats = prevSeats.filter((row, rowIdx) => {
        const isRowBlocked = row.every((_, colIdx) =>
          blockedSeats.has(`${rowIdx}-${colIdx}`),
        );

        // 만약 행이 전부 차단되었다면 blockedSeats에서도 해당 좌석들을 제거
        if (isRowBlocked) {
          row.forEach((_, colIdx) => {
            blockedSeats.delete(`${rowIdx}-${colIdx}`);
          });
        }

        return !isRowBlocked; // 차단되지 않은 행만 유지
      });

      // 2. 차단된 열을 삭제
      if (updatedSeats.length > 0) {
        // 첫 번째 행의 길이를 사용하여 열의 길이 결정
        const columnLength = updatedSeats[0].length;

        // 열을 검사하여 전부 차단된 열만 필터링
        const columnsToKeep = Array.from(
          { length: columnLength },
          (_, colIdx) => {
            const isColBlocked = updatedSeats.every((row, rowIdx) =>
              blockedSeats.has(`${rowIdx}-${colIdx}`),
            );

            // 만약 열이 전부 차단되었다면 blockedSeats에서도 해당 좌석들을 제거
            if (isColBlocked) {
              updatedSeats.forEach((_, rowIdx) => {
                blockedSeats.delete(`${rowIdx}-${colIdx}`);
              });
            }

            return !isColBlocked; // 차단되지 않은 열만 유지
          },
        );

        // 차단된 열을 제외하고 남아있는 열로 새 좌석 배열 구성
        updatedSeats = updatedSeats.map((row) =>
          row.filter((_, colIdx) => columnsToKeep[colIdx]),
        );
      }

      return updatedSeats; // 업데이트된 좌석 배열 반환
    });
  };
  // --------------------  학생수, 자리 수 비교  -------------------- //

  //구성원과 자리표 개수 비교 확인
  const confirmSetting = (
    seats,
    blockedSeats,
    pairMode,
    genderMode,
    genderSeatRule,
  ) => {
    const totalSeats = seats.length * seats[0].length;
    const numberOfBlockedSeats = blockedSeats.size;

    const remainingSeats = totalSeats - numberOfBlockedSeats;
    const remainingNumbers = numberList.length - notUsingNumbers.length;
    if (remainingSeats - remainingNumbers < 0) {
      alert('학생수가 자리수보다 많습니다. 자리를 추가해주세요.');
    } else if (remainingSeats - remainingNumbers > 0) {
      if (
        window.confirm('자리수가 학생수보다 많습니다. 이대로 저장하시겠습니까?')
      ) {
        saveSeatInfo(seats, blockedSeats, pairMode, genderMode, genderSeatRule);
      }
    } else {
      saveSeatInfo(seats, blockedSeats, pairMode, genderMode, genderSeatRule);
    }
  };
  // --------------------  자리 리셋  -------------------- //
  const resetSeats = useCallback(() => {
    setPairMode(false);
    setGenderMode(false);
    setPreAssigningSeats({});
    setBlockedSeats(new Set());
    const emptySeats = Array(5) // 세로 자리 수
      .fill()
      .map(() => Array(6).fill(null)); // 가로 자리 수
    setSeats(emptySeats);
  }, []);
  // --------------------  불러오기 기능 -------------------- //
  const handleLoadSeatInfo = () => {
    if (userId) {
      loadSeatInfo();
    } else {
      openRegisterModal();
    }
  };
  return (
    <>
      <SeatSetting
        setPairMode={setPairMode}
        pairMode={pairMode}
        setGenderMode={setGenderMode}
        genderMode={genderMode}
        setIsPreAssignMode={setIsPreAssignMode}
        isPreAssignMode={isPreAssignMode}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        seats={seats}
        blockedSeats={blockedSeats}
        preAssigningSeats={preAssigningSeats}
        genderMap={genderMap}
        genderSeatRule={genderSeatRule}
        selectedSeat={selectedSeat}
        handleSeatClick={handleSeatClick}
        addCol={addCol}
        removeCol={removeCol}
        addRow={addRow}
        removeRow={removeRow}
        setGenderSeatRule={setGenderSeatRule}
        confirmSetting={confirmSetting}
        saveSeatInfo={saveSeatInfo}
        loadSeatInfo={loadSeatInfo}
        resetSeats={resetSeats}
        savePreAssignment={savePreAssignment}
        // isChangeSeatMode={isChangeSeatMode}
        // setIsChangeSeatMode={setIsChangeSeatMode}
        inputRef={inputRef}
        setIsDrawSettingOpen={setIsDrawSettingOpen}
        setMaleSeatsNum={setMaleSeatsNum}
        setfemaleSeatsNum={setfemaleSeatsNum}
        handleLoadSeatInfo={handleLoadSeatInfo}
      />
    </>
  );
};
export default SeatSettingContainer;
