import { forwardRef, useCallback, useEffect, useState } from 'react';
import KoreanDate from '../../commonFunction/KoreanDate';
import {
  MobileProgressRecordBtns,
  ProgressNoteByClassTopic,
  ProgressNoteCardLayout3,
  ProgressNoteCardLayout3Inner,
  ProgressNoteCardLayout3Inner1,
  ProgressNoteCardLayout3Inner2,
  ProgressNoteCardLayout3Inner3,
  ProgressNoteCardLayout3Inner4,
  ProgressNoteClassDelete,
  ProgressNoteClassRecord,
  ProgressNoteClassRecord2,
  ProgressNoteData,
  ProgressNoteTopicTitle,
  ProgressRecordTextarea,
  ProgressRecordTextareaContainer,
} from '../progress/ProgressNoteStyle';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaCheck } from 'react-icons/fa';
import { DateCustomInputStyle } from './AddProgressNoteStyle';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import Tooltip from '../../components/Tooltip';

const AllClassMode = ({
  note,
  topicTitle,
  topicDetail,
  deleteProgressNote,
  updateRecord,
}) => {
  //---------------- 클릭 시 수업기록 수정, 업데이트  ----------------//
  const [editableNoteId, setEditableNoteId] = useState(null);
  const [value, setValue] = useState();

  const editOn = (event, noteId, noteRecord) => {
    event.stopPropagation(); // 이벤트 전파 막음
    setEditableNoteId(null);

    setEditableNoteId(noteId);
    setValue(noteRecord);
  };

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      // input 요소를 포함하지 않은 곳을 클릭했을 때
      if (editableNoteId && !event.target.closest('textarea')) {
        setEditableNoteId(null);
      }
    },
    [editableNoteId],
  );
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleKeyDown = (e, noteId, noteDate) => {
    if (e.key === 'Enter') {
      // console.log(value);
      submitRecord(noteId, noteDate);
      e.preventDefault();
    } else if (e.key === 'Escape') {
      setEditableNoteId(null);
      e.preventDefault();
    }
  };
  const submitRecord = (noteId, noteDate) => {
    if (!value.trim()) {
      alert('내용을 입력해주세요.');
      return;
    }
    updateRecord(noteId, noteDate, value);
    setEditableNoteId(null);
  };

  //---------------- 날짜 선택  ----------------//
  const [noteDate, setNoteDate] = useState(null);

  const handleDateChange = (noteId, date, originalRecord) => {
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    ); // 날짜를 UTC로 변환
    setNoteDate(utcDate);
    submitNoteDate(noteId, utcDate, originalRecord);
  };

  const submitNoteDate = (noteId, noteDate, originalRecord) => {
    updateRecord(noteId, noteDate, originalRecord);
    // setValue('');
    // setEditableNoteId(null);
  };
  //React date picker 포멧 변경
  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <DateCustomInputStyle
      className="custom-date-input"
      onClick={onClick}
      ref={ref}
    >
      {noteDate ? KoreanDate(noteDate) : KoreanDate(note.updatedAt)}
    </DateCustomInputStyle>
  ));

  const navigate = useNavigate();
  const goTopicProgressNote = () => {
    navigate('/progressnote/bytopic/${note.topicNum}');
  };
  const isMobile = window.innerWidth <= 768; // 768px 이하를 모바일로 간주
  return (
    <ProgressNoteCardLayout3>
      <ProgressNoteCardLayout3Inner>
        <ProgressNoteCardLayout3Inner1>
          <ProgressNoteByClassTopic>
            수업 {note.topicNum}
          </ProgressNoteByClassTopic>{' '}
        </ProgressNoteCardLayout3Inner1>
        <ProgressNoteCardLayout3Inner2>
          <ProgressNoteTopicTitle>
            {topicTitle ? topicTitle : `수업 ${note.topicNum}`}
          </ProgressNoteTopicTitle>{' '}
        </ProgressNoteCardLayout3Inner2>
        {/* {!isMobile && ( */}
        <ProgressNoteCardLayout3Inner3>
          <ReactDatePicker
            selected={noteDate}
            onChange={(date) => handleDateChange(note.id, date, note.record)}
            dateFormat="MM/dd(eee)"
            locale="ko"
            customInput={<DateCustomInput />}
            showPopperArrow={false}
            popperPlacement="bottom-end"
          />
          {editableNoteId === note.id ? (
            <ProgressNoteClassDelete>
              <FaCheck
                size={18}
                onClick={() => {
                  submitRecord(note.id, note.updatedAt);
                }}
              />
            </ProgressNoteClassDelete>
          ) : (
            <ProgressNoteClassDelete>
              <RiDeleteBin6Line
                size={18}
                onClick={() => {
                  deleteProgressNote(note.id);
                }}
              />
            </ProgressNoteClassDelete>
          )}
        </ProgressNoteCardLayout3Inner3>
        {/* )} */}
      </ProgressNoteCardLayout3Inner>
      <ProgressNoteCardLayout3Inner>
        <ProgressNoteCardLayout3Inner1>
          <ProgressNoteClassRecord>기록</ProgressNoteClassRecord>{' '}
        </ProgressNoteCardLayout3Inner1>
        <ProgressNoteCardLayout3Inner4>
          {editableNoteId === note.id ? (
            <ProgressNoteClassRecord2>
              <ProgressRecordTextareaContainer>
                {/* {note.updatedAt} */}
                <ProgressRecordTextarea
                  placeholder={note.record}
                  type="text"
                  value={value}
                  onBlur={handleClickOutside}
                  onChange={onChange}
                  onKeyDown={(e) => handleKeyDown(e, note.id, note.updatedAt)}
                  className={note.id}
                />
              </ProgressRecordTextareaContainer>
              <MobileProgressRecordBtns>
                <div
                  onClick={() => {
                    submitRecord(note.id, note.updatedAt);
                  }}
                >
                  저장
                </div>
                <div
                  onClick={() => {
                    deleteProgressNote(note.id);
                  }}
                >
                  삭제
                </div>
              </MobileProgressRecordBtns>
            </ProgressNoteClassRecord2>
          ) : (
            <ProgressNoteClassRecord2
              onClick={(event) => editOn(event, note.id, note.record)}
            >
              {note.record != null
                ? note.record
                : '클릭하면 진도노트를 입력할 수 있습니다.'}
            </ProgressNoteClassRecord2>
          )}{' '}
        </ProgressNoteCardLayout3Inner4>
      </ProgressNoteCardLayout3Inner>
    </ProgressNoteCardLayout3>
  );
};

export default AllClassMode;
