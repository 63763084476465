import PageContainer from '../../pageLayout/PageContainer';
import Login2ConfirmContainer from './Login2ConfirmContainer';
import Login2Container from './Login2ConfirmContainer';

const Login2ConfirmPage = () => {
  return (
    <PageContainer>
      <Login2ConfirmContainer />
    </PageContainer>
  );
};

export default Login2ConfirmPage;
