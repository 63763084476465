import {
  AuthCardContainer,
  AuthCardTitle,
} from '../../components/AuthCardContainer';
import { AuthStyledLink } from '../../components/AuthPageStyle';
import LinkTo from '../../components/LinkTo';
import MainButton from '../../components/MainButton';
import Input from '../../components/StyledInput';
import { GreenBadge } from '../../styles/BasicStyle';
import { AuthBox, AuthLayout1 } from '../register2/Register2Style';

const Login2Confirm = ({
  handleChange,
  handleLogin,
  loginInfo,
  rememberMe,
  handleRememberMeChange,
}) => {
  return (
    <AuthBox>
      <AuthLayout1>
        자리뽑기<GreenBadge>beta</GreenBadge> 서비스 이용을 위해 다시 한 번
        인증해주세요!
      </AuthLayout1>
      <AuthCardContainer>
        <form className="form" onSubmit={handleLogin}>
          <div>
            <Input
              type="id"
              placeholder="아이디"
              onChange={handleChange}
              name="userId"
              value={loginInfo.userId}
            />
          </div>

          <div>
            <Input
              type="password"
              placeholder="비밀번호"
              onChange={handleChange}
              name="password"
              value={loginInfo.password}
            />
          </div>
          <div style={{ margin: '5px 0' }}>
            <label style={{ cursor: 'pointer' }}>
              <input
                type="checkbox"
                checked={rememberMe} // 체크박스의 상태를 rememberMe로 설정
                onChange={handleRememberMeChange} // 체크박스 상태 변경 시 호출
                style={{ cursor: 'pointer' }}
              />
              로그인 정보 저장
            </label>
          </div>
          <MainButton onClick={handleLogin}>베타서비스 이용하기</MainButton>
          <LinkTo>
            <AuthStyledLink to="/register">
              아직 아이디가 없으신가요?
            </AuthStyledLink>
          </LinkTo>
        </form>
      </AuthCardContainer>
    </AuthBox>
  );
};

export default Login2Confirm;
