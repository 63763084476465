import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import GradeContext from '../contexts/grade';
import {
  colors,
  fontSize,
  fontWeight,
  height,
  margin,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import { media } from '../styles/media';
import axios from '../../node_modules/axios/index';
import UserContext from '../contexts/userContext';

export const SelectGradeBox = styled.div`
  padding: 0 ${padding.large};
  display: flex;

  align-items: center;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  background-color: ${colors.background};
  position: relative;
  z-index: 999 !important;

  ${media.desktop`
    height: ${height.header};
    width: ${pageWidth.desktop};

  `}

  ${media.tablet`
    height: ${height.headerTablet};    
    width: ${pageWidth.tablet};

  `}

  ${media.mobile`
    display: none;
    height: ${height.headerMobile};    
    width: ${pageWidth.mobile};
  `}
`;

export const GradeMenu = styled.div`
  cursor: pointer;
  margin-right: ${margin.large};
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    color: ${colors.primary};
    font-weight: 600;
  }

  ${({ $isSelectedGrade }) =>
    $isSelectedGrade &&
    css`
      color: ${colors.primary};
      font-weight: 600;
    `}
`;

const SelectGradeMenuBar = () => {
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const { state, actions } = useContext(GradeContext);
  const [selectedGrade, setSelectedGrade] = useState(state.currentGrade);
  const [loading, setLoading] = useState(true);

  //gradeinfodb직접 가져오기
  const [gradeInfoDB, setGradeInfoDB] = useState([]);
  const fetchGradeInfo = useCallback(async () => {
    try {
      const res = await axios.get(
        `https://teachernote.me/api/gradeinfo/${userId}`,
      );
      const sortedGradeInfo = res.data.sort((a, b) => a.grade - b.grade);
      setGradeInfoDB(sortedGradeInfo);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // 데이터 로딩 완료 시 로딩 상태 변경
    }
  }, [userId]);
  useEffect(() => {
    fetchGradeInfo();
  }, [fetchGradeInfo]);

  const handleChangeGrade = (grade) => {
    actions.setGrade(grade);
    setSelectedGrade(grade);
  };
  return (
    <SelectGradeBox>
      {!loading && (
        <>
          {gradeInfoDB.length === 0 ? (
            <>
              <GradeMenu>전체보기</GradeMenu>
            </>
          ) : (
            <>
              {gradeInfoDB.map((gradeInfo) => (
                <GradeMenu
                  key={gradeInfo.grade}
                  onClick={() => {
                    handleChangeGrade(gradeInfo.grade);
                  }}
                  $isSelectedGrade={gradeInfo.grade === selectedGrade}
                >
                  {gradeInfo.grade}학년
                </GradeMenu>
              ))}
            </>
          )}
        </>
      )}
    </SelectGradeBox>
  );
};

export default SelectGradeMenuBar;
