import { useNavigate } from 'react-router-dom';
import {
  HeaderLeft,
  HeaderMenuBarContainer,
  HeaderRight,
  MenuItem,
  StyledNavLink,
  SubheaderBarContainer,
  TopMenuDownload,
  TopMenuLogo,
} from './HeaderMenuBarStyle';
import { NavLink } from '../../node_modules/react-router-dom/dist/index';
import LogoutComponent from '../components/Logout';
import { useContext, useEffect, useReducer, useState } from 'react';
import DownloadProgramDiv from '../components/DownloadProgramMenu';
import { DisplayColumn, NewBadge } from '../styles/BasicStyle';
import UserContext from '../contexts/userContext';
import {
  SubHeaderMenubar_Progress,
  SubHeaderMenubar_SeatDraw,
  SubHeaderMenubar_User,
} from './SubHeaderMenuBar';

const HeaderMenuBar = ({
  isRegisterModalOpen,
  setRegisterModalOpen,
  isLoginModalOpen,
  setLoginModalOpen,
  setModalSource,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  const [, forceUpdate] = useReducer((x) => x + 1, 0); // 리렌더링 강제

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSubheader_Progress, setShowSubheader_Progress] = useState(false);
  const [showSubheader_SeatDraw, setShowSubheader_SeatDraw] = useState(false);
  const [showSubheader_User, setShowSubheader_User] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const navigate = useNavigate();
  const goMain = () => {
    navigate('/bloglist');
  };
  const goLogin = () => {
    navigate('/login');
  };

  //-------------------- subHeader 관련  --------------------//
  const handleMouseEnter_Progress = () => {
    setShowSubheader_Progress(true);
  };

  const handleMouseLeave_Progress = () => {
    setShowSubheader_Progress(false);
  };
  const handleMouseEnter_SeatDraw = () => {
    setShowSubheader_SeatDraw(true);
  };

  const handleMouseLeave_SeatDraw = () => {
    setShowSubheader_SeatDraw(false);
  };
  const handleMouseEnter_User = () => {
    setShowSubheader_User(true);
  };

  const handleMouseLeave_User = () => {
    setShowSubheader_User(false);
  };

  return (
    <DisplayColumn>
      <HeaderMenuBarContainer>
        <HeaderLeft>
          <TopMenuLogo onClick={goMain}>티처노트1.1</TopMenuLogo>

          <MenuItem>
            <StyledNavLink
              to="/bloglist/insta"
              className={
                window.location.pathname.startsWith('/bloglist') ? 'active' : ''
              }
            >
              선생님 쉼터<NewBadge>N</NewBadge>
            </StyledNavLink>
          </MenuItem>
          <MenuItem
            onMouseEnter={handleMouseEnter_SeatDraw}
            onMouseLeave={handleMouseLeave_SeatDraw}
          >
            <StyledNavLink
              to="/drawseat"
              className={
                window.location.pathname.startsWith('/draw') ? 'active' : ''
              }
            >
              자리뽑기<NewBadge>N</NewBadge>
            </StyledNavLink>
            {showSubheader_SeatDraw && <SubHeaderMenubar_SeatDraw />}
          </MenuItem>
          <MenuItem
            onMouseEnter={handleMouseEnter_Progress}
            onMouseLeave={handleMouseLeave_Progress}
          >
            <StyledNavLink
              to="/progress"
              className={
                window.location.pathname.startsWith('/progress') ||
                window.location.pathname.startsWith('/classinfo') ||
                window.location.pathname.startsWith('/topic')
                  ? 'active'
                  : ''
              }
            >
              진도관리
            </StyledNavLink>
            {showSubheader_Progress && <SubHeaderMenubar_Progress />}
          </MenuItem>
        </HeaderLeft>
        <HeaderRight>
          <TopMenuDownload>
            <DownloadProgramDiv />
          </TopMenuDownload>
          {userId ? (
            <>
              <MenuItem
                onMouseEnter={handleMouseEnter_User}
                onMouseLeave={handleMouseLeave_User}
              >
                <div>{userId}님</div>
                {showSubheader_User && <SubHeaderMenubar_User />}
              </MenuItem>
            </>
          ) : (
            <div>
              <span
                onClick={() => {
                  setModalSource('menubar');
                  setRegisterModalOpen(true);
                }}
              >
                회원가입/
              </span>
              <span
                onClick={() => {
                  setModalSource('menubar');
                  setLoginModalOpen(true);
                }}
              >
                로그인
              </span>
            </div>
          )}
          {/* {isLoggedIn ? (
            <LogoutComponent />
          ) : (
            <div onClick={goLogin}>로그인</div>
          )} */}
        </HeaderRight>
      </HeaderMenuBarContainer>
    </DisplayColumn>
  );
};

export default HeaderMenuBar;
