import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  height,
  margin,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import { media } from '../styles/media';

export const SeatDrawingMenuBox = styled.div`
  background-color: ${colors.background};

  ${media.desktop`
    height: ${height.header};
    width: ${pageWidth.desktop};
  `}

  ${media.tablet`
    height: ${height.headerTablet};    
    width: ${pageWidth.tablet};
  `}

  ${media.mobile`
    height: ${height.headerMobile};    
    width: ${pageWidth.mobile};
    display: none;
  `}
  height: ${height.header};
  padding: 0 ${padding.large};
  display: flex;
  align-items: center;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
`;

const activeStyle = css`
  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
`;

const inactiveStyle = css`
  color: black;
`;

export const SeatDrawingMenu = styled.div`
  cursor: pointer;
  margin-right: ${margin.large};
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => (props.isActive ? colors.primary : 'black')};
  font-weight: ${(props) =>
    props.isActive ? fontWeight.bold : fontWeight.medium};

  &:hover {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
  }
`;

const SeatBeforeSettingMenu = ({ onSelect, selectedMenu }) => {
  return (
    <SeatDrawingMenuBox>
      <SeatDrawingMenu
        onClick={() => onSelect('main')}
        isActive={selectedMenu === 'main'}
      >
        자리 사전 세팅
      </SeatDrawingMenu>
      <SeatDrawingMenu
        onClick={() => onSelect('memberCheck')}
        isActive={selectedMenu === 'memberCheck'}
      >
        구성원 확인
      </SeatDrawingMenu>
      <SeatDrawingMenu
        onClick={() => onSelect('seatSetting')}
        isActive={selectedMenu === 'seatSetting'}
      >
        자리 기본 설정
      </SeatDrawingMenu>
      <SeatDrawingMenu
        onClick={() => onSelect('otherSetting')}
        isActive={selectedMenu === 'otherSetting'}
      >
        기타 설정
      </SeatDrawingMenu>
    </SeatDrawingMenuBox>
  );
};

export default SeatBeforeSettingMenu;
