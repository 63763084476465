import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import GradeContext from '../contexts/grade';
import {
  colors,
  fontSize,
  fontWeight,
  height,
  margin,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import { media } from '../styles/media';

const SelectGradeBox = styled.div`
  padding: 0 ${padding.large};
  display: flex;
  align-items: center;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  background-color: ${colors.background};
  position: relative;
  z-index: 999 !important;

  ${media.desktop`
    height: ${height.header};
    width: ${pageWidth.desktop};

  `}

  ${media.tablet`
    height: ${height.headerTablet};    
    width: ${pageWidth.tablet};

  `}

  ${media.mobile`
    display: none;
    height: ${height.headerMobile};    
    width: ${pageWidth.mobile};

  `}
`;

const GradeMenu = styled.div`
  cursor: pointer;
  margin-right: ${margin.large};
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    color: ${colors.primary};
    font-weight: 600;
  }

  ${({ $isSelectedGrade }) =>
    $isSelectedGrade &&
    css`
      color: ${colors.primary};
      font-weight: 600;
    `}
`;

const ClassInfoMenuBar = () => {
  const { state, actions } = useContext(GradeContext);
  const [selectedGrade, setSelectedGrade] = useState(state.currentGrade);

  const handleChangeGrade = (grade) => {
    actions.setGrade(grade);
    setSelectedGrade(grade);
  };

  return (
    <SelectGradeBox>
      <GradeMenu>전체보기</GradeMenu>
    </SelectGradeBox>
  );
};

export default ClassInfoMenuBar;
