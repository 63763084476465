import { useEffect, useState } from 'react';
import {
  CornerNum,
  CurrentStudentSet,
  SeatDrawContentBox,
  SeatDrawPageContentBox,
  SeatDrawPageContentBox_left,
  SeatDrawPageContentBox_right,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleBox_right,
  SeatDrawPageTitleDetail,
  SeatDrawThirdButton,
  StudentSettingInput_wide,
} from '../../SeatDrawPageStyle';
import { SeatDrawButton, SelectFrontNumContainer } from './StudentSettingStyle';
import frontSeatsImg from '../../../img/frontSeats3.png';
import Tooltip from '../../../components/Tooltip';
import { OneByOneButton } from '../OneByOneStyle';
import { BsX } from 'react-icons/bs';
import { colors, gray } from '../../../styles/commonStyle';

const SelectFrontNum = ({
  numberList,
  frontNums,
  setFrontNums,
  notUsingNumbers,
  adjacentNums,
  cornerNums,
  setAdjacentNums,
  setCornerNums,
  preAssigningSeats,
  setPreAssigningSeats,
  seatPreAssignments,
  setSeatPreAssignments,
}) => {
  const [inputFrontNums, setInputFrontNums] = useState('');

  const handleInputFrontChange = (e) => {
    setInputFrontNums(e.target.value);
  };

  // useEffect(() => {
  //   console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
  //   console.log('seatPreAssignments:' + JSON.stringify(seatPreAssignments));
  // }, [preAssigningSeats, seatPreAssignments]);
  // const handleAddFrontNums = () => {
  //   const nums = inputFrontNums
  //     .split(',')
  //     .map((num) => num.trim()) // 공백 제거
  //     .filter((num) => num !== '') // 공백이 아닌 항목만 필터링
  //     .map(Number);

  //   // 이미 frontNums에 있는 숫자와 새로 입력된 nums의 중복 숫자 찾기
  //   const duplicateNums = nums.filter((num) => frontNums.includes(num));
  //   if (duplicateNums.length > 0) {
  //     alert(`${duplicateNums.join(', ')}번은 이미 등록된 번호입니다.`);
  //     setInputFrontNums('');
  //     return;
  //   }

  //   // 사용 불가능한 번호 확인
  //   const invalidNums = nums.filter((num) => notUsingNumbers.includes(num));
  //   if (invalidNums.length > 0) {
  //     alert(
  //       `${invalidNums.join(
  //         ', ',
  //       )}번은 사용할 수 없는 번호입니다. 사용하시려면 표의 X표시를 해제한 후 다시 추가해주세요.`,
  //     );
  //     setInputFrontNums('');
  //     return;
  //   }

  //   // adjacentNums 또는 cornerNums에 이미 포함된 번호가 있는지 확인
  //   const numsInAdjacentOrCorner = nums.filter(
  //     (num) =>
  //       adjacentNums.some((pair) => pair.includes(num)) ||
  //       cornerNums.includes(num),
  //   );

  //   if (numsInAdjacentOrCorner.length > 0) {
  //     const confirmRemoval = window.confirm(
  //       `기존에 등록된 내역을 삭제하고 앞에 앉을 학생으로 등록하시겠습니까?`,
  //     );

  //     if (confirmRemoval) {
  //       // adjacentNums에서 제거
  //       numsInAdjacentOrCorner.forEach((num) => {
  //         setAdjacentNums((prevAdjacentNums) =>
  //           prevAdjacentNums.filter((pair) => !pair.includes(num)),
  //         );
  //       });

  //       // cornerNums에서 제거
  //       setCornerNums((prevCornerNums) =>
  //         prevCornerNums.filter((n) => !numsInAdjacentOrCorner.includes(n)),
  //       );
  //     } else {
  //       setInputFrontNums('');
  //       return;
  //     }
  //   }

  //   const filteredNums = nums.filter(
  //     (num) => !isNaN(num) && !frontNums.includes(num),
  //   );
  //   const invalidFilteredNums = filteredNums.filter(
  //     (num) => !numberList.includes(num),
  //   );

  //   if (invalidFilteredNums.length > 0) {
  //     alert(
  //       '해당 번호는 존재하지 않습니다. 학생 번호에서 번호를 먼저 추가해주세요.',
  //     );
  //     setInputFrontNums('');
  //     return;
  //   }

  //   // 앞에 앉을 학생 수를 5명으로 제한
  //   if (frontNums.length + filteredNums.length > 5) {
  //     alert('앞에 앉을 학생은 최대 5명까지 가능합니다.');
  //     setInputFrontNums('');
  //     return;
  //   }

  //   if (filteredNums.length >= 1 && filteredNums.length <= 5) {
  //     setFrontNums((prevFrontNums) => [...prevFrontNums, ...filteredNums]);
  //     setInputFrontNums('');
  //   } else {
  //     alert('번호는 중복없이 1개에서 5개까지 입력할 수 있습니다.');
  //   }
  // };

  // useEffect(() => {
  //   console.log('numberList:' + JSON.stringify(numberList));
  // }, [numberList]);

  const handleAddFrontNums = () => {
    const nums = inputFrontNums
      .split(',')
      .map((num) => num.trim()) // 공백 제거
      .filter((num) => num !== '') // 공백이 아닌 항목만 필터링
      .map(Number);

    // 이미 frontNums에 있는 숫자와 새로 입력된 nums의 중복 숫자 찾기
    const duplicateNums = nums.filter((num) => frontNums.includes(num));
    if (duplicateNums.length > 0) {
      alert(`${duplicateNums.join(', ')}번은 이미 등록된 번호입니다.`);
      setInputFrontNums('');
      return;
    }

    // 사용 불가능한 번호 확인
    const invalidNums = nums.filter((num) => notUsingNumbers.includes(num));
    if (invalidNums.length > 0) {
      alert(
        `${invalidNums.join(
          ', ',
        )}번은 사용할 수 없는 번호입니다. 사용하시려면 표의 X표시를 해제한 후 다시 추가해주세요.`,
      );
      setInputFrontNums('');
      return;
    }

    // adjacentNums, cornerNums, preAssigningSeats 또는 seatPreAssignments에 이미 포함된 번호가 있는지 확인
    const numsInExistingAssignments = nums.filter(
      (num) =>
        adjacentNums.some((pair) => pair.includes(num)) ||
        cornerNums.includes(num) ||
        Object.values(preAssigningSeats).includes(String(num)) ||
        Object.values(seatPreAssignments).includes(String(num)),
    );

    if (numsInExistingAssignments.length > 0) {
      const confirmRemoval = window.confirm(
        `기존에 등록된 내역을 삭제하고 앞에 앉을 학생으로 등록하시겠습니까?`,
      );

      if (confirmRemoval) {
        // adjacentNums에서 제거
        numsInExistingAssignments.forEach((num) => {
          setAdjacentNums((prevAdjacentNums) =>
            prevAdjacentNums.filter((pair) => !pair.includes(num)),
          );
        });

        // cornerNums에서 제거
        setCornerNums((prevCornerNums) =>
          prevCornerNums.filter((n) => !numsInExistingAssignments.includes(n)),
        );

        // preAssigningSeats에서 제거
        setPreAssigningSeats((prevPreAssigningSeats) => {
          const updatedPreAssigningSeats = { ...prevPreAssigningSeats };
          Object.keys(updatedPreAssigningSeats).forEach((key) => {
            if (
              updatedPreAssigningSeats[key] ===
              String(numsInExistingAssignments)
            ) {
              delete updatedPreAssigningSeats[key];
            }
          });
          return updatedPreAssigningSeats;
        });

        // seatPreAssignments에서 제거
        setSeatPreAssignments((prevSeatPreAssignments) => {
          const updatedSeatPreAssignments = { ...prevSeatPreAssignments };
          Object.keys(updatedSeatPreAssignments).forEach((key) => {
            if (
              updatedSeatPreAssignments[key] ===
              String(numsInExistingAssignments)
            ) {
              delete updatedSeatPreAssignments[key];
            }
          });
          return updatedSeatPreAssignments;
        });
      } else {
        setInputFrontNums('');
        return;
      }
    }

    const filteredNums = nums.filter(
      (num) => !isNaN(num) && !frontNums.includes(num),
    );
    const invalidFilteredNums = filteredNums.filter(
      (num) => !numberList.map(Number).includes(num),
    );

    if (invalidFilteredNums.length > 0) {
      alert('numberList:' + JSON.stringify(numberList));
      alert(
        '해당 번호는 존재하지 않습니다. 학생 번호에서 번호를 먼저 추가해주세요.',
      );
      setInputFrontNums('');
      return;
    }

    // 앞에 앉을 학생 수를 5명으로 제한
    if (frontNums.length + filteredNums.length > 5) {
      alert('앞에 앉을 학생은 최대 5명까지 가능합니다.');
      setInputFrontNums('');
      return;
    }

    if (filteredNums.length >= 1 && filteredNums.length <= 5) {
      setFrontNums((prevFrontNums) => [...prevFrontNums, ...filteredNums]);
      setInputFrontNums('');
    } else {
      alert('번호는 중복없이 1개에서 5개까지 입력할 수 있습니다.');
    }
  };

  const handleRemoveFrontNum = (numToRemove) => {
    setFrontNums((prevFrontNums) =>
      prevFrontNums.filter((num) => num !== numToRemove),
    );
  };

  return (
    <SelectFrontNumContainer>
      <SeatDrawPageContentBox_left>
        <img src={frontSeatsImg} style={{ width: '200px', height: 'auto' }} />
      </SeatDrawPageContentBox_left>
      <SeatDrawPageContentBox_right>
        <div>
          <Tooltip text="삭제하려면 번호 클릭">
            <CurrentStudentSet>
              앞 2줄에 앉을 학생들:{' '}
              {frontNums.map((num, index) => (
                <CornerNum
                  key={index}
                  onClick={() => handleRemoveFrontNum(num)} // 번호 클릭 시 삭제 기능
                >
                  {num} <BsX color={`${gray.dark}`} />
                </CornerNum>
              ))}
            </CurrentStudentSet>
          </Tooltip>
        </div>
        <div>
          <StudentSettingInput_wide
            type="text"
            value={inputFrontNums}
            onChange={handleInputFrontChange}
            placeholder="번호를 콤마로 구분하여 입력"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddFrontNums(e);
              }
            }}
          />
          <OneByOneButton onClick={handleAddFrontNums}>추가</OneByOneButton>
        </div>
      </SeatDrawPageContentBox_right>
    </SelectFrontNumContainer>
  );
};

export default SelectFrontNum;
