import styled from 'styled-components';
import { media } from './media';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  padding,
  pageWidth,
} from './commonStyle';

export const DisplayColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DisplayRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const DisplayRowSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CenterAlign = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageBox = styled.div`
  ${media.desktop` 
  width: ${pageWidth.desktop};  
  margin: ${margin.large} 0px ${margin.small} 0px;
  padding: ${padding.medium};
  `}

  ${media.tablet`
  // width: ${pageWidth.tablet};
  margin: ${margin.large} 0px ${margin.small} 0px;
  padding: ${padding.medium};

      `}

  ${media.mobile`
  // width:90%;
  `}
`;

export const PageBox_noMargin = styled.div`
  ${media.desktop` 
  width: ${pageWidth.desktop};  
  padding-bottom: ${padding.medium};
  `}

  ${media.tablet`
      width: ${pageWidth.tablet};
  padding: ${padding.medium};

      `}

  ${media.mobile`
  `}
`;

export const NewBadge = styled.span`
  color: ${colors.background};
  background-color: red;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.extraBold};
  vertical-align: middle;

  padding: 0px 5px;
  border-radius: ${boerderRadius.medium};
`;
export const GreenBadge = styled.span`
  color: ${colors.background};
  background-color: ${colors.lightGreen};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.extraBold};
  vertical-align: middle;

  padding: 0px 5px;
  border-radius: ${boerderRadius.medium};
`;

export const TitleContainer = styled.div`
  padding-bottom: ${padding.mediumLarge};
`;

export const TitleText = styled.div`
  font-size: ${fontSize.categoryLarge};
  font-weight: ${fontWeight.bold};
`;

export const TitleDetail = styled.div`
  color: ${gray.darktext};
  font-size: ${fontSize.medium};
`;
