import { createGlobalStyle } from 'styled-components';
import { fontSize } from './commonStyle';

const GlobalStyle = createGlobalStyle`
  body {


  //모바일
    @media (max-width: 768px) {
      font-size: ${fontSize.medium};
    }
  }
`;

export default GlobalStyle;
