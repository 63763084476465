import React, { useEffect, useRef, useState } from 'react';

import { IoSettingsSharp } from 'react-icons/io5';
import {
  DrawBox_Bottom,
  DrawBox_Main,
  DrawBox_Title,
  DrawBox_Title_Text,
  DrawContainer_Top,
  OneByOneButton,
  SeatDrawMapContainer,
} from '../OneByOneStyle';
import {
  Blackboard,
  CenterVertical,
  DisplayRight,
  LargeResultModeDiv,
  Seat,
  SeatDrawSettingContent,
  SeatGrid,
  SeatGridContainer,
  SeatMapContainer,
} from '../3-1seatSetting/SeatSettingStyle';
import Tooltip from '../../../components/Tooltip';
import SmallSwitch from '../../../components/SmallSwitch';
import {
  DropdownMenu,
  MenuContainer,
  MenuItem,
} from '../../../components/DropdownMenu';
import { FiMoreVertical } from 'react-icons/fi';
import {
  boerderRadius,
  colors,
  margin,
  padding,
} from '../../../styles/commonStyle';
import NumberList from './StudentStatus';
import StudentStatus from './StudentStatus';
import { BsXLg } from 'react-icons/bs';
import { MdFullscreen } from 'react-icons/md';
import LeftTooltip from '../../../components/LeftTooltip';
import { MdFullscreenExit } from 'react-icons/md';
import { CenterAlign } from '../../../styles/BasicStyle';
import {
  StudentStatus_Td,
  StudentStatus_Title,
  StudentStatusContainer,
} from './StudentStatusStyle';

const SeatSetting = ({
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  setIsPreAssignMode,
  isPreAssignMode,

  seats,
  blockedSeats,
  preAssigningSeats,
  genderSeatRule,
  selectedSeat,
  handleSeatClick,
  handlePreAssignInput,

  setGenderSeatRule,
  // confirmSetting,
  saveSeatInfo,
  loadSeatInfo,
  savePreAssignment,
  inputRef,
  completedNum,
  setCompletedNum,
  numberSeatMap,
  handleCompleteRemaining,
  isDrawSettingOpen,
  setIsDrawSettingOpen,
  numberList,
  notUsingNumbers,
  currentNumIndex,
  isReversed,
  setIsReversed,
  resetDrawing,
  setIsMoreSeatResultSettingOpen,
  isMoreSeatResultSettingOpen,
  isEditResultMode,
  setIsEditResultMode,
  selectedSeats,
  // selectedSeatNumbers,

  isLargeResultMode,
  setIsLargeResultMode,
  highlight,
  setIsRevealAll,
  isRevealAll,
  setCurrentNumIndex,
  isDrawOk,
  isClickedByJebi,
  localGenderMap,
  setIsClickedByJebi,
  frontNums,
}) => {
  const dropdownRef = useRef(null);

  const validNumbers = numberList.filter(
    (num) => !notUsingNumbers.includes(num),
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMoreSeatResultSettingOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePrint = () => {
    window.print(); // 브라우저의 인쇄 대화상자를 호출
  };
  return (
    <SeatDrawMapContainer>
      <DrawBox_Title>
        <DrawBox_Title_Text $isLargeResultMode={isLargeResultMode}>
          자리 배치 결과
        </DrawBox_Title_Text>

        <MenuContainer ref={dropdownRef}>
          <FiMoreVertical
            onClick={() => {
              setIsMoreSeatResultSettingOpen(!isMoreSeatResultSettingOpen);
            }}
          />
          <DropdownMenu $isOpen={isMoreSeatResultSettingOpen}>
            <MenuItem
              onClick={() => {
                resetDrawing();
              }}
            >
              다시 뽑기
            </MenuItem>
            {isRevealAll && (
              <>
                {' '}
                <MenuItem
                  onClick={() => {
                    setIsEditResultMode(!isEditResultMode);
                    setIsMoreSeatResultSettingOpen(
                      !isMoreSeatResultSettingOpen,
                    );
                  }}
                >
                  자리 교환
                </MenuItem>
                {isLargeResultMode && (
                  <MenuItem onClick={handlePrint}>자리 프린트</MenuItem>
                )}
              </>
            )}{' '}
            {/* {isDrawOk && (
              <MenuItem
                onClick={() => {
                  setIsRevealAll(true);
                  setIsMoreSeatResultSettingOpen(!isMoreSeatResultSettingOpen);
                  setCurrentNumIndex();
                }}
              >
                남은 번호 한번에 뽑기
              </MenuItem>
            )} */}
          </DropdownMenu>
        </MenuContainer>

        {/* <OneByOneButton onClick={handleCompleteRemaining}>
          남은 번호 한 번에 뽑기
        </OneByOneButton> */}
      </DrawBox_Title>

      <DrawBox_Main>
        <DisplayRight>
          <Tooltip text="같은 자리표를 선생님/학생 입장에서 확인">
            <OneByOneButton onClick={() => setIsReversed(!isReversed)}>
              자리표 방향 바꾸기
            </OneByOneButton>
          </Tooltip>
          <LargeResultModeDiv
            onClick={() => {
              setIsLargeResultMode(!isLargeResultMode);
            }}
          >
            {!isLargeResultMode ? (
              <CenterVertical>
                전체 화면 보기 <MdFullscreen />
              </CenterVertical>
            ) : (
              <CenterVertical>
                전체 화면 닫기
                <MdFullscreenExit />
              </CenterVertical>
            )}
          </LargeResultModeDiv>
        </DisplayRight>
        {isEditResultMode && (
          <>
            <CenterAlign>
              자리를 연속으로 2개 클릭하면 자리 교환가능!
              <OneByOneButton
                onClick={() => {
                  setIsEditResultMode(false);
                }}
              >
                교환 완료
              </OneByOneButton>
            </CenterAlign>
            {/* 선택된 좌석 번호가 있을 때만 출력 */}
            {/* {`${selectedSeatNumbers[0]}번과 ${selectedSeatNumbers[1]}번의 자리 바꾸기`}{' '} */}
          </>
        )}

        <SeatMapContainer>
          {/* 가운데 정렬용 div */}
          {isReversed ? (
            <Blackboard $isLargeResultMode={isLargeResultMode}>칠판</Blackboard>
          ) : (
            <></>
          )}
          <SeatGridContainer>
            <SeatGrid
              $columns={seats[0].length}
              $isLargeResultMode={isLargeResultMode}
            >
              {(isReversed ? [...seats].reverse() : seats).map((row, rowIdx) =>
                (isReversed ? [...row].reverse() : row).map((seat, colIdx) => {
                  // 이 부분 수정
                  const seatKey = `${rowIdx}-${colIdx}`;
                  const isBlocked = blockedSeats.has(seatKey);
                  const seatNumber = seat ? `${seat}` : ``;
                  const seatGender = seat ? localGenderMap[seat] : null;
                  const genderMismatch =
                    genderMode &&
                    seatGender &&
                    ((seatGender === '남' && colIdx % 2 !== 0) ||
                      (seatGender === '여' && colIdx % 2 === 0));
                  const showSeatNumber = completedNum.includes(seat);
                  return (
                    <Seat
                      key={seatKey}
                      $pairmode={pairMode}
                      $genderMode={genderMode}
                      $col={colIdx}
                      $isReversed={isReversed}
                      $blocked={
                        isReversed
                          ? blockedSeats.has(
                              `${seats.length - 1 - rowIdx}-${
                                seats[0].length - 1 - colIdx
                              }`,
                            )
                          : blockedSeats.has(seatKey)
                      }
                      $genderMismatch={genderMismatch}
                      $genderSeatRule={genderSeatRule}
                      // $highlighted={seat === currentNumIndex}
                      $highlighted={seat === validNumbers[currentNumIndex - 1]}
                      $highlight={highlight}
                      $selected={selectedSeats.some(
                        (seat) => seat.row === rowIdx && seat.col === colIdx,
                      )}
                      $isLargeResultMode={isLargeResultMode}
                      $isEditResultMode={isEditResultMode}
                      $isClickedByJebi={isClickedByJebi}
                      style={{ cursor: 'default' }}
                      onClick={() => {
                        handleSeatClick(rowIdx, colIdx);
                      }}
                    >
                      {isReversed ? (
                        blockedSeats.has(
                          `${seats.length - 1 - rowIdx}-${
                            seats[0].length - 1 - colIdx
                          }`,
                        ) ? (
                          <span className="blocked-text">
                            <BsXLg />
                          </span>
                        ) : showSeatNumber ? (
                          seatNumber
                        ) : (
                          ''
                        )
                      ) : isBlocked ? (
                        <span className="blocked-text">
                          <BsXLg />
                        </span>
                      ) : showSeatNumber ? (
                        seatNumber
                      ) : (
                        ''
                      )}
                    </Seat>
                  );
                }),
              )}
            </SeatGrid>
          </SeatGridContainer>
          {!isReversed ? (
            <Blackboard $isLargeResultMode={isLargeResultMode}>칠판</Blackboard>
          ) : (
            <></>
          )}
        </SeatMapContainer>
      </DrawBox_Main>
      {!isLargeResultMode ? (
        <DrawBox_Main>
          <StudentStatus
            numberList={numberList}
            completedNum={completedNum}
            notUsingNumbers={notUsingNumbers}
            genderMode={genderMode}
            localGenderMap={localGenderMap}
          />
          {frontNums.length > 0 && (
            <StudentStatusContainer>
              <StudentStatus_Title>앞 2줄에 앉을 학생</StudentStatus_Title>
              <div style={{ gap: `${padding.medium}` }}>
                {frontNums
                  .sort((a, b) => a - b) // 여기서 숫자를 오름차순으로 정렬
                  .map((num) => {
                    // 성별에 따른 배경색 설정
                    const gender = localGenderMap[num];

                    let backgroundColor = '';
                    if (genderMode) {
                      if (gender === '여') {
                        backgroundColor = `${colors.femaleMedium}`;
                      } else if (gender === '남') {
                        backgroundColor = `${colors.maleMedium}`;
                      }
                    }

                    return (
                      <span
                        key={num}
                        style={{
                          backgroundColor: `${backgroundColor}`,
                          marginRight: `${margin.small}`,
                          padding: '8px',
                          borderRadius: `${boerderRadius.medium}`,
                        }}
                      >
                        {num}
                      </span>
                    );
                  })}
              </div>
            </StudentStatusContainer>
          )}
        </DrawBox_Main>
      ) : (
        <></>
      )}
    </SeatDrawMapContainer>
  );
};

export default SeatSetting;
