import { useContext, useState } from 'react';
import Register2 from './RegisterModal';
import UserContext from '../../contexts/userContext';
import axios from 'axios';
import { WelcomeModal } from '../register2/WelcomeModal';
import RegisterModal from './RegisterModal';

const RegisterModalContainer = ({
  handleAuthComplete,
  openLoginModal,
  modalSource,
}) => {
  //가입 정보 정의
  const [registerInfo, setRegisterInfo] = useState({
    userId: '',
    email: '',
    password: '',
    passwordConfirm: '',
  });
  const [loginInfo, setLoginInfo] = useState({
    userId: '',
    password: '',
  });

  const [userId, setUserId] = useState('');
  const [isValidUserId, setIsValidUserId] = useState(true);
  const [isFirstUserId, setIsFirstUserId] = useState(null); //아이디 input 밑의 경고 문구
  const [didUserIdCheck, setDidUserIdCheck] = useState(null); //회원가입 밑의 경고 문구

  const [password, setPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isValidPasswordConfirm, setIsValidPasswordConfirm] = useState(true);

  const [isValidRegister, setIsValidRegister] = useState(true); //회원가입 밑의 경고 문구

  //userContext : 회원가입 완료 시 userContext 정의 해주기
  const { state, actions } = useContext(UserContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  //----------------input 유효성 검사  ----------------//
  //아이디 유효성 검사
  //input값 받아오기
  function handleUserIdChange(e) {
    const newUserId = e.target.value;
    setUserId(newUserId);
    setRegisterInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    //ID 입력 값이 빈값이면 유효성 검증을 다시 true로
    const isValid = validateUserId(newUserId);
    setIsValidUserId(isValid);

    // ID 입력 값이 변경될 때마다 isFirstUserId, 중복체크 여부를 다시 null로 설정
    setIsFirstUserId(null); //아이디 input 밑의 경고 문구
    setDidUserIdCheck(null); //회원가입 버튼 밑의 경고 문구

    //회원가입 밑의 전체 경고 문구 초기화
    setIsValidRegister(true);
  }

  // 아이디 유효성 검사
  function validateUserId(userId) {
    const regex = /^[a-z0-9]{5,16}$/;
    return regex.test(userId);
  }

  //메일주소 유효성 검사
  function validateEmail(email) {
    const emailRegExp = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
    return emailRegExp.test(email);
  }

  function handleEmailChange(e) {
    const newEmail = e.target.value;
    setRegisterInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    setEmail(newEmail);
    const isValid = validateEmail(newEmail);
    setIsValidEmail(isValid);
    setIsValidRegister(true);
  }

  //비밀번호 유효성 검사
  function handlePasswordChange(e) {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setRegisterInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const isValid = validatePassword(newPassword, userId);
    setIsValidPassword(isValid);
    setIsValidRegister(true);
  }

  function validatePassword(password, userId) {
    if (password.length < 8 || password.length > 16) {
      return false;
    }
    // 영문 소문자가 적어도 하나 이상 포함되어 있는지 확인
    const letterPattern = /[a-zA-Z]/;
    if (!letterPattern.test(password)) {
      return false;
    }
    // 숫자가 적어도 하나 이상 포함되어 있는지 확인
    const numberPattern = /\d/;
    if (!numberPattern.test(password)) {
      return false;
    }
    // // 비밀번호와 아이디가 동일한지 확인
    // if (password === userId) {
    //   return false;
    // }
    return true;
  }

  function handlePasswordConfirmChange(e) {
    const newPasswordConfirm = e.target.value;
    setPasswordConfirm(newPasswordConfirm);
    setRegisterInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const isValid = validatePasswordConfirm(password, newPasswordConfirm);
    setIsValidPasswordConfirm(isValid);
    setIsValidRegister(true);
  }

  function validatePasswordConfirm(password, confirmPassword) {
    return password === confirmPassword;
  }
  //---------------- 아이디 중복 확인  ----------------//

  const checkUserIdAvailability = async (userId) => {
    try {
      const response = await axios.post(
        'https://teachernote.me/api/checkUserId',
        {
          userId,
        },
      );
      return response.data.available;
    } catch (error) {
      console.error('아이디 중복 확인 오류:', error);
      return false;
    }
  };
  // 아이디 중복 확인 버튼 클릭
  const findSameId = async (event) => {
    // 중복 확인 버튼을 클릭한 경우 폼 제출을 막음
    event.preventDefault();
    const isAvailable = await checkUserIdAvailability(registerInfo.userId);
    // console.log('isAvailable:' + JSON.stringify(isAvailable));
    setIsFirstUserId(isAvailable); //아이디 input 밑의 경고 문구
  };

  //---------------- 메일 인증  ----------------//
  const confirmEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/confirmemail', { email });
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  //---------------- 가입 정보 저장  ----------------//
  //가입 정보 DB 저장(암호화는 backend에서)
  // RegisterModalContainer.js

  const saveRegisterInfo = async (e) => {
    e.preventDefault();
    if (isFirstUserId === null) {
      setDidUserIdCheck(false);
    } else {
      setDidUserIdCheck(isFirstUserId);
    }
    if (isValidUserId && isValidPassword && isValidEmail && isFirstUserId) {
      const { userId, password } = registerInfo;
      setLoginInfo({ userId, password });

      try {
        // 회원가입 정보 저장 요청
        await axios.post('https://teachernote.me/api/register', registerInfo);

        // 기존 코드와 달라진 부분: 로그인 요청을 분리하여 회원가입 후에 수행
        const loginResponse = await axios.post(
          'https://teachernote.me/api/login',
          registerInfo,
        );

        // 토큰 저장 (예: localStorage)
        const token = loginResponse.data.token;
        localStorage.setItem('token', token);

        // 받아온 user data 확인 및 저장
        const user = loginResponse.data.user;
        localStorage.setItem('user', JSON.stringify(user));

        // userContext에 받아온 user data 넣기
        actions.setUser(user);
        // console.log('UserContext 업데이트 완료:', user); // 변경점: 콘솔 로그 추가

        // handleAuthComplete를 호출하여 UI 업데이트
        handleAuthComplete(user.userId);
        // 모달을 열어 회원가입 완료 메시지 표시
        setIsModalOpen(true); // 기존 코드
      } catch (error) {
        console.error('회원가입 또는 로그인 오류:', error);
        alert('회원가입 또는 로그인에 실패하였습니다.');
      }
    } else {
      setIsValidRegister(false);
    }
  };

  return (
    <>
      <RegisterModal
        saveRegisterInfo={saveRegisterInfo}
        handleUserIdChange={handleUserIdChange}
        handlePasswordChange={handlePasswordChange}
        isValidUserId={isValidUserId}
        isValidPassword={isValidPassword}
        handleEmailChange={handleEmailChange}
        isValidEmail={isValidEmail}
        isValidPasswordConfirm={isValidPasswordConfirm}
        handlePasswordConfirmChange={handlePasswordConfirmChange}
        isValidRegister={isValidRegister}
        findSameId={findSameId}
        isFirstUserId={isFirstUserId}
        didUserIdCheck={didUserIdCheck}
        openLoginModal={openLoginModal}
      />
    </>
  );
};

export default RegisterModalContainer;
