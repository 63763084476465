import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import GradeContext from '../../contexts/grade';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import { MdAdd } from 'react-icons/md';
import {
  ClassStyledLink,
  ProgressNoteCardLayout23Inner1,
  ProgressNoteCardLayout23Inner2,
  ProgressNoteCardLayout23Inner3,
  ProgressNoteCardLayout3Inner,
  ProgressNoteClassDelete,
  ProgressRecordTextarea,
} from '../progress/ProgressNoteStyle';
import { RiDeleteBin6Line } from 'react-icons/ri';
import KoreanDate from '../../commonFunction/KoreanDate';
import { DateCustomInputStyle } from '../progressnoteByClass/AddProgressNoteStyle';
import ReactDatePicker from 'react-datepicker';
import { FaCheck } from 'react-icons/fa';

const AllTopicMode = ({
  topicNum,
  topicTableData,
  deleteProgressNote,
  updateRecord,
  note,
}) => {
  const { state: gradeState } = useContext(GradeContext);
  const grade = gradeState.currentGrade;

  //---------------- 클릭 시 수업기록 수정, 업데이트  ----------------//
  const [editableNoteId, setEditableNoteId] = useState(null);
  const [value, setValue] = useState('');

  //기록 클릭 시 편집 상태로 변경
  const editOn = (event, noteId, noteRecord) => {
    event.stopPropagation(); // 이벤트 전파 막음
    setEditableNoteId(noteId);
    setValue(noteRecord || ''); // noteRecord가 null이나 undefined일 때 빈 문자열로 설정
    // setNoteInfo((prev) => ({ ...prev, noteId: noteId }));
  };

  //노트 입력 시 정보 저장
  const onChange = useCallback((e) => {
    setValue(e.target.value);
    // setNoteInfo((prev) => ({ ...prev, noteRecord: e.target.value }));
    // console.log('noteInfo:' + JSON.stringify(noteInfo));
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      // input 요소를 포함하지 않은 곳을 클릭했을 때
      if (editableNoteId && !event.target.closest('textarea')) {
        setEditableNoteId(null);
      }
    },
    [editableNoteId],
  );
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleKeyDown = (e, noteId, noteDate) => {
    if (e.key === 'Enter') {
      // console.log(value);
      submitRecord(noteId, noteDate);
      e.preventDefault();
    } else if (e.key === 'Escape') {
      setEditableNoteId(null);
      e.preventDefault();
    }
  };
  const submitRecord = (noteId, noteDate) => {
    updateRecord(noteId, noteDate, value);
  };
  //---------------- 날짜 선택  ----------------//
  const [noteDate, setNoteDate] = useState(null);

  const handleDateChange = (date, noteId, originalRecord) => {
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    setNoteDate(utcDate);
    // setNoteInfo((prev) => ({ ...prev, noteDate: utcDate }));
    // console.log('noteInfo:' + JSON.stringify(noteInfo));

    submitNoteDate(noteId, utcDate, originalRecord);
  };

  const submitNoteDate = (noteId, noteDate, originalRecord) => {
    updateRecord(noteId, noteDate, originalRecord);
  };
  //React date picker 포멧 변경
  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <DateCustomInputStyle
      className="custom-date-input"
      onClick={onClick}
      ref={ref}
    >
      {noteDate ? KoreanDate(noteDate) : KoreanDate(note.updatedAt)}
    </DateCustomInputStyle>
  ));

  return (
    <>
      {note.classNum && (
        <ProgressNoteCardLayout3Inner>
          <ProgressNoteCardLayout23Inner1>
            <ClassStyledLink to={`/progressnote/byclass/${note.classNum}`}>
              {grade}학년 {note.classNum}반
            </ClassStyledLink>
          </ProgressNoteCardLayout23Inner1>
          {editableNoteId === note.id ? (
            <ProgressNoteCardLayout23Inner2>
              <ProgressRecordTextarea
                placeholder={note.record}
                type="text"
                value={value || ''} // value가 null이나 undefined일 때 빈 문자열로 설정
                onBlur={handleClickOutside}
                onChange={onChange}
                onKeyDown={(e) => handleKeyDown(e, note.id, note.updatedAt)}
              />
            </ProgressNoteCardLayout23Inner2>
          ) : (
            <ProgressNoteCardLayout23Inner2
              onClick={(event) => editOn(event, note.id, note.record)}
            >
              {note.record !== null
                ? note.record
                : '클릭하면 진도노트를 입력할 수 있습니다.'}
            </ProgressNoteCardLayout23Inner2>
          )}
          <ProgressNoteCardLayout23Inner3>
            <ReactDatePicker
              selected={noteDate}
              onChange={(date) => handleDateChange(date, note.id, note.record)} // note.id 전달
              dateFormat="MM/dd(eee)"
              locale="ko"
              customInput={<DateCustomInput />}
              showPopperArrow={false}
              popperPlacement="bottom-end"
            />
            {editableNoteId === note.id ? (
              <ProgressNoteClassDelete>
                <FaCheck
                  size={18}
                  onClick={() => {
                    submitRecord(note.id, note.updatedAt);
                  }}
                />
              </ProgressNoteClassDelete>
            ) : (
              <ProgressNoteClassDelete>
                <RiDeleteBin6Line
                  size={18}
                  onClick={() => deleteProgressNote(note.id)}
                />
              </ProgressNoteClassDelete>
            )}
          </ProgressNoteCardLayout23Inner3>
        </ProgressNoteCardLayout3Inner>
      )}
    </>
  );
};
export default AllTopicMode;
