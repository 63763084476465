import {
  StyledTable,
  StyledTd,
  StyledTh,
} from '../../../pages/progress/ProgressTableStyle';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  margin,
  objWidth,
  padding,
  pageWidth,
} from '../../../styles/commonStyle';
import styled from 'styled-components';

export const SeatStudentSetting_Table_Container = styled.div`
  width: 100%;
  border-radius: ${boerderRadius.medium};
`;

export const SeatStudentSetting_Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border: 1px solid ${colors.lightGreenBorder};

  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: -1px; /* 표 사이의 경계선이 1px 만큼 겹쳐지지 않도록 조정 */
`;

export const SeatStudentSetting_Thead = styled.thead``;

export const SeatStudentSetting_FirstTh = styled.th`
  width: 6.6%;
  height: ${objWidth.small};
  border-right: 1px solid ${gray.light};
`;

export const SeatStudentSetting_Th = styled.th`
  height: ${objWidth.small};
  width: ${({ columnCount }) => `calc(100% / ${columnCount})`};
  cursor: pointer;
`;

export const SeatStudentSetting_Th_X = styled.div`
  width: 100%;
  height: ${objWidth.small};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${gray.light};
  color: ${gray.medium};

  cursor: pointer;
`;

export const SeatStudentSetting_FirstTd = styled.td`
  width: 6.6%;
  height: ${objWidth.small};
  background-color: ${gray.extralight};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SeatStudentSetting_Td = styled.td`
  width: 6.6%;
  height: ${objWidth.small};
  width: ${({ columnCount }) => `calc(100% / ${columnCount})`};

  background-color: ${({ isDisabled, $gender }) => {
    if (isDisabled) return `${gray.light}`;
    if ($gender === '남') return `${colors.maleMedium}`; // 남일 때의 배경색 (예: 파란색)
    if ($gender === '여') return `${colors.femaleMedium}`; // 여일 때의 배경색 (예: 분홍색)
    return 'inherit';
  }};

  color: ${({ isDisabled }) => (isDisabled ? `${gray.medium}` : 'inherit')};
  font-size: ${({ isDisabled }) =>
    isDisabled ? `${fontSize.extraLarge}` : 'inherit'};
`;

export const SeatStudentSetting_Tbody = styled.tbody``;

export const PlusButtonTh = styled(StyledTh)`
  width: auto;
  min-width: auto;
`;

export const StudentInfoCell = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${objWidth.small};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  //   background-color: red;
  cursor: pointer;
  background-color: ${(props) => (props.$isNotUsing ? `${gray.light}` : '')};
  color: ${(props) => (props.$isNotUsing ? `${gray.medium}` : '')};
`;

export const AddNumberButton = styled.button`
width: ${objWidth.small};
  font-size: ${fontSize.extraLarge};
  border: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);seatdrawbutton
  }
`;

export const SeatDrawButton = styled.button`
  width: ${objWidth.mediumLarge};
  padding: 10px 20px;

  border-radius: ${boerderRadius.small};

  background-color: ${colors.primary};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primarydark};
    cursor: pointer;
  }
`;

export const SeatDrawAdditionalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${gap.extraSmall};
  margin-bottom: ${margin.small};
`;

export const SeatDrawAdditionalButton = styled.button`
  width: 150px;
  padding: 10px 20px;

  border-radius: ${boerderRadius.small};

  background-color: ${gray.medium};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
  }
`;

export const EditTableContainer = styled.div`
  width: 100%;
  padding: ${padding.medium} 0;

  display: flex;
  justify-content: flex-end;
`;
export const TableTitleTr = styled.tr`
  width: 100%;
  padding: ${padding.small} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;

  color: ${colors.lightGreen};
`;

export const SelectFrontNumContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${padding.mediumLarge} ${padding.mediumLarge};
  background-color: ${colors.lightGreenBackground};
`;

export const TextBackground = styled.span`
  padding: 0.5px ${padding.small};
  border-radius: ${boerderRadius.medium};
`;

export const TextBackground_Green = styled(TextBackground)`
  background-color: ${colors.lightGreenBorder};
`;
export const TextBackground_Female = styled(TextBackground)`
  background-color: ${colors.femaleMedium};
`;
export const TextBackground_Male = styled(TextBackground)`
  background-color: ${colors.maleMedium};
`;
