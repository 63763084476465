import { useEffect, useState } from 'react';
import BlogViewContainer from './BlogViewContainer';
import axios from '../../../node_modules/axios/index';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import PageContainer from '../../pageLayout/PageContainer';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';

const BlogViewPage = () => {
  const [selectedBlog, setSelectedBlog] = useState([]);
  const { paramBlogNum } = useParams();

  useEffect(() => {
    const fetchSelectedBlog = async () => {
      // console.log(paramBlogNum);
      try {
        const res = await axios.get('https://teachernote.me/api/blog/', {
          params: { blogId: paramBlogNum }, // 올바른 요청 옵션 사용
        });
        setSelectedBlog(res.data);
        // console.log('Selectedblog:' + JSON.stringify(res.data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchSelectedBlog();
  }, [paramBlogNum]);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <PageContainer>
      <PageSubheader>
        <ArticleMenuBar />
      </PageSubheader>
      {isMobile && <MobileTopMenuBarArticle />}
      <PageBox>
        <BlogViewContainer selectedBlog={selectedBlog} />
      </PageBox>
    </PageContainer>
  );
};

export default BlogViewPage;
