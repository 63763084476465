import { useEffect, useState } from 'react';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import {
  AddNoteSelectBox,
  AddNoteSelectOption,
  AddProgressNoteSelectTopic,
  ArrowBox,
} from '../progressnoteByClass/AddProgressNoteStyle';
import { SelectClass } from '../progressnoteByClass/SelectClass';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const SelectTopicForProgressNote = ({ topics, paramTopicNum }) => {
  const navigate = useNavigate();
  const topicIds = topics.map((topic) => topic.topicId).sort((a, b) => a - b);
  const currentIndex = topicIds.indexOf(parseInt(paramTopicNum));
  const [selectedTopicNum, setSelectedTopicNum] = useState('');

  useEffect(() => {
    setSelectedTopicNum(parseInt(paramTopicNum));
  }, [paramTopicNum]);

  const handleTopicNumChange = (e) => {
    const value = e.target.value;
    setSelectedTopicNum(value);
    if (value) {
      navigate(`/progressnote/bytopic/${value}`);
    }
  };

  const goToForwardTopicNote = () => {
    if (currentIndex > 0) {
      const forwardNote = topicIds[currentIndex - 1];
      navigate(`/progressnote/bytopic/${forwardNote}`);
    }
  };

  const goToNextTopicNote = () => {
    if (currentIndex < topicIds.length - 1) {
      const nextNote = topicIds[currentIndex + 1];
      navigate(`/progressnote/bytopic/${nextNote}`);
    }
  };

  return (
    <SelectClass>
      <AddProgressNoteSelectTopic>
        <ArrowBox onClick={() => goToForwardTopicNote()}>
          <IoIosArrowBack />
        </ArrowBox>
        <AddNoteSelectBox
          name="topicNum"
          onChange={handleTopicNumChange}
          value={selectedTopicNum}
        >
          <AddNoteSelectOption value=" ">수업 주제 전체</AddNoteSelectOption>
          {topics.map((topic, index) => (
            <AddNoteSelectOption
              key={index}
              value={topic.topicId}
              name="topicNum"
            >
              주제{topic.topicId} {topic.topicTitle}
            </AddNoteSelectOption>
          ))}
        </AddNoteSelectBox>
        <ArrowBox onClick={() => goToNextTopicNote()}>
          <IoIosArrowForward />
        </ArrowBox>
      </AddProgressNoteSelectTopic>
    </SelectClass>
  );
};

export default SelectTopicForProgressNote;
