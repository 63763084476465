import { createContext, useEffect, useState } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const UserContext = createContext({
  state: { userId: 'tempId' },
  actions: {
    setUser: (user) => {},
  },
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // 페이지 로드 시 로컬 스토리지에서 사용자 정보 가져오기
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setIsLoading(false);
  }, []);

  // setUser 함수 정의
  const handleSetUser = (user) => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user)); // 사용자 정보 로컬 스토리지에 저장
  };
  const value = {
    state: { user },
    actions: { setUser: handleSetUser },
  };
  return (
    <UserContext.Provider value={value}>
      {isLoading ? <LoadingSpinner /> : children}
    </UserContext.Provider>
  );
};

const UserConsumer = UserContext.Consumer;

export { UserProvider, UserConsumer };
export default UserContext;
