import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { colors } from '../styles/commonStyle';
import {
  DrawModalCustomStyles,
  DrawModalInner,
  ModalCustomStyles,
} from '../seatdrawpages/3drawOneByOnePage/OneByOneStyle';

Modal.setAppElement('#root'); // 애플리케이션의 루트 엘리먼트를 설정

const ModalExample = ({
  children,
  modalIsOpen,
  setModalIsOpen,
  openModal,
  closeModal,
  currentNumIndex,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={DrawModalCustomStyles}
      contentLabel="Example Modal"
    >
      {children}
    </Modal>
  );
};

export default ModalExample;
