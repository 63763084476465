import { useEffect, useRef, useState } from 'react';
import OneByOne from './OneByOne';

const OneByOneContainer = ({
  myNum,
  numberList,
  genderMap,
  localGenderMap,
  seats,
  usedSeats,
  placeNumber,
  randomSeat,
  avoidPreviousSeats,
  setAvoidPreviousSeats,
  isEditMode,
  setIsEditMode,
  notUsingNumbers,
  setMyNum,
  numberSeatMap,
  setRandomSeat,
  preAssigningSeats,
  adjacentNums,
  cornerNums,
  frontNums,
  separateNums,
  setNumberSeatMap,
  setUsedSeats,
  blockedSeats,
  genderSeatRule,
  genderMode,
  setUsedNumbers,
  setSeats,
  setFrontNums,
  preAssignment,
  LoadPreviousSeat,
  previousNumberSeatMap,
  completedNum,
  setCompletedNum,
  isDrawOk,
  setIsDrawSettingOpen,
  isDrawSettingOpen,
  confirmIsDrawOk,
  currentNumIndex,
  setCurrentNumIndex,

  clickedJebiNums,
  setClickedJebiNums,
  jebiPositions,
  setJebiPositions,

  setDrawingResult,
  drawingResult,
  setIsDrawOk,
  isLargeResultMode,
  setIsLargeResultMode,
  setIsRevealAll,
  isRevealAll,
  isClickedByJebi,
  setIsClickedByJebi,
}) => {
  const [remainingNumbers, setRemainingNumbers] = useState([]);

  // useEffect(() => {
  //   console.log('genderMap:' + JSON.stringify(genderMap));
  // }, [genderMap]);
  // useEffect(() => {
  //   console.log('localgenderMap:' + JSON.stringify(localGenderMap));
  // }, [localGenderMap]);
  const seatDrawing = () => {
    //사용 가능한 번호 = numberlist-notUsingNumbers
    let availableNumbers = numberList.filter(
      (num) => !notUsingNumbers.includes(num),
    );
    const newSeats = seats.map((row) => row.map(() => null));
    const newUsedSeats = new Set();
    const newNumberSeatMap = {};

    // 성별에 따른 자리 구분 로직
    const isGenderMatched = (num, rowIndex, colIndex) => {
      if (!genderMode) {
        return true;
      }
      let seatGender = localGenderMap[num];

      // genderSeatRule이 true인 경우 남녀 반전
      if (genderSeatRule) {
        seatGender = seatGender === '여' ? '남' : '여';
      }

      return (
        (seatGender === '여' && colIndex % 2 === 0) ||
        (seatGender === '남' && colIndex % 2 !== 0)
      );
    };

    // 1. Pre-assignment 처리
    if (preAssignment) {
      Object.entries(preAssignment).forEach(([seatKey, num]) => {
        const [rowIndex, colIndex] = seatKey.split('-').map(Number);
        if (
          newSeats[rowIndex] &&
          newSeats[rowIndex][colIndex] === null &&
          isGenderMatched(num, rowIndex, colIndex) &&
          !blockedSeats.has(`${rowIndex}-${colIndex}`)
        ) {
          newSeats[rowIndex][colIndex] = parseInt(num, 10);
          newUsedSeats.add(seatKey);
          newNumberSeatMap[num] = { row: rowIndex, col: colIndex };
          availableNumbers = availableNumbers.filter((n) => n != num);
        }
      });
    }

    const numRows = newSeats.length;
    const numCols = newSeats[0].length;

    // 2. 구석자리 배치
    const cornerTypes = [
      [
        [numRows - 1, 0],
        [numRows - 1, 1],
        [numRows - 2, 0],
      ],
      [
        [numRows - 1, numCols - 1],
        [numRows - 1, numCols - 2],
        [numRows - 2, numCols - 1],
      ],
      [
        [0, 0],
        [1, 0],
        [0, 1],
      ],
      [
        [0, numCols - 1],
        [0, numCols - 2],
        [1, numCols - 1],
      ],
    ];

    const usedCornerTypes = new Array(cornerTypes.length).fill(false);

    cornerNums.forEach((num) => {
      let placed = false;
      for (let attempt = 0; attempt < 100 && !placed; attempt++) {
        const randomCorner = Math.floor(Math.random() * cornerTypes.length);
        if (!usedCornerTypes[randomCorner]) {
          const cornerType = cornerTypes[randomCorner];
          const availablePositions = cornerType.filter(
            ([rowIndex, colIndex]) => {
              return (
                newSeats[rowIndex] &&
                newSeats[rowIndex][colIndex] === null &&
                isGenderMatched(num, rowIndex, colIndex) &&
                !blockedSeats.has(`${rowIndex}-${colIndex}`)
              );
            },
          );

          if (availablePositions.length > 0) {
            const [rowIndex, colIndex] =
              availablePositions[
                Math.floor(Math.random() * availablePositions.length)
              ];
            newSeats[rowIndex][colIndex] = num;
            newUsedSeats.add(`${rowIndex}-${colIndex}`);
            newNumberSeatMap[num] = { row: rowIndex, col: colIndex };
            availableNumbers = availableNumbers.filter((n) => n !== num);
            usedCornerTypes[randomCorner] = true;
            placed = true;
          }
        }
      }
      if (!placed) {
        console.warn(`Couldn't place number ${num} in corner; retrying later.`);
        availableNumbers.push(num); // 자리 배치에 실패한 번호를 다시 배치 목록에 추가
      }
    });

    // 3. 인접자리 배치
    const adjacentOffsets = [
      [0, -1], // Left
      [0, 1], // Right
      [-1, 0], // Up
      [1, 0], // Down
    ];

    adjacentNums.forEach((adjacentPair) => {
      const [num1, num2] = adjacentPair;
      let placed = false;
      for (let attempt = 0; attempt < 100 && !placed; attempt++) {
        const rowIndex = Math.floor(Math.random() * numRows);
        const colIndex = Math.floor(Math.random() * numCols);

        if (
          newSeats[rowIndex] &&
          newSeats[rowIndex][colIndex] === null &&
          isGenderMatched(num1, rowIndex, colIndex) &&
          !blockedSeats.has(`${rowIndex}-${colIndex}`)
        ) {
          const availableOffsets = adjacentOffsets.filter(
            ([rowOffset, colOffset]) => {
              const newRow = rowIndex + rowOffset;
              const newCol = colIndex + colOffset;
              return (
                newRow >= 0 &&
                newRow < numRows &&
                newCol >= 0 &&
                newCol < numCols &&
                newSeats[newRow][newCol] === null &&
                isGenderMatched(num2, newRow, newCol) &&
                !blockedSeats.has(`${newRow}-${newCol}`)
              );
            },
          );

          if (availableOffsets.length > 0) {
            const [rowOffset, colOffset] =
              availableOffsets[
                Math.floor(Math.random() * availableOffsets.length)
              ];
            const adjRowIndex = rowIndex + rowOffset;
            const adjColIndex = colIndex + colOffset;

            newSeats[rowIndex][colIndex] = num1;
            newSeats[adjRowIndex][adjColIndex] = num2;
            newUsedSeats.add(`${rowIndex}-${colIndex}`);
            newUsedSeats.add(`${adjRowIndex}-${adjColIndex}`);
            newNumberSeatMap[num1] = { row: rowIndex, col: colIndex };
            newNumberSeatMap[num2] = { row: adjRowIndex, col: adjColIndex };

            availableNumbers = availableNumbers.filter(
              (n) => n !== num1 && n !== num2,
            );
            placed = true;
          }
        }
      }
      if (!placed) {
        console.warn(`Couldn't place pair [${num1}, ${num2}]; retrying later.`);
        availableNumbers.push(num1, num2); // 자리 배치에 실패한 번호를 다시 배치 목록에 추가
      }
    });

    // 4. 앞에 앉을 학생들 배치
    const frontRows = 2;
    const frontRowIndexes = Array.from(
      { length: frontRows },
      (_, i) => numRows - 1 - i,
    );

    frontNums.forEach((num) => {
      let placed = false;
      for (let attempt = 0; attempt < 100 && !placed; attempt++) {
        const rowIndex = frontRowIndexes[Math.floor(Math.random() * frontRows)];
        const colIndex = Math.floor(Math.random() * numCols);

        const previousSeat = previousNumberSeatMap[num];
        const isPreviousSeat =
          avoidPreviousSeats &&
          previousSeat &&
          previousSeat.row === rowIndex &&
          previousSeat.col === colIndex;

        if (
          newSeats[rowIndex] &&
          newSeats[rowIndex][colIndex] === null &&
          !isPreviousSeat &&
          isGenderMatched(num, rowIndex, colIndex) &&
          !blockedSeats.has(`${rowIndex}-${colIndex}`)
        ) {
          newSeats[rowIndex][colIndex] = num;
          newUsedSeats.add(`${rowIndex}-${colIndex}`);
          newNumberSeatMap[num] = { row: rowIndex, col: colIndex };
          availableNumbers = availableNumbers.filter((n) => n !== num);
          placed = true;
        }
      }
      if (!placed) {
        console.warn(`Couldn't place number ${num} in front; retrying later.`);
        availableNumbers.push(num); // 자리 배치에 실패한 번호를 다시 배치 목록에 추가
      }
    });

    // 5. 남은 번호 랜덤 배치
    availableNumbers.forEach((num) => {
      let placed = false;
      for (let attempt = 0; attempt < 100 && !placed; attempt++) {
        const rowIndex = Math.floor(Math.random() * numRows);
        const colIndex = Math.floor(Math.random() * numCols);
        if (
          newSeats[rowIndex] &&
          newSeats[rowIndex][colIndex] === null &&
          isGenderMatched(num, rowIndex, colIndex) &&
          !blockedSeats.has(`${rowIndex}-${colIndex}`)
        ) {
          newSeats[rowIndex][colIndex] = num;
          newUsedSeats.add(`${rowIndex}-${colIndex}`);
          newNumberSeatMap[num] = { row: rowIndex, col: colIndex };
          placed = true;
        }
      }
      if (!placed) {
        console.error(`Failed to place number: ${num}`);
        // 무한 반복을 방지하기 위해 자리 배치 실패 시 더 이상 재시도하지 않음
      }
    });

    setRemainingNumbers(availableNumbers);
    setSeats(newSeats);
    setUsedSeats(newUsedSeats);
    setNumberSeatMap(newNumberSeatMap);
    updateUsedNumbers(newNumberSeatMap);
    setDrawingResult({
      seats: newSeats,
      numberSeatMap: newNumberSeatMap,
    });
  };

  // 사용된 번호 업데이트 함수 => 아래 자리 배치 결과 표에 표시됨
  const updateUsedNumbers = (newNumberSeatMap) => {
    const updatedUsedNumbers = new Map();
    Object.entries(newNumberSeatMap).forEach(([num, seat]) => {
      updatedUsedNumbers.set(parseInt(num), `${seat.row}-${seat.col}`);
    });
    setUsedNumbers(updatedUsedNumbers);
  };

  // useRef를 사용하여 첫 렌더링 여부를 감지
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      // 처음 로드 시에는 isInitialRender를 false로 설정하고, 함수를 실행하지 않음
      isInitialRender.current = false;
      return;
    }
    if (isDrawOk) {
      seatDrawing(); // isDrawOk가 true일 때만 seatDrawing 실행
    }
  }, [isDrawOk]);

  return (
    <OneByOne
      numberList={numberList}
      notUsingNumbers={notUsingNumbers}
      numberSeatMap={numberSeatMap}
      completedNum={completedNum}
      setCompletedNum={setCompletedNum}
      isDrawOk={isDrawOk}
      setIsDrawSettingOpen={setIsDrawSettingOpen}
      isDrawSettingOpen={isDrawSettingOpen}
      confirmIsDrawOk={confirmIsDrawOk}
      currentNumIndex={currentNumIndex}
      setCurrentNumIndex={setCurrentNumIndex}
      clickedJebiNums={clickedJebiNums}
      setClickedJebiNums={setClickedJebiNums}
      jebiPositions={jebiPositions}
      setJebiPositions={setJebiPositions}
      setIsDrawOk={setIsDrawOk}
      isLargeResultMode={isLargeResultMode}
      setIsLargeResultMode={setIsLargeResultMode}
      remainingNumbers={remainingNumbers}
      setIsRevealAll={setIsRevealAll}
      isRevealAll={isRevealAll}
      isClickedByJebi={isClickedByJebi}
      setIsClickedByJebi={setIsClickedByJebi}
    />
  );
};

export default OneByOneContainer;
