import 'dayjs/locale/ko';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';

export default function KoreanDate(date) {
  //날짜 형식 바꾸기 1/1(월)
  dayjs.locale('ko');
  const changedDate = dayjs(date);
  const dayOfWeek = changedDate.format('dd'); // 요일을 텍스트로 표시

  return (
    <>
      {changedDate.format('MM/DD')}({dayOfWeek})
    </>
  );
}
