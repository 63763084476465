import { useEffect, useRef } from 'react';
import Modal from '../../components/Modal';
import {
  ModalContent,
  ModalContents,
  ModalTitle,
  ModalWrapper,
} from '../../components/ModalStyle';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';

export const WelcomeModal = ({}) => {
  const navigate = useNavigate();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        window.location.href = '/classinfo';
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <>
      <ModalWrapper>
        <ModalContent ref={modalRef}>
          <ModalTitle>축하합니다🎉</ModalTitle>
          <ModalContents>
            이제 티처노트 무료 진도표를 이용하실 수 있습니다.
            <br />
            나의 담당 학급을 설정해보세요!
          </ModalContents>
        </ModalContent>
      </ModalWrapper>
    </>
  );
};
