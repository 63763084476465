import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import axios from '../../../node_modules/axios/index';
import UserContext from '../../contexts/userContext';
import SeatDrawMapComponent from './3-4afterDrawing/SeatDrawMapComponent';
import {
  DrawBox,
  DrawPageMain,
  DrawPageBox,
  DrawBox_Content_65,
  DrawBox_Content_35,
  OneByOne_Footer,
  OneByOne_Footer_Content_Left,
  OneByOne_Footer_Content_Right,
  SettingSwitchBox,
  SettingSwitchBoxInner,
  MobileMessageContainer,
} from './OneByOneStyle';

import DrawPageContainer from '../../drawPageLayout/DrawPageContainer';
import OneByOneContainer from './3-3drawDesk/OneByOneContainer';
import SeatSettingComponent from './3-1seatSetting/SeatSettingComponent';
import StudentSettingComponent from './3-2studentSetting/StudentSettingComponent';
import Switch from '../../components/Switch';
import { padding } from '../../styles/commonStyle';
import LeftTooltip from '../../components/LeftTooltip';
import { PageHeader } from '../../pageLayout/PageContainerStyle';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import RegisterModalComponent from '../../pages/registerModal/RegisterModalComponent';
import LoginModalComponent from '../../pages/loginModal/LoginModalComponent';
import HeaderMenuBar from '../../pageLayout/HeaderMenuBar';
import Notification from '../../components/Notification';
import { DisplayRowSpaceBetween, GreenBadge } from '../../styles/BasicStyle';

const OneByOnePage = () => {
  // 학생 번호
  const [numberList, setNumberList] = useState(
    Array.from({ length: 30 }, (_, index) => index + 1),
  );
  const [myNum, setMyNum] = useState(null); // 자리 설정 시 사용하는 번호
  const [usedNumbers, setUsedNumbers] = useState(new Map()); // 사용된 번호
  const [notUsingNumbers, setNotUsingNumbers] = useState([]); // 사용하지 않는 번호
  const [completedNum, setCompletedNum] = useState([]); //seatmap에 한개씩 보여주기 위해 정의
  // 자리
  const [blockedSeats, setBlockedSeats] = useState(new Set()); // X표시 하여 사용하지 않는 자리
  const [randomSeat, setRandomSeat] = useState(null); // 랜덤으로 뽑은 자리
  const [usedSeats, setUsedSeats] = useState(new Set()); // 뽑힌 자리
  const [numberSeatMap, setNumberSeatMap] = useState({}); // 번호별 좌석 정보
  const [previousNumberSeatMap, setPreviousNumberSeatMap] = useState({}); // 번호별 좌석 정보
  const [maleSeatsNum, setMaleSeatsNum] = useState(0); //남자 자리 숫자
  const [femaleSeatsNum, setfemaleSeatsNum] = useState(0); //여자 자리 숫자

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCol, setSelectedCol] = useState(null);

  // 자리뽑기 옵션
  const [pairMode, setPairMode] = useState(false); // 짝 만들기
  const [genderMode, setGenderMode] = useState(false); // 남녀구분
  const [genderSeatRule, setGenderSeatRule] = useState(false); // 남녀 자리 위치 바꾸기

  //미리 배치
  const [seatPreAssignments, setSeatPreAssignments] = useState({}); //저장된+현재수정
  const [preAssignment, setPreAssignment] = useState([]); // 미리 배치된 학생(저장 후 불러오기)
  const [preAssigningSeats, setPreAssigningSeats] = useState({}); //미리 배치된 학생(저장 전)

  useEffect(() => {
    const mergedAssignments = {
      ...seatPreAssignments,
      ...preAssigningSeats,
    };
    setSeatPreAssignments(mergedAssignments);
  }, [preAssigningSeats, preAssignment]);

  const [frontNums, setFrontNums] = useState([]); //앞자리
  const [adjacentNums, setAdjacentNums] = useState([]);
  const [separateNums, setSeparateNums] = useState([]);
  const [cornerNums, setCornerNums] = useState([]);

  //자리 수정
  const [isEditMode, setIsEditMode] = useState(false); // 자리 수정 모드 상태

  //자리 정보 저장, 불러오기
  const [avoidPreviousSeats, setAvoidPreviousSeats] = useState(true); // 이전 좌석 피하기 옵션
  const [isDrawSettingOpen, setIsDrawSettingOpen] = useState(true);

  //학생수, 자리수, 성별 수
  const [actualStudentCount, setActualStudentCount] = useState(
    numberList.length,
  );
  const [availableSeats, setAvailableSeats] = useState('');
  const [isDrawOk, setIsDrawOk] = useState(false); //자리뽑기 준비 완료

  //자리뽑기
  const [currentNumIndex, setCurrentNumIndex] = useState(0);
  const [isReversed, setIsReversed] = useState(false); // 추가된 상태
  const [clickedJebiNums, setClickedJebiNums] = useState([]);
  const [jebiPositions, setJebiPositions] = useState(
    numberList.map(() => ({
      top: Math.random() * 65,
      left: Math.random() * 80,
    })),
  );
  const [drawingResult, setDrawingResult] = useState(null);
  const [isLargeResultMode, setIsLargeResultMode] = useState(false);
  const [isRevealAll, setIsRevealAll] = useState(false); //자리뽑기 나머지 결과 전부 보여주기

  //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton'); //menubar,bottombutton

  const [isClickedByJebi, setIsClickedByJebi] = useState(false); //제비를 클릭해서 currentNumIndex가 바뀌었을 때만 자리배치 결과 배경 연두색으로
  const [showNotification, setShowNotification] = useState(false);

  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  // -------------------- 성별 미리 설정하기(기능 확인 필요)  -------------------- //
  const [localGenderMap, setLocalGenderMap] = useState({}); // 각 번호의 성별
  const initializeGenderMap = (numberList) => {
    const map = {};
    numberList.forEach((number) => {
      if (number >= 1 && number <= 15) {
        map[number] = '여';
      } else if (number >= 16 && number <= 30) {
        map[number] = '남';
      }
    });
    return map;
  };

  const [genderMap, setGenderMap] = useState(() =>
    initializeGenderMap(numberList),
  ); //처음에 불러온, 설정한 각 번호의 성별!

  const [maleCount, setMaleCount] = useState(''); //남자 숫자
  const [femaleCount, setFemaleCount] = useState(''); //여자 숫자

  // useEffect(() => {
  //   console.log('localGenderMap:' + JSON.stringify(localGenderMap));
  // }, [localGenderMap]);
  // useEffect(() => {
  //   console.log('genderMap:' + JSON.stringify(genderMap));
  // }, [genderMap]);

  // -------------------- 기본 세팅  -------------------- //

  // 기본 자리 세팅
  const [seats, setSeats] = useState(
    Array(5) // 세로 자리 수
      .fill()
      .map(() => Array(6).fill(null)), // 가로 자리 수
  );

  // -------------------- 자리 설정 불러오기 -------------------- //

  //자리 설정 불러오기
  const loadSeatInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getSeatInfo/' + userId,
      );
      const data = response.data;

      if (data && data.length > 0 && data[0].seats) {
        // data가 빈 배열이 아닌지, seats가 존재하는지 확인
        setSeats(data[0].seats);
        setBlockedSeats(new Set(data[0].blockedSeats));
        setPairMode(data[0].pairMode);
        setGenderMode(data[0].genderMode);
        setGenderSeatRule(data[0].genderRule);
      } else {
        // console.error('Invalid data format:', data);
        // 데이터가 비어있거나 잘못된 경우 기본값 설정
        setSeats(
          Array(5)
            .fill()
            .map(() => Array(6).fill(null)),
        ); // 기본 좌석 배열
        setBlockedSeats(new Set());
        setPairMode(false);
        setGenderMode(false);
        setGenderSeatRule(false);
      }
    } catch (error) {
      console.error('Error retrieving preferences:', error);
      // API 호출 실패 시 기본 설정으로 초기화
      setSeats(
        Array(5)
          .fill()
          .map(() => Array(6).fill(null)),
      ); // 기본 좌석 배열
      setBlockedSeats(new Set());
      setPairMode(false);
      setGenderMode(false);
      setGenderSeatRule(true);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      loadSeatInfo();
    }
  }, [userId]);
  // --------------------  학생 정보 불러오기  -------------------- //

  //로그인할 때 학생 정보 불러오기
  const fetchStudentInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getstudentinfo/' + userId,
      );

      const studentInfos = response.data;
      if (studentInfos && studentInfos.length > 0) {
        // numberList 생성
        const maxStudentNum = Math.max(
          ...studentInfos.map((info) => info.studentNum),
        );
        const allNumbers = Array.from(
          { length: maxStudentNum },
          (_, i) => i + 1,
        );
        setNumberList(allNumbers);

        // 사용 중인 번호 및 localGenderMap 생성
        const usedNumbers = [];
        const genderMap = {};

        let allGendersNull = true;

        studentInfos.forEach(({ studentNum, gender, isUsingNum }) => {
          genderMap[studentNum] = gender;
          if (isUsingNum) {
            usedNumbers.push(studentNum);
          }

          if (gender !== null) {
            allGendersNull = false;
          }
        });

        // 모든 성별이 null일 경우 기본값 설정
        if (allGendersNull) {
          allNumbers.forEach((num) => {
            if (num >= 1 && num <= 15) {
              genderMap[num] = '여';
            } else {
              genderMap[num] = '남';
            }
          });
        }

        // notUsingNumbers 계산 (isUsingNum이 false인 번호만 추가)
        const notUsing = allNumbers.filter(
          (num) =>
            !usedNumbers.includes(num) ||
            studentInfos.find(
              (student) => student.studentNum === num && !student.isUsingNum,
            ),
        );
        setNotUsingNumbers(notUsing);
        setLocalGenderMap(genderMap);
      } else {
        // 데이터가 비어있거나 잘못된 경우 기본값 설정
      }
    } catch (error) {
      console.error('학생 정보 불러오기 실패:', error);
      alert('학생정보 불러오기 실패');
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchStudentInfo();
    }
  }, [userId]);

  // -------------------- 학생 배치 사전 세팅 불러오기  -------------------- //

  const fetchSeatStudentSetting = useCallback(async () => {
    if (userId) {
      try {
        const response = await axios.get(
          'https://teachernote.me/api/getSeatStudentSetting/' + userId,
        );
        const seatStudentSetting = response.data;
        setAdjacentNums(seatStudentSetting[0]['adjacentNums']);
        setSeparateNums(seatStudentSetting[0]['separateNums']);
        setCornerNums(seatStudentSetting[0]['cornerNums']);
        setPreAssignment(seatStudentSetting[0]['preAssignment']);
      } catch (error) {
        console.error('학생 정보 불러오기 실패:', error);
      }
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchSeatStudentSetting();
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchPreAssignments();
    }
  }, [userId]);

  //미리배치할 학생들 불러오기
  const fetchPreAssignments = useCallback(async () => {
    try {
      const preAssignRes = await axios.get(
        `https://teachernote.me/api/getPreAssignment/${userId}`,
      );
      if (preAssignRes.data) {
        const assignments = preAssignRes.data.preAssignment;

        // 가져온 데이터를 상태에 설정
        setSeatPreAssignments(assignments);
      }
    } catch (error) {
      console.error('Error fetching pre-assignments:', error);
    }
  }, [userId]);

  // -------------------- 로그아웃 시 초기화  -------------------- //
  const handleLogout = useCallback(() => {
    // 모든 상태를 초기값으로 설정
    setNumberList(Array.from({ length: 30 }, (_, index) => index + 1));
    setMyNum(null);
    setUsedNumbers(new Map());
    setNotUsingNumbers([]);
    setCompletedNum([]);
    setBlockedSeats(new Set());
    setRandomSeat(null);
    setUsedSeats(new Set());
    setNumberSeatMap({});
    setPreviousNumberSeatMap({});
    setMaleSeatsNum(0);
    setfemaleSeatsNum(0);
    setSelectedRow(null);
    setSelectedCol(null);
    setPairMode(false);
    setGenderMode(false);
    setGenderSeatRule(false);
    setPreAssigningSeats({});
    setFrontNums([]);
    setAdjacentNums([]);
    setSeparateNums([]);
    setCornerNums([]);
    setPreAssignment([]);
    setIsEditMode(false);
    setSeatPreAssignments({});
    setAvoidPreviousSeats(true);
    setIsDrawSettingOpen(true);
    setLocalGenderMap({});
    setActualStudentCount(numberList.length);
    setAvailableSeats('');
    setMaleCount('');
    setFemaleCount('');
    setIsDrawOk(false);
    setCurrentNumIndex(0);
    setIsReversed(false);
    setClickedJebiNums([]);
    setJebiPositions(
      numberList.map(() => ({
        top: Math.random() * 65,
        left: Math.random() * 80,
      })),
    );
    setDrawingResult(null);
    setIsLargeResultMode(false);
    setIsRevealAll(false);
    setSeats(
      Array(5) // 세로 자리 수
        .fill()
        .map(() => Array(6).fill(null)), // 가로 자리 수
    );
  }, []);

  // 로그아웃 감지 useEffect
  useEffect(() => {
    if (!userState?.user) {
      handleLogout();
    }
  }, [userState?.user, handleLogout]);

  // -------------------- 학생수, 자리수, 성별 비교  -------------------- //

  //학생 수 계산
  useEffect(() => {
    // console.log('numberList:' + JSON.stringify(numberList));
    // console.log('notUsingNumbers:' + JSON.stringify(notUsingNumbers));
    setActualStudentCount(numberList.length - notUsingNumbers.length);
  }, [numberList, notUsingNumbers]);

  //남은 자리 수 계산
  useEffect(() => {
    if (seats && seats.length > 0) {
      const totalSeats = seats.length * seats[0].length;
      const blockedSeatsCount = blockedSeats.size;
      setAvailableSeats(totalSeats - blockedSeatsCount);
    }
  }, [seats, blockedSeats]);

  //성별 계산
  useEffect(() => {
    const genderCounts = Object.values(localGenderMap).reduce(
      (counts, gender) => {
        if (gender === '남') {
          counts.male += 1;
        } else if (gender === '여') {
          counts.female += 1;
        }
        return counts;
      },
      { male: 0, female: 0, noGender: 0 }, // 초기 값 설정
    );
    setMaleCount(genderCounts.male);
    setFemaleCount(genderCounts.female);
  }, [localGenderMap]);

  // useEffect(() => {
  //   console.log('numberSeatMap:' + JSON.stringify(numberSeatMap));
  // }, [numberSeatMap]);

  //학생수,자리수,성별  비교 =>START 버튼 눌렀을 때 실행
  const confirmIsDrawOk = useCallback(() => {
    if (Object.keys(numberSeatMap).length > 0) {
      const resetConfirmed = window.confirm(
        '기존 자리 배치 결과가 리셋됩니다. 자리뽑기를 다시 시작하시겠습니까?',
      );
      if (resetConfirmed) {
        setIsDrawOk(false);
        const initialSeats = seats.map((row) => row.map(() => null)); // 모든 좌석을 null로 초기화
        setSeats(initialSeats);
        setCompletedNum([]); // 완료된 번호 초기화

        setCurrentNumIndex(0);
        // setSelectedSeat(null);
        setClickedJebiNums([]);
        setJebiPositions(
          numberList.map(() => ({
            top: Math.random() * 80,
            left: Math.random() * 80,
          })),
        );
        setCompletedNum([]);
        setIsRevealAll(false);
      } else {
        return; // 사용자가 취소를 눌렀을 경우 더 이상의 진행을 막음
      }
    }

    if (genderMode) {
      // 성별 구분 모드일 때, notUsingNumbers에 포함되지 않은 모든 학생의 성별이 지정되었는지 확인
      const hasUnspecifiedGender = numberList.some((number) => {
        return (
          !notUsingNumbers.includes(number) && // notUsingNumbers에 포함되지 않은 경우만 체크
          !['남', '여'].includes(localGenderMap[number])
        );
      });

      if (hasUnspecifiedGender) {
        alert(
          '성별 구분 모드에서는 모든 학생의 성별이 지정되어야 합니다. 성별 구분 모드를 해제하거나 학생의 성별을 지정해주세요.',
        );
        setIsDrawOk(false);
        setIsDrawSettingOpen(true);
        return;
      }
    }
    if (availableSeats > actualStudentCount) {
      const proceedWithExtraSeats = window.confirm(
        '자리수가 학생수보다 많아 자리가 남습니다. 이대로 진행하시겠습니까?',
      );
      if (!proceedWithExtraSeats) {
        setIsDrawOk(false);
        setIsDrawSettingOpen(true);
        return;
      }
    }

    if (availableSeats >= actualStudentCount) {
      if (
        !genderMode ||
        (maleSeatsNum >= maleCount && femaleSeatsNum >= femaleCount)
      ) {
        setIsDrawOk(true);
        setIsDrawSettingOpen(false);
      } else {
        setIsDrawOk(false);
        alert('(남,녀)자리수보다 학생수가 많습니다. 설정을 확인해주세요.');
        setIsDrawSettingOpen(true);
      }
    } else {
      setIsDrawOk(false);
      alert('(남, 녀)자리수보다 학생수가 많습니다. 설정을 확인해주세요.');
      setIsDrawSettingOpen(true);
    }
  }, [
    availableSeats,
    actualStudentCount,
    genderMode,
    maleSeatsNum,
    femaleSeatsNum,
    maleCount,
    femaleCount,
    numberList,
    notUsingNumbers,
    localGenderMap,
    numberSeatMap,
  ]);

  // -------------------- 전체 설정 저장하기  -------------------- //
  const saveSettings = () => {
    if (userId) {
      if (genderMode) {
        // 성별 구분 모드일 때, notUsingNumbers에 포함되지 않은 모든 학생의 성별이 지정되었는지 확인
        const hasUnspecifiedGender = numberList.some((number) => {
          return (
            !notUsingNumbers.includes(number) && // notUsingNumbers에 포함되지 않은 경우만 체크
            !['남', '여'].includes(localGenderMap[number])
          );
        });

        if (hasUnspecifiedGender) {
          alert(
            '성별 구분 모드에서는 모든 학생의 성별이 지정되어야 합니다. 성별 구분 모드를 해제하거나 학생의 성별을 지정해주세요.',
          );
          return;
        }
      }

      if (availableSeats >= actualStudentCount) {
        if (
          !genderMode ||
          (maleSeatsNum >= maleCount && femaleSeatsNum >= femaleCount)
        ) {
          saveSeatInfo(
            seats,
            blockedSeats,
            pairMode,
            genderMode,
            genderSeatRule,
            userId,
          );
          saveStudentInfo(numberList, localGenderMap, notUsingNumbers, userId);
          handleSaveFrontNums();
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 1000);
        } else {
          alert('(남, 녀)자리수보다 학생수가 많습니다. 설정을 확인해주세요.');
        }
      } else {
        alert('(남, 녀)자리수보다 학생수가 많습니다. 설정을 확인해주세요.');
      }
    } else {
      setPendingSaveType('settings');
      setRegisterModalOpen(true);
    }
  };

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
    await loadSeatInfo(); // 자리 설정 정보 불러오기
    await fetchStudentInfo(); // 학생 정보 불러오기
    await fetchSeatStudentSetting(); // 학생 배치 사전 세팅 불러오기
    await loadFrontNums(); // 앞자리 설정 정보 불러오기
    await fetchPreAssignments(); // 미리 배치된 학생 정보 불러오기

    // if (modalSource === 'bottombutton') {
    //   if (userId) {
    //     if (pendingSaveType === 'seatmap') {
    //       await handleSeatmapSave(userId); // 회원가입 후 자리 배치 저장
    //     } else if (pendingSaveType === 'settings') {
    //       await saveSeatInfo(
    //         seats,
    //         blockedSeats,
    //         pairMode,
    //         genderMode,
    //         genderSeatRule,
    //         userId,
    //       );
    //       await saveStudentInfo(
    //         numberList,
    //         localGenderMap,
    //         notUsingNumbers,
    //         userId,
    //       );
    //       await handleSaveFrontNums();
    //       // await fetchStudentInfo();
    //       setShowNotification(true); // 알림 상태를 true로 변경
    //       setTimeout(() => {
    //         setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
    //       }, 1000);
    //     }
    //     setPendingSaveType(null); // 작업이 완료되면 상태 초기화
    //   } else {
    //     // alert('userId 없음');
    //   }
    // }
  };
  // -------------------- 자리 설정 저장하기  -------------------- //

  //자리 설정 저장하기
  const saveSeatInfo = useCallback(
    async (
      seats,
      blockedSeats,
      pairMode,
      genderMode,
      genderSeatRule,
      userId,
    ) => {
      if (userId) {
        try {
          const response = await axios.post(
            'https://teachernote.me/api/saveSeatInfo',
            {
              userId,
              seats: JSON.stringify(seats),
              blockedSeats: JSON.stringify(Array.from(blockedSeats)),
              pairMode,
              genderMode,
              genderSeatRule,
            },
          );
          // console.log('Seat info saved successfully:', response.data);
        } catch (error) {
          console.error('Error saving seat info:', error);
        }
      } else {
        console.error('User ID is missing, cannot save seat info');
      }
    },
    [userId],
  );
  // --------------------  frontNums 저장,불러오기  -------------------- //

  // frontNums 저장하기
  const saveFrontNums = useCallback(
    async (frontNums, userId) => {
      if (userId) {
        try {
          const response = await axios.post(
            'https://teachernote.me/api/saveFrontNums',
            {
              userId,
              frontNums: JSON.stringify(frontNums),
            },
          );
          console.log('FrontNums saved successfully:', response.data);
        } catch (error) {
          console.error('Error saving frontNums:', error);
        }
      } else {
        console.error('User ID is missing, cannot save frontNums');
      }
    },
    [userId],
  );

  // frontNums 불러오기
  const loadFrontNums = useCallback(async () => {
    if (!userId) {
      console.error('User ID is missing, cannot load frontNums');
      return;
    }
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getFrontNums/' + userId,
      );
      const frontNumData = response.data || [];
      // console.log('.frontNums:' + JSON.stringify(frontNumData));
      // console.log('fontNumData type:' + typeof frontNumData);
      // setFrontNums(frontNumData); // frontNums 상태로 설정

      // 모든 frontNums 배열을 합쳐서 하나의 배열로 만듦
      const extractedFrontNums = frontNumData
        .flatMap((item) => item.frontNums) // 각 객체의 frontNums를 추출하여 병합
        .filter((num) => !isNaN(num)); // 숫자만 필터링

      // console.log('Extracted frontNums:', extractedFrontNums);

      setFrontNums(extractedFrontNums); // 추출한 frontNums를 설정
    } catch (error) {
      console.error('Error retrieving frontNums:', error);
      setFrontNums([]); // 오류 발생 시 기본값 설정
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      loadFrontNums();
    }
  }, [userId]);

  // 예시: frontNums 변경 후 저장 호출
  const handleSaveFrontNums = () => {
    saveFrontNums(frontNums, userId);
  };

  // --------------------  학생 정보 저장  -------------------- //
  const saveStudentInfo = useCallback(
    async (numberList, localGenderMap, notUsingNumbers, userId) => {
      if (userId) {
        const availableNumbers = numberList;
        const studentInfos = availableNumbers.map((studentNum) => ({
          studentNum,
          gender: Object.keys(localGenderMap).includes(String(studentNum))
            ? localGenderMap[studentNum]
            : null,
          isUsingNum: !notUsingNumbers.includes(studentNum),
        }));
        try {
          const res = await axios.post(
            `https://teachernote.me/api/savestudentinfo/${userId}`,
            {
              studentInfos: studentInfos,
            },
          );
        } catch (error) {
          console.error('Error saving student info:', error);
        }
      } else {
        console.error('User ID is missing, cannot save student info');
      }
    },
    [userId],
  );
  // --------------------  앞에 앉을 학생들 저장  -------------------- //

  // -------------------- 배치 결과 저장하기  -------------------- //
  const [pendingSaveType, setPendingSaveType] = useState(null);

  const handleSeatmapSave = async (userId) => {
    if (userId) {
      if (Object.keys(numberSeatMap).length === 0) {
        alert(
          '배치된 결과가 없습니다. START 버튼을 눌러서 자리를 배치해보세요!',
        );
        return;
      }

      const allNumbersAssigned = Object.keys(numberSeatMap).every((num) =>
        completedNum.includes(Number(num)),
      );

      if (!allNumbersAssigned) {
        const confirmCompleteAll = window.confirm(
          '모든 번호가 배치되지 않았습니다. 모든 번호를 배치하고 저장하시겠습니까?',
        );

        if (confirmCompleteAll) {
          // 모든 번호를 completedNum에 추가
          setCompletedNum((prevCompletedNum) => [
            ...prevCompletedNum,
            ...Object.keys(numberSeatMap)
              .map(Number)
              .filter((num) => !prevCompletedNum.includes(num)),
          ]);
          saveSeatMap();
        } else {
          return;
        }
      } else {
        saveSeatMap(); // 모든 번호가 배치된 상태이므로 바로 저장
      }
    } else {
      setPendingSaveType('seatmap');
      setRegisterModalOpen(true);
    }
  };

  const saveSeatMap = async () => {
    try {
      const response = await axios.post(
        'https://teachernote.me/api/saveSeatMap',
        {
          userId,
          numberSeatMap,
        },
      );
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1000);
    } catch (error) {
      console.error('Error saving seat map:', error);
      // alert('자리 배치 결과 저장 실패');
    }
  };

  // -------------------- 설정 저장->회원가입/로그인 모달  -------------------- //

  // register 창을 띄우고 가입 완료 시 콜백을 실행하는 함수

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  // --------------------  전체화면 보기에서 뒤로가기 기능  -------------------- //

  const navigate = useNavigate();

  useEffect(() => {
    if (isLargeResultMode) {
      // 현재 경로를 유지하는 새로운 상태를 푸시
      window.history.pushState(null, document.title, window.location.pathname);

      const handlePopState = (event) => {
        // 뒤로 가기 버튼을 눌렀을 때, 다시 현재 페이지의 상태를 푸시하여 뒤로 가기를 방지
        setIsLargeResultMode(!isLargeResultMode);
        window.history.pushState(
          null,
          document.title,
          window.location.pathname,
        );
      };
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [isLargeResultMode]);

  // --------------------  자리뽑기 중에 세팅창 열 때  -------------------- //

  const handleOpenSetting = () => {
    if (isDrawOk && !isDrawSettingOpen) {
      const resetConfirmed = window.confirm(
        '현재까지 자리뽑기 결과가 리셋됩니다. 자리 및 학생을 다시 설정하시겠습니까?',
      );
      if (resetConfirmed) {
        // 자리 배치 결과 리셋
        setSeats(
          Array(5)
            .fill()
            .map(() => Array(6).fill(null)),
        ); // 기본 좌석 배열로 초기화

        setCompletedNum([]);
        setNumberSeatMap({});
        setIsDrawSettingOpen(true);
        setIsDrawOk(false);

        const initialSeats = seats.map((row) => row.map(() => null));
        setSeats(initialSeats);
        setCompletedNum([]); // 완료된 번호 초기화

        setCurrentNumIndex(0);
        // setSelectedSeat(null);
        setClickedJebiNums([]);
        setJebiPositions(
          numberList.map(() => ({
            top: Math.random() * 80,
            left: Math.random() * 80,
          })),
        );
        setCompletedNum([]);
        setIsRevealAll(false);
      }
    } else {
      setIsDrawSettingOpen(!isDrawSettingOpen);
    }
  };

  return (
    <>
      {!isLargeResultMode ? (
        <PageHeader>
          <HeaderMenuBar
            isRegisterModalOpen={isRegisterModalOpen}
            setRegisterModalOpen={setRegisterModalOpen}
            modalSource={modalSource}
            setModalSource={setModalSource}
            isLoginModalOpen={isLoginModalOpen}
            setLoginModalOpen={setLoginModalOpen}
          />
        </PageHeader>
      ) : (
        <></>
      )}
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}

      <DrawPageContainer>
        <>
          <DrawPageMain>
            <DrawPageBox>
              {isLargeResultMode ? (
                <div style={{ flex: 1 }}>
                  <SeatDrawMapComponent
                    setSeats={setSeats}
                    setBlockedSeats={setBlockedSeats}
                    avoidPreviousSeats={avoidPreviousSeats}
                    setAvoidPreviousSeats={setAvoidPreviousSeats}
                    setPairMode={setPairMode}
                    pairMode={pairMode}
                    setGenderMode={setGenderMode}
                    genderMode={genderMode}
                    seats={seats}
                    blockedSeats={blockedSeats}
                    preAssigningSeats={preAssigningSeats}
                    genderMap={genderMap}
                    localGenderMap={localGenderMap}
                    genderSeatRule={genderSeatRule}
                    setGenderSeatRule={setGenderSeatRule}
                    setPreAssigningSeats={setPreAssigningSeats}
                    numberList={numberList}
                    notUsingNumbers={notUsingNumbers}
                    setNumberList={setNumberList}
                    preAssignment={preAssignment}
                    completedNum={completedNum}
                    setCompletedNum={setCompletedNum}
                    numberSeatMap={numberSeatMap}
                    isDrawSettingOpen={isDrawSettingOpen}
                    setIsDrawSettingOpen={setIsDrawSettingOpen}
                    currentNumIndex={currentNumIndex}
                    isReversed={isReversed}
                    setIsReversed={setIsReversed}
                    setNumberSeatMap={setNumberSeatMap}
                    clickedJebiNums={clickedJebiNums}
                    setClickedJebiNums={setClickedJebiNums}
                    jebiPositions={jebiPositions}
                    setJebiPositions={setJebiPositions}
                    setCurrentNumIndex={setCurrentNumIndex}
                    setDrawingResult={setDrawingResult}
                    drawingResult={drawingResult}
                    setIsDrawOk={setIsDrawOk}
                    isLargeResultMode={isLargeResultMode}
                    setIsLargeResultMode={setIsLargeResultMode}
                    setIsRevealAll={setIsRevealAll}
                    isRevealAll={isRevealAll}
                    openRegisterModal={openRegisterModal}
                  />
                </div>
              ) : (
                <>
                  <Notification
                    message="변경사항 저장 완료"
                    isVisible={showNotification}
                  />
                  <SettingSwitchBox>
                    <LeftTooltip text="학생수와 교실좌석을 설정하려면 클릭!">
                      <SettingSwitchBoxInner>
                        <div
                          style={{ paddingRight: `${padding.small}` }}
                          onClick={handleOpenSetting}
                        >
                          세팅창 열기
                        </div>
                        <Switch
                          isOn={isDrawSettingOpen}
                          handleToggle={handleOpenSetting}
                        />
                      </SettingSwitchBoxInner>
                    </LeftTooltip>
                  </SettingSwitchBox>
                  <DrawBox
                    style={{ display: isDrawSettingOpen ? 'flex' : 'none' }}
                  >
                    <DrawBox_Content_35>
                      <SeatSettingComponent
                        setSeats={setSeats}
                        setBlockedSeats={setBlockedSeats}
                        avoidPreviousSeats={avoidPreviousSeats}
                        setAvoidPreviousSeats={setAvoidPreviousSeats}
                        setPairMode={setPairMode}
                        pairMode={pairMode}
                        setGenderMode={setGenderMode}
                        genderMode={genderMode}
                        seats={seats}
                        blockedSeats={blockedSeats}
                        preAssigningSeats={preAssigningSeats}
                        genderMap={genderMap}
                        genderSeatRule={genderSeatRule}
                        setGenderSeatRule={setGenderSeatRule}
                        setPreAssigningSeats={setPreAssigningSeats}
                        numberList={numberList}
                        notUsingNumbers={notUsingNumbers}
                        setNumberList={setNumberList}
                        preAssignment={preAssignment}
                        setIsDrawSettingOpen={setIsDrawSettingOpen}
                        setMaleSeatsNum={setMaleSeatsNum}
                        setfemaleSeatsNum={setfemaleSeatsNum}
                        saveSeatInfo={saveSeatInfo}
                        loadSeatInfo={loadSeatInfo}
                        seatPreAssignments={seatPreAssignments}
                        setSeatPreAssignments={setSeatPreAssignments}
                        openRegisterModal={openRegisterModal}
                      />
                    </DrawBox_Content_35>
                    <DrawBox_Content_65>
                      <StudentSettingComponent
                        fetchStudentInfo={fetchStudentInfo}
                        numberList={numberList}
                        setGenderMap={setGenderMap}
                        notUsingNumbers={notUsingNumbers}
                        setNumberList={setNumberList}
                        setNotUsingNumbers={setNotUsingNumbers}
                        frontNums={frontNums}
                        setFrontNums={setFrontNums}
                        setIsDrawSettingOpen={setIsDrawSettingOpen}
                        genderMode={genderMode}
                        setGenderMode={setGenderMode}
                        seats={seats}
                        blockedSeats={blockedSeats}
                        maleSeatsNum={maleSeatsNum}
                        femaleSeatsNum={femaleSeatsNum}
                        localGenderMap={localGenderMap}
                        setLocalGenderMap={setLocalGenderMap}
                        availableSeats={availableSeats}
                        actualStudentCount={actualStudentCount}
                        femaleCount={femaleCount}
                        maleCount={maleCount}
                        saveStudentInfo={saveStudentInfo}
                        adjacentNums={adjacentNums}
                        cornerNums={cornerNums}
                        setAdjacentNums={setAdjacentNums}
                        setCornerNums={setCornerNums}
                        preAssigningSeats={preAssigningSeats}
                        setPreAssigningSeats={setPreAssigningSeats}
                        preAssignment={preAssignment}
                        setPreAssignment={setPreAssignment}
                        seatPreAssignments={seatPreAssignments}
                        setSeatPreAssignments={setSeatPreAssignments}
                        setMaleCount={setMaleCount}
                        setFemaleCount={setFemaleCount}
                        openRegisterModal={openRegisterModal}
                        loadFrontNums={loadFrontNums}
                      />
                    </DrawBox_Content_65>
                  </DrawBox>
                  <DrawBox>
                    <DrawBox_Content_65>
                      <OneByOneContainer
                        myNum={myNum}
                        randomSeat={randomSeat}
                        avoidPreviousSeats={avoidPreviousSeats}
                        setAvoidPreviousSeats={setAvoidPreviousSeats}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        notUsingNumbers={notUsingNumbers}
                        setMyNum={setMyNum}
                        numberSeatMap={numberSeatMap}
                        setRandomSeat={setRandomSeat}
                        usedSeats={usedSeats}
                        adjacentNums={adjacentNums}
                        seats={seats}
                        cornerNums={cornerNums}
                        frontNums={frontNums}
                        separateNums={separateNums}
                        numberList={numberList}
                        genderMap={genderMap}
                        localGenderMap={localGenderMap}
                        setNumberSeatMap={setNumberSeatMap}
                        setUsedSeats={setUsedSeats}
                        blockedSeats={blockedSeats}
                        genderSeatRule={genderSeatRule}
                        genderMode={genderMode}
                        setUsedNumbers={setUsedNumbers}
                        setSeats={setSeats}
                        setFrontNums={setFrontNums}
                        preAssignment={preAssignment}
                        previousNumberSeatMap={previousNumberSeatMap}
                        setPreviousNumberSeatMap={setPreviousNumberSeatMap}
                        completedNum={completedNum}
                        setCompletedNum={setCompletedNum}
                        isDrawOk={isDrawOk}
                        setIsDrawSettingOpen={setIsDrawSettingOpen}
                        isDrawSettingOpen={isDrawSettingOpen}
                        confirmIsDrawOk={confirmIsDrawOk}
                        currentNumIndex={currentNumIndex}
                        setCurrentNumIndex={setCurrentNumIndex}
                        clickedJebiNums={clickedJebiNums}
                        setClickedJebiNums={setClickedJebiNums}
                        jebiPositions={jebiPositions}
                        setJebiPositions={setJebiPositions}
                        setDrawingResult={setDrawingResult}
                        drawingResult={drawingResult}
                        setIsDrawOk={setIsDrawOk}
                        isLargeResultMode={isLargeResultMode}
                        setIsLargeResultMode={setIsLargeResultMode}
                        setIsRevealAll={setIsRevealAll}
                        isRevealAll={isRevealAll}
                        isClickedByJebi={isClickedByJebi}
                        setIsClickedByJebi={setIsClickedByJebi}
                      />
                    </DrawBox_Content_65>
                    <DrawBox_Content_35>
                      <SeatDrawMapComponent
                        setSeats={setSeats}
                        setBlockedSeats={setBlockedSeats}
                        avoidPreviousSeats={avoidPreviousSeats}
                        setAvoidPreviousSeats={setAvoidPreviousSeats}
                        setPairMode={setPairMode}
                        pairMode={pairMode}
                        setGenderMode={setGenderMode}
                        genderMode={genderMode}
                        seats={seats}
                        blockedSeats={blockedSeats}
                        preAssigningSeats={preAssigningSeats}
                        genderSeatRule={genderSeatRule}
                        setGenderSeatRule={setGenderSeatRule}
                        setPreAssigningSeats={setPreAssigningSeats}
                        numberList={numberList}
                        notUsingNumbers={notUsingNumbers}
                        setNumberList={setNumberList}
                        preAssignment={preAssignment}
                        completedNum={completedNum}
                        setCompletedNum={setCompletedNum}
                        numberSeatMap={numberSeatMap}
                        isDrawSettingOpen={isDrawSettingOpen}
                        setIsDrawSettingOpen={setIsDrawSettingOpen}
                        currentNumIndex={currentNumIndex}
                        isReversed={isReversed}
                        setIsReversed={setIsReversed}
                        setNumberSeatMap={setNumberSeatMap}
                        clickedJebiNums={clickedJebiNums}
                        setClickedJebiNums={setClickedJebiNums}
                        jebiPositions={jebiPositions}
                        setJebiPositions={setJebiPositions}
                        setCurrentNumIndex={setCurrentNumIndex}
                        setDrawingResult={setDrawingResult}
                        drawingResult={drawingResult}
                        setIsDrawOk={setIsDrawOk}
                        isDrawOk={isDrawOk}
                        isLargeResultMode={isLargeResultMode}
                        setIsLargeResultMode={setIsLargeResultMode}
                        setIsRevealAll={setIsRevealAll}
                        isRevealAll={isRevealAll}
                        isClickedByJebi={isClickedByJebi}
                        setIsClickedByJebi={setIsClickedByJebi}
                        localGenderMap={localGenderMap}
                        frontNums={frontNums}
                        // handleCompleteRemaining={handleCompleteRemaining}
                      />
                    </DrawBox_Content_35>
                  </DrawBox>
                </>
              )}
            </DrawPageBox>
          </DrawPageMain>
          {!isLargeResultMode ? (
            <OneByOne_Footer>
              <OneByOne_Footer_Content_Left onClick={() => saveSettings()}>
                설정 저장하기{' '}
              </OneByOne_Footer_Content_Left>

              <OneByOne_Footer_Content_Right
                onClick={() => handleSeatmapSave(userId)}
              >
                결과 저장하기
              </OneByOne_Footer_Content_Right>
            </OneByOne_Footer>
          ) : (
            <></>
          )}
        </>
      </DrawPageContainer>
      <MobileMessageContainer>
        <p>
          이 페이지는 <br />
          태블릿 및 데스크탑에서 <br />
          이용가능합니다.
        </p>
      </MobileMessageContainer>
    </>
  );
};

export default OneByOnePage;
