import React, { useEffect, useState, useRef } from 'react';
import { HelmetContent } from '../../components/HelmetContent';
import PageContainer from '../../pageLayout/PageContainer';
import { DisplayRow, PageBox } from '../../styles/BasicStyle';
import styled from 'styled-components';
import { gap, margin } from '../../styles/commonStyle';
import { PageSubheader } from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';

const InstaContents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InstaContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${margin.mediumlarge};
`;

const InstagramPage = () => {
  const [visibleContentCount, setVisibleContentCount] = useState(1); // 초기에는 1개의 콘텐츠만 표시
  const observerRef = useRef(null);

  const posts = [
    'https://www.instagram.com/reel/C-94wdaSUdu/',
    'https://www.instagram.com/reel/C-SIPDLy_7f/',
    'https://www.instagram.com/reel/C-KYFTRSKd0/',
    'https://www.instagram.com/reel/C-Hy990yiF9/',
    'https://www.instagram.com/reel/C-FNySxyA7_/',
    'https://www.instagram.com/reel/C-CpOuOynvW/',
    'https://www.instagram.com/reel/C-ASVFKSqgd/',
    'https://www.instagram.com/reel/C919lEoSNBy/',
    'https://www.instagram.com/reel/C9zdy6Myzqy/',
    'https://www.instagram.com/reel/C9wty7kSrKo/',
    'https://www.instagram.com/reel/C9uSe0IySX6/',
    'https://www.instagram.com/reel/C9kGbabSrdO/',
    'https://www.instagram.com/reel/C9e7-2KyFAO/',
    'https://www.instagram.com/reel/C9SDw-gyw-4/',
    'https://www.instagram.com/reel/C9PlpgzSzTx/',
    'https://www.instagram.com/reel/C9PfDI_SOQj/',
    'https://www.instagram.com/reel/C9J6la2yp2k/',
    'https://www.instagram.com/reel/C9AL5sxSRda/',
    'https://www.instagram.com/reel/C89nj30yErI/',
    'https://www.instagram.com/reel/C87EXHryGl3/',
    'https://www.instagram.com/reel/C8rqspbyt3Y/',
    'https://www.instagram.com/reel/C8hdyzPyZ-Z/',
    'https://www.instagram.com/reel/C8euz7Vy7Pw/',
    'https://www.instagram.com/reel/C8cM550SIwW/',
    'https://www.instagram.com/reel/C8W-NGJycn1/',
    'https://www.instagram.com/reel/C8MlWZtSrIf/',
    'https://www.instagram.com/reel/C8HmsSkSj57/',
  ];

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    script.onload = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    let observer;

    const observeLastContent = () => {
      if (observerRef.current) observerRef.current.disconnect(); // 이전 옵저버 해제

      observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setVisibleContentCount((prevCount) =>
            Math.min(prevCount + 1, posts.length),
          );
        }
      });

      const lastContent = document.querySelector(
        `#instaContent-${visibleContentCount - 1}`,
      );
      if (lastContent) {
        observer.observe(lastContent);
      }

      observerRef.current = observer;
    };

    // IntersectionObserver가 너무 빨리 동작하지 않도록 500ms 지연
    const timeoutId = setTimeout(observeLastContent, 500);

    return () => {
      clearTimeout(timeoutId);
      if (observer) observer.disconnect(); // 컴포넌트 언마운트 시 옵저버 해제
    };
  }, [visibleContentCount]);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process(); // 새로 추가된 콘텐츠에 대해 Instagram Embed 처리
    }
  }, [visibleContentCount]); // visibleContentCount가 변경될 때마다 실행

  return (
    <PageContainer>
      <HelmetContent />
      <PageSubheader>
        <ArticleMenuBar />
      </PageSubheader>
      <PageBox>
        <InstaContents>
          {posts.slice(0, visibleContentCount).map((post, index) => (
            <InstaContent
              key={index}
              id={`instaContent-${index}`}
              dangerouslySetInnerHTML={{
                __html: `
              <blockquote class="instagram-media" data-instgrm-permalink="${post}" data-instgrm-version="14" style=" background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);">
                <div style="padding:16px;"> 
                  <a href="${post}" style=" color:#000; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:normal; line-height:17px; text-decoration:none;" target="_blank"> 
                    A post shared by Instagram</a>
                </div> 
              </blockquote>
            `,
              }}
            />
          ))}
        </InstaContents>
      </PageBox>
    </PageContainer>
  );
};

export default InstagramPage;
