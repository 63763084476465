import styled from 'styled-components';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import {
  BlogGridThumbnailImg,
  BlogGridTitle,
  Content,
  GridPageBox,
  GridPageCategory,
  GridPageContainer,
  GridPageLayout1,
  GridPageLayout2,
  GridPageWriteBtn,
  PostCard,
} from '../../components/BlogGridStyle';
import logo from '../../img/logo_white.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BlogGrid = ({ blogs, src }) => {
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgWidth(img.width);
    };
  }, [src]);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/blogwrite');
  };

  return (
    <GridPageContainer>
      <GridPageBox>
        <GridPageLayout1>
          <GridPageCategory>카테고리</GridPageCategory>
          <GridPageWriteBtn onClick={handleClick}>글쓰기</GridPageWriteBtn>
        </GridPageLayout1>
        <GridPageLayout2>
          {/* link to={`/blog/${post.id}`} */}
          {blogs.map((post) => (
            <PostCard to={`/blog/${post.id}`} key={post.id}>
              <BlogGridThumbnailImg src={logo} />
              <BlogGridTitle>{post.title}</BlogGridTitle>
              <Content dangerouslySetInnerHTML={{ __html: post.content }} />
            </PostCard>
          ))}
        </GridPageLayout2>
      </GridPageBox>
    </GridPageContainer>
  );
};

export default BlogGrid;
