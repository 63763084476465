import styled from 'styled-components';
import {
  boerderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  objHeight,
  padding,
  pageWidth,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

//progressnoteByClass/AddProgressNoteByClass.js에 대한 스타일
export const AddProgressNoteCard = styled.div`
  height: auto;
  width: 90%;
  margin: 1%;
  border-radius: ${boerderRadius.large};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  background-color: white;
  font-size: 15px;

  //   border: 1px solid lightgray;
`;
export const AddProgressNoteCardBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AddProgressNoteCardLayout1 = styled.div`
  height: ${objHeight.large};
  padding: ${padding.medium} 0 ${padding.small} ${padding.large};

  border-radius: ${boerderRadius.large} ${boerderRadius.large} 0px 0px;
  background-color: ${gray.light};

  font-weight: ${fontWeight.bold};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddProgressNoteCardLayout2 = styled.div`
  // height: 50px;
  height: ${objHeight.large};
  ${media.mobile`
    font-size: ${fontSize.small};
    height: ${objHeight.mediumLarge};
  `}

  border-bottom: 1px solid ${gray.light};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const AddProgressNoteCardLayout2_input = styled.div`
  width: 20%;
  margin: 0;
  ${media.mobile`
    height: ${objHeight.mediumLarge};
    font-size: ${fontSize.small};
  `}
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AddProgressNoteCardLayout2_text = styled.div`
  width: 20%;
  height: ${objHeight.large};
  ${media.mobile`
    height: ${objHeight.mediumLarge};
  `}

  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: ${gray.light};
  background-color: ${colors.lightGreenBackground};
`;

export const AddProgressNoteCardLayout2_date = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DateCustomInputStyle = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${media.mobile`
  font-size: ${fontSize.small};
  `}

  &:hover {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
  }
`;

export const AddProgressNoteCardLayout3 = styled.div`
  height: ${objHeight.large};
  ${media.mobile`
    height: ${objHeight.mediumLarge};
  `}

  border-bottom: 1px solid ${gray.light};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AddProgressNoteInput = styled.input`
  width: 100%;
  height: ${objHeight.large};
  ${media.mobile`
    height: ${objHeight.mediumLarge};
  `}

  border: none;
  text-align: center;
  border-bottom: 1px solid ${gray.light};

  border-radius: ${boerderRadius.small};
  font-size: ${fontSize.medium};
  ${media.mobile`
  font-size: ${fontSize.small};
  `}
  outline: none; /* 포커스 효과 제거 */
  transition: border-color 0.3s ease; /* 전환 효과 */

  /* 포커스 했을 때 스타일 */
  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.2); /* 포커스 효과 추가 */
  }
`;
export const ArrowBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${padding.small};
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  // background-color: yellow;

  &:hover {
    color: ${colors.primary};
    transform: scale(1.3);
  }
`;

export const AddNoteSelectBox = styled.select`
  width: 100%;
  height: 100%;
  padding: ${padding.medium};

  font-size: ${fontSize.medium};
  ${media.mobile`
  font-size: ${fontSize.small};
  `}
  border: 1px solid #ccc;
  border-radius: ${boerderRadius.small};
  outline: none;
  background-color: #fff;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: right 10px center; /* 화살표 위치 조정 */
  transition: all 0.3s ease;
  border: none;
`;
export const AddNoteSelectOption = styled.option`
  padding: ${padding.large};

  font-size: ${fontSize.medium};
  ${media.mobile`
  font-size: ${fontSize.small};
  `}
`;

export const AddProgressNoteSelectTopic = styled.div`
  width: 100%;
  padding: 0 ${padding.large} 0 ${padding.medium};

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AddProgressNoteCardLayout4 = styled.div`
  height: 100%;
  width: 100%;
`;

export const AddProgressNoteTextArea = styled.textarea`
width: calc(100% - 80px); /* 부모 요소와 일치하도록 조정 */
height: 15vh;  
padding: 20px 40px;  
 ${media.mobile`
    padding:  20px;
    height: 10vh;  

  `}

resize: none;
border: none;
font-size: ${fontSize.medium};
 ${media.mobile`
  font-size: ${fontSize.small};
  `}
outline: none; /* 포커스시 테두리 제거 */
font-family: 'Gothic A1', sans-serif;
autofocus;
`;

export const AddProgressNoteCardLayout5 = styled.div`
  width: 100%;
  height: ${objHeight.large};
  ${media.mobile`
    width: auto;
    height: ${objHeight.medium};
    padding: ${padding.medium};
  `}

  // background-color: ${gray.light};
  background-color: ${colors.lightGreenBackground};
  border-radius: 0px 0px ${boerderRadius.large} ${boerderRadius.large};

  font-weight: ${fontWeight.bold};
  color: ${gray.darktext};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > *:first-child {
    border-right: 1px solid ${colors.background};
  }
`;

export const AddProgressNoteCardLayout5Inner = styled.div`
  width: 50%;
  height: 100%;
  ${media.mobile`
   padding: ${padding.medium};
   font-size: ${fontSize.small};
  `}
  &:hover {
    // color: ${colors.background};
    // background-color: ${gray.medium};
    background-color: ${colors.lightGreenBorder};
    cursor: pointer;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  // background-color: yellow;
`;
