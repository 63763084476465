import React from 'react';
import styled from 'styled-components';

// Button 스타일 컴포넌트 정의
const LinkToBox = styled.div`
  width: 100%;
  margin: 5px 0px;
  text-align: right;
  text-decoration: none; /* 밑줄 제거 */
  color: inherit; /* 부모 요소의 색상 상속 */
`;

const LinkTo = ({ children }) => {
  return <LinkToBox>{children}</LinkToBox>;
};

export default LinkTo;
