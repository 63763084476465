import styled from 'styled-components';
import {
  fontSize,
  gray,
  height,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import { media } from '../styles/media';

export const FooterContainer = styled.div`
  ${media.desktop`
    width: ${pageWidth.desktop};
      height: ${height.footer};

  `}
  ${media.tablet`
        width: ${pageWidth.tablet};
  `}

  ${media.mobile`
    display: none;
        width: ${pageWidth.mobile};
  `}
  padding: ${padding.large};

  color: ${gray.medium};
  font-size: ${fontSize.small};

  display: flex;
  align-items: center;
`;

export const FooterLeft = styled.div``;
export const FooterRight = styled.div`
  //   border-left: 1px solid ${gray.medium};
`;

export const FooterSnsImg = styled.img`
  width: 30px;
  margin-right: 10px;
`;
