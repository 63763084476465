import {
  ClassInfoAddCardLayout1,
  ClassInfoAddCardLayout2,
  ClassInfoAddCardLayout1Divide,
  ClassInfoCardBodyItem,
  ClassInfoStyledInput,
  ClassInfoAddCardLayout1Inner,
  ClassInfoInputErrorText,
  ClassInfoForm,
} from './ClassInfoStyle';
import { FaCheck } from 'react-icons/fa';
import { iconSize } from '../../styles/commonStyle';
import { CardTitle, StyledCard } from '../../components/StyledCard';
import { useState } from 'react';

const ClassInfoInput = ({
  handleGradeChange,
  handleClassChange,
  saveClassInfoFromInput,
  findDoubleCheck,
  gradeError,
  classError,
  classInfo,
  inputError,
}) => {
  return (
    <>
      <StyledCard>
        <CardTitle>학급 추가</CardTitle>
        <ClassInfoCardBodyItem>
          <ClassInfoForm
            className="form"
            onSubmit={(e) => saveClassInfoFromInput(e)}
          >
            <ClassInfoAddCardLayout1>
              <ClassInfoAddCardLayout1Inner>
                <ClassInfoAddCardLayout1Divide>
                  <ClassInfoStyledInput
                    type="number"
                    placeholder=""
                    name="grade"
                    onChange={handleGradeChange}
                    value={classInfo.grade}
                  />
                </ClassInfoAddCardLayout1Divide>
                <ClassInfoAddCardLayout1Divide>
                  학년
                </ClassInfoAddCardLayout1Divide>
                <ClassInfoAddCardLayout1Divide>
                  <ClassInfoStyledInput
                    type="number"
                    placeholder=""
                    name="class"
                    onChange={handleClassChange}
                    value={classInfo.class}
                  />
                </ClassInfoAddCardLayout1Divide>
                <ClassInfoAddCardLayout1Divide>
                  반
                </ClassInfoAddCardLayout1Divide>
              </ClassInfoAddCardLayout1Inner>
              <button type="submit" style={{ display: 'none' }} />
            </ClassInfoAddCardLayout1>
            <ClassInfoAddCardLayout2 onClick={(e) => saveClassInfoFromInput(e)}>
              <FaCheck />
            </ClassInfoAddCardLayout2>
          </ClassInfoForm>
        </ClassInfoCardBodyItem>
        {(!findDoubleCheck || gradeError || classError || inputError) && (
          <ClassInfoCardBodyItem>
            <ClassInfoInputErrorText>
              {!findDoubleCheck && <div>이미 존재하는 학급입니다. </div>}
              {gradeError && (
                <div>학년은 1~3 사이의 숫자만 입력 가능합니다. &nbsp; </div>
              )}
              {classError && (
                <div>학급은 1~15 사이의 숫자만 입력 가능합니다. &nbsp; </div>
              )}
              {inputError && <div>{inputError}</div>}
            </ClassInfoInputErrorText>
          </ClassInfoCardBodyItem>
        )}
      </StyledCard>
    </>
  );
};

export default ClassInfoInput;
