import React, { useState } from 'react';
import { SeatmapNumberInput } from '../2seatDrawPage/2-2seatSetting/SeatSettingStyle';

const SeatDraw2 = () => {
  const [seats, setSeats] = useState(
    Array(5)
      .fill()
      .map(() => Array(6).fill(null)),
  );
  const [gender, setGender] = useState('male'); // 남학생(male) 또는 여학생(female)
  const [selectedSeat, setSelectedSeat] = useState(null); // 선택된 자리
  const [students, setStudents] = useState({ male: [], female: [] });

  // 행 추가
  const addRow = () => {
    setSeats([...seats, Array(seats[0].length).fill(null)]);
  };

  // 열 추가
  const addColumn = () => {
    setSeats(seats.map((row) => [...row, null]));
  };

  // 자리 삭제
  const removeSeat = (rowIdx, colIdx) => {
    const newSeats = seats.map((row, rIdx) =>
      row.map((seat, cIdx) =>
        rIdx === rowIdx && cIdx === colIdx ? null : seat,
      ),
    );
    setSeats(newSeats);
  };

  // 남녀 자리 지정
  const assignGenderToSeat = (rowIdx, colIdx) => {
    const newSeats = seats.map((row, rIdx) =>
      row.map((seat, cIdx) => {
        if (rIdx === rowIdx && cIdx === colIdx) {
          return gender;
        }
        return seat;
      }),
    );
    setSeats(newSeats);
  };

  // 자리 뽑기
  const drawSeat = () => {
    if (!selectedSeat) {
      alert('번호를 선택해주세요.');
      return;
    }
    const availableSeats = seats
      .flatMap((row, rowIdx) =>
        row.map((seat, colIdx) =>
          seat === gender ? { rowIdx, colIdx } : null,
        ),
      )
      .filter((seat) => seat !== null);
    if (availableSeats.length === 0) {
      alert('사용 가능한 자리가 없습니다.');
      return;
    }
    const randomIndex = Math.floor(Math.random() * availableSeats.length);
    const chosenSeat = availableSeats[randomIndex];
    const newSeats = seats.map((row, rIdx) =>
      row.map((seat, cIdx) => {
        if (rIdx === chosenSeat.rowIdx && cIdx === chosenSeat.colIdx) {
          return selectedSeat;
        }
        return seat;
      }),
    );
    setSeats(newSeats);
    setStudents({
      ...students,
      [gender]: [...students[gender], selectedSeat],
    });
    setSelectedSeat(null);
  };

  return (
    <div>
      <h1>자리 뽑기</h1>
      <div>
        <button onClick={addRow}>행 추가</button>
        <button onClick={addColumn}>열 추가</button>
      </div>
      <div>
        <label>
          성별 선택:
          <select value={gender} onChange={(e) => setGender(e.target.value)}>
            <option value="male">남학생</option>
            <option value="female">여학생</option>
          </select>
        </label>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${seats[0].length}, 50px)`,
          gap: '5px',
        }}
      >
        {seats.map((row, rowIdx) =>
          row.map((seat, colIdx) => (
            <div
              key={`${rowIdx}-${colIdx}`}
              style={{
                width: '50px',
                height: '50px',
                border: '1px solid black',
                backgroundColor: seat
                  ? seat === 'male'
                    ? 'blue'
                    : seat === 'female'
                    ? 'pink'
                    : 'white'
                  : 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() =>
                seat
                  ? removeSeat(rowIdx, colIdx)
                  : assignGenderToSeat(rowIdx, colIdx)
              }
            >
              {seat}
            </div>
          )),
        )}
      </div>
      <div>
        <SeatmapNumberInput
          type="text"
          placeholder="번호 입력"
          value={selectedSeat || ''}
          onChange={(e) => setSelectedSeat(e.target.value)}
        />
        <button onClick={drawSeat}>자리 뽑기</button>
      </div>
    </div>
  );
};

export default SeatDraw2;
