import { useCallback, useEffect, useState } from 'react';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import {
  Left,
  MarginRightText,
  Middle,
  ProgressNoteInputCard,
  ProgressNoteLink,
  ProgressNoteTextArea,
  ProgressNoteTextAreaContainer,
  ProgressNoteTitleNum,
  ProgressNoteTopicDiv,
  ProgressNoteTopicNum,
  Right,
  Width15,
  Width85,
  WidthLarge,
  WidthSmall,
} from '../../components/ProgressNoteInputStyle';
import {
  CardBody,
  CardBottom,
  CardBottomDivide,
  CardBottomMargin,
  CardBox,
  CardTitle,
  StyledCard,
} from '../../components/StyledCard';
import { ProgressNoteTopicTitle } from './ProgressNoteStyle';
import { RiDeleteBin6Line } from 'react-icons/ri';

const ProgressNote = ({
  grade,
  classNum,
  topicNum,
  progressNoteOne,
  saveProgressNote,
  topicTitle,
  handleProgressNote,
  deleteProgressNote,
}) => {
  const [individualRecord, setIndividualRecord] = useState('');

  //기존 정보 받아와서 보여주기
  useEffect(() => {
    if (progressNoteOne && progressNoteOne.length > 0) {
      setIndividualRecord(progressNoteOne[0].record);
    } else {
      setIndividualRecord('');
    }
  }, [progressNoteOne]);

  //새로운 기록 입력
  const [value, setValue] = useState();
  let noteId;

  useEffect(() => {
    setValue(individualRecord);
  }, [individualRecord]);

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      saveProgressNote(e, value);
      setValue('');
    }
  };

  return (
    <CardBottomMargin>
      <StyledCard>
        <CardBox>
          <CardTitle>
            <MarginRightText>
              {grade}학년 {classNum}반 진도노트
            </MarginRightText>
            <ProgressNoteLink to={`/progressnote/byclass/${classNum}`}>
              {grade}학년 {classNum}반 전체보기
            </ProgressNoteLink>{' '}
            {/* <ProgressNoteLink to={`/progressnote/bytopic/${topicNum}`}>
              주제{topicNum} 전체보기
            </ProgressNoteLink> */}
          </CardTitle>

          <CardBody>
            <ProgressNoteTopicDiv>
              <Left>
                <ProgressNoteTitleNum>주제{topicNum}</ProgressNoteTitleNum>
              </Left>
              <Middle>{topicTitle ? topicTitle : `수업 ${topicNum}`}</Middle>
              {progressNoteOne[0] && (
                <Right
                  onClick={() => {
                    deleteProgressNote();
                  }}
                >
                  노트 삭제
                </Right>
              )}
            </ProgressNoteTopicDiv>
            <form onSubmit={(e) => saveProgressNote(e, value)}>
              {/* {progressNoteOne[0] && progressNoteOne[0].id} */}
              <ProgressNoteTextAreaContainer>
                <ProgressNoteTextArea
                  placeholder={individualRecord}
                  type="text"
                  value={value}
                  name="value"
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                />
              </ProgressNoteTextAreaContainer>{' '}
            </form>
          </CardBody>
          <CardBottom>
            <CardBottomDivide onClick={(e) => saveProgressNote(e, value)}>
              저장하기
            </CardBottomDivide>
            <CardBottomDivide
              onClick={() =>
                handleProgressNote(grade, classNum, topicNum, topicTitle)
              }
            >
              닫기
            </CardBottomDivide>
          </CardBottom>
        </CardBox>
      </StyledCard>
    </CardBottomMargin>
  );
};

export default ProgressNote;
