import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 에러가 발생하면 상태를 업데이트하여 fallback UI를 렌더링
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 에러 로깅 등의 추가 작업을 수행할 수 있습니다.
    console.error('Error caught in Error Boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // 에러가 발생했을 때 404 페이지로 리디렉션
      return <Navigate to="/404" replace />;
    }

    // 에러가 발생하지 않았을 때는 자식 컴포넌트를 렌더링
    return this.props.children;
  }
}

export default ErrorBoundary;
