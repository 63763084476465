import PageContainer from '../../pageLayout/PageContainer';
import Login2Container from './Login2Container';

const Login2Page = () => {
  return (
    <PageContainer>
      <Login2Container />
    </PageContainer>
  );
};

export default Login2Page;
