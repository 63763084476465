import './App.css';
import { Routes, Route } from 'react-router-dom';
import TopicPage from './pages/topic/TopicPage';
import ClassInfoPage from './pages/classInfo/ClassInfoPage';
import GradeContext, { GradeProvider } from './contexts/grade';
import { useEffect, useState } from 'react';
import UserContext, { UserProvider } from './contexts/userContext';
import ProgressPage from './pages/progress/ProgressPage';
import ByClassProgressNotePage from './pages/progressnoteByClass/ByClassProgressNotePage';
import ByTopicProgressNotePage from './pages/progressnoteByTopic/ByTopicProgressNotePage';
import DateSelector from './commonFunction/DateSelector';
import { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { HelmetContent } from './components/HelmetContent';
import BlogWritePage from './pages/blogWrite/BlogWritePage';
import BlogViewPage from './pages/blogView/BlogViewPage';
import BlogGridPage from './pages/blogGrid/BlogGridPage';
import BlogListPage from './pages/blogList/BlogListPage';
import Register2Page from './pages/register2/Register2Page';
import AuthPage from './pages/register2/Register2Page';
import Login2Page from './pages/login2/Login2Page';
import GlobalStyle from './styles/GlobalStyle';
import SeatDraw1 from './seatdrawpages/seatDrawTemp/SeatDraw1';
import SeatDraw3 from './seatdrawpages/seatDrawTemp/SeatDraw3';
import SeatDraw2 from './seatdrawpages/seatDrawTemp/SeatDraw2';
import SeatBeforeSettingPage from './seatdrawpages/1seatBeforePage/SeatBeforeSettingPage';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import OneByOnePage from './seatdrawpages/3drawOneByOnePage/OneByOnePage';
import SeatDrawPage1 from './seatdrawpages/2seatDrawPage1/SeatDrawPage1';
import DrawSettingPage from './seatdrawpages/1drawSettingPage/DrawSettingPage';
import UserCommentPage from './pages/IconLink/UserCommentPage';
import InstagramPage from './pages/IconLink/InstagramPage';
import Login2ConfirmPage from './pages/login2-confirm/Login2ConfirmPage';
import UseOnDesktopPage from './pages/IconLink/UseOnDesktopPage';
import NotFoundPage from './pages/IconLink/NotFoundPage';
import ErrorBoundary from './pageLayout/ErrorBoundary';

registerLocale('ko', ko);

const App = () => {
  return (
    <>
      <GlobalStyle />

      <HelmetContent />

      <UserProvider>
        <GradeProvider>
          {/* <ErrorBoundary> */}
          <Routes>
            <Route path="/" element={<ProgressPage />} />

            {/* 진도표 */}
            <Route path="/topic" element={<TopicPage />} />
            <Route path="/progress" element={<ProgressPage />} />

            <Route
              path="/progressnote/byclass/:paramClassNum"
              element={<ByClassProgressNotePage />}
            />
            <Route
              path="/progressnote/byclass"
              element={<ByClassProgressNotePage />}
            />
            <Route
              path="/progressnote/bytopic/:paramTopicNum"
              element={<ByTopicProgressNotePage />}
            />
            <Route
              path="/progressnote/bytopic"
              element={<ByTopicProgressNotePage />}
            />
            <Route path="/progressnote" element={<ByTopicProgressNotePage />} />

            <Route path="/classinfo" element={<ClassInfoPage />} />
            <Route path="/dateselector" element={<DateSelector />} />

            <Route path="/:username">
              <Route index element={<ProgressPage />} />
              <Route path=":postId" element={<ProgressPage />} />
            </Route>
            {/* 아티클 */}
            <Route path="/blogwrite" element={<BlogWritePage />} />
            <Route path="/bloggrid" element={<BlogGridPage />} />
            <Route path="/bloglist" element={<BlogListPage />} />
            <Route path="/bloglist/:paramCategory" element={<BlogListPage />} />
            <Route path="/blog/:paramBlogNum" element={<BlogViewPage />} />
            <Route path="/bloglist/insta" element={<InstagramPage />} />

            {/* 자리뽑기 */}
            <Route path="/seatdraw3" element={<SeatDraw3 />} />
            <Route path="/seatdraw1" element={<SeatDraw1 />} />
            <Route path="/seatdraw2" element={<SeatDraw2 />} />
            <Route path="/seatdraw" element={<SeatDrawPage1 />} />
            <Route
              path="/seatbeforesetting"
              element={<SeatBeforeSettingPage />}
            />
            <Route path="/drawsetting" element={<DrawSettingPage />} />
            <Route path="/drawseat" element={<OneByOnePage />} />

            {/* 기타 */}
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/usercomment" element={<UserCommentPage />} />
            <Route path="/useOnDesktop" element={<UseOnDesktopPage />} />

            {/* 비로그인 사용자를 위한 라우트 */}
            <Route path="/register" element={<Register2Page />} />
            <Route path="/login" element={<Login2Page />} />
            <Route path="/login-confirm" element={<Login2ConfirmPage />} />
            <Route path="*" element={<AuthPage />} />
            <Route path="/404" element={<NotFoundPage />} />
          </Routes>{' '}
          {/* </ErrorBoundary> */}
        </GradeProvider>
      </UserProvider>
    </>
  );
};
export default App;
