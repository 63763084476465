import BlogList from './BlogList';

const BlogListContainer = ({ blogs }) => {
  return (
    <>
      <BlogList blogs={blogs} />
    </>
  );
};

export default BlogListContainer;
