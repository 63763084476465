import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MdRemoveCircleOutline, MdAdd } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';

import {
  TopicListItemLayout1,
  TopicListItemLayout2,
  TopicListStyledItem,
  TopicListTitle,
  TopicStyleTag,
} from './TopicStyle';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Input from '../../components/StyledInput';
import axios from '../../../node_modules/axios/index';
import UserContext from '../../contexts/userContext';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import Tooltip from '../../components/Tooltip';
import TooltipRight from '../../components/TooltipRight';
import { alignments } from 'C:/Users/minji/AppData/Local/Microsoft/TypeScript/5.5/node_modules/@floating-ui/utils/dist/floating-ui.utils';

const TopicListItem = ({
  topic,
  order,
  onRemove,
  fetchTopicsByGrade,
  setShowNotification,
  setRegisterModalOpen,
}) => {
  const { id, grade, topicId, topicTitle } = topic;
  const itemOrder = order;

  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;

  const ref = useRef(null);
  const [value, setValue] = useState(topic.topicTitle || '');
  const [editable, setEditable] = useState(false);
  const [placeholder, setPlaceholder] = useState(topic.topicTitle);

  useEffect(() => {
    setValue(topicTitle); // topicTitle이 변경될 때 value 초기화
    setPlaceholder(topicTitle); // topicTitle이 변경될 때 placeholder 초기화
  }, [topicTitle, topicId]);

  // useEffect(() => {
  //   setPlaceholder(value || '');
  // }, [value]);

  //수업 주제 업데이트

  const navigate = useNavigate();
  const handleUpdate = async (id, value) => {
    value = value || '';
    if (!userId) {
      alert('로그인 후 이용 가능합니다.');
      setRegisterModalOpen(true);
    } else {
      if (!value.trim()) {
        setEditable(false);

        return;
      }

      try {
        await axios
          .put('https://teachernote.me/api/topics/' + id, {
            value: value,
          })
          .then(() => {
            fetchTopicsByGrade();
          });
        setEditable(false);
        setShowNotification(true); // 알림 상태를 true로 변경
        setTimeout(() => {
          setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const editOn = () => {
    setEditable(true);
  };
  const onChange = useCallback((e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setValue(e.target.value);
    } else {
    }
  }, []);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleUpdate(id, value);
      setEditable(false);
      e.preventDefault();
    } else if (e.key === 'Escape') {
      setEditable(false);
      setValue(topicTitle);
    }
  };
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setEditable(false);
      setValue(topicTitle);
    }
  };

  useEffect(() => {
    if (editable) {
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }
  }, [editable]);

  return (
    <>
      {editable ? (
        <TopicListStyledItem ref={ref}>
          <TopicListItemLayout1>
            <TopicStyleTag>{topic.topicId}</TopicStyleTag>
            <Input
              placeholder={placeholder}
              type="text"
              value={value || ''}
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </TopicListItemLayout1>
          <TopicListItemLayout2
            className="update"
            onClick={() => handleUpdate(id, value)}
          >
            <FaCheck />
          </TopicListItemLayout2>
        </TopicListStyledItem>
      ) : (
        <TopicListStyledItem>
          <TopicListItemLayout1
            className="topicTitle"
            onClick={() => {
              editOn();
            }}
          >
            <TopicStyleTag>{topic.topicId}</TopicStyleTag>
            {/* <TopicListTitle> */}{' '}
            {topic.topicTitle
              ? topic.topicTitle
              : `${topic.grade}학년 수업${topic.topicId}`}
            {/* </TopicListTitle> */}
          </TopicListItemLayout1>
          <TopicListItemLayout2
            className="remove"
            onClick={(e) => onRemove(e, id)}
          >
            <RiDeleteBin6Line />
          </TopicListItemLayout2>
        </TopicListStyledItem>
      )}
    </>
  );
};

export default TopicListItem;
