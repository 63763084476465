import { useCallback, useContext, useEffect, useState } from 'react';
import UserContext from '../../contexts/userContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MobileMessage, PageHeader } from '../../pageLayout/PageContainerStyle';
import SeatDrawHeaderMenuBar from '../../pageLayout/DrawHeaderMenuBar';
import DrawPageContainer from '../../drawPageLayout/DrawPageContainer';
import {
  DrawBox,
  DrawBox_Content_65,
  DrawBox_Content_35,
  DrawPageBox,
  DrawPageMain,
  OneByOne_Footer,
  OneByOne_Footer_Content_Left,
  OneByOne_Footer_Content_Right,
  SettingSwitchBox,
  SettingSwitchBoxInner,
  MobileMessageContainer,
} from '../3drawOneByOnePage/OneByOneStyle';
import LeftTooltip from '../../components/LeftTooltip';
import { colors, padding } from '../../styles/commonStyle';
import Switch from '../../components/Switch';
import SeatSettingComponent from '../3drawOneByOnePage/3-1seatSetting/SeatSettingComponent';
import StudentSettingComponent from '../3drawOneByOnePage/3-2studentSetting/StudentSettingComponent';
import PreAssignmentComponent from './4preAssignment/PreAssignmentComponent';
import StudentSeatSettingContainer from './3studentSeatSetting/StudentSeatSettingContainer';
import HeaderMenuBar from '../../pageLayout/HeaderMenuBar';
import { DisplayRowSpaceBetween, GreenBadge } from '../../styles/BasicStyle';
import { useLocation } from '../../../node_modules/react-router-dom/dist/index';
import Notification from '../../components/Notification';

const DrawSettingPage = () => {
  // 학생 번호
  const [numberList, setNumberList] = useState(
    Array.from({ length: 30 }, (_, index) => index + 1),
  );
  const [myNum, setMyNum] = useState(null); // 자리 설정 시 사용하는 번호
  const [usedNumbers, setUsedNumbers] = useState(new Map()); // 사용된 번호
  const [notUsingNumbers, setNotUsingNumbers] = useState([]); // 사용하지 않는 번호
  const [completedNum, setCompletedNum] = useState([]); //seatmap에 한개씩 보여주기 위해 정의

  // 자리
  const [blockedSeats, setBlockedSeats] = useState(new Set()); // X표시 하여 사용하지 않는 자리
  const [randomSeat, setRandomSeat] = useState(null); // 랜덤으로 뽑은 자리
  const [usedSeats, setUsedSeats] = useState(new Set()); // 뽑힌 자리
  const [numberSeatMap, setNumberSeatMap] = useState({}); // 번호별 좌석 정보
  const [previousNumberSeatMap, setPreviousNumberSeatMap] = useState({}); // 번호별 좌석 정보
  const [maleSeatsNum, setMaleSeatsNum] = useState(0);
  const [femaleSeatsNum, setfemaleSeatsNum] = useState(0);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCol, setSelectedCol] = useState(null);

  // 자리뽑기 옵션
  const [pairMode, setPairMode] = useState(false); // 짝 만들기
  const [genderMode, setGenderMode] = useState(false); // 남녀구분
  const [genderSeatRule, setGenderSeatRule] = useState(true); // 남녀 자리 위치 바꾸기

  //미리 배치
  const [seatPreAssignments, setSeatPreAssignments] = useState({}); //저장된 사전세팅+지금설정
  const [preAssignment, setPreAssignment] = useState([]); //저장된 사전세팅 불러오기
  const [preAssigningSeats, setPreAssigningSeats] = useState({}); //지금 설정한 미리 배치된 학생(저장 전)
  //저장할 때 seatPreAssignments, preAssigningSeats 합쳐서 저장하고 seatAssignment로 만들음.

  useEffect(() => {
    const mergedAssignments = {
      ...seatPreAssignments,
      ...preAssigningSeats,
    };
    setSeatPreAssignments(mergedAssignments);
  }, [preAssigningSeats, preAssignment]);

  const [frontNums, setFrontNums] = useState([]); //앞자리
  const [adjacentNums, setAdjacentNums] = useState([]);
  const [separateNums, setSeparateNums] = useState([]);
  const [cornerNums, setCornerNums] = useState([]);

  //자리 수정
  const [isEditMode, setIsEditMode] = useState(false); // 자리 수정 모드 상태

  //자리 정보 저장, 불러오기
  const [avoidPreviousSeats, setAvoidPreviousSeats] = useState(true); // 이전 좌석 피하기 옵션

  const [isMoreSettingOpen, setIsMoreSettingOpen] = useState(true);

  //학생수, 자리수, 성별 수
  const [localGenderMap, setLocalGenderMap] = useState({}); // 각 번호의 성별
  const [actualStudentCount, setActualStudentCount] = useState(
    numberList.length,
  );
  const [availableSeats, setAvailableSeats] = useState('');
  const [maleCount, setMaleCount] = useState('');
  const [femaleCount, setFemaleCount] = useState('');
  const [isDrawOk, setIsDrawOk] = useState(false);

  //자리뽑기
  const [currentNumIndex, setCurrentNumIndex] = useState(0);
  const [isReversed, setIsReversed] = useState(false); // 추가된 상태
  const [clickedJebiNums, setClickedJebiNums] = useState([]);
  const [jebiPositions, setJebiPositions] = useState(
    numberList.map(() => ({
      top: Math.random() * 65,
      left: Math.random() * 80,
    })),
  );
  const [drawingResult, setDrawingResult] = useState(null);
  const [isLargeResultMode, setIsLargeResultMode] = useState(false);
  const [isRevealAll, setIsRevealAll] = useState(false); //자리뽑기 나머지 결과 전부 보여주기

  //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('menubar'); //menubar,bottombutton

  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  // 로그인 확인 완료 상태 관리
  const location = useLocation(); // location 객체 가져오기
  const isLoginConfirmed = location.state?.isLoginConfirmed || false;

  const [showNotification, setShowNotification] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false); // userId 확인 상태 추가

  // -------------------- 초기 로그인/ 추가 로그인 설정   -------------------- //
  const openRegisterModal = () => {
    // console.log('openRegisterModal');
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    // console.log('openLoginModal');
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  useEffect(() => {
    // 로그인이 되어 있지 않으면 로그인 페이지로 리다이렉트
    if (!userId) {
      alert('자리뽑기 베타 서비스는 로그인 후 이용가능합니다.');

      navigate('/login');
    } else if (!isLoginConfirmed) {
      // 로그인 확인이 안된 경우에만 /login-confirm으로 이동
      navigate('/login-confirm');
    } else {
      setIsUserVerified(true); // userId와 로그인 확인이 완료되었을 때 상태 설정
    }
  }, [userId, isLoginConfirmed, navigate]);
  // 페이지가 userId 확인 전에 보여지지 않도록 설정

  // -------------------- 뒤로가기 눌러도 현재 페이지로 보이기   -------------------- //

  useEffect(() => {
    if (isLargeResultMode) {
      // 현재 경로를 유지하는 새로운 상태를 푸시
      window.history.pushState(null, document.title, window.location.pathname);

      const handlePopState = (event) => {
        // 뒤로 가기 버튼을 눌렀을 때, 다시 현재 페이지의 상태를 푸시하여 뒤로 가기를 방지

        setIsLargeResultMode(!isLargeResultMode);
        window.history.pushState(
          null,
          document.title,
          window.location.pathname,
        );
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [isLargeResultMode]);

  // -------------------- 자리 설정 불러오기 -------------------- //

  //자리 설정 불러오기
  const loadSeatInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getSeatInfo/' + userId,
      );
      const data = response.data;

      setSeats(data[0].seats);
      setBlockedSeats(new Set(data[0].blockedSeats));
      setPairMode(data[0].pairMode);
      setGenderMode(data[0].genderMode);
      setGenderSeatRule(data[0].genderRule);
    } catch (error) {
      console.error('Error retrieving preferences:', error);
    }
  }, [userId]);
  useEffect(() => {
    loadSeatInfo();
  }, []);

  // -------------------- 성별 미리 설정하기(기능 확인 필요)  -------------------- //

  const initializeGenderMap = (numberList) => {
    const map = {};
    numberList.forEach((number) => {
      if (number >= 1 && number <= 15) {
        map[number] = '여';
      } else if (number >= 16 && number <= 30) {
        map[number] = '남';
      }
    });
    return map;
  };

  const [genderMap, setGenderMap] = useState(() =>
    initializeGenderMap(numberList),
  );

  // -------------------- 기본 세팅  -------------------- //

  // 기본 자리 세팅
  const [seats, setSeats] = useState(
    Array(5) // 세로 자리 수
      .fill()
      .map(() => Array(6).fill(null)), // 가로 자리 수
  );

  // --------------------  학생 정보 불러오기  -------------------- //

  //로그인할 때 학생 정보 불러오기
  const fetchStudentInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getstudentinfo/' + userId,
      );

      const studentInfos = response.data;
      if (studentInfos && studentInfos.length > 0) {
        // numberList 생성
        const maxStudentNum = Math.max(
          ...studentInfos.map((info) => info.studentNum),
        );
        const allNumbers = Array.from(
          { length: maxStudentNum },
          (_, i) => i + 1,
        );
        setNumberList(allNumbers);

        // 사용 중인 번호 및 localGenderMap 생성
        const usedNumbers = [];
        const genderMap = {};

        let allGendersNull = true;

        studentInfos.forEach(({ studentNum, gender, isUsingNum }) => {
          genderMap[studentNum] = gender;
          if (isUsingNum) {
            usedNumbers.push(studentNum);
          }

          if (gender !== null) {
            allGendersNull = false;
          }
        });

        // 모든 성별이 null일 경우 기본값 설정
        if (allGendersNull) {
          allNumbers.forEach((num) => {
            if (num >= 1 && num <= 15) {
              genderMap[num] = '여';
            } else {
              genderMap[num] = '남';
            }
          });
        }

        // notUsingNumbers 계산 (isUsingNum이 false인 번호만 추가)
        const notUsing = allNumbers.filter(
          (num) =>
            !usedNumbers.includes(num) ||
            studentInfos.find(
              (student) => student.studentNum === num && !student.isUsingNum,
            ),
        );
        setNotUsingNumbers(notUsing);
        setLocalGenderMap(genderMap);
      } else {
        // 데이터가 비어있거나 잘못된 경우 기본값 설정
      }
    } catch (error) {
      console.error('학생 정보 불러오기 실패:', error);
      alert('학생정보 불러오기 실패');
    }
  }, [userId]);

  useEffect(() => {
    fetchStudentInfo();
  }, []);
  // -------------------- 저장된 사전 세팅 불러오기  -------------------- //

  const fetchSeatStudentSetting = useCallback(async () => {
    if (userId) {
      try {
        const response = await axios.get(
          'https://teachernote.me/api/getSeatStudentSetting/' + userId,
        );
        const seatStudentSetting = response.data;
        setAdjacentNums(seatStudentSetting[0]['adjacentNums']);
        setSeparateNums(seatStudentSetting[0]['separateNums']);
        setCornerNums(seatStudentSetting[0]['cornerNums']);
        setPreAssignment(seatStudentSetting[0]['preAssignment']);
      } catch (error) {
        console.error('학생 정보 불러오기 실패:', error);
      }
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchSeatStudentSetting();
    }
  }, [userId]);

  // -------------------- 페이지 로드 시 데이터 불러오기 -------------------- //
  // 페이지가 처음 로드될 때 데이터 불러오기
  useEffect(() => {
    fetchAdjacentNums();
    fetchCornerNums();
    fetchPreAssignments();
  }, [userId]);

  //가까이 앉을 학생들 불러오기
  const fetchAdjacentNums = useCallback(async () => {
    try {
      const adjRes = await axios.get(
        `https://teachernote.me/api/getadjacentnums/${userId}`,
      );
      if (adjRes.data) setAdjacentNums(adjRes.data.adjacentNums);
    } catch (error) {
      console.error('Error fetching adjacent numbers:', error);
    }
  }, [userId]);

  //구석에 앉을 학생들 불러오기
  const fetchCornerNums = useCallback(async () => {
    try {
      const cornerRes = await axios.get(
        `https://teachernote.me/api/getcornernums/${userId}`,
      );
      if (cornerRes.data) setCornerNums(cornerRes.data.cornerNums);
    } catch (error) {
      console.error('Error fetching corner numbers:', error);
    }
  }, [userId]);

  //미리배치할 학생들 불러오기
  const fetchPreAssignments = useCallback(async () => {
    try {
      const preAssignRes = await axios.get(
        `https://teachernote.me/api/getPreAssignment/${userId}`,
      );
      if (preAssignRes.data) {
        const assignments = preAssignRes.data.preAssignment;

        // 가져온 데이터를 상태에 설정
        setSeatPreAssignments(assignments);
      }
    } catch (error) {
      console.error('Error fetching pre-assignments:', error);
    }
  }, [userId]);

  // -------------------- 학생수, 자리수, 성별 비교  -------------------- //

  //학생 수 계산
  useEffect(() => {
    setActualStudentCount(numberList.length - notUsingNumbers.length);
  }, [numberList, notUsingNumbers]);

  //남은 자리 수 계산
  useEffect(() => {
    const totalSeats = seats.length * seats[0].length;
    const blockedSeatsCount = blockedSeats.size;
    setAvailableSeats(totalSeats - blockedSeatsCount);
  }, [seats, blockedSeats]);

  //성별 계산
  useEffect(() => {
    // console.log('localGenderMap:' + JSON.stringify(localGenderMap));
    const genderCounts = Object.values(localGenderMap).reduce(
      (counts, gender) => {
        if (gender === '남') {
          counts.male += 1;
        } else if (gender === '여') {
          counts.female += 1;
        }
        return counts;
      },
      { male: 0, female: 0, noGender: 0 }, // 초기 값 설정
    );
    setMaleCount(genderCounts.male);
    setFemaleCount(genderCounts.female);
  }, [localGenderMap]);

  //학생수,자리수,성별  비교
  const confirmIsDrawOk = useCallback(() => {
    if (genderMode) {
      // 성별 구분 모드일 때 모든 학생의 성별이 지정되었는지 확인
      const hasUnspecifiedGender = numberList.some((number) => {
        return !['남', '여'].includes(localGenderMap[number]);
      });

      if (hasUnspecifiedGender) {
        alert(
          '성별 구분 모드에서는 모든 학생의 성별이 지정되어야 합니다. 성별 구분 모드를 해제하거나 학생의 성별을 지정해주세요.',
        );
        setIsDrawOk(false);
        return;
      }
    }
    if (availableSeats >= actualStudentCount) {
      if (
        !genderMode ||
        (maleSeatsNum >= maleCount && femaleSeatsNum >= femaleCount)
      ) {
        setIsDrawOk(true);
        // setIsDrawSettingOpen(false);
      } else {
        setIsDrawOk(false);
        alert('(남,녀) 학생수가 자리수보다 많습니다. 설정을 확인해주세요.');
        // setIsDrawSettingOpen(true);
      }
    } else {
      setIsDrawOk(false);
      alert('(남, 녀)자리수보다 학생수가 많습니다. 설정을 확인해주세요.');
      // setIsDrawSettingOpen(true);
    }
  }, [
    availableSeats,
    actualStudentCount,
    genderMode,
    maleSeatsNum,
    femaleSeatsNum,
    maleCount,
    femaleCount,
  ]);

  useEffect(() => {
    // console.log('maleSeatsNum:' + JSON.stringify(maleSeatsNum));
    // console.log('maleCount:' + JSON.stringify(maleCount));
    // console.log('isLargeResultMode:' + JSON.stringify(isLargeResultMode));
  }, [isLargeResultMode]);

  // -------------------- 자리배치결과 저장하기  -------------------- //

  //자리 설정 저장하기
  const saveSeatInfo = useCallback(
    async (seats, blockedSeats, pairMode, genderMode, genderSeatRule) => {
      try {
        const response = await axios.post(
          'https://teachernote.me/api/saveSeatInfo',
          {
            userId,
            seats: JSON.stringify(seats),
            blockedSeats: JSON.stringify(Array.from(blockedSeats)),
            pairMode,
            genderMode,
            genderSeatRule,
          },
        );
        // console.log(response.data);
      } catch (error) {
        console.error('Error saving preferences:', error);
      }
    },
    [],
  );
  //구성원과 자리표 개수 비교 확인
  const confirmSetting = (
    seats,
    blockedSeats,
    pairMode,
    genderMode,
    genderSeatRule,
  ) => {
    const totalSeats = seats.length * seats[0].length;
    const numberOfBlockedSeats = blockedSeats.size;

    const remainingSeats = totalSeats - numberOfBlockedSeats;
    const remainingNumbers = numberList.length - notUsingNumbers.length;
    if (remainingSeats - remainingNumbers < 0) {
      alert('(남,녀) 학생수가 자리수보다 많습니다. 자리를 추가해주세요.');
    } else {
      saveSeatInfo(seats, blockedSeats, pairMode, genderMode, genderSeatRule);
      // alert('저장되었습니다.');
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1000);
    }
  };

  // --------------------  학생 정보 저장  -------------------- //
  const saveStudentInfo = async (
    numberList,
    localGenderMap,
    notUsingNumbers,
  ) => {
    // 사용 가능한 번호 선택
    const availableNumbers = numberList;

    // 학생 정보 생성
    const studentInfos = availableNumbers.map((studentNum) => ({
      studentNum,
      gender: Object.keys(localGenderMap).includes(String(studentNum))
        ? localGenderMap[studentNum]
        : null,
      isUsingNum: !notUsingNumbers.includes(studentNum),
    }));

    // 데이터베이스에 저장
    try {
      const res = await axios.post(
        'https://teachernote.me/api/savestudentinfo/' + userId,
        {
          studentInfos: studentInfos,
        },
      );
    } catch (error) {
      console.error(error);
    }
  };
  // -------------------- 설정 저장하기  -------------------- //
  const saveSettings = () => {
    if (genderMode) {
      // 성별 구분 모드일 때, notUsingNumbers에 포함되지 않은 모든 학생의 성별이 지정되었는지 확인
      const hasUnspecifiedGender = numberList.some((number) => {
        return (
          !notUsingNumbers.includes(number) && // notUsingNumbers에 포함되지 않은 경우만 체크
          !['남', '여'].includes(localGenderMap[number])
        );
      });

      if (hasUnspecifiedGender) {
        alert(
          '성별 구분 모드에서는 모든 학생의 성별이 지정되어야 합니다. 성별 구분 모드를 해제하거나 학생의 성별을 지정해주세요.',
        );
        setIsDrawOk(false);
        return;
      }
    }

    if (availableSeats >= actualStudentCount) {
      if (
        !genderMode ||
        (maleSeatsNum >= maleCount && femaleSeatsNum >= femaleCount)
      ) {
      } else {
        alert('(남,녀) 학생수가 자리수보다 많습니다. 설정을 확인해주세요.');
        return;
      }
    } else {
      alert('(남, 녀)자리수보다 학생수가 많습니다. 설정을 확인해주세요.');
      return;
    }

    alert(
      '자리 및 학생 설정이 저장되었습니다. 자리 배치 순서는 미리배치 → 집중이 필요한 학생 배치 → 가까이 앉을 학생 배치 → 나머지 학생 배치입니다. 집중이 필요한 학생 및 가까이 앉을 학생 배치 시 (성별) 사용가능한 좌석이 없을 경우 남은 다른 자리에 랜덤 배치될 수 있습니다.',
    );
    saveSeatInfo(seats, blockedSeats, pairMode, genderMode, genderSeatRule);
    saveStudentInfo(numberList, localGenderMap, notUsingNumbers);
    saveAdjacentNums(adjacentNums);
    saveCornerNums(cornerNums);
    savePreAssignment(preAssigningSeats);
    handleSaveFrontNums();

    // alert('자리 및 학생 설정이 저장되었습니다.');
    // setShowNotification(true);
    // setTimeout(() => {
    //   setShowNotification(false);
    // }, 1000);
  };
  // -------------------- 배치 결과 저장하기  -------------------- //

  const handleSeatmapSave = async () => {
    // console.log('numberSeatMap:' + JSON.stringify(numberSeatMap));
    try {
      const response = await axios.post(
        'https://teachernote.me/api/saveSeatMap',
        {
          userId,
          numberSeatMap,
        },
      );
      alert('자리 배치 결과가 저장되었습니다.');

      // console.log(response.data);
    } catch (error) {
      console.error('Error saving seat map:', error);
    }
  };

  useEffect(() => {
    // console.log('numberSeatMap:' + JSON.stringify(numberSeatMap));
  }, [numberSeatMap]);

  // -------------------- 데이터 DB 저장 -------------------- //

  //인접 배치 DB저장
  const saveAdjacentNums = async (adjacentNums) => {
    // console.log('adjacentNums:' + JSON.stringify(adjacentNums));
    try {
      const res = await axios.post(
        'https://teachernote.me/api/saveadjacentnums/' + userId,
        {
          adjacentNums: JSON.stringify(adjacentNums),
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  //구석에 앉을 학생들 DB저장
  const saveCornerNums = async (cornerNums) => {
    // console.log('cornerNums:' + JSON.stringify(cornerNums));
    try {
      const res = await axios.post(
        'https://teachernote.me/api/savecornernums/' + userId,
        {
          cornerNums: JSON.stringify(cornerNums),
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  //미리 배치한 학생 저장
  const savePreAssignment = async (preAssigningSeats) => {
    try {
      // 기존에 저장된 seatPreAssignments와 현재 설정된 preAssigningSeats를 병합합니다.
      const mergedAssignments = {
        ...seatPreAssignments,
        ...preAssigningSeats,
      };

      // 병합된 결과를 서버에 저장합니다.
      const res = await axios.post(
        'https://teachernote.me/api/savePreAssignment/' + userId,
        {
          preAssignment: JSON.stringify(mergedAssignments),
        },
      );

      // seatPreAssignments 상태를 병합된 결과로 업데이트합니다.
      setSeatPreAssignments(mergedAssignments);

      // 성공 메시지를 출력합니다.
      // alert('미리 배치된 학생 정보가 저장되었습니다.');
    } catch (error) {
      console.error('Error saving pre-assignments:', error);
    }
  };

  // --------------------  frontNums 저장,불러오기  -------------------- //

  // frontNums 저장하기
  const saveFrontNums = useCallback(
    async (frontNums, userId) => {
      if (userId) {
        try {
          const response = await axios.post(
            'https://teachernote.me/api/saveFrontNums',
            {
              userId,
              frontNums: JSON.stringify(frontNums),
            },
          );
          console.log('FrontNums saved successfully:', response.data);
        } catch (error) {
          console.error('Error saving frontNums:', error);
        }
      } else {
        console.error('User ID is missing, cannot save frontNums');
      }
    },
    [userId],
  );

  // frontNums 불러오기
  const loadFrontNums = useCallback(async () => {
    if (!userId) {
      console.error('User ID is missing, cannot load frontNums');
      return;
    }
    try {
      const response = await axios.get(
        'https://teachernote.me/api/getFrontNums/' + userId,
      );
      const frontNumData = response.data || [];
      // console.log('fontNumData.frontNums:' + JSON.stringify(frontNumData));
      // console.log('fontNumData type:' + typeof frontNumData);
      // setFrontNums(frontNumData); // frontNums 상태로 설정

      // 모든 frontNums 배열을 합쳐서 하나의 배열로 만듦
      const extractedFrontNums = frontNumData
        .flatMap((item) => item.frontNums) // 각 객체의 frontNums를 추출하여 병합
        .filter((num) => !isNaN(num)); // 숫자만 필터링

      // console.log('Extracted frontNums:', extractedFrontNums);

      setFrontNums(extractedFrontNums); // 추출한 frontNums를 설정
    } catch (error) {
      console.error('Error retrieving frontNums:', error);
      setFrontNums([]); // 오류 발생 시 기본값 설정
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      loadFrontNums();
    }
  }, [userId]);

  // 예시: frontNums 변경 후 저장 호출
  const handleSaveFrontNums = () => {
    saveFrontNums(frontNums, userId);
  };
  //-------------------- 초기화 --------------------//
  const resetAll = useCallback(() => {
    // 자리 설정 초기화
    setSeats(
      Array(5)
        .fill()
        .map(() => Array(6).fill(null)),
    ); // 기본 자리 배열로 초기화
    setBlockedSeats(new Set()); // BlockedSeats 초기화
    setPairMode(false); // pairMode 초기화
    setGenderMode(false); // gendermode 초기화
    setPreAssigningSeats({}); // preAssigningSeats 초기화
    setSeatPreAssignments({}); // seatPreAssignments 초기화

    // 학생 번호 및 성별 초기화
    setNumberList(Array.from({ length: 30 }, (_, index) => index + 1)); // 기본 번호 리스트로 초기화
    setLocalGenderMap(
      initializeGenderMap(Array.from({ length: 30 }, (_, index) => index + 1)),
    ); // 성별 초기화
    setNotUsingNumbers([]); // 사용하지 않는 번호 초기화

    // cornernums, adjacentnums 초기화
    setCornerNums([]);
    setAdjacentNums([]);
    setFrontNums([]);

    const result = window.confirm(
      '모든 설정이 초기화되었습니다. 초기화된 설정을 저장하시겠습니까?',
    );
    if (result) {
      // 초기화된 내용을 서버에 저장
      saveSeatInfo(
        Array(5)
          .fill()
          .map(() => Array(6).fill(null)),
        new Set(),
        false,
        false,
        true,
      );
      saveStudentInfo(
        Array.from({ length: 30 }, (_, index) => index + 1),
        initializeGenderMap(
          Array.from({ length: 30 }, (_, index) => index + 1),
        ),
        [],
      );
      saveAdjacentNums([]);
      saveCornerNums([]);
      savePreAssignment({});
      handleSaveFrontNums();

      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1000);
    } else {
    }
  }, []);
  if (!isUserVerified) {
    return null; // 또는 로딩 표시를 위한 컴포넌트를 렌더링할 수 있음
  }

  return (
    <>
      <PageHeader>
        <HeaderMenuBar
          isRegisterModalOpen={isRegisterModalOpen}
          setRegisterModalOpen={setRegisterModalOpen}
          modalSource={modalSource}
          setModalSource={setModalSource}
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
        />
      </PageHeader>

      <DrawPageContainer>
        <>
          <DrawPageMain
            style={{
              backgroundColor: `${colors.lightGreenBackground}`,
              // marginBottom: '70px',
            }}
          >
            <DrawPageBox>
              <Notification
                message="변경사항 저장 완료"
                isVisible={showNotification}
              />
              <SettingSwitchBox>
                <DisplayRowSpaceBetween>
                  <div>
                    자리뽑기<GreenBadge>Beta</GreenBadge>에서는 자리뽑기를 위한
                    추가 설정을 할 수 있습니다.
                    <br />이 페이지에서 미리 설정한 내용은 자리뽑기 시
                    반영됩니다.
                    <br />
                    사용하지 않으시려면 '초기화' 버튼을 눌러주세요.
                  </div>
                  <LeftTooltip text="기타 사항을 설정하려면 클릭!">
                    <SettingSwitchBoxInner>
                      <div style={{ paddingRight: `${padding.small}` }}>
                        추가 설정 열기
                      </div>
                      <Switch
                        isOn={isMoreSettingOpen}
                        handleToggle={() => {
                          setIsMoreSettingOpen(!isMoreSettingOpen);
                        }}
                      />
                    </SettingSwitchBoxInner>
                  </LeftTooltip>
                </DisplayRowSpaceBetween>
              </SettingSwitchBox>
              {/* 상단 */}
              <DrawBox>
                <DrawBox_Content_35>
                  <SeatSettingComponent
                    setSeats={setSeats}
                    setBlockedSeats={setBlockedSeats}
                    avoidPreviousSeats={avoidPreviousSeats}
                    setAvoidPreviousSeats={setAvoidPreviousSeats}
                    setPairMode={setPairMode}
                    pairMode={pairMode}
                    setGenderMode={setGenderMode}
                    genderMode={genderMode}
                    seats={seats}
                    blockedSeats={blockedSeats}
                    preAssigningSeats={preAssigningSeats}
                    genderMap={genderMap}
                    genderSeatRule={genderSeatRule}
                    setGenderSeatRule={setGenderSeatRule}
                    setPreAssigningSeats={setPreAssigningSeats}
                    numberList={numberList}
                    notUsingNumbers={notUsingNumbers}
                    setNumberList={setNumberList}
                    preAssignment={preAssignment}
                    // setIsDrawSettingOpen={setIsDrawSettingOpen}
                    setMaleSeatsNum={setMaleSeatsNum}
                    setfemaleSeatsNum={setfemaleSeatsNum}
                    saveSeatInfo={saveSeatInfo}
                    loadSeatInfo={loadSeatInfo}
                    seatPreAssignments={seatPreAssignments}
                    setSeatPreAssignments={setSeatPreAssignments}
                  />
                </DrawBox_Content_35>
                <DrawBox_Content_65>
                  <StudentSettingComponent
                    numberList={numberList}
                    setGenderMap={setGenderMap}
                    notUsingNumbers={notUsingNumbers}
                    setNumberList={setNumberList}
                    setNotUsingNumbers={setNotUsingNumbers}
                    frontNums={frontNums}
                    setFrontNums={setFrontNums}
                    // setIsDrawSettingOpen={setIsDrawSettingOpen}
                    genderMode={genderMode}
                    setGenderMode={setGenderMode}
                    seats={seats}
                    blockedSeats={blockedSeats}
                    maleSeatsNum={maleSeatsNum}
                    femaleSeatsNum={femaleSeatsNum}
                    localGenderMap={localGenderMap}
                    setLocalGenderMap={setLocalGenderMap}
                    availableSeats={availableSeats}
                    actualStudentCount={actualStudentCount}
                    femaleCount={femaleCount}
                    maleCount={maleCount}
                    saveStudentInfo={saveStudentInfo}
                    fetchStudentInfo={fetchStudentInfo}
                    adjacentNums={adjacentNums}
                    setAdjacentNums={setAdjacentNums}
                    cornerNums={cornerNums}
                    setCornerNums={setCornerNums}
                    preAssigningSeats={preAssigningSeats}
                    setPreAssigningSeats={setPreAssigningSeats}
                    seatPreAssignments={seatPreAssignments}
                    setSeatPreAssignments={setSeatPreAssignments}
                    setFemaleCount={setFemaleCount}
                    setMaleCount={setMaleCount}
                    loadFrontNums={loadFrontNums}
                  />
                </DrawBox_Content_65>
              </DrawBox>
              {/* 하단 */}
              {isMoreSettingOpen ? (
                <DrawBox>
                  <DrawBox_Content_65>
                    <StudentSeatSettingContainer
                      adjacentNums={adjacentNums}
                      setAdjacentNums={setAdjacentNums}
                      separateNums={separateNums}
                      setSeparateNums={setSeparateNums}
                      cornerNums={cornerNums}
                      setCornerNums={setCornerNums}
                      saveAdjacentNums={saveAdjacentNums}
                      saveCornerNums={saveCornerNums}
                      preAssigningSeats={preAssigningSeats}
                      setPreAssigningSeats={setPreAssigningSeats}
                      seatPreAssignments={seatPreAssignments}
                      setSeatPreAssignments={setSeatPreAssignments}
                      fetchAdjacentNums={fetchAdjacentNums}
                      fetchCornerNums={fetchCornerNums}
                      numberList={numberList}
                      notUsingNumbers={notUsingNumbers}
                    />
                  </DrawBox_Content_65>
                  <DrawBox_Content_35>
                    <PreAssignmentComponent
                      numberList={numberList}
                      setNumberList={setNumberList}
                      notUsingNumbers={notUsingNumbers}
                      genderMap={genderMap}
                      seats={seats}
                      setSeats={setSeats}
                      blockedSeats={blockedSeats}
                      setBlockedSeats={setBlockedSeats}
                      avoidPreviousSeats={avoidPreviousSeats}
                      setAvoidPreviousSeats={setAvoidPreviousSeats}
                      pairMode={pairMode}
                      setPairMode={setPairMode}
                      genderMode={genderMode}
                      setGenderMode={setGenderMode}
                      preAssigningSeats={preAssigningSeats}
                      setPreAssigningSeats={setPreAssigningSeats}
                      genderSeatRule={genderSeatRule}
                      setGenderSeatRule={setGenderSeatRule}
                      savePreAssignment={savePreAssignment}
                      seatPreAssignments={seatPreAssignments}
                      setSeatPreAssignments={setSeatPreAssignments}
                      fetchPreAssignments={fetchPreAssignments}
                      loadSeatInfo={loadSeatInfo}
                      cornerNums={cornerNums}
                      adjacentNums={adjacentNums}
                      setCornerNums={setCornerNums}
                      setAdjacentNums={setAdjacentNums}
                    />
                  </DrawBox_Content_35>
                </DrawBox>
              ) : (
                <></>
              )}
            </DrawPageBox>
          </DrawPageMain>

          <OneByOne_Footer>
            <OneByOne_Footer_Content_Left onClick={() => resetAll()}>
              초기화
            </OneByOne_Footer_Content_Left>
            <OneByOne_Footer_Content_Right onClick={() => saveSettings()}>
              설정 저장하기
            </OneByOne_Footer_Content_Right>
          </OneByOne_Footer>
        </>
      </DrawPageContainer>
      <MobileMessageContainer>
        <p>
          이 페이지는 <br />
          태블릿 및 데스크탑에서 <br />
          이용가능합니다.
        </p>
      </MobileMessageContainer>
    </>
  );
};

export default DrawSettingPage;
