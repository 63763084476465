import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import PageContainer from '../../pageLayout/PageContainer';
import BlogListContainer from './BlogListContainer';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBar from '../../topMenuBarMobile/MobileTopMenuBar';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';

const BlogListPage = () => {
  const [blogDB, setBlogDB] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { paramCategory } = useParams(); // URL 파라미터로부터 카테고리 가져오기

  const fetchBlogs = useCallback(async () => {
    try {
      const res = await axios.get('https://teachernote.me/api/blogs/');
      setBlogDB(res.data);
      // console.log('blogDB:' + JSON.stringify(res.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const filteredBlogs = paramCategory
    ? blogDB.filter((blog) => blog.category === paramCategory)
    : blogDB;

  const sortedBlogs = [...filteredBlogs].sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );

  return (
    <PageContainer>
      <PageSubheader>
        <ArticleMenuBar />
      </PageSubheader>
      {isMobile && <MobileTopMenuBarArticle />}
      <PageBox>
        <BlogListContainer blogs={sortedBlogs} />
      </PageBox>
    </PageContainer>
  );
};

export default BlogListPage;
