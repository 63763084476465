import React from 'react';
import {
  DrawContainer,
  DrawContainer_Top,
  DrawInner,
  HandImageLeft,
  HandImageRight,
  JebiImage,
  OneByOneButton,
} from '../OneByOneStyle';

const Draw = ({
  numberList,
  onJebiSelect,
  positions,
  shuffling,
  clickedJebiNums,
  shuffleJebi,
  notUsingNumbers,
}) => {
  const handleClick = (JebiNum) => {
    if (!clickedJebiNums.includes(JebiNum)) {
      // 이미 클릭된 번호는 무시
      onJebiSelect(JebiNum);
    }
  };

  return (
    <>
      {' '}
      <DrawContainer>
        <DrawInner>
          {numberList
            .filter((num) => !notUsingNumbers.includes(num)) // notUsingNumbers에 없는 번호만 필터링
            .map(
              (JebiNum, index) =>
                !clickedJebiNums.includes(JebiNum) && ( // 클릭된 번호는 렌더링하지 않음
                  <JebiImage
                    key={index}
                    $num={JebiNum}
                    src="jebi_image_green.png"
                    alt="제비 이미지"
                    onClick={() => handleClick(JebiNum)}
                    $position={positions[index]}
                    shuffling={shuffling ? 'true' : undefined}
                  />
                ),
            )}
          {shuffling && (
            <>
              <HandImageLeft
                src="hand_image_left.png"
                alt="왼쪽 손 이미지"
                shuffling={shuffling ? 'true' : undefined}
              />
              <HandImageRight
                src="hand_image_right.png"
                alt="오른쪽 손 이미지"
                shuffling={shuffling ? 'true' : undefined}
              />
            </>
          )}
        </DrawInner>
      </DrawContainer>
    </>
  );
};

export default Draw;
