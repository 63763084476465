import React, { useState } from 'react';
import {
  SeatDrawContentBox,
  SeatDrawDetailBox,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleBox_right,
  SeatDrawPageTitleDetail,
  SeatDrawSecondaryButton,
} from '../../SeatDrawPageStyle';
import {
  AddNumberButton,
  FixedWidthFirstTd,
  FixedWidthFirstTh,
  FixedWidthTable,
  FixedWidthTd,
  FixedWidthTh,
  PlusButtonTh,
  SaveButton,
  SeatDrawAdditionalButton,
  SeatDrawAdditionalButtonContainer,
  SeatDrawButton,
  SeatStudentSetting_FirstTd,
  SeatStudentSetting_FirstTh,
  SeatStudentSetting_Table,
  SeatStudentSetting_Tbody,
  SeatStudentSetting_Td,
  SeatStudentSetting_Th,
  SeatStudentSetting_Thead,
  StudentInfoCell,
} from './StudentSettingStyle';
import {
  FirstTr,
  StyledFirstTh,
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  TableContainer,
} from '../../../pages/progress/ProgressTableStyle';
import styled from 'styled-components';

const StudentSetting = ({
  setGenderSelectionMode,
  numberList,
  toggleNumberUsage,
  addNumber,
  handleMouseEnter,
  handleClick,
  notUsingNumbers,
  localGenderMap,
  genderMap,
  saveStudentInfo,
  fetchStudentInfo,
  resetStudentInfo,
}) => {
  const [isEditMode, setIsEditMode] = useState(true);

  const renderTable = (numbers, showAddButton = false) => (
    <TableContainer>
      <SeatStudentSetting_Table>
        <SeatStudentSetting_Thead>
          <tr>
            <SeatStudentSetting_FirstTh>번호</SeatStudentSetting_FirstTh>
            {numbers.map((number, index) => (
              <SeatStudentSetting_Th
                key={index}
                onClick={() => {
                  if (number !== '') toggleNumberUsage(number);
                }}
              >
                {number}
              </SeatStudentSetting_Th>
            ))}
            {showAddButton && (
              <PlusButtonTh>
                <AddNumberButton onClick={addNumber}>+</AddNumberButton>
              </PlusButtonTh>
            )}
          </tr>
        </SeatStudentSetting_Thead>
        <SeatStudentSetting_Tbody>
          <tr>
            <SeatStudentSetting_FirstTd>성별</SeatStudentSetting_FirstTd>
            {numbers.map((number, index) => (
              <SeatStudentSetting_Td
                key={index}
                onMouseEnter={(e) =>
                  e.buttons === 1 && number !== '' && handleMouseEnter(number)
                }
                onClick={() => number !== '' && handleClick(number)}
                $isDisabled={notUsingNumbers.includes(number)}
              >
                <StudentInfoCell>
                  {notUsingNumbers.includes(number)
                    ? 'X'
                    : localGenderMap[number] || ''}
                </StudentInfoCell>
              </SeatStudentSetting_Td>
            ))}
          </tr>
        </SeatStudentSetting_Tbody>
      </SeatStudentSetting_Table>
    </TableContainer>
  );

  const chunkedNumberList = [];
  const chunkSize = 15;
  for (let i = 0; i < numberList.length; i += chunkSize) {
    chunkedNumberList.push(numberList.slice(i, i + chunkSize));
  }

  // 각 테이블의 column 개수를 동일하게 맞추기 위해 빈 칸을 추가합니다.
  // const maxColumns = Math.ceil(numberList.length / chunkSize);
  // const normalizedChunkedNumberList = chunkedNumberList.map((chunk) => {
  //   while (chunk.length < maxColumns) {
  //     chunk.push('');
  //   }
  //   return chunk;
  // });

  return (
    <SeatDrawContentBox isSpecialPage>
      <SeatDrawPageTitleBox>
        <SeatDrawPageTitleBox_left>
          <SeatDrawPageTitle>구성원 확인</SeatDrawPageTitle>
          <SeatDrawPageTitleDetail>
            학급 구성원의 성별과 전학생 현황을 관리할 수 있습니다.
          </SeatDrawPageTitleDetail>
        </SeatDrawPageTitleBox_left>
        <SeatDrawPageTitleBox_right>
          {isEditMode ? (
            <>
              <SeatDrawSecondaryButton onClick={() => resetStudentInfo()}>
                구성원 리셋
              </SeatDrawSecondaryButton>
              <SeatDrawSecondaryButton onClick={() => fetchStudentInfo()}>
                불러오기
              </SeatDrawSecondaryButton>
              <SeatDrawButton onClick={() => saveStudentInfo()}>
                저장하기
              </SeatDrawButton>
              {/* <SeatDrawButton
                onClick={() => {
                  setIsEditMode(false);
                }}
              >
                취소
              </SeatDrawButton> */}
            </>
          ) : (
            <SeatDrawButton
              onClick={() => {
                setIsEditMode(true);
              }}
            >
              수정하기
            </SeatDrawButton>
          )}
        </SeatDrawPageTitleBox_right>
      </SeatDrawPageTitleBox>
      <SeatDrawAdditionalButtonContainer>
        <SeatDrawAdditionalButton onClick={() => setGenderSelectionMode('남')}>
          남자 선택(드래그)
        </SeatDrawAdditionalButton>
        <SeatDrawAdditionalButton onClick={() => setGenderSelectionMode('여')}>
          여자 선택(드래그)
        </SeatDrawAdditionalButton>
      </SeatDrawAdditionalButtonContainer>

      {isEditMode && (
        <>
          {chunkedNumberList.map((numbers, index) =>
            renderTable(numbers, index === chunkedNumberList.length - 1),
          )}
          <SeatDrawDetailBox>
            <div>📌전학생 설정(번호 사용 여부 변경, 번호 삭제) : 번호 클릭</div>
            <div>
              📌성별 변경 : 성별 버튼 클릭 후 해당 번호 아래 성별칸 드래그
            </div>{' '}
            <div>
              📌자리에 남녀 구분이 없는 경우 성별은 입력하지 않으셔도 됩니다.
            </div>{' '}
          </SeatDrawDetailBox>
        </>
      )}
    </SeatDrawContentBox>
  );
};

export default StudentSetting;
