import React, { useState, useEffect, useContext, useCallback } from 'react';
import UserContext from '../../../contexts/userContext';
import axios from 'axios';
import StudentSettingContainer from './StudentSettingContainer';

const StudentSettingComponent = ({
  numberList,
  genderMap,
  setGenderMap,
  notUsingNumbers,
  setNumberList,
  setNotUsingNumbers,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;

  const [genderSelectionMode, setGenderSelectionMode] = useState(null); // 현재 남녀 선택 모드
  const [localGenderMap, setLocalGenderMap] = useState({}); // 각 번호의 성별

  // --------------------  학생 정보 불러오기  -------------------- //
  useEffect(() => {
    fetchStudentInfo();
  }, []);
  const fetchStudentInfo = useCallback(async () => {
    try {
      // console.log('userId:' + userId);

      const response = await axios.get(
        'https://teachernote.me/api/getstudentinfo/' + userId,
      );

      const studentInfos = response.data;
      // console.log('studentInfos:' + JSON.stringify(studentInfos));

      // numberList 생성
      const allNumbers = Array.from({ length: 30 }, (_, i) => i + 1);
      setNumberList(allNumbers);

      // 사용 중인 번호 및 localGenderMap 생성
      const usedNumbers = [];
      const genderMap = {};

      studentInfos.forEach(({ studentNum, gender, isUsingNum }) => {
        genderMap[studentNum] = gender;
        if (isUsingNum) {
          usedNumbers.push(studentNum);
        }
      });

      // notUsingNumbers 계산 (isUsingNum이 false인 번호만 추가)
      const notUsing = allNumbers.filter(
        (num) =>
          !usedNumbers.includes(num) ||
          studentInfos.find(
            (student) => student.studentNum === num && !student.isUsingNum,
          ),
      );

      setNotUsingNumbers(notUsing);
      setLocalGenderMap(genderMap);
    } catch (error) {
      console.error('학생 정보 불러오기 실패:', error);
    }
  }, [userId]);

  // --------------------  학생 정보 저장  -------------------- //
  const saveStudentInfo = async () => {
    // 사용 가능한 번호 선택
    const availableNumbers = numberList;

    // 학생 정보 생성
    const studentInfos = availableNumbers.map((studentNum) => ({
      studentNum,
      gender: Object.keys(localGenderMap).includes(String(studentNum))
        ? localGenderMap[studentNum]
        : null,
      isUsingNum: !notUsingNumbers.includes(studentNum),
    }));

    // console.log('studentInfos:' + JSON.stringify(studentInfos));
    // 데이터베이스에 저장
    try {
      const res = await axios.post(
        'https://teachernote.me/api/savestudentinfo/' + userId,
        {
          studentInfos: studentInfos,
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <StudentSettingContainer
        setGenderMap={setGenderMap}
        localGenderMap={localGenderMap}
        genderSelectionMode={genderSelectionMode}
        notUsingNumbers={notUsingNumbers}
        setNotUsingNumbers={setNotUsingNumbers}
        setLocalGenderMap={setLocalGenderMap}
        numberList={numberList}
        setNumberList={setNumberList}
        setGenderSelectionMode={setGenderSelectionMode}
        genderMap={genderMap}
        saveStudentInfo={saveStudentInfo}
        fetchStudentInfo={fetchStudentInfo}
      />
    </>
  );
};

export default StudentSettingComponent;
