import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Blackboard,
  Seat,
  SeatDrawSettingButtonContainer,
  SeatDrawSettingContent,
  SeatDrowBottomButtons,
  SeatDrowRightButtons,
  SeatGrid,
  SeatMapBox,
  SeatMapContainer,
  SeatMapEditButton,
  SeatmapNumberInput,
} from './SeatSettingStyle';
import {
  SeatGridAndEditButtions,
  SeatGridAndEditButtions_left,
  SeatGridAndEditButtions_right,
} from '../../SeatDrawPageStyle';

import {
  DrawBox_Main,
  DrawBox_Title,
  DrawBox_Title_Text,
} from '../OneByOneStyle';
import { VscChromeClose } from 'react-icons/vsc';
import Tooltip from '../../../components/Tooltip';
import SmallSwitch from '../../../components/SmallSwitch';
import { FiMoreVertical } from 'react-icons/fi';
import {
  DropdownMenu,
  MenuContainer,
  MenuItem,
} from '../../../components/DropdownMenu';
import { IoMdAdd } from 'react-icons/io';
import { FiMinus } from 'react-icons/fi';
import { BsXLg } from 'react-icons/bs';

const SeatSetting = ({
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  setIsPreAssignMode,
  isPreAssignMode,

  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  selectedSeat,
  handleSeatClick,
  handlePreAssignInput,
  addCol,
  removeCol,
  addRow,
  removeRow,
  setGenderSeatRule,
  loadSeatInfo,
  resetSeats,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
  setIsDrawSettingOpen,
  inputRef,
  setMaleSeatsNum,
  setfemaleSeatsNum,
  handleLoadSeatInfo,
}) => {
  const location = useLocation();
  const [isSeatEditMode, setIsSeatEditMode] = useState(true);
  const [isMoreSeatSettingOpen, setIsMoreSeatSettingOpen] = useState(false);

  // console.log('isMoreSeatSettingOpen:' + JSON.stringify(isMoreSeatSettingOpen));

  //남자 자리 수, 여자 자리 수 계산
  useEffect(() => {
    let maleCount = 0;
    let femaleCount = 0;
    // seats가 올바르게 정의되지 않은 경우 바로 return

    seats.forEach((row, rowIdx) => {
      row.forEach((seat, colIdx) => {
        const seatKey = `${rowIdx}-${colIdx}`;

        // 블록된 자리인지 확인
        if (blockedSeats.has(seatKey)) {
          return; // 블록된 자리는 카운트하지 않음
        }

        // 성별 자리를 계산
        if (genderSeatRule) {
          // 짝수 열이 남자 자리인 경우
          if (colIdx % 2 === 0) {
            maleCount += 1;
          } else {
            femaleCount += 1;
          }
        } else {
          // 홀수 열이 남자 자리인 경우
          if (colIdx % 2 !== 0) {
            maleCount += 1;
          } else {
            femaleCount += 1;
          }
        }
      });
    });

    setMaleSeatsNum(maleCount);
    setfemaleSeatsNum(femaleCount);
  }, [seats, genderSeatRule, blockedSeats]);

  // DropdownMenu 바깥쪽 클릭 시 닫히도록 하는 효과
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMoreSeatSettingOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <DrawBox_Title>
        <DrawBox_Title_Text>자리 설정</DrawBox_Title_Text>

        <MenuContainer
          ref={dropdownRef}
          onClick={() => {
            setIsMoreSeatSettingOpen(!isMoreSeatSettingOpen);
          }}
        >
          <FiMoreVertical />
          <DropdownMenu $isOpen={isMoreSeatSettingOpen}>
            <MenuItem onClick={() => resetSeats()}>리셋</MenuItem>
            <MenuItem onClick={() => handleLoadSeatInfo()}>불러오기</MenuItem>
          </DropdownMenu>
        </MenuContainer>
      </DrawBox_Title>

      <DrawBox_Main>
        <SeatMapContainer>
          <SeatDrawSettingButtonContainer>
            <SeatDrawSettingContent
              onClick={() => {
                setPairMode(!pairMode);
              }}
            >
              <SmallSwitch
                $isOn={pairMode}
                handleToggle={() => {
                  setPairMode(!pairMode);
                }}
              />
              짝 만들기
            </SeatDrawSettingContent>
            <SeatDrawSettingContent
              onClick={() => {
                setGenderMode(!genderMode);
              }}
            >
              <SmallSwitch
                $isOn={genderMode}
                handleToggle={() => {
                  setGenderMode(!genderMode);
                }}
              />
              성별구분
            </SeatDrawSettingContent>
            {genderMode ? (
              <SeatDrawSettingContent
                onClick={() => {
                  setGenderSeatRule(!genderSeatRule);
                }}
              >
                <SmallSwitch
                  $isOn={genderSeatRule}
                  handleToggle={() => {
                    setGenderSeatRule(!genderSeatRule);
                  }}
                />
                남녀위치 바꾸기
              </SeatDrawSettingContent>
            ) : (
              <></>
            )}
          </SeatDrawSettingButtonContainer>

          <SeatMapBox>
            <SeatGridAndEditButtions>
              <SeatGridAndEditButtions_left>
                <SeatGrid $columns={seats[0].length}>
                  {seats.map((row, rowIdx) =>
                    row.map((seat, colIdx) => {
                      const seatKey = `${rowIdx}-${colIdx}`;
                      const isBlocked = blockedSeats.has(seatKey);
                      const preAssignedNumber = preAssigningSeats[seatKey];
                      const seatNumber = seat ? `${seat}` : ``;
                      const seatGender = seat ? genderMap[seat] : null;
                      const genderMismatch =
                        genderMode &&
                        seatGender &&
                        ((seatGender === '남' && colIdx % 2 !== 0) ||
                          (seatGender === '여' && colIdx % 2 === 0));

                      return (
                        <div key={rowIdx + '-' + colIdx}>
                          <Tooltip text="사용하지 않을 자리는 해당 위치 클릭!">
                            <Seat
                              key={seatKey}
                              $pairmode={pairMode}
                              $genderMode={genderMode}
                              $col={colIdx}
                              $blocked={isBlocked}
                              $genderMismatch={genderMismatch}
                              $genderSeatRule={genderSeatRule}
                              onClick={() => handleSeatClick(rowIdx, colIdx)}
                            >
                              {isBlocked ? (
                                <span className="blocked-text">
                                  <BsXLg />
                                </span>
                              ) : (
                                selectedSeat === seatKey && (
                                  <SeatmapNumberInput
                                    ref={inputRef}
                                    type="number"
                                    placeholder="번호"
                                    onBlur={handlePreAssignInput}
                                    autoFocus
                                  />
                                )
                              )}
                            </Seat>
                          </Tooltip>
                        </div>
                      );
                    }),
                  )}
                </SeatGrid>
                {/* 아래쪽 + - */}
                {isSeatEditMode && (
                  <SeatDrowBottomButtons>
                    <SeatMapEditButton onClick={addRow}>
                      <IoMdAdd />
                    </SeatMapEditButton>
                    <SeatMapEditButton onClick={removeRow}>
                      <FiMinus />
                    </SeatMapEditButton>
                  </SeatDrowBottomButtons>
                )}
              </SeatGridAndEditButtions_left>
              <SeatGridAndEditButtions_right>
                {/* 오른쪽 + - */}
                {isSeatEditMode && (
                  <SeatDrowRightButtons>
                    <SeatMapEditButton onClick={addCol}>
                      <IoMdAdd />
                    </SeatMapEditButton>
                    <SeatMapEditButton onClick={removeCol}>
                      <FiMinus />
                    </SeatMapEditButton>
                  </SeatDrowRightButtons>
                )}
              </SeatGridAndEditButtions_right>
            </SeatGridAndEditButtions>
            <Blackboard>칠판</Blackboard>
          </SeatMapBox>
        </SeatMapContainer>
      </DrawBox_Main>
    </>
  );
};

export default SeatSetting;
