import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/commonStyle';

const SmallSwitchContainer = styled.div`
  display: inline-block;
  width: 40px; /* 이전보다 작게 설정 */
  height: 20px;
`;

const SmallSwitchLabel = styled.div`
  width: 100%;
  height: 100%;
  ${colors}
  background-color: ${(props) =>
    props.$isOn ? `${colors.lightGreen}` : '#bbb'}; /* 새로운 색상으로 변경 */
  border-radius: 20px; /* 작은 크기에 맞게 둥글게 */
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const SmallSwitchToggle = styled.div`
  width: 16px; /* 크기를 작게 설정 */
  height: 16px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px; /* 상단과 하단의 간격을 줄임 */
  left: ${(props) =>
    props.$isOn ? '22px' : '2px'}; /* 토글의 위치를 크기에 맞게 조정 */
  transition: left 0.3s;
`;

function SmallSwitch({ $isOn, handleToggle }) {
  return (
    <SmallSwitchContainer onClick={handleToggle}>
      <SmallSwitchLabel $isOn={$isOn}>
        <SmallSwitchToggle $isOn={$isOn} />
      </SmallSwitchLabel>
    </SmallSwitchContainer>
  );
}

export default SmallSwitch;
