import { useContext } from 'react';
import GradeContext from '../../contexts/grade';
import {
  ProgressNoteByClassCardLayout1,
  ProgressNoteCardLayout1,
} from '../progress/ProgressNoteStyle';
import { MdAdd } from 'react-icons/md';
import {
  useLocation,
  useNavigate,
} from '../../../node_modules/react-router-dom/dist/index';
import { CardTitle, CardTitleInner } from '../../components/StyledCard';
import { RiDeleteBin6Line } from 'react-icons/ri';

const AllClassModeHeader = ({ classNum, deleteProgressNote }) => {
  //현재 학년, user context 받아오기
  const { state: gradeState } = useContext(GradeContext);
  const grade = gradeState.currentGrade;

  const isMobile = window.innerWidth <= 768;
  // 페이지 이동
  const navigate = useNavigate();
  const handleClick = () => {
    if (!isMobile) {
      navigate(`/progressnote/byclass/${classNum}`);
    }
  };
  const location = useLocation();

  return (
    <>
      {' '}
      <CardTitle>
        {location.pathname == '/progressnote/byclass/' ? (
          <CardTitleInner onClick={handleClick}>
            {grade}학년 {classNum}반
          </CardTitleInner>
        ) : (
          <>
            {grade}학년 {classNum}반
          </>
        )}
      </CardTitle>
    </>
  );
};

export default AllClassModeHeader;
