import { BlogWritePageContainer } from './BlogWriteStyle';
import PageContainer from '../../pageLayout/PageContainer';
import BlogWriteContainer from './BlogWriteContainer';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';

const BlogWritePage = () => {
  return (
    <PageContainer>
      <PageSubheader>
        <ArticleMenuBar />
      </PageSubheader>

      <PageBox>
        <BlogWriteContainer />
      </PageBox>
    </PageContainer>
  );
};

export default BlogWritePage;
