import {
  FooterContainer,
  FooterLeft,
  FooterRight,
  FooterSnsImg,
} from './FooterStyle';
import sns01 from '../img/sns01.png';
import sns02 from '../img/sns02.png';
import sns03 from '../img/sns03.png';
import sns04 from '../img/sns04.png';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLeft></FooterLeft>
      <FooterRight>
        {/* <p>서비스 이용약관 ｜ 개인정보 처리방침</p> */}
        <p>
          (주)티처노트 ｜ 대표 박진현 ｜ 서비스 이용약관 ｜ 개인정보 처리방침{' '}
        </p>
        <p>
          주소 : 서울 성동구 아차산로7길 15-1 제이제이빌딩 ｜ 문의:
          teachernoteofficial@gmail.com
        </p>
        <p>
          <a
            href="https://blog.naver.com/teachernote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterSnsImg src={sns04} />
          </a>
          <a
            href="https://instagram.com/ourteachernote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterSnsImg src={sns02} />
          </a>
          <a
            href="https://www.youtube.com/@teachernote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterSnsImg src={sns03} />
          </a>
          {/* <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
          <FooterSnsImg src={sns01} />
          </a> */}
        </p>
        <p>Copyright ⓒ 2024 Teachernote Inc. All rights reserved.</p>
      </FooterRight>
    </FooterContainer>
  );
};

export default Footer;
