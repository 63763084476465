import { useContext } from 'react';
import AllClassMode from './AllClassMode';
import AllClassModeHeader from './AllClassModeHeader';
import {
  NoProgressNoteDiv,
  NoteContainer,
  PrimaryText,
  ProgressNoteCard,
} from '../progress/ProgressNoteStyle';
import GradeContext from '../../contexts/grade';

const OneClassModeContainer = ({
  sortedProgressNoteByGrade,
  topics,
  classInfoByGrade,
  deleteProgressNote,
  updateRecord,
  paramClassNum,
  showAddWindow,
  setShowAddWindow,
}) => {
  const { state: gradeState } = useContext(GradeContext);
  const grade = gradeState.currentGrade;

  // sortedProgressNoteByGrade를 classNum으로 오름차순으로 정렬합니다.
  const sortedNotes = sortedProgressNoteByGrade.sort(
    (a, b) => a.classNum - b.classNum,
  );

  // classNum이 같은 항목들을 묶어서 반환하는 함수를 정의합니다.
  const groupByClassNum = (notes) => {
    const grouped = {};
    notes.forEach((note) => {
      const { classNum } = note;
      if (!grouped[classNum]) {
        grouped[classNum] = [];
      }
      grouped[classNum].push(note);
    });
    return grouped;
  };

  // classNum으로 묶인 객체를 가져옵니다.
  const groupedNotes = groupByClassNum(sortedNotes);

  return (
    <>
      {' '}
      {!showAddWindow && (
        <NoteContainer>
          {paramClassNum ? (
            <ProgressNoteCard key={`class-${paramClassNum}`}>
              <AllClassModeHeader
                key={`header-${paramClassNum}`}
                classNum={paramClassNum}
              />
              {groupedNotes[paramClassNum] ? (
                groupedNotes[paramClassNum].map((note) => {
                  const topic = topics.find(
                    (topic) => topic.topicId === note.topicNum,
                  );
                  return (
                    <AllClassMode
                      key={`note-${note.id}`}
                      note={note}
                      topicTitle={
                        topic ? topic.topicTitle : '주제를 입력해주세요'
                      }
                      topicDetail={
                        topic ? topic.topicDetail : '설명을 입력해주세요'
                      }
                      deleteProgressNote={deleteProgressNote}
                      updateRecord={updateRecord}
                    />
                  );
                })
              ) : (
                <>
                  <NoProgressNoteDiv>
                    {grade}학년 {paramClassNum}반의 진도노트를 추가해보세요!
                  </NoProgressNoteDiv>
                  <PrimaryText
                    onClick={() => {
                      setShowAddWindow(true);
                    }}
                  >
                    추가하기
                  </PrimaryText>
                </>
              )}
            </ProgressNoteCard>
          ) : (
            <NoProgressNoteDiv>반을 선택해주세요!</NoProgressNoteDiv>
          )}
        </NoteContainer>
      )}
    </>
  );
};

export default OneClassModeContainer;

// import { useContext, useEffect } from 'react';
// import AllClassMode from './AllClassMode';
// import AllClassModeHeader from './AllClassModeHeader';
// import {
//   NoProgressNoteDiv,
//   ProgressNoteCard,
// } from '../progress/ProgressNoteStyle';
// import GradeContext from '../../contexts/grade';

// const OneClassModeContainer = ({
//   sortedProgressNoteByGrade,
//   topics,
//   classInfoByGrade,
//   deleteProgressNote,
//   updateRecord,
//   paramClassNum,
// }) => {
//   const { state: gradeState } = useContext(GradeContext);
//   const grade = gradeState.currentGrade;
//   // sortedProgressNoteByGrade를 classNum으로 오름차순으로 정렬합니다.
//   const sortedNotes = sortedProgressNoteByGrade.sort(
//     (a, b) => a.classNum - b.classNum,
//   );
//   // console.log(JSON.stringify(sortedNotes));

//   // classNum이 같은 항목들을 묶어서 반환하는 함수를 정의합니다.
//   const groupByClassNum = (notes) => {
//     const grouped = {};
//     notes.forEach((note) => {
//       const { classNum } = note;
//       if (!grouped[classNum]) {
//         grouped[classNum] = [];
//       }
//       grouped[classNum].push(note);
//     });
//     return grouped;
//   };

//   // classNum으로 묶인 객체를 가져옵니다.
//   const groupedNotes = groupByClassNum(sortedNotes);

//   return (
//     <>
//       {paramClassNum ? (
//         <ProgressNoteCard key={`class-${paramClassNum}`}>
//           <AllClassModeHeader
//             key={`header-${paramClassNum}`}
//             classNum={paramClassNum}
//           />
//           {groupedNotes[paramClassNum] ? (
//             groupedNotes[paramClassNum].map((note) => (
//               <AllClassMode
//                 key={`note-${note.id}`}
//                 note={note}
//                 topicTitle={
//                   topics.find((topic) => topic.topicId === note.topicNum)
//                     .topicTitle
//                 }
//                 topicDetail={
//                   topics.find((topic) => topic.topicId === note.topicNum)
//                     .topicDetail
//                 }
//                 deleteProgressNote={deleteProgressNote}
//                 updateRecord={updateRecord}
//               />
//             ))
//           ) : (
//             <NoProgressNoteDiv>
//               {grade}학년 {paramClassNum}반의 진도노트를 추가해보세요!
//             </NoProgressNoteDiv>
//           )}
//         </ProgressNoteCard>
//       ) : (
//         <NoProgressNoteDiv>반을 선택해주세요!</NoProgressNoteDiv>
//       )}
//     </>
//   );
// };

// export default OneClassModeContainer;
