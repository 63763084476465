import { HelmetContent } from '../../components/HelmetContent';
import HeaderMenuBar from '../../pageLayout/HeaderMenuBar';
import PageContainer from '../../pageLayout/PageContainer';
import {
  PageContainerBox,
  PageHeader,
} from '../../pageLayout/PageContainerStyle';
import { pageWidth } from '../../styles/commonStyle';

const UserCommentPage = () => {
  return (
    <PageContainer>
      <HelmetContent />

      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeoEHQRhUYWfQlczq_pmE6de980K8LZQyinNTzJtwo-5xsMtg/viewform?embedded=true"
        width="100%"
        height="1188"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        로드 중…
      </iframe>
    </PageContainer>
  );
};

export default UserCommentPage;
