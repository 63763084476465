import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'; // useLocation 훅을 추가로 임포트

import {
  EditTableContainer,
  SeatStudentSetting_Table,
  SeatStudentSetting_Table_Container,
  SeatStudentSetting_Tbody,
  SeatStudentSetting_Td,
  SeatStudentSetting_Th,
  SeatStudentSetting_Th_X,
  SeatStudentSetting_Thead,
  StudentInfoCell,
  TableTitleTr,
  TextBackground_Female,
  TextBackground_Green,
  TextBackground_Male,
} from './StudentSettingStyle';

import {
  DrawBox_Bottom,
  DrawBox_Main,
  DrawBox_Title,
  DrawBox_Title_Text,
  OneByOneButton,
  TextEmphasis,
} from '../OneByOneStyle';
import SelectFrontNum from './SelectFrontNum';
import {
  DropdownMenu,
  MenuContainer,
  MenuItem,
} from '../../../components/DropdownMenu';
import { FiMoreVertical } from 'react-icons/fi';
import Tooltip, { TooltipInner } from '../../../components/Tooltip';
import { BsXLg } from 'react-icons/bs';
import {
  SeatDrawSettingButtonContainer,
  SeatDrawSettingContent,
} from '../3-1seatSetting/SeatSettingStyle';
import SmallSwitch from '../../../components/SmallSwitch';
import { IoMdAdd } from 'react-icons/io';
import { FiMinus } from 'react-icons/fi';
import LeftTooltip from '../../../components/LeftTooltip';
import UserContext from '../../../contexts/userContext';

const StudentSetting = ({
  setGenderSelectionMode,
  numberList,
  toggleNumberUsage,
  addNumber,
  handleMouseEnter,
  handleNumberClick,
  notUsingNumbers,
  setNotUsingNumbers,
  localGenderMap,
  genderMap,
  saveStudentInfo,
  fetchStudentInfo,
  resetStudentInfo,
  frontNums,
  setFrontNums,
  setIsDrawSettingOpen,
  genderMode,
  setGenderMode,
  removeLastNumber,
  seats,
  blockedSeats,
  handleGenderClick,
  toggleAllGenders,
  maleSeatsNum,
  femaleSeatsNum,
  availableSeats,
  actualStudentCount,
  femaleCount,
  maleCount,
  setIsDragging,
  adjacentNums,
  cornerNums,
  setAdjacentNums,
  setCornerNums,
  preAssigningSeats,
  setPreAssigningSeats,
  seatPreAssignments,
  setSeatPreAssignments,
  openRegisterModal,
  loadFrontNums,
}) => {
  const [isEditMode, setIsEditMode] = useState(true);
  const [isMoreStudentSettingOpen, setIsMoreStudentSettingOpen] =
    useState(false);
  const [isEditFrontNumsMode, setIsEditFrontNumsMode] = useState(true);
  const [isEditNotUsingNumsMode, setIsEditNotUsingNumsMode] = useState(true);

  // 현재 경로를 가져오기 위해 useLocation 사용
  const location = useLocation();
  const isDrawSettingPath = location.pathname === '/drawsetting'; // 현재 경로가 /drawsetting 인지 확인

  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  // DropdownMenu 바깥쪽 클릭 시 닫히도록 하는 효과
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMoreStudentSettingOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // isEditNotUsingNumsMode가 false로 변경될 때 notUsingNumbers를 초기화
  useEffect(() => {
    if (!isEditNotUsingNumsMode) {
      setNotUsingNumbers([]);
    }
  }, [isEditNotUsingNumsMode, setNotUsingNumbers]);

  const renderTable = (numbers, index, showAddButton = false) => {
    const columnCount = Math.max(numbers.length, 15); // 열 수를 15개로 고정

    return (
      <SeatStudentSetting_Table_Container key={index}>
        <SeatStudentSetting_Table>
          <SeatStudentSetting_Thead>
            <tr>
              {numbers.map((number, index) => (
                <SeatStudentSetting_Th
                  key={index}
                  $columnCount={columnCount} // 열 수를 전달
                  onClick={() => {
                    if (isEditNotUsingNumsMode && number !== '') {
                      toggleNumberUsage(number);
                    }
                  }}
                >
                  {isEditNotUsingNumsMode ? (
                    <Tooltip
                      text={
                        notUsingNumbers.includes(number)
                          ? '번호를 사용하려면 클릭!'
                          : '(전학생)사용하지 않을 번호는 번호 클릭!'
                      }
                    >
                      {notUsingNumbers.includes(number) ? (
                        <SeatStudentSetting_Th_X>
                          <BsXLg size={20} />
                        </SeatStudentSetting_Th_X>
                      ) : (
                        number
                      )}
                    </Tooltip>
                  ) : (
                    number
                  )}
                </SeatStudentSetting_Th>
              ))}
              {/* 여백 추가 */}
              {Array.from({ length: 15 - numbers.length }).map((_, index) => (
                <SeatStudentSetting_Th
                  key={`empty-${index}`}
                  $columnCount={columnCount}
                />
              ))}
            </tr>
          </SeatStudentSetting_Thead>
          {genderMode ? (
            <SeatStudentSetting_Tbody>
              <tr>
                {numbers.map((number, index) => (
                  <SeatStudentSetting_Td
                    key={index}
                    $columnCount={columnCount}
                    onMouseEnter={(e) =>
                      e.buttons === 1 &&
                      number !== '' &&
                      !notUsingNumbers.includes(number) &&
                      handleMouseEnter(number)
                    }
                    onClick={() =>
                      number !== '' &&
                      !notUsingNumbers.includes(number) &&
                      handleNumberClick(number)
                    }
                    $isDisabled={notUsingNumbers.includes(number)}
                    $gender={localGenderMap[number]}
                  >
                    {notUsingNumbers.includes(number) ? (
                      <StudentInfoCell
                        $isNotUsing={true}
                        onClick={() => {
                          handleGenderClick(number);
                        }}
                      >
                        <BsXLg size={20} />
                      </StudentInfoCell>
                    ) : (
                      <Tooltip text="성별을 한명씩 바꾸려면 클릭">
                        <StudentInfoCell
                          $isNotUsing={false}
                          onClick={() => {
                            handleGenderClick(number);
                          }}
                        >
                          {localGenderMap[number] || '성별없음'}
                        </StudentInfoCell>
                      </Tooltip>
                    )}
                  </SeatStudentSetting_Td>
                ))}
                {Array.from({ length: 15 - numbers.length }).map((_, index) => (
                  <SeatStudentSetting_Td
                    key={`empty-${index}`}
                    $columnCount={columnCount}
                  />
                ))}
              </tr>
            </SeatStudentSetting_Tbody>
          ) : (
            <SeatStudentSetting_Tbody>
              {/* 빈 tbody */}
            </SeatStudentSetting_Tbody>
          )}
        </SeatStudentSetting_Table>
      </SeatStudentSetting_Table_Container>
    );
  };

  const chunkedNumberList = [];
  const chunkSize = 15;
  for (let i = 0; i < numberList.length; i += chunkSize) {
    chunkedNumberList.push(numberList.slice(i, i + chunkSize));
  }

  //-------------------- 불러오기 기능 --------------------//

  const handleLoadStudentInfo = () => {
    if (userId) {
      fetchStudentInfo();
      loadFrontNums();
    } else {
      openRegisterModal();
    }
    setIsMoreStudentSettingOpen(!isMoreStudentSettingOpen);
  };
  return (
    <>
      <DrawBox_Title>
        <DrawBox_Title_Text>학생 설정 </DrawBox_Title_Text>
        <MenuContainer ref={dropdownRef}>
          <FiMoreVertical
            onClick={() => {
              setIsMoreStudentSettingOpen(!isMoreStudentSettingOpen);
            }}
          />
          <DropdownMenu $isOpen={isMoreStudentSettingOpen}>
            <MenuItem
              onClick={() => {
                resetStudentInfo();
                setIsMoreStudentSettingOpen(!isMoreStudentSettingOpen);
              }}
            >
              리셋
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLoadStudentInfo();
              }}
            >
              불러오기
            </MenuItem>
          </DropdownMenu>
        </MenuContainer>
      </DrawBox_Title>
      <DrawBox_Main>
        <SeatDrawSettingButtonContainer>
          <div>
            <Tooltip text="(전학생)사용하지 않을 번호는 번호 클릭!">
              <SeatDrawSettingContent
                onClick={() => {
                  setIsEditNotUsingNumsMode(!isEditNotUsingNumsMode);
                }}
              >
                <SmallSwitch
                  $isOn={isEditNotUsingNumsMode}
                  handleToggle={() => {
                    setIsEditNotUsingNumsMode(!isEditNotUsingNumsMode);
                  }}
                />
                전학생 설정(사용하지 않는 번호)
              </SeatDrawSettingContent>
            </Tooltip>
          </div>
          <div>
            <Tooltip text="성별을 한명씩 바꾸려면 클릭">
              <SeatDrawSettingContent
                onClick={() => {
                  setGenderMode(!genderMode);
                }}
              >
                <SmallSwitch
                  $isOn={genderMode}
                  handleToggle={() => {
                    setGenderMode(!genderMode);
                  }}
                />
                성별 설정
              </SeatDrawSettingContent>
            </Tooltip>
          </div>
          {/* {!isDrawSettingPath && ( // /drawsetting 경로가 아닐 때만 "앞에 앉을 학생 지정" 기능을 표시 */}
          <div>
            <Tooltip
              text="시력이 좋지 않은 학생은 
                앞 2줄에서 랜덤으로 배치가능!"
            >
              <SeatDrawSettingContent
                onClick={() => {
                  setIsEditFrontNumsMode(!isEditFrontNumsMode);
                }}
              >
                <SmallSwitch
                  $isOn={isEditFrontNumsMode}
                  handleToggle={() => {
                    setIsEditFrontNumsMode(!isEditFrontNumsMode);
                  }}
                />
                앞에 앉을 학생 지정
              </SeatDrawSettingContent>
            </Tooltip>
          </div>
          {/* )} */}
        </SeatDrawSettingButtonContainer>
        <EditTableContainer>
          {genderMode ? (
            <LeftTooltip text="전체 학생 남/여 전환">
              {' '}
              <OneByOneButton
                onClick={() => {
                  toggleAllGenders();
                }}
              >
                남녀 성별 전체 바꾸기
              </OneByOneButton>
            </LeftTooltip>
          ) : (
            ''
          )}
        </EditTableContainer>
        <SeatStudentSetting_Table>
          <thead>
            <TableTitleTr>
              <td>학생 번호 {genderMode ? '및 성별' : ''}</td>
            </TableTitleTr>
          </thead>
        </SeatStudentSetting_Table>
        {chunkedNumberList.map((numbers, index) =>
          renderTable(numbers, index === chunkedNumberList.length - 1),
        )}
        <EditTableContainer>
          <div>
            <LeftTooltip text="우리반 학생수에 맞춰 번호를 추가, 삭제하려면 클릭!">
              <>
                <OneByOneButton onClick={() => removeLastNumber()}>
                  번호 삭제
                  <FiMinus />
                </OneByOneButton>
                <OneByOneButton onClick={() => addNumber()}>
                  번호 추가
                  <IoMdAdd />
                </OneByOneButton>
              </>
            </LeftTooltip>
          </div>
        </EditTableContainer>{' '}
        {/* {!isDrawSettingPath && ( */}
        <>
          {isEditFrontNumsMode && (
            <SelectFrontNum
              numberList={numberList}
              frontNums={frontNums}
              setFrontNums={setFrontNums}
              notUsingNumbers={notUsingNumbers}
              adjacentNums={adjacentNums}
              cornerNums={cornerNums}
              setAdjacentNums={setAdjacentNums}
              setCornerNums={setCornerNums}
              preAssigningSeats={preAssigningSeats}
              setPreAssigningSeats={setPreAssigningSeats}
              seatPreAssignments={seatPreAssignments}
              setSeatPreAssignments={setSeatPreAssignments}
            />
          )}
        </>
        {/* )} */}
      </DrawBox_Main>
      <DrawBox_Bottom>
        <div>
          자리 <TextEmphasis>{availableSeats}</TextEmphasis>개
          {genderMode ? (
            <>
              (
              <TextBackground_Female>
                여자 자리 {femaleSeatsNum}
              </TextBackground_Female>
              <TextBackground_Male>
                남자 자리 {maleSeatsNum}
              </TextBackground_Male>
              )
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          학생수 <TextEmphasis>{actualStudentCount}</TextEmphasis>명
          {genderMode ? (
            <>
              (<TextBackground_Female>여자 {femaleCount}</TextBackground_Female>
              <TextBackground_Male>남자 {maleCount}</TextBackground_Male>
              {actualStudentCount > femaleCount + maleCount && (
                <TextBackground_Green>
                  성별없음 {actualStudentCount - femaleCount - maleCount}
                </TextBackground_Green>
              )}
              )
            </>
          ) : (
            <></>
          )}
        </div>
      </DrawBox_Bottom>
    </>
  );
};

export default StudentSetting;
