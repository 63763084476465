import { useContext, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
} from '../../node_modules/react-router-dom/dist/index';
import {
  GradeMenu,
  MenuItemsContainer,
  MobileMenuBar,
  ArticleMenubarSmall,
  ArticleMenubarLarge,
  ArticleMenuItemsContainer,
  ColorText,
} from './MobileTopMenuBarStyle';
import axios from '../../node_modules/axios/index';
import UserContext from '../contexts/userContext';

const MobileTopMenuBarArticle = ({ handleSubmit }) => {
  const location = useLocation();
  const { state: userState } = useContext(UserContext);
  const userId = userState.user ? userState.user.userId : null;

  const navigate = useNavigate();
  const goList = () => {
    navigate('/bloglist');
  };
  const goWrite = () => {
    if (userId) {
      navigate('/blogwrite');
    } else {
      navigate('/register');
    }
  };

  return (
    <MobileMenuBar>
      <ArticleMenuItemsContainer>
        <ArticleMenubarSmall onClick={goList}>리스트</ArticleMenubarSmall>
        {/* <ArticleMenubarLarge>전체보기</ArticleMenubarLarge> */}

        {location.pathname === '/blogwrite' ? (
          <ArticleMenubarSmall onClick={handleSubmit}>
            <ColorText>올리기</ColorText>
          </ArticleMenubarSmall>
        ) : (
          <ArticleMenubarSmall onClick={goWrite}>
            <ColorText>글쓰기</ColorText>
          </ArticleMenubarSmall>
        )}
      </ArticleMenuItemsContainer>
    </MobileMenuBar>
  );
};
export default MobileTopMenuBarArticle;
